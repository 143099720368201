import axios from "axios"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { getOrgID } from "@routes"
import { accessTokenSelector } from "@selectors/auth"
import { addAuth } from "@lib/auth"
import { handleError } from "@utils/errorHandling"
import { ServiceAccount, CreateServiceAccountResponse } from "./types"
import { QUERYKEYS } from "@lib/queryKeys"

// query all service accounts
export function useServiceAccounts() {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)

  const { data, ...rest } = useQuery(
    QUERYKEYS.svc_accounts.all(orgID),
    () => axios.get<ServiceAccount[]>(`/api/v1/org/${orgID}/svc_accounts`, addAuth(token)),
    { onError: (error: Error) => handleError(error), enabled: true, refetchOnWindowFocus: false },
  )

  return {
    svcAccounts: data?.data,
    ...rest,
  }
}

// create a service account
export function useCreateServiceAccount() {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)
  const queryClient = useQueryClient()

  const { isSuccess, isError, error, mutate, isLoading, data } = useMutation(
    (serviceAccountName: string) =>
      axios.post<CreateServiceAccountResponse>(`/api/v1/org/${orgID}/svc_accounts`, { name: serviceAccountName }, addAuth(token)),
    {
      onError: (error: Error) => handleError(error),
      onSuccess: () => {
        // invalidate the list query so it refetches
        queryClient.invalidateQueries(QUERYKEYS.svc_accounts.all(orgID))
      },
    },
  )
  return { isSuccess, isError, error, mutate, isLoading, data: data?.data }
}

// delete a service account
export function useDeleteServiceAccount() {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)
  const queryClient = useQueryClient()

  const { isSuccess, isError, error, mutate, isLoading } = useMutation(
    (accountID: string) => axios.delete<CreateServiceAccountResponse>(`/api/v1/org/${orgID}/svc_accounts/${accountID}`, addAuth(token)),
    {
      onError: (error: Error) => handleError(error),
      onSuccess: () => {
        // invalidate the list query so it refetches
        queryClient.invalidateQueries(QUERYKEYS.svc_accounts.all(orgID))
      },
    },
  )
  return { isSuccess, isError, error, mutate, isLoading }
}
