import * as x509 from "@fidm/x509"
import k8s from "@kubernetes/client-node"

import { ClusterData, RuleMessage, ResourceStatus } from "../types"
import { Issuer, ClusterIssuer } from "../cert-manager"
import { Gateway } from "../istio"
import { handleError } from "@utils/errorHandling"

import { Resource } from "./resource"

type Identity = {
  key: string
  value: string
}
export abstract class Certificate extends Resource {
  usages: CertificateUsage[]
  secret?: {
    resource: k8s.V1Secret
    rule_messages: RuleMessage[]
  }
  status: ResourceStatus
  clusterId: string
  deleted_at?: string

  constructor(data: ClusterData, id: string) {
    super()
    // usage is established through the resources linked to the secret rather than certificate
    this.usages = this.discoverUsages(data, id)
    const item = data.resource_index[id]
    this.status = item.status ? item.status : { type: "unknown", message: "Unknown" }
    this.deleted_at = item.deleted_at
    this.clusterId = data.cluster_id
  }

  // decode will decoded the PEM certificate into a pki.Certificate
  decode(): x509.Certificate[] {
    const certs: x509.Certificate[] = []
    const secret = this.resource.status?.certificate ? this.resource.status?.certificate : this.secret?.resource.data?.["tls.crt"]
    const Buffer = require("buffer/").Buffer
    if (secret) {
      try {
        const cert: x509.Certificate[] = x509.Certificate.fromPEMs(Buffer.from(atob(secret)))
        certs.push(...cert)
      } catch (error) {
        handleError(error, `Cannot decode certificate '${this.id}'`)
      }
    }
    return certs
  }

  // Extracts the identities currently from the subject and alternative names

  getIdentities(): Identity[] {
    const decodedCerts = this.decode()
    const cert = decodedCerts.length > 0 ? decodedCerts[0] : undefined
    if (cert) {
      const subjects = cert.subject.attributes
        .map(
          attr =>
            attr && {
              key: attr.name,
              value: attr.value,
            },
        )
        .filter(i => Boolean(i))

      const altNames = getCertificateAlternativeNames(cert)
      return [...subjects, ...altNames]
    }
    return []
  }

  // issuers will return the string array containing the issuers (organizationName/commonName)
  issuers(): string[] {
    const certs = this.decode()
    const issuers = certs.map((cert: x509.Certificate) => {
      const organizationName = cert.issuer.getField("O")?.value
      const commonName = cert.issuer.getField("CN")?.value
      if (organizationName) {
        return `${organizationName} / ${commonName}`
      }
      return commonName
    })
    return issuers
  }

  // finds all usages of the certificate in a k8s.Secret
  discoverUsages(data: ClusterData, secretResourceKey: string): CertificateUsage[] {
    const usages: CertificateUsage[] = []
    data.resource_graph[secretResourceKey]?.forEach(consumerID => {
      const consumer = data.resource_index[consumerID]
      switch (consumer.resource.kind) {
        case "Pod":
          usages.push({
            resource: consumer.resource as k8s.V1Pod,
          })
          break
        case "Ingress":
          usages.push({
            resource: consumer.resource as k8s.V1Ingress,
          })
          break
        case "Issuer":
          usages.push({
            resource: consumer.resource as Issuer,
          })
          break
        case "ClusterIssuer":
          usages.push({
            resource: consumer.resource as ClusterIssuer,
          })
          break
        case "Gateway":
          usages.push({
            resource: consumer.resource as Gateway,
          })
          break
      }
    })
    return usages
  }
}

type CertificateName = {
  key: string
  value: string
}

// getCertificateAlternativeNames gets all the altNames in a certificate
export const getCertificateAlternativeNames = (cert?: x509.Certificate): CertificateName[] => {
  if (cert) {
    type AltName = {
      dnsName: string
      email: string
      ip: string
      uri: string
      oid: string
    }

    const altNames: AltName[] = cert.getExtension("subjectAltName", "altNames")
    if (altNames) {
      return altNames.map(({ dnsName, email, ip, uri, oid }) => {
        let key = "Unknown"
        let value = "Unknown"
        if (dnsName) {
          key = "DNS Name"
          value = dnsName
        }
        if (email) {
          key = "Email"
          value = email
        }
        if (ip) {
          key = "IP"
          value = ip
        }
        if (uri) {
          key = "URI"
          value = uri
        }
        if (oid) {
          key = "OID"
          value = oid
        }

        return { key, value }
      })
    }
    return []
  }
  return []
}

export interface CertificateUsage {
  resource: k8s.V1Pod | k8s.V1Ingress | Issuer | ClusterIssuer | Gateway
}
