import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { fetchCertInventory } from "@actions/certInventory"
import { fetchClusters } from "@actions/clusters"
import { getOrgID } from "@routes"
import { certInventorySelector } from "@selectors/certInventory"
import { clustersWithCertInventorySelector } from "@selectors/clusters"
import { clustersNetworkSelector } from "@selectors/clustersNetwork"

// The skip property is for this hook to not load all certificates in cases
// when the page doesn't need it like Certificates page when its only required
// to load the certInventory for a single cluster

const useGetAllCertInventories = (skip = false) => {
  const orgID = getOrgID(useParams())
  const dispatch = useDispatch()
  const clusters = useSelector(clustersWithCertInventorySelector)
  const certInventory = useSelector(certInventorySelector)

  const { isLoading, loadingError } = useSelector(clustersNetworkSelector)

  const hasLoadedCertInventory =
    !isLoading &&
    (clusters.length > 0
      ? Object.keys(certInventory).length > 0 || !Object.keys(certInventory).some(key => certInventory[key].isLoading)
      : true)

  useEffect(() => {
    if (clusters.length < 1) {
      dispatch(fetchClusters(orgID))
    }
  }, [orgID, skip])

  useEffect(() => {
    if (!clusters || clusters.length === 0 || skip) {
      return
    }
    clusters.map(c => {
      const certInventoryForCluster = certInventory[c.cluster]
      // Only fetch cert inventory info for cluster if it is not already in
      // state and it is not loading and there wasn't a previous error.
      const requireNewClusterFetch =
        !certInventoryForCluster ||
        (!certInventoryForCluster.certInventory && !certInventoryForCluster.isLoading && certInventoryForCluster.loadingError === "")

      if (requireNewClusterFetch) {
        dispatch(fetchCertInventory(orgID, c.cluster, Boolean(c.isDemoData)))
      }
    })
  }, [clusters, certInventory, skip])

  return {
    orgID,
    clusters,
    certInventory,
    isLoading: skip ? false : !hasLoadedCertInventory,
    loadingError,
  }
}

export { useGetAllCertInventories }
