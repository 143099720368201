import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Paper, createStyles, makeStyles, Theme } from "@material-ui/core"

import { rudderanalytics as rudderstack } from "@utils/rudderstack"
import { getOrgID, pathToCertInventory, pathToOverview } from "@routes"
import { useSupportsMultiClusterSelector } from "@hooks/useSupportsMultiCluster"

import { Page } from "@components/Page"
import { Breadcrumbs, BreadcrumbsNavItem } from "@components/Navigation/Breadcrumbs"
import { InstallCLI, INSTALL_CLI } from "./InstallCLI"
import { LoginConfigureCLI, LOGIN_AND_CONFIG_CLI } from "./LoginConfigureCLI"
import { NameAndConnectCluster, NAME_AND_CONNECT_CLUSTER } from "./NameAndConnectCluster"
import { CheckClusterConnection, CHECK_CLUSTER_CONNECTION } from "./CheckClusterConnection"
import { DeployOperator, DEPLOY_OPERATOR } from "./DeployOperator"
import { ComponentInstallation, COMPONENT_INSTALLATION } from "./ComponentInstallation"
import { useSteps } from "./hooks/useSteps"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      margin: theme.spacing(2, "auto", "auto", "auto"),
      padding: theme.spacing(4),
      width: "100%",
      "& p": {
        "& code": {
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.3, 0.5),
          fontFamily: "monospace",
        },
      },
    },
    disabled: {
      pointerEvents: "none",
      opacity: 0.6,
    },
    divider: {
      width: "100%",
      height: 1,
      background: theme.palette.grey[200],
      margin: theme.spacing(4, 0, 2, 0),
    },
  }),
)

const steps = [
  INSTALL_CLI,
  LOGIN_AND_CONFIG_CLI,
  NAME_AND_CONNECT_CLUSTER,
  CHECK_CLUSTER_CONNECTION,
  DEPLOY_OPERATOR,
  COMPONENT_INSTALLATION,
]

export function ConnectCluster() {
  const classes = useStyles()

  const orgID = getOrgID(useParams())

  const [clusterName, setClusterName] = useState("")
  const { showMultiCluster, supportsMultiCluster } = useSupportsMultiClusterSelector()

  const { setStep, currentStep } = useSteps(steps)

  useEffect(() => {
    rudderstack.page("Add cluster")
  })

  const getParentCrumbs = (): BreadcrumbsNavItem[] => {
    const clusterBreadCrumb = {
      label: showMultiCluster ? "Clusters" : "Cluster",
      href: pathToCertInventory(orgID),
    }
    return supportsMultiCluster
      ? [
          {
            label: "Overview",
            href: pathToOverview(orgID),
          },
          clusterBreadCrumb,
        ]
      : [clusterBreadCrumb]
  }

  return (
    <Page title="Connect a cluster" reqAuth fixedWidth breadcrumbs={<Breadcrumbs current="Connect Cluster" parents={getParentCrumbs()} />}>
      <Paper className={classes.panel}>
        <InstallCLI currentStep={currentStep} setStep={setStep} />
        <div className={classes.divider}></div>

        <LoginConfigureCLI orgID={orgID} currentStep={currentStep} setStep={setStep} />
        <div className={classes.divider}></div>

        <NameAndConnectCluster clusterName={clusterName} setClusterName={setClusterName} currentStep={currentStep} setStep={setStep} />
        <div className={classes.divider}></div>

        <CheckClusterConnection orgID={orgID} clusterName={clusterName} currentStep={currentStep} setStep={setStep} />
        <div className={classes.divider}></div>

        <DeployOperator currentStep={currentStep} setStep={setStep} />

        <div className={classes.divider}></div>

        <ComponentInstallation setStep={setStep} currentStep={currentStep} orgID={orgID} />
      </Paper>
    </Page>
  )
}
