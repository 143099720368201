import React from "react"

import { ClusterSelect } from "../ClusterSelect"
import { DiscoveredCertificatesFilterState } from "@lib/discoveredCertificates"

import { createStyles, makeStyles, Theme, Link } from "@material-ui/core"
import { ExpirySlider } from "../ExpirySlider"

export type FilterProps = { [x: string]: string }

type Props = {
  filterState: DiscoveredCertificatesFilterState
  isDisabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(2, 0, 2, 0),
      display: "flex",
      flexDirection: "column",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    slider: {
      display: "flex",
      flex: 1,
      margin: theme.spacing(0, 0, 0, 3),
      flexDirection: "column",
    },
    link: {
      cursor: "pointer",
    },
  })
})

export const FiltersBar = ({ filterState, isDisabled = false }: Props) => {
  const classes = useStyles()
  const onResetFilters = () => {
    filterState.setCluster("")
    filterState.setExpiryIn(100)
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <ClusterSelect filterState={filterState} isDisabled={isDisabled} />
        <ExpirySlider filterState={filterState} />
      </div>

      <Link className={classes.link} variant="body2" onClick={onResetFilters}>
        Reset all filters
      </Link>
    </div>
  )
}
