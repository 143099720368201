import React, { useState } from "react"
import axios, { AxiosResponse } from "axios"
import { Typography, createStyles, makeStyles, Theme, Button, Dialog, TextField, FormHelperText } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"

import { LoadingMessage } from "@components/LoadingMessage"
import { handleError } from "@utils/errorHandling"
import { addAuth } from "@lib/auth"
import { PullSecretInstall } from "../PullSecretInstall"
import { ServiceAccount } from ".."

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(2),
      fontSize: "1.8rem",
    },
    dialog: {
      padding: theme.spacing(4),
      position: "relative",
      maxWidth: "80rem",
    },
    serviceAccountInput: {
      minWidth: "20rem",
      marginBottom: theme.spacing(1),
    },
    error: {
      color: theme.palette.error.main,
    },
    closeBtn: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    button: {
      margin: theme.spacing(0, 2),
    },
  }),
)

type CreateModalProps = {
  isOpen: boolean
  onClose: () => void
  reload: () => void
  orgID: string
  token: string
}

type ServiceAccountWithKey = {
  key: {
    privateData: string
    dockerConfig: string
  }
} & ServiceAccount

export function CreateModal({ isOpen, onClose, orgID, token, reload }: CreateModalProps) {
  const classes = useStyles()
  const [PIKValue, setPIKValue] = useState("")
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [b64Dockercfg, setB64Dockercfg] = useState<string | undefined>(undefined)

  const createServiceAccount = async () => {
    setError("")
    if (PIKValue.length < 1) {
      setError("You need to enter a value.")
      return
    }

    setIsLoading(true)
    try {
      const { data } = await axios.post<unknown, AxiosResponse<ServiceAccountWithKey[]>>(
        `/subscription/api/v1/org/${orgID}/svc_accounts`,
        [{ displayName: PIKValue }],
        addAuth(token),
      )
      setB64Dockercfg(data[0].key.dockerConfig)
      reload()
    } catch (error) {
      setError("Failed to create: " + error)
      handleError(error, "Failed to create")
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPIKValue(event.target.value)
    setError("")
  }

  const handleClose = () => {
    onClose()
    setB64Dockercfg(undefined)
    setPIKValue("")
  }

  return (
    <Dialog
      maxWidth={false}
      scroll="paper"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.dialog}>
        <CancelIcon className={classes.closeBtn} onClick={handleClose} data-testid="alerts-close-button" />
        <Typography id="alert-dialog-title" variant="h4" className={classes.title}>
          Create a key to access private images
        </Typography>
        <>
          <TextField
            className={classes.serviceAccountInput}
            label="Insert a name for your key"
            variant="outlined"
            value={PIKValue}
            onChange={handleChange}
            type="text"
            size="small"
            error={Boolean(error)}
            inputProps={{
              "data-testid": "team-email-input",
            }}
            disabled={!!b64Dockercfg || isLoading}
          />
          {isLoading ? (
            <LoadingMessage label="creating key..." />
          ) : !!b64Dockercfg ? (
            <div>
              <Typography id="alert-dialog-title" gutterBottom>
                Store this key securely as this key cannot be recovered if lost. You can always delete it and create a new one:
              </Typography>
              <PullSecretInstall b64Dockercfg={b64Dockercfg} name={PIKValue} />
            </div>
          ) : (
            <Button variant="contained" color="primary" onClick={createServiceAccount} className={classes.button} disabled={!!b64Dockercfg}>
              Create image pull secret
            </Button>
          )}
          {error && <FormHelperText className={classes.error}>{error}</FormHelperText>}
        </>
      </div>
    </Dialog>
  )
}
