import React, { useCallback, useEffect, useState } from "react"
import { Typography, createStyles, makeStyles, Theme, Button, Dialog, TextField, FormHelperText } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import { LoadingMessage } from "@components/LoadingMessage"
import { useGetQueryString } from "@hooks/useGetQueryString"
import { useCreateServiceAccount } from "@lib/serviceAccounts"
import { isValidServiceAccountName } from "@utils/validation"
import { useGetCurrentOrg } from "@hooks/useGetCurrentOrg"
import { Credentials, CredentialsFormat, FormatFields } from "./Credentials"

// program to generate random strings

// declare all characters
const characters = "0123456789"

function generateString(length: number) {
  let result = ""
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "1.8rem",
      marginBottom: theme.spacing(1),
    },
    dialog: {
      padding: theme.spacing(4),
      position: "relative",
      maxWidth: "80rem",
    },
    serviceAccountInput: {
      minWidth: "20rem",
      marginBottom: theme.spacing(1),
    },
    error: {
      color: theme.palette.error.main,
    },
    helperText: {
      marginBottom: theme.spacing(2),
      fontSize: "1rem",
    },
    closeBtn: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    button: {
      margin: theme.spacing(0, 2),
    },
  }),
)

type CreateModalProps = {
  isOpen: boolean
  onClose: () => void
  svcAccountNames?: string[]
}

export function CreateModal({ isOpen, onClose, svcAccountNames }: CreateModalProps) {
  const classes = useStyles()
  const currentOrg = useGetCurrentOrg()
  const [svcName, setSvcName] = useState("")
  const [svcNameError, setSvcNameError] = useState(false)
  const [svcNameInvalid, setSvcNameInvalid] = useState(false)
  const { isError, isLoading, mutate, error, isSuccess, data } = useCreateServiceAccount()
  const [formatValue, setFormatValue] = useState(FormatFields as CredentialsFormat)
  const { hasQueryString, value: credentialsFormat } = useGetQueryString("defaultFormat")

  // change behaviour based on search query
  useEffect(() => {
    if (hasQueryString && credentialsFormat) {
      setFormatValue(credentialsFormat as CredentialsFormat)
    }
  }, [credentialsFormat, hasQueryString])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSvcName(event.target.value)
  }

  const handleClose = useCallback(() => {
    setSvcName("")
    onClose()
  }, [onClose])

  const handleCreate = useCallback(() => {
    if (svcName && svcName !== "") {
      mutate(svcName)
    }
  }, [svcName, mutate])

  useEffect(() => {
    if (isSuccess) {
      setSvcName("")
    }
  }, [isSuccess])

  useEffect(() => {
    if (svcName !== "" && !isValidServiceAccountName(svcName)) {
      setSvcNameInvalid(true)
    } else {
      setSvcNameInvalid(false)
    }
    if (svcAccountNames?.includes(svcName)) {
      setSvcNameError(true)
    } else {
      setSvcNameError(false)
    }
  }, [svcName])

  return (
    <Dialog
      maxWidth={false}
      scroll="paper"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.dialog}>
        <CancelIcon className={classes.closeBtn} onClick={handleClose} data-testid="alerts-close-button" />

        {!data && (
          <>
            <Typography id="alert-dialog-title" variant="h4" className={classes.title}>
              Create service account
            </Typography>
            <Typography color="textSecondary" className={classes.helperText}>
              Name can only contain alphanumeric values, dots and underscores
            </Typography>
            <TextField
              className={classes.serviceAccountInput}
              label="Service Account Email"
              variant="outlined"
              value={svcName}
              onChange={handleChange}
              type="text"
              size="small"
              error={Boolean(error) || svcNameError || svcNameInvalid}
              inputProps={{
                "data-testid": "service-account-input",
              }}
              disabled={isLoading}
            />
            {isLoading ? (
              <LoadingMessage label="creating service account..." />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreate}
                className={classes.button}
                disabled={isLoading || svcNameError || svcNameInvalid}
              >
                Create
              </Button>
            )}
            {svcName.length > 0 && !isLoading && !svcNameError && !svcNameInvalid && (
              <Typography color="textSecondary" className={classes.helperText}>
                {`${svcName}${generateString(10)}@${currentOrg?.id}.platform.jetstack.io`}
              </Typography>
            )}
            {isError && <FormHelperText className={classes.error}>{error?.message}</FormHelperText>}
            {svcNameError && (
              <FormHelperText className={classes.error}>There is already a service account with the same name</FormHelperText>
            )}
            {svcNameInvalid && (
              <FormHelperText className={classes.error}>Only alphanumeric values, dots and underscores allowed</FormHelperText>
            )}
          </>
        )}

        {data && (
          <>
            <Typography id="alert-dialog-title" variant="h4" className={classes.title}>
              Service account created
            </Typography>
            <Typography color="textSecondary" className={classes.helperText}>
              Store this secret key securely
            </Typography>
            <Credentials data={data} defaultFormat={formatValue} />
          </>
        )}
      </div>
    </Dialog>
  )
}
