import { get, isEmpty, isEqual } from "lodash"
import { FilterProps } from "@components/FiltersBar"
import { FilterData } from "@selectors/filters"
import { CertificateRequest } from "@lib/certificateRequests/types"

const SEARCHKEYS = ["name", "namespace", "issuerRef.kind"] as const

export const getRequestFilterOptions = (requests: CertificateRequest[] | undefined): FilterData[] => {
  if (!requests) {
    return []
  }

  const namespaceOptions: { value: string; label: string }[] = []
  const kindOptions: { value: string; label: string }[] = []

  requests?.forEach(request => {
    if (request.namespace) {
      const item = { value: request.namespace, label: request.namespace }
      if (!namespaceOptions.some(nameSpace => nameSpace.value === item.value)) {
        namespaceOptions.push(item)
      }
    }
    if (request?.issuerRef?.kind) {
      const item = { value: request?.issuerRef?.kind, label: request?.issuerRef?.kind }
      if (!kindOptions.some(kind => kind.value === item.value)) {
        kindOptions.push(item)
      }
    }
  })

  return [
    {
      id: "filterByStatus",
      label: "By status",
      default: "",
      options: [
        {
          value: "ready",
          label: "Ready",
        },
        {
          value: "error",
          label: "Error",
        },
      ],
    },
    {
      id: "filterByNamespace",
      label: "By namespace",
      default: "",
      options: namespaceOptions,
    },
    {
      id: "filterByKind",
      label: "By kind",
      default: "",
      options: kindOptions,
    },
  ]
}

export const getFilteredRequests = (requests: CertificateRequest[] | undefined, filtersState: FilterProps) => {
  if (!requests) return []
  if (isEmpty(filtersState)) return requests
  const lowSearch = filtersState?.search?.toLowerCase() || null

  const filterMethods = [
    (request: CertificateRequest) => {
      if (!lowSearch) return true
      return SEARCHKEYS.some(key => {
        return get(request, key)?.toLowerCase()?.includes(lowSearch)
      })
    },
    (request: CertificateRequest) => {
      if (!filtersState.filterByNamespace) return true
      return isEqual(get(request, "namespace"), filtersState.filterByNamespace)
    },
    (request: CertificateRequest) => {
      if (!filtersState.filterByKind) return true
      return isEqual(get(request, "issuerRef.kind"), filtersState.filterByKind)
    },
    (request: CertificateRequest) => {
      if (!filtersState.filterByStatus) return true
      return (
        (get(request, "status.ready") === true && filtersState.filterByStatus === "ready") ||
        (get(request, "status.ready") === false && filtersState.filterByStatus === "error")
      )
    },
  ]

  return requests.filter(request => {
    for (let i = 0; i < filterMethods.length; i++) {
      if (!filterMethods[i](request)) {
        return false
      }
    }
    return true
  })
}
