import React from "react"
import { makeStyles, Theme, createStyles, Box, Link, Typography, Breadcrumbs as MUIBreadcrumbs } from "@material-ui/core"
import { useNavigate } from "react-router"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbsWrapper: {
      padding: theme.spacing(1, 1, 1, 0),
    },
  }),
)

const isString = (x: unknown): x is string => {
  return typeof x === "string"
}

export type BreadcrumbsNavItem =
  | string
  | {
      label: string
      href: string
    }
  | undefined
  | false

type BreadcrumbsProps = {
  current: string | BreadcrumbsNavItem
  parents?: BreadcrumbsNavItem[]
}

export const Breadcrumbs = ({ current, parents = [] }: BreadcrumbsProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const makeHandleClick = (href: string) => (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    navigate(href)
    ev.preventDefault()
    ev.stopPropagation()
  }
  return (
    <Box className={classes.breadcrumbsWrapper}>
      <MUIBreadcrumbs>
        {parents.map((item, index) => {
          if (!item) return
          if (isString(item)) {
            return (
              <Link color="inherit" key={`breadcrumb-nav-item-${index}`} underline="none">
                {item}
              </Link>
            )
          }
          const { href, label } = item
          return (
            <Link color="inherit" key={`breadcrumb-nav-item-${index}`} href={href} onClick={makeHandleClick(href)}>
              {label}
            </Link>
          )
        })}

        {current && typeof current !== "string" ? (
          <Link color="inherit" href={current.href} onClick={makeHandleClick(current.href)}>
            {current.label}
          </Link>
        ) : (
          <Typography color="textPrimary">{current}</Typography>
        )}
      </MUIBreadcrumbs>
    </Box>
  )
}
