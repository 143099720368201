import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Typography, createStyles, makeStyles, Theme, Paper, Switch } from "@material-ui/core"

import { configNotificationReceiversLevel } from "@actions/settings"
import { settingsSelector } from "@selectors/settings"
import { isAdminInCurrentOrgSelector } from "@selectors/auth"
import { ClusterAlertConfig } from "../../CertInventory/ClusterAlertConfig"
import { fetchReceivers } from "@actions/receivers"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    view: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(4),
      "& h5": {
        marginBottom: theme.spacing(2),
      },
    },
    section: {
      margin: theme.spacing(4, 0),
      display: "flex",
      flexWrap: "wrap",
      gridGap: "1rem",
    },
    error: {
      background: theme.palette.error.main,
      padding: theme.spacing(1, 2),
      margin: theme.spacing(2, 0),
      color: "#fff",
      borderRadius: "5px",
    },
  }),
)

export enum NotificationReceiversLevel {
  Cluster = 0,
  Organization = 1,
}

type AlertsProps = {
  orgID: string
}

const Alerts: React.FC<AlertsProps> = ({ orgID }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { settings } = useSelector(settingsSelector())
  const isGlobalAlertsEnabled = settings?.notification_receivers_level === NotificationReceiversLevel.Organization
  const isAdmin = useSelector(isAdminInCurrentOrgSelector)

  useEffect(() => {
    if (orgID) {
      dispatch(fetchReceivers(orgID))
    }
  }, [orgID])

  const handleGlobalAlertsChange = useCallback(() => {
    const receiversLevel = isGlobalAlertsEnabled ? NotificationReceiversLevel.Cluster : NotificationReceiversLevel.Organization
    dispatch(configNotificationReceiversLevel(orgID, receiversLevel))
  }, [dispatch, settings, orgID])

  if (!settings) return null

  return (
    <Paper className={classes.view}>
      {isAdmin ? (
        <>
          <Typography gutterBottom variant="h4">
            Global alerts
          </Typography>
          <Typography gutterBottom>
            Alerts can work per cluster or globally for all the clusters in the organization. Those are mutually exclusive: when using
            global alerts, per-cluster alerts are disabled. When using per-cluster alerts, global alerts are disabled.
          </Typography>
          <div className={classes.section}>
            <Switch checked={isGlobalAlertsEnabled} onChange={handleGlobalAlertsChange} color="primary" disabled={false} />
            <Typography gutterBottom>
              Global alerts are {isGlobalAlertsEnabled ? "enabled" : "disabled"}
              <Typography gutterBottom>Per-cluster alerts are {isGlobalAlertsEnabled ? "disabled" : "enabled"}</Typography>
            </Typography>
            {isGlobalAlertsEnabled && <ClusterAlertConfig />}
          </div>
        </>
      ) : (
        <div className={classes.error}>
          <Typography variant="body2" align="left">
            Only organization admins can change this setting.
          </Typography>
        </div>
      )}
    </Paper>
  )
}

export { Alerts }
