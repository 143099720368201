import React from "react"
import { Typography } from "@material-ui/core"
import { MessageContainer } from "@components/MessageContainer"

export const SubjectSection = ({ subject }: { subject?: string }) => {
  return subject ? (
    <>
      <Typography gutterBottom>Subject</Typography>
      <MessageContainer>
        <Typography color="textPrimary" variant="body2" display="inline">
          {subject}
        </Typography>
      </MessageContainer>
    </>
  ) : null
}
