import React, { ChangeEvent, useState } from "react"
import {
  Typography,
  createStyles,
  makeStyles,
  Theme,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
} from "@material-ui/core"

import { isValidEmail } from "@utils/validation"
import { LoadingMessage } from "@components/LoadingMessage"

import { Member } from "../types"
import { useCreateUser } from "@lib/users"
import { ErrorMessage } from "@components/ErrorMessage"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
    },
    wrapper: {
      marginTop: theme.spacing(2),
      gridGap: "1rem",
      display: "flex",
      flexWrap: "wrap",
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
    emailInput: {
      width: "20rem",
    },
    roleSelect: {
      textTransform: "capitalize",
      minWidth: 100,
    },
    menuItem: {
      textTransform: "capitalize",
    },
    error: {
      color: theme.palette.error.main,
      maxWidth: "20rem",
    },
  }),
)

type Props = {
  roles?: string[]
  members?: Member[]
}

export const CreateMemberForm = ({ roles = [], members }: Props) => {
  const classes = useStyles()
  const { mutate, isLoading, isError } = useCreateUser()
  const [emailValue, setEmailValue] = useState("")
  const [roleValue, setRoleValue] = useState<string>("")
  const [inputError, setInputError] = useState("")
  const [selectError, setSelectError] = useState("")

  const handleGrantAccess = async () => {
    if (validateInputs()) {
      mutate({ email: emailValue, role: roleValue })
      resetInputs()
    }
  }

  const resetInputs = () => {
    setEmailValue("")
    setRoleValue("")
  }

  const onEmailChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputError("")
    setEmailValue(event.target.value)
  }

  const onRoleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setSelectError("")
    setRoleValue(event.target.value as string)
  }

  const validateInputs = () => {
    setInputError("")
    setSelectError("")
    const memberExists = members ? members.filter(m => m.email === emailValue).length > 0 : false

    if (memberExists) {
      setInputError("This email address has already been invited.")
      return false
    }
    if (!emailValue) {
      setInputError("You need to fill in the email address.")
      return false
    } else if (!isValidEmail(emailValue)) {
      setInputError("This is an invalid email.")
      return false
    }

    if (!roleValue) {
      setSelectError("You need to select a role.")
      return false
    }
    return true
  }

  return (
    <>
      <Typography gutterBottom variant="h5">
        Add a member to your team
      </Typography>
      <div className={classes.wrapper}>
        <div>
          <TextField
            className={classes.emailInput}
            label="Email address"
            variant="outlined"
            value={emailValue}
            onChange={onEmailChange}
            type="email"
            size="small"
            error={Boolean(inputError)}
            inputProps={{
              "data-testid": "team-email-input",
            }}
          />
          {inputError && <FormHelperText className={classes.error}>{inputError}</FormHelperText>}
        </div>
        <div>
          <FormControl variant="outlined" size="small">
            <InputLabel id="role-select">Role</InputLabel>
            <Select
              labelId="role-select"
              id="role-select"
              value={roleValue}
              onChange={onRoleChange}
              className={classes.roleSelect}
              label="Role"
              error={Boolean(selectError)}
              inputProps={{
                "data-testid": "team-role-select",
              }}
            >
              {roles.map((role, index) => (
                <MenuItem key={role + index} value={role} className={classes.menuItem}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectError && <FormHelperText className={classes.error}>{selectError}</FormHelperText>}
        </div>
      </div>
      <Button variant="contained" color="primary" onClick={handleGrantAccess} className={classes.marginTop}>
        Grant access
      </Button>
      {isLoading && <LoadingMessage label="Inviting member to the team..." />}
      {isError && <ErrorMessage message="Error inviting member" />}
    </>
  )
}
