import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Tooltip,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@material-ui/core"

import { getOrgID } from "@routes"
import { createReceiver, updateReceiver, deleteReceiver } from "@actions/receivers"
import { receiversSelector } from "@selectors/receivers"
import { LoadingMessage } from "@components/LoadingMessage"

import { Channel } from "../types"
import { getReceiverConfig, pagerDuty, slack, teams, zoom } from "../helpers"
import { Visibility, VisibilityOff } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      display: "flex",
    },
    input: {
      width: "100%",
      margin: theme.spacing(2, 0),
    },
    buttons: {
      display: "flex",
      gap: theme.spacing(1.5),
      marginTop: theme.spacing(1),
    },
    disableButton: {
      background: theme.palette.error.main,
    },
    content: {
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
    },

    deleteButton: {
      color: "#fff",
      background: theme.palette.error.main,
    },
    formControl: {
      margin: theme.spacing(1),
    },
    group: {
      margin: theme.spacing(1, 0),
    },
  }),
)

type Props = {
  onCancel: () => void
  cluster?: string
  type?: Channel
  receiver?: Receiver
}

export function ConfigForm({ type, onCancel, receiver, cluster }: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = cluster ? useParams<{ orgId: string; clusterID: string; clusterView: string }>() : useParams<{ orgId: string }>()
  const organization = getOrgID(params)

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    hook: "",
    key: "",
  })
  const [receiverName, setReceiverName] = useState("")
  const [webhook, setWebhook] = useState("")
  const [key, setKey] = useState("")
  const [enableWarnings, setEnableWarnings] = useState(false)
  const [enableSystemCerts, setEnableSystemCerts] = useState(false)
  const receiversData = useSelector(receiversSelector(cluster))
  const [showPassword, setShowPassword] = useState(false)
  const isZoomChat = type === zoom ? true : false
  const isPagerDuty = type === pagerDuty ? true : false
  const pagerDutyUrls: [string, string][] = [
    ["https://events.pagerduty.com/v2/enqueue", "United States"],
    ["https://events.eu.pagerduty.com/v2/enqueue", "Europe"],
  ]
  const [pagerDutyValue, setPagerDutyValue] = useState(pagerDutyUrls[0][0])

  useEffect(() => {
    if (receiver) {
      setReceiverName(receiver.display_name)
      if (type === slack) {
        setWebhook(receiver.slack_webhook_url)
      } else if (type === teams) {
        setWebhook(receiver.microsoft_teams_webhook_url)
      } else if (type === zoom) {
        setWebhook(receiver.zoom_chat_webhook_url.slice(0, receiver.zoom_chat_webhook_url.lastIndexOf("/")))
        setKey(receiver.zoom_chat_webhook_url.slice(receiver.zoom_chat_webhook_url.lastIndexOf("/") + 1))
      } else if (type === pagerDuty) {
        setPagerDutyValue(receiver.pager_duty_integration_url.slice(0, receiver.pager_duty_integration_url.lastIndexOf("/")))
        setKey(receiver.pager_duty_integration_url.slice(receiver.pager_duty_integration_url.lastIndexOf("/") + 1))
      }
      setEnableWarnings(receiver.send_warnings)
      setEnableSystemCerts(receiver.alert_on_system_certificates)
    }
  }, [receiver])

  const handleSave = () => {
    if (!isValidWebhook(webhook, type)) {
      setErrorMessages(state => ({ ...state, hook: "Invalid integration url" }))
      return
    }

    const sharedReceiverData = {
      display_name: receiverName,
      slack_webhook_enabled: type === slack,
      slack_webhook_url: type === slack ? webhook : "",
      microsoft_teams_webhook_enabled: type === teams,
      microsoft_teams_webhook_url: type === teams ? webhook : "",
      pager_duty_integration_enabled: type === pagerDuty,
      pager_duty_integration_url: type === pagerDuty ? pagerDutyValue + "/" + key : "",
      zoom_chat_webhook_enabled: type === zoom,
      zoom_chat_webhook_url: type === zoom ? webhook + "/" + key : "",
      send_errors: true, // not editable by form
      send_warnings: enableWarnings,
      alert_on_system_certificates: enableSystemCerts,
    }

    const clusterReceiverData = {
      ...sharedReceiverData,
      organization: organization,
      cluster: cluster,
    }

    const orgReceiverData = {
      ...sharedReceiverData,
      org_name: organization,
    }

    if (receiverName && (webhook || (pagerDutyValue && key))) {
      const receiverData = cluster ? clusterReceiverData : orgReceiverData

      if (receiver) {
        dispatch(
          updateReceiver(
            organization,
            {
              id: receiver.id,
              ...receiverData,
            },
            cluster,
          ),
        )
      } else {
        dispatch(createReceiver(organization, { id: undefined, ...receiverData }, cluster))
      }
      handleCancel()
    } else {
      const errorMessages = {
        hook: !webhook && (isZoomChat || !pagerDutyValue) ? "Integration url is required" : "",
        name: !receiverName ? "Name is required" : "",
        key: !key ? "Integration key is required" : "",
      }
      setErrorMessages(errorMessages)
    }
  }

  const handleDelete = () => {
    if (receiver && receiver.id) {
      dispatch(deleteReceiver(organization, receiver.id, cluster))
      handleCancel()
    }
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    switch (name) {
      case "display-name":
        setReceiverName(value)
        break
      case "hook-url":
        setWebhook(value)
        break
      case "pager-duty-url":
        setPagerDutyValue(value)
        break
      case "key":
        setKey(value)
        break
    }
    setErrorMessages({
      name: "",
      hook: "",
      key: "",
    })
  }

  const isValidWebhook = (url: string, type: Channel | undefined): boolean => {
    if (!url) {
      return true
    }

    switch (type) {
      case slack:
        return /^https:\/\/hooks\.slack\.com/.test(url) && isUrl(url)
      case teams:
        return !/^https:\/\/hooks\.slack\.com/.test(url) && isUrl(url) // ToDo: needs to be fixed with correct teams url or if it's dynamic not have the first check.
      case zoom:
        return /^https:\/\/(.*)\.zoom\.us/.test(url) && isUrl(url)
      case pagerDuty:
        return /^https:\/\/events(.*)\.pagerduty\.com/.test(url) && isUrl(url)
      default:
        return false
    }
  }

  function isUrl(url: string) {
    const regexp =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
    return regexp.test(url)
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const config = getReceiverConfig(type)
  const isCreateMode = !receiver

  if (receiversData?.isLoading) {
    return <LoadingMessage label={isCreateMode ? "Creating your integration ..." : "Updating your integration ..."} />
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="body1" align="left">
          You can {isCreateMode ? "create a new " : " learn how to change an"} integration in {config?.name}{" "}
          <a target="_blank" rel="noreferrer" href={config?.docLink}>
            here
          </a>
        </Typography>
        <div className={classes.input}>
          <TextField
            inputProps={{
              "data-testid": "alerts-name-input",
            }}
            name="display-name"
            error={Boolean(errorMessages.name)}
            label="Name your receiver"
            helperText={errorMessages.name}
            variant="outlined"
            onChange={handleChange}
            value={receiverName}
            size="small"
            fullWidth
          />
        </div>
        {!isPagerDuty ? (
          <div className={classes.input}>
            <TextField
              inputProps={{
                "data-testid": "alerts-hook-input",
              }}
              name="hook-url"
              error={Boolean(errorMessages.hook)}
              label={config?.label}
              helperText={errorMessages.hook !== "" ? errorMessages.hook : config?.urlExample}
              variant="outlined"
              onChange={handleChange}
              value={webhook}
              size="small"
              fullWidth
            />
            {isZoomChat && (
              <div className={classes.input}>
                <TextField
                  inputProps={{
                    "data-testid": "alerts-key-input",
                  }}
                  type={showPassword ? "text" : "password"}
                  name="key"
                  error={Boolean(errorMessages.key)}
                  label={config?.label2}
                  helperText={errorMessages.key !== "" ? errorMessages.key : ""}
                  variant="outlined"
                  onChange={handleChange}
                  value={key}
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={classes.input}>
            <FormControl component="fieldset" className={classes.formControl} error={Boolean(errorMessages.hook)}>
              <FormLabel component="legend">PagerDuty Service Region</FormLabel>
              <RadioGroup name="pager-duty-url" className={classes.group} value={pagerDutyValue} onChange={handleChange}>
                <FormControlLabel
                  value={pagerDutyUrls[0][0]}
                  control={<Radio color="primary" />}
                  label={
                    <Tooltip title={pagerDutyUrls[0][0]} placement="right" arrow interactive>
                      <Typography>{pagerDutyUrls[0][1]}</Typography>
                    </Tooltip>
                  }
                />
                <FormControlLabel
                  value={pagerDutyUrls[1][0]}
                  control={<Radio color="primary" />}
                  label={
                    <Tooltip title={pagerDutyUrls[1][0]} placement="right" arrow interactive>
                      <Typography>{pagerDutyUrls[1][1]}</Typography>
                    </Tooltip>
                  }
                />
                <FormHelperText error={Boolean(errorMessages.hook)}>{errorMessages.hook !== "" ? errorMessages.hook : ""}</FormHelperText>
              </RadioGroup>
            </FormControl>

            <TextField
              inputProps={{
                "data-testid": "alerts-key-input",
              }}
              type={showPassword ? "text" : "password"}
              name="key"
              error={Boolean(errorMessages.key)}
              label={config?.label2}
              helperText={errorMessages.key !== "" ? errorMessages.key : ""}
              variant="outlined"
              onChange={handleChange}
              value={key}
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
        <div>
          <FormControlLabel
            data-testid={"alerts-warnings-checkbox"}
            control={<Switch checked={enableWarnings} onChange={event => setEnableWarnings(event.target.checked)} color="primary" />}
            label="Include warnings"
          />
          <FormControlLabel
            data-testid={"alerts-system-certificates-checkbox"}
            control={<Switch checked={enableSystemCerts} onChange={event => setEnableSystemCerts(event.target.checked)} color="primary" />}
            label="Include system certificates"
          />
        </div>
        <div className={classes.buttons}>
          <Button variant="contained" size="small" color="primary" onClick={handleSave}>
            {isCreateMode ? "Create" : "Save Changes"}
          </Button>
          {!isCreateMode && (
            <Button variant="contained" size="small" color="primary" className={classes.deleteButton} onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button variant="contained" size="small" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}
