import React, { ReactElement, PropsWithChildren } from "react"

import { createStyles, makeStyles, Theme, Grid } from "@material-ui/core"
import { FieldInfo } from "../FieldInfo/"

const useStyles = ({ noBottomMargin }: { noBottomMargin: boolean }) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginBottom: noBottomMargin ? theme.spacing(2, 2, 0, 2) : theme.spacing(2),
      },
    }),
  )

export type FormRowProps = {
  info?: string
  noBottomMargin?: boolean
}

export function FormRow({ info, noBottomMargin = false, children }: PropsWithChildren<FormRowProps>): ReactElement {
  const classes = useStyles({ noBottomMargin })()
  return (
    <div className={classes.root}>
      {info && <FieldInfo message={info} />}
      {React.Children.map(children, child => (
        <Grid item>{child}</Grid>
      ))}
    </div>
  )
}
