import { createAuth0Client } from "@auth0/auth0-spa-js"
import { Auth0Client } from "@auth0/auth0-spa-js/dist/typings/Auth0Client"
import { AppConfigAuth, AppConfigAuthAuth0 } from "@lib/appConfig"
import { AppState, RedirectResult } from "./types"

export class Auth0AuthClient {
  client: Auth0Client | null
  config: AppConfigAuthAuth0

  constructor(cfg: AppConfigAuth) {
    if (!cfg.auth0) {
      throw new Error("cannot create auth0 client, missing auth0 config")
    }
    this.config = cfg.auth0
    this.client = null
  }

  async initialize() {
    this.client = await createAuth0Client({
      domain: this.config.domain,
      clientId: this.config.client_id,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https://preflight.jetstack.io/api/v1",
      },
      useRefreshTokens: true,
    })
    return this
  }

  async redirectToLogin(appState: AppState) {
    return this.client?.loginWithRedirect({ appState })
  }

  async handleRedirectCallback(): Promise<RedirectResult | undefined> {
    return this.client?.handleRedirectCallback()
  }

  async getToken(force: boolean): Promise<string | undefined> {
    return this.client?.getTokenSilently({ authorizationParams: { cacheMode: force ? "off" : "on" } })
  }

  async isAuthenticated(): Promise<boolean | undefined> {
    return this.client?.isAuthenticated()
  }

  async logout(returnTo?: string): Promise<void> {
    this.client?.logout({
      clientId: this.config.client_id,
      logoutParams: {
        returnTo,
      },
    })
  }
}
