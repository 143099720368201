import React, { useState } from "react"

import { Typography, createStyles, makeStyles, Theme, Button } from "@material-ui/core"
import { ChevronRight } from "@components/Icons"
import { Snippet } from "@components/Snippet"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
      paddingTop: theme.spacing(4),
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
    container: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 5,
    },
    caption: {
      marginTop: "-0.8rem",
    },
    pre: {
      padding: theme.spacing(0.3, 0.5),
      borderRadius: "5px",
      border: `1px solid ${theme.palette.grey[400]}`,
      background: "white",
      font: `12px Monaco, Consolas, 'Andale  Mono', 'DejaVu Sans Mono', monospace`,
      display: "inline-block",
    },
    generateButton: {
      marginTop: theme.spacing(1),
      fontWeight: 500,
      "& svg": {
        transform: "scale(0.8)",
        marginLeft: theme.spacing(0.8),
      },
    },
    snippetOpen: {
      "& svg": {
        transform: "scale(0.8) rotate(90deg)",
      },
    },
    snippetContainer: {
      marginTop: theme.spacing(2),
    },
  }),
)

export const TroubleshootingStep = () => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        color="primary"
        className={`${classes.generateButton} ${isOpen ? classes.snippetOpen : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>I am having issues with the installation</span> <ChevronRight />
      </Button>
      {isOpen && (
        <div className={classes.container}>
          <Typography gutterBottom>Check the agent is running.</Typography>
          <Typography gutterBottom>
            Make sure the deployment has finished successfully. You can check if the agent's pod is{" "}
            <span className={classes.pre}>Running</span>.
          </Typography>
          <Snippet text="kubectl -n=jetstack-secure get pods" language="shell" />
          <Typography gutterBottom>This is the expected output when the agent is running:</Typography>
          <Snippet
            language="shell"
            text={`kubectl -n=jetstack-secure get pods
  NAME                    READY   STATUS                  RESTARTS   AGE
  agent-68c8d9bd5-h7lf5   1/1     Running                 1          1m
        `}
            copyText="kubectl -n=jetstack-secure get pods"
          />
          <Typography gutterBottom>
            If the pod is in status <span className={classes.pre}>CrashLoopBackoff</span> then it indicates a problem with the execution of
            the agent. In that case you should consult the pod logs:
          </Typography>
          <Snippet
            language="shell"
            text={`kubectl -n=jetstack-secure logs --tail 20 -l app.kubernetes.io/name=agent
  ...
        `}
            copyText="kubectl -n=jetstack-secure logs --tail 20 -l app.kubernetes.io/name=agent"
          />
          <Typography gutterBottom>These are some common error messages:</Typography>
          <Snippet
            language="plaintext"
            text='Error fetching with DataGatherer "pods": pods is forbidden: User "system:serviceaccount:jetstack-secure:agent" cannot list resource "pods" in API group "" at the cluster scope'
          />
          <Typography gutterBottom variant="body2" className={classes.caption}>
            This indicates that the pod is not running with the correct service account. Check the service account and the cluster role
            binding configuration in your cluster.
          </Typography>
          <br />
          <Snippet
            language="plaintext"
            text={`Post to server failed: Received response with status code 401. Body: {'{'} "error": "not authorized", "code": 401 {'}'}`}
          />
          <Typography gutterBottom variant="body2" className={classes.caption}>
            This indicates that the pod does not have a valid token. Ensure that you ran the{" "}
            <span className={classes.pre}>kubectl set env</span> command.
          </Typography>
        </div>
      )}
    </div>
  )
}
