import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    notification: {
      background: theme.palette.error.main,
      color: "white",
      fontSize: "0.9rem",
      textAlign: "center",
      padding: theme.spacing(1.4),
      margin: theme.spacing(-4, -4, 2, -4),
    },
  })
})

export function AppNotificationBanner({ message }: { message: string }) {
  const classes = useStyles()
  return <div className={classes.notification}>{message}</div>
}
