import React, { ReactElement } from "react"
import { Grid, Typography } from "@material-ui/core"

import { Snippet } from "@components/Snippet"
import { InstructionStep } from "../../InstructionStep"
import { ACMEIssuer, IssuerCommonFields } from "../../types"
import { renderIssuerYaml } from "../../renderYaml"
import { VerifyIssuerInitialized } from "../../VerifyIssuerInitialized"
import { renderAcmeYaml } from "./renderACMEYaml"

type ACMEInstructionsProps = {
  common: IssuerCommonFields
  issuer: ACMEIssuer
}

export const ACMEInstructions = ({ common, issuer }: ACMEInstructionsProps): ReactElement => {
  const issuerFilename = "acme-issuer.yml"
  const isUsingNamespace = common.useNamespace === "use-namespace"
  const namespace = isUsingNamespace ? common.namespace : "cert-manager"
  const acmeYaml = renderAcmeYaml(common.name, issuer)
  const issuerYaml = renderIssuerYaml(common.name, isUsingNamespace, namespace, acmeYaml)
  const kind = isUsingNamespace ? "issuer" : "clusterissuer"

  return (
    <Grid container direction="column" spacing={4}>
      <InstructionStep step={1}>
        <Typography gutterBottom>
          Copy the following configuration and save it in a file <strong>{issuerFilename}</strong>.
        </Typography>
        <Snippet showLineNumbers language="yaml" text={issuerYaml} />
      </InstructionStep>

      <InstructionStep step={2}>
        <Typography gutterBottom>
          Create the <strong>Issuer</strong>.
        </Typography>
        <Snippet language="shell" text={`kubectl create -f ${issuerFilename}`} />
      </InstructionStep>

      <InstructionStep step={3}>
        <VerifyIssuerInitialized name={common.name} namespace={namespace} label={issuer.label} kind={kind} />
      </InstructionStep>
    </Grid>
  )
}
