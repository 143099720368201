import { AppState } from "../reducers"
import { currentOrgSelector } from "./orgs"

export function receiversSelector(clusterID?: string) {
  return (state: AppState) => {
    const orgID = currentOrgSelector(state)
    const key = clusterID ? `${orgID}/${clusterID}` : orgID
    if (key in state.receivers.receivers) {
      return state.receivers.receivers[key]
    }
  }
}
