import React, { ReactElement } from "react"
import { Grid, Typography } from "@material-ui/core"

import { Snippet } from "@components/Snippet"
import { ButtonSeeDoc } from "../../ButtonSeeDoc"
import { InstructionStep } from "../../InstructionStep"
import { IssuerCommonFields, VenafiIssuer } from "../../types"
import { renderIssuerYaml } from "../../renderYaml"
import { renderVenafiYaml } from "./renderVenafiYaml"
import { VerifyIssuerInitialized } from "../../VerifyIssuerInitialized"

const APIInstructions = ({ common, issuer }: VenafiInstructionsProps): ReactElement => {
  const isUsingNamespace = common.useNamespace === "use-namespace"
  const namespace = isUsingNamespace ? common.namespace : "cert-manager"
  const venafiYaml = renderVenafiYaml(issuer)
  const issuerYaml = renderIssuerYaml(common.name, common.useNamespace === "use-namespace", namespace, venafiYaml)
  const kind = isUsingNamespace ? "issuer" : "clusterissuer"

  return (
    <>
      <InstructionStep step={1}>
        <Typography gutterBottom>
          In order to set up a Venafi Cloud Issuer, you must first create a Kubernetes Secret resource containing your Venafi Cloud API
          credentials:
        </Typography>
        <Snippet
          language="shell"
          text={`kubectl create secret generic \\
    ${issuer.install.api.secretName} \\
    --namespace='${namespace}' \\
    --from-literal=apikey='${issuer.install.api.secretKey}'`}
        />
      </InstructionStep>

      <InstructionStep step={2}>
        <Typography gutterBottom>
          Copy the following configuration and save it in a file <strong>venafi-cloud-issuer.yaml</strong>.
        </Typography>
        <Snippet showLineNumbers language="yaml" text={issuerYaml} />
      </InstructionStep>

      <InstructionStep step={3}>
        <Typography gutterBottom>
          Create the <strong>Issuer</strong>.
        </Typography>
        <Snippet language="shell" text={`kubectl create -f venafi-cloud-issuer.yaml`} />
      </InstructionStep>

      <InstructionStep step={4}>
        <VerifyIssuerInitialized name={common.name} namespace={namespace} label={issuer.label} kind={kind} />
      </InstructionStep>
    </>
  )
}

const TPPInstructions = ({ common, issuer }: VenafiInstructionsProps): ReactElement => {
  const isUsingNamespace = common.useNamespace === "use-namespace"
  const kind = isUsingNamespace ? "issuer" : "clusterissuer"
  const namespace = isUsingNamespace ? common.namespace : "cert-manager"
  const venafiYaml = renderVenafiYaml(issuer)
  const issuerYaml = renderIssuerYaml(common.name, common.useNamespace === "use-namespace", namespace, venafiYaml)

  return (
    <>
      <InstructionStep step={1}>
        <Typography gutterBottom>Setup token authentication on Venafi web console.</Typography>
        <ButtonSeeDoc link="https://docs.venafi.com/Docs/19.2/TopNav/Content/SDK/WebSDK/t-sdk-Setup-OAuth.php">
          Setup auth token
        </ButtonSeeDoc>
      </InstructionStep>

      <InstructionStep step={2}>
        <Typography gutterBottom>
          Create a new user with sufficient privileges to manage and revoke certificates for your policy zone.
        </Typography>
        <ButtonSeeDoc link="https://docs.venafi.com/Docs/19.2/TopNav/Content/SDK/WebSDK/t-sdk-Setup-OAuth.php">Create a user</ButtonSeeDoc>
      </InstructionStep>

      <InstructionStep step={3}>
        <Typography gutterBottom>
          Create a new application integration with the name and ID <strong>cert-manager</strong>, and the "API Access Settings" set to{" "}
          <strong>Certificates: Read, Manage, Revoke</strong>.
        </Typography>

        <ButtonSeeDoc link="https://docs.venafi.com/Docs/19.2/TopNav/Content/API-ApplicationIntegration/t-APIAppIntegrations-creatingNew-Aperture.htm">
          Create app integration
        </ButtonSeeDoc>
      </InstructionStep>

      <InstructionStep step={4}>
        <Typography gutterBottom>Generate an access token.</Typography>
        <Snippet
          language="shell"
          text={`vcert getcred \\
    --username USER_MANAGING_POLICY_ZONE \\
    --password A_STRONG_PASSWORD \\
    -u ${issuer.install.tpp.serverUrl} \\
    --client-id cert-manager \\
    --scope "certificate:manage,revoke"`}
        />
        <Typography gutterBottom>
          This will print an <strong>access token</strong>, such as:
        </Typography>
        <Snippet
          showLineNumbers
          showCopyButton={false}
          language="plaintext"
          text={`vCert: 2020/10/07 16:34:27 Getting credentials
access_token:  I69n.............y1VjNJT3o9U0Wko19g==
access_token_expires:  2021-01-05T15:34:30Z`}
        />
      </InstructionStep>

      <InstructionStep step={5}>
        <Typography gutterBottom>
          Save the <strong>access token</strong> to a Secret in the Kubernetes cluster.
        </Typography>
        <Snippet
          language="shell"
          text={`kubectl create secret generic \\
    ${issuer.install.tpp.secretName} \\
    --namespace='${namespace}' \\
    --from-literal=access-token='YOUR_TPP_ACCESS_TOKEN'`}
        />
      </InstructionStep>

      <InstructionStep step={6}>
        <Typography gutterBottom>
          Copy the following configuration and save it in a file <strong>venafi-tpp-issuer.yaml</strong>.
        </Typography>
        <Snippet showLineNumbers language="yaml" text={issuerYaml} />
      </InstructionStep>

      <InstructionStep step={7}>
        <Typography gutterBottom>
          Create the <strong>Issuer</strong>.
        </Typography>
        <Snippet language="shell" text={`kubectl create -f venafi-tpp-issuer.yaml`} />
      </InstructionStep>

      <InstructionStep step={8}>
        <VerifyIssuerInitialized name={common.name} namespace={namespace} label={issuer.label} kind={kind} />
      </InstructionStep>
    </>
  )
}

type VenafiInstructionsProps = {
  common: IssuerCommonFields
  issuer: VenafiIssuer
}

export const VenafiInstructions = ({ common, issuer }: VenafiInstructionsProps) => {
  return (
    <Grid container direction="column" spacing={4}>
      {issuer.installType === "api" && <APIInstructions common={common} issuer={issuer} />}
      {issuer.installType === "tpp" && <TPPInstructions common={common} issuer={issuer} />}
    </Grid>
  )
}
