import React from "react"
import { Typography } from "@material-ui/core"
import { PathIcon } from "@components/Icons"
import { MessageContainer } from "@components/MessageContainer"

type HostPathsInfoProps = {
  path: string
  backend: {
    serviceName: string
    servicePort: string
  }
}

export function HostPathsInfo({ path, backend }: HostPathsInfoProps) {
  return (
    <MessageContainer icon={<PathIcon />}>
      <Typography color="textPrimary" variant="body2" display="block">
        {path ? path : <>&nbsp;</>}
      </Typography>
      <Typography color="textSecondary" variant="body2" display="block">
        {backend.serviceName} : {backend.servicePort}
      </Typography>
    </MessageContainer>
  )
}
