import { AllCertsGroupedByIdentity, CalculatedResources } from "@lib/certInventory/resources"
import { ClusterData } from "../lib/certInventory"

export const FETCH_CERT_INVENTORY = "FETCH_CERT_INVENTORY"
export const FETCH_CERT_INVENTORY_FAILED = "FETCH_CERT_INVENTORY_FAILED"
export const FETCH_CERT_INVENTORY_SUCCESS = "FETCH_CERT_INVENTORY_SUCCESS"

export interface FetchCertInventoryAction {
  type: typeof FETCH_CERT_INVENTORY
  orgID: string
  clusterID: string
  isDemo: boolean
}

export interface FetchCertInventoryFailedAction {
  type: typeof FETCH_CERT_INVENTORY_FAILED
  orgID: string
  clusterID: string
  errorMessage: string
}

export interface FetchCertInventorySuccessAction {
  type: typeof FETCH_CERT_INVENTORY_SUCCESS
  orgID: string
  clusterID: string
  certInventory: ClusterData
  calculatedResources: CalculatedResources
  allCertsGroupedByIdentity: AllCertsGroupedByIdentity
}

export const fetchCertInventory = (orgID: string, clusterID: string, isDemo: boolean): FetchCertInventoryAction => ({
  type: FETCH_CERT_INVENTORY,
  orgID,
  clusterID,
  isDemo,
})

export const fetchCertInventoryFailed = (orgID: string, clusterID: string, errorMessage: string): FetchCertInventoryFailedAction => ({
  type: FETCH_CERT_INVENTORY_FAILED,
  orgID,
  clusterID,
  errorMessage,
})

export const fetchCertInventorySuccess = (
  orgID: string,
  clusterID: string,
  certInventory: ClusterData,
  calculatedResources: CalculatedResources,
  allCertsGroupedByIdentity: AllCertsGroupedByIdentity,
): FetchCertInventorySuccessAction => ({
  type: FETCH_CERT_INVENTORY_SUCCESS,
  orgID,
  clusterID,
  certInventory,
  calculatedResources,
  allCertsGroupedByIdentity,
})

export type CertInventoryActions = FetchCertInventoryAction | FetchCertInventoryFailedAction | FetchCertInventorySuccessAction
