import React from "react"
import { Typography, createStyles, makeStyles, Theme, Box, Button, Paper } from "@material-ui/core"
import { MailForwardIcon } from "@components/Icons/MailForwardIcon"
import { ProgressBar } from "./../ProgressBar"

import { LinkWithIcon } from "./../LinkWithIcon"
import { LoadingMessage } from "@components/LoadingMessage"

const CONTACT_SALES_URL = "https://www.jetstack.io/get-in-touch/"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageSection: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(4),
      marginBottom: theme.spacing(2),
      "& h5": {
        marginBottom: theme.spacing(2),
      },
    },
    container: {
      display: "grid",
      gridTemplateColumns: "1fr 1px 3fr  1px 2fr",
      gridGap: "6rem",
      [theme.breakpoints.down(1360)]: {
        gridGap: "3rem",
      },
      [theme.breakpoints.down(1100)]: {
        gridGap: "2rem",
      },
      [theme.breakpoints.down(1024)]: {
        gridGap: "2.5rem",
        display: "flex",
        flexDirection: "column",
      },
    },
    tierValue: {
      fontWeight: 700,
      fontSize: "1.5rem",
    },
    clusterNumber: {
      fontWeight: 700,
      fontSize: "1.5rem",
    },
    supportText: {
      fontWeight: 700,
      fontSize: "1.5rem",
    },
    divider: {
      background: "#eee",
      width: "1px",
      height: "100%",
      [theme.breakpoints.down(1024)]: {
        width: "100%",
        height: "1px",
      },
    },
    item: {},
  }),
)

type TierQuotaViewProps = {
  tier?: string
  clusterLimit?: number
  clusterCount: number
  supportText: string
  supportLink: string
  isLoading: boolean
}

const TierQuotaView = ({ tier, clusterLimit, clusterCount, supportText, supportLink, isLoading }: TierQuotaViewProps) => {
  const classes = useStyles()
  return (
    <Paper className={classes.pageSection}>
      {isLoading ? (
        <LoadingMessage label="loading data" />
      ) : (
        <div className={classes.container}>
          {/* Tier level */}
          {tier && (
            <div>
              <Typography gutterBottom>Current tier:</Typography>
              <Typography variant="body1" className={classes.tierValue} gutterBottom>
                {tier}
              </Typography>

              <Button variant="contained" color="primary" href={CONTACT_SALES_URL} target="_blank" rel="noopener">
                Upgrade
              </Button>
            </div>
          )}
          <div className={classes.divider}></div>

          {/* Cluster quota */}
          {clusterLimit && clusterLimit > 0 && (
            <div>
              <Typography gutterBottom>Cluster quota:</Typography>
              <Typography>
                <Box component="span" className={classes.clusterNumber}>
                  {clusterCount}
                </Box>{" "}
                out of{" "}
                <Box component="span" className={classes.clusterNumber}>
                  {clusterLimit}
                </Box>
              </Typography>

              <ProgressBar variant="determinate" value={(clusterCount / clusterLimit) * 100} />
            </div>
          )}

          <div className={classes.divider}></div>
          {/* Support level */}
          <div>
            <Typography gutterBottom>Support:</Typography>
            <Typography gutterBottom className={classes.supportText}>
              {supportText}
            </Typography>
            <Typography color="textPrimary" variant="body2" display="inline">
              <LinkWithIcon icon={<MailForwardIcon />} href={supportLink}>
                Contact support
              </LinkWithIcon>
            </Typography>
          </div>
        </div>
      )}
    </Paper>
  )
}

export { TierQuotaView }
