import React, { ReactElement } from "react"
import { createStyles, FormControl, makeStyles, MenuItem, Theme, Typography } from "@material-ui/core"
import { Field } from "formik"
import { Select as FormSelect } from "formik-material-ui"

import { VenafiForm } from "../issuers/venafi/VenafiForm"
import { ACMEForm } from "../issuers/acme/ACMEForm"
import { VaultForm } from "../issuers/vault/VaultForm"
import { GoogleCASForm } from "../issuers/googlecas/GoogleCASForm"
import { AwsPCAForm } from "../issuers/awspca/AwsPCAForm"
import { IssuerKey, Issuers } from "../types"
import { FormWrapper } from "../FormWrapper"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formSelect: {
      minWidth: 200,
    },
    issuer: {
      maxWidth: "34rem",
      marginBottom: theme.spacing(4),
    },
  }),
)

type IssuerFormProps = {
  issuers: Issuers
  selectedIssuer: IssuerKey
}

export const IssuerForm = ({ issuers, selectedIssuer }: IssuerFormProps): ReactElement => {
  const classes = useStyles()
  const issuer = issuers[selectedIssuer]
  return (
    <FormWrapper title="Issuer">
      <div className={classes.issuer}>
        <FormControl variant="outlined" margin="dense">
          <Field
            className={classes.formSelect}
            component={FormSelect}
            name="common.selectedIssuer"
            inputProps={{ id: "common.selectedIssuer" }}
          >
            {Object.values(issuers).map(issuer => (
              <MenuItem key={issuer.key} value={issuer.key}>
                {issuer.label}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <Typography variant="body2" color="textSecondary" gutterBottom dangerouslySetInnerHTML={{ __html: issuer.description }} />
      </div>
      {issuer.key === "acme" && <ACMEForm />}
      {issuer.key === "venafi" && <VenafiForm issuer={issuer} />}
      {issuer.key === "vault" && <VaultForm issuer={issuer} />}
      {issuer.key === "googlecas" && <GoogleCASForm issuer={issuer} />}
      {issuer.key === "awspca" && <AwsPCAForm />}
    </FormWrapper>
  )
}
