import React, { ReactElement } from "react"
import { makeStyles, createStyles, Theme, Typography, Paper } from "@material-ui/core"
import { formatDate } from "@utils/date"
import { StatusIndicator, Status } from "@components/StatusIndicator"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      margin: theme.spacing(1, 0),
    },
    paperClass: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.grey[300]}`,
      [theme.breakpoints.down(500)]: {
        display: "block",
      },
    },
    statistic: {
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down(500)]: {
        margin: theme.spacing(1.5, 0),
      },
    },
    statValue: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "0.875rem",
      lineHeight: "0.875rem",
      paddingBottom: 2,
      color: "white",
      marginRight: theme.spacing(1),
      background: theme.palette.text.primary,
      width: "1.75rem",
      height: "1.75rem",
      borderRadius: "5rem",
      fontWeight: 700,
    },
    statLabel: {
      fontSize: "0.75rem",
    },
    statusList: {
      "& > div": {
        marginLeft: theme.spacing(3),
        padding: theme.spacing(0.5, 0),
      },
      [theme.breakpoints.down(500)]: {
        "& > div:first-child": {
          marginLeft: 0,
        },
      },
      [theme.breakpoints.down(500)]: {
        "& > div": {
          margin: theme.spacing(0, 0, 1, 0),
          width: "100%",
        },
      },
    },
  }),
)

type StatusBarProps = {
  statusList?: StatusItem[]
  staticStats?: StaticStat[]
  lastUpdated?: string
}

export type StatusItem = {
  status: Status
  message: string
  value: string | number
  description?: string
}

type StaticStat = {
  label: string
  value: string | number
}

export function StatusBar({ statusList = [], staticStats = [], lastUpdated }: StatusBarProps): ReactElement {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paperClass}>
        {staticStats.map(stat => (
          <Statistic key={stat.label} label={stat.label} statistic={stat.value} />
        ))}
        <div className={classes.statusList}>
          {statusList.map(({ status, message, value, description }, index) => (
            <StatusIndicator
              key={`${status}-${value}-${index}`}
              status={status}
              message={message}
              description={description}
              value={value}
            />
          ))}
        </div>
      </Paper>
      {lastUpdated && (
        <Typography variant="caption" color="textSecondary" data-testid="last-update-ts">
          Last update {formatDate(lastUpdated)}
        </Typography>
      )}
    </div>
  )
}

type StatisticProps = {
  label: string
  statistic: string | number
}
const Statistic = ({ label, statistic }: StatisticProps): ReactElement => {
  const classes = useStyles()
  return (
    <div className={classes.statistic}>
      <Typography variant="body1" className={classes.statValue}>
        {statistic < 100 ? statistic : "99+"}
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" className={classes.statLabel}>
        {label}
      </Typography>
    </div>
  )
}
