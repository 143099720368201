import React, { useEffect, useState, PropsWithChildren } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createStyles, makeStyles } from "@material-ui/core"

import { LoadingMessage } from "@components/LoadingMessage"
import { FatalError } from "@components/FatalError"

import { startClient, redirectCallbackReceived, authFailure } from "@actions/auth"
import { isStartingClientSelector, clientSelector, errorSelector } from "@selectors/auth"
import { ReactElement } from "react-markdown/lib/react-markdown"
import { useNavigate } from "react-router"

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      fontFamily: "'sofia-pro', sans-serif",
      display: "flex",
      height: "100vh",
    },
  })
})

export const Auth = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isStartingClient = useSelector(isStartingClientSelector)
  const client = useSelector(clientSelector)
  const authError = useSelector(errorSelector)
  const navigate = useNavigate()

  const [comesFromRedirect, setComesFromRedirect] = useState(window.location.search.includes("code="))
  const [redirectError, setRedirectError] = useState(getRedirectError())

  useEffect(() => {
    let cancelled = false
    if (redirectError !== "") {
      dispatch(authFailure(redirectError))

      if (!cancelled) {
        setRedirectError("")
      }
    } else {
      if (!isStartingClient && !client) {
        dispatch(startClient())
      }
      if (client && comesFromRedirect) {
        dispatch(redirectCallbackReceived(navigate))
        if (!cancelled) {
          setComesFromRedirect(false)
        }
      }
    }

    return () => {
      cancelled = true
    }
  }, [dispatch, isStartingClient, client, comesFromRedirect, setComesFromRedirect, redirectError, setRedirectError])

  if (authError) {
    return (
      <div className="Auth">
        <FatalError message={authError} />
      </div>
    )
  }

  if (!client || comesFromRedirect) {
    return (
      <div className={classes.root}>
        <LoadingMessage label="Loading app..." />
      </div>
    )
  }

  return <>{children}</>
}

const getRedirectError = (): string => {
  const urlParams = new URLSearchParams(window.location.search)

  if (!urlParams.has("error")) {
    return ""
  }

  return `${urlParams.get("error")}: ${urlParams.get("error_description")}`
}
