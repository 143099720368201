import React from "react"
import { Typography } from "@material-ui/core"
import { useStyles } from "./useStyles"

type SummaryTitleProps = {
  isNegative?: boolean
  title: string
  variant?: "small"
}

function SummaryTitle({ title, variant }: SummaryTitleProps) {
  const classes = useStyles()

  return (
    <Typography variant="h2" color="textPrimary" className={`${classes.title} ${variant === "small" ? classes.small : ""}`}>
      <div className={classes.titleContainer}>{title}</div>
    </Typography>
  )
}

export { SummaryTitle }
