import React, { Dispatch, SetStateAction, useState } from "react"
import { createStyles, makeStyles, Button, Typography } from "@material-ui/core"

import { CheckingAgentStatus } from "@components/Wizards/ConnectClusterWizard/CheckingAgentStatus"

type CheckClusterConnectionProps = {
  clusterName: string
  orgID: string
  currentStep: string
  setStep: Dispatch<SetStateAction<number>>
}

export const NAME_AND_CONNECT_CLUSTER = "NAME_AND_CONNECT_CLUSTER"
const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      pointerEvents: "none",
      opacity: 0.6,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
)

export const CHECK_CLUSTER_CONNECTION = "CHECK_CLUSTER_CONNECTION"

export const CheckClusterConnection = ({ currentStep, clusterName, orgID, setStep }: CheckClusterConnectionProps) => {
  const [showAgentStatus, setShowAgentStatus] = useState(false)
  const [agentStatusOK, setAgentStatusOK] = useState(false)
  const isCurrentStep = currentStep === CHECK_CLUSTER_CONNECTION
  const classes = useStyles()

  return (
    <div className={!isCurrentStep ? classes.disabled : ""}>
      <Typography variant="h5" gutterBottom>
        Step 4. Check the Cluster Connection
      </Typography>
      {isCurrentStep && (
        <div>
          <Typography color="textSecondary" gutterBottom>
            If you have successfully connected the '{clusterName}' cluster to TLS Protect for Kubernetes, you can click the button below to
            check the status. If you are waiting for more than a few seconds, please check the deployments in the{" "}
            <code>jetstack-secure</code> namespace are running correctly.
          </Typography>
          <br />
          {showAgentStatus ? (
            <CheckingAgentStatus orgID={orgID} clusterName={clusterName} onSuccessCallback={() => setAgentStatusOK(true)} />
          ) : (
            <Button variant="contained" color="primary" onClick={() => setShowAgentStatus(true)}>
              check '{clusterName}' status
            </Button>
          )}
          <br />
          <br />
          <div className={classes.buttonWrapper}>
            {agentStatusOK ? (
              <Button variant="contained" color="primary" disabled={!agentStatusOK} onClick={() => setStep(step => step + 1)}>
                Next
              </Button>
            ) : (
              <span></span>
            )}
            <Button variant="contained" color="secondary" disabled={false} onClick={() => setStep(step => step - 1)}>
              Back
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
