import { useMemo, useState, Dispatch, SetStateAction } from "react"

type IUseSteps = {
  step: number
  setStep: Dispatch<SetStateAction<number>>
  currentStep: string
}

// hook to help manage with wizard step is active
export function useSteps(stepsArray: string[]): IUseSteps {
  const [step, setStep] = useState(0)
  const currentStep = useMemo(() => stepsArray[step], [step])

  return {
    step,
    setStep,
    currentStep,
  }
}
