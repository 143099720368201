import React, { ReactElement } from "react"
import { useSelector } from "react-redux"
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core"
import { userSelector } from "@selectors/auth"
import { BlurbFeature } from "@components/Welcome/BlurbFeature"
import { CertManagerIconSimple, WarningIcon, EyeCheckIcon, ActivityIcon } from "@components/Icons"

const noMargin = {
  marginRight: 0,
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    title: {
      fontSize: "2rem",
      fontWeight: 500,
      marginBottom: theme.spacing(5),
    },
    content: {
      fontSize: "1.125rem",
    },
    container: {
      width: "100%",
    },
    item: {
      display: "inline-block",
      width: "calc(25% - 2rem)",
      margin: theme.spacing(2, 4, 2, 0),
      textAlign: "center",
      "&:last-child": {
        marginRight: 0,
      },
      "& p": {
        color: theme.palette.text.primary,
      },
      [theme.breakpoints.down(768)]: {
        width: "calc(50% - 2rem)",
        "&:nth-child(2n)": noMargin,
      },
      [theme.breakpoints.down(480)]: {
        width: "100%",
        margin: theme.spacing(2, 0),
        maxWidth: "22rem",
      },
    },
  }),
)

const IntroView = (): ReactElement => {
  const user = useSelector(userSelector)
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title} data-testid="new-user-welcome-message">
        Welcome, {user!.name}!
      </Typography>
      <Typography variant="body2" className={classes.content} gutterBottom>
        TLS Protect for Kubernetes is the Kubernetes machine identity management solution keeping modern enterprise cloud native
        environments secured and protected.
      </Typography>
      <div className={classes.container}>
        <div className={classes.item}>
          <BlurbFeature icon={<CertManagerIconSimple />} text="cert-manager health status and alerts" noIconBackground />
        </div>
        <div className={classes.item}>
          <BlurbFeature icon={<WarningIcon />} text="Configuration status across multiple clusters" />
        </div>
        <div className={classes.item}>
          <BlurbFeature icon={<EyeCheckIcon />} text="Full visibility of all machine identities" />
        </div>
        <div className={classes.item}>
          <BlurbFeature icon={<ActivityIcon />} text="Prevent outages and maintain high availability" />
        </div>
      </div>
    </div>
  )
}
export { IntroView }
