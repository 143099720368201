import React, { ReactElement } from "react"
import classnames from "classnames"
import { Typography, makeStyles, createStyles, Theme } from "@material-ui/core"
import { StatusIndicator } from "@components/StatusIndicator"
import { CertificateRequest } from "@lib/certificateRequests/types"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      borderLeft: `3px solid transparent`,
      fontSize: "0.875rem",
      "&:hover": {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.default,
        boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
      },
      "& td": {
        padding: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        verticalAlign: "middle",
      },
    },
    selectedRow: {
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.default,
      boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
    },
    namespace: {
      display: "block",
      fontWeight: 300,
      color: theme.palette.text.secondary,
      fontSize: "0.875rem",
    },
    status: {
      textAlign: "center",
    },
    title: {
      fontSize: "1rem",
      lineHeight: 1.3,
      color: theme.palette.text.primary,
    },
    icon: {
      "& svg": {
        marginBottom: theme.spacing(-0.375),
      },
    },
  }),
)

type Props = {
  request: CertificateRequest
  handleSelect: (res: CertificateRequest) => void
  selected?: boolean
  icon?: ReactElement
}

export function CertifcateRequestsRow({ request, handleSelect, selected }: Props) {
  const classes = useStyles()

  const handleClick = () => {
    handleSelect(request)
  }

  const rowClass = classnames(classes.row, { [classes.selectedRow]: selected })

  return (
    <tr className={rowClass} onClick={handleClick} data-tut="issuer-row">
      <td>
        <Typography variant="body1" display="inline" data-testid="resource-row-title" className={classes.title}>
          {request.namespace && <span className={classes.namespace}>{request.namespace}</span>}
          {request.name}
        </Typography>
      </td>

      <td>
        <Typography variant="body1" display="inline" data-testid="resource-row-title" className={classes.title}>
          {request.type.kind}
        </Typography>
      </td>
      <td className={classes.status}>
        {request.status.ready ? <StatusIndicator iconsOnly status="ok" /> : <StatusIndicator iconsOnly status="error" />}
      </td>
    </tr>
  )
}
