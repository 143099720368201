import { FiltersActions, UPDATE_FILTERS } from "@actions/filters"
import { FilterType } from "@selectors/filters"

export type FiltersState = { [x in FilterType]: { [x: string]: string } }

const initialState = {
  certificates: {},
  clusters: {},
  ingresses: {},
  issuers: {},
}

export function filtersReducer(state: FiltersState = initialState, action: FiltersActions): FiltersState {
  switch (action.type) {
    case UPDATE_FILTERS: {
      return {
        ...state,
        ...action.filter,
      }
    }

    default:
      return state
  }
}
