import React, { Dispatch, SetStateAction } from "react"
import { Paper, Typography } from "@material-ui/core"
import { Table, TableHeader } from "@components/ResourcesTable"
import { ErrorMessage } from "@components/ErrorMessage"
import { FiltersBar } from "./FiltersBar"
import { ExpiringSoonRow } from "./ExpiringSoonRow"
import { useStyles } from "./useStyles"
import { LoadingMessage } from "@components/LoadingMessage"
import { SummaryTitle } from "../SummaryTitle"
import { useGetDiscoveredCertificates } from "@lib/discoveredCertificates"
import { useDiscoveredCertificatesFilters } from "./filters"

type Props = {
  setCertificateId: Dispatch<SetStateAction<string>>
}

export function ExpiringSoon({ setCertificateId }: Props) {
  const classes = useStyles()
  const filterState = useDiscoveredCertificatesFilters()
  const { certificates, isError, isSuccess, isFetching, isLoading } = useGetDiscoveredCertificates(filterState)

  const certificateCount = certificates?.length || 0

  return (
    <Paper className={classes.paper}>
      {isError ? (
        <div className={classes.error}>
          <ErrorMessage message="Something went wrong while loading the Discovered Certificate Requests. Please try again or contact support." />
        </div>
      ) : (
        <>
          <SummaryTitle title="Certificates Expiring Soon" />
          <div className={classes.filterContainer}>
            <FiltersBar filterState={filterState} />
          </div>

          <div className={classes.pageSection}>
            {isFetching && <LoadingMessage label={"Loading certificates..."} />}
            {isSuccess && !isFetching && (
              <Table>
                <TableHeader
                  rows={[
                    { label: "Cluster", size: 11 },
                    { label: "Issuer" },
                    { label: "Namespace", size: 11 },
                    { label: "Subject" },
                    { label: "Ingest Time", size: 6 },
                  ]}
                />
                <tbody>
                  {certificates?.map((certificate, index) => (
                    <ExpiringSoonRow
                      key={`${certificate.uid}-${index}`}
                      certificate={certificate}
                      handleSelect={() => setCertificateId(certificate.uid)}
                    />
                  ))}
                </tbody>
              </Table>
            )}
            {certificateCount === 0 && !isFetching && !isLoading && (
              <div className={classes.errorContainer}>
                <Typography>No Issues found with this filter.</Typography>
              </div>
            )}
          </div>
        </>
      )}
    </Paper>
  )
}
