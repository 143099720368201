import React, { useState, useEffect, useMemo } from "react"
import { userSelector } from "@selectors/auth"
import { useDispatch, useSelector } from "react-redux"
import { ErrorMessage } from "@components/ErrorMessage"
import { Paper, Typography, createStyles, makeStyles, Theme } from "@material-ui/core"
import { orgRolesSelector } from "@selectors/auth"
import { logoutStarted } from "@actions/auth"
import { MemberRow } from "./MemberRow"
import { Member } from "./types"
import { CreateMemberForm } from "./CreateMemberForm"
import { Table, TableHeader } from "@components/ResourcesTable"
import { useDeleteUser, useDeleteCurrentUser, useUsers } from "@lib/users"
import { DataTableWrapper } from "@components/DataTableWrapper"
import { compact } from "lodash"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: theme.spacing(4),
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
    memberSection: {
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(3),
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      "& h5": {
        marginBottom: theme.spacing(2),
      },
    },
    error: {
      color: theme.palette.error.main,
    },
    dialog: {
      padding: theme.spacing(4),
      position: "relative",
    },
  }),
)

export const ManageTeam = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { members, isLoading, isError, error, isFetching } = useUsers()
  const { isError: isDeleteError, mutate, error: deleteError, isLoading: isDeleting } = useDeleteUser()
  const {
    isError: isDeleteCurrentError,
    mutate: mutateCurrent,
    error: deleteCurrentError,
    isSuccess: isDeleteCurrentSuccess,
    isLoading: isDeletingCurrent,
  } = useDeleteCurrentUser()
  const [sortedMembers, setSortedMembers] = useState<Member[]>([])
  const currentUser = useSelector(userSelector)
  const roles = useSelector(orgRolesSelector)

  useEffect(() => {
    if (members) {
      const sortedList = members
        // This sorts the item depending on how many roles are ie [admin, member] appears first before just admin or member
        .sort((a: Member, b: Member) => (a.roles.length < 2 ? -1 : b.roles.length < 2 ? 1 : 0))
        // Show all the members that are admins
        .sort((a: Member, b: Member) => (a.roles.includes("admin") ? -1 : b.roles.includes("admin") ? 1 : 0))

      if (currentUser) {
        const curr = sortedList.find(member => member.user_id === currentUser?.user_id)
        const foundIdx = sortedList.findIndex(member => member.user_id === currentUser?.user_id)
        sortedList.splice(foundIdx, 1)
        if (curr) {
          sortedList.unshift(curr)
        }
      }

      setSortedMembers(sortedList.filter(item => !item.is_deleted))
    }
  }, [members, currentUser])

  const handleDelete = async (userID: string) => {
    if (userID === "current") {
      mutateCurrent()
    } else {
      mutate(userID)
    }
  }

  const handleLogout = () => {
    dispatch(logoutStarted())
  }

  useEffect(() => {
    if (isDeleteCurrentSuccess) {
      handleLogout()
    }
  }, [isDeleteCurrentSuccess])

  const isCurrentMemberAdmin = useMemo(() => {
    const currentMember = members?.find(member => member.user_id === currentUser?.user_id)
    return currentMember?.roles.some(role => role === "admin")
  }, [members, currentUser])

  return (
    <Paper className={classes.root}>
      <CreateMemberForm roles={roles} members={members} />
      {isError && <ErrorMessage message={error.message} />}
      {isDeleteError && deleteError && <ErrorMessage message={deleteError?.message} />}
      {isDeleteCurrentError && deleteCurrentError && <ErrorMessage message={deleteCurrentError?.message} />}
      <div className={classes.memberSection}>
        <Typography gutterBottom variant="h5">
          Team members
        </Typography>
        <DataTableWrapper
          noResultsText="You currently have no members part of your team."
          isLoading={isLoading}
          data={sortedMembers}
          isFetching={isFetching || isDeleting || isDeletingCurrent}
        >
          <Table>
            <TableHeader
              rows={compact([
                { label: "", size: 2 },
                { label: "Member name" },
                { label: "Email address" },
                { label: "Role", style: { textAlign: "left" } },
                { label: "Remove", size: 6, style: { textAlign: "right" } },
              ])}
            />
            <tbody>
              {sortedMembers.map((member, index) => (
                <MemberRow
                  isUserAdmin={isCurrentMemberAdmin}
                  isCurrentUser={currentUser?.user_id === member.user_id}
                  key={member.email + index}
                  member={member}
                  onDelete={handleDelete}
                />
              ))}
            </tbody>
          </Table>
        </DataTableWrapper>
      </div>
    </Paper>
  )
}
