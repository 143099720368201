import React from "react"

type Props = {
  [x: string]: unknown
}

export function ZoomIcon(props: Props) {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...props}>
      <defs>
        <radialGradient
          id="radial-gradient"
          cx="14"
          cy="12.37272"
          fx="14"
          fy="12.37272"
          r="18.87568"
          gradientTransform="translate(0 2.0319) scale(1 .83578)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".82" stopColor="#0b5cff" stopOpacity="0" />
          <stop offset=".98" stopColor="#003cb3" />
        </radialGradient>
        <radialGradient
          id="radial-gradient-2"
          cx="14.00004"
          cy="15.62077"
          fx="14.00004"
          fy="15.62077"
          r="18.86891"
          gradientTransform="translate(0 2.5653) scale(1 .83578)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".8" stopColor="#0b5cff" stopOpacity="0" />
          <stop offset="1" stopColor="#71a5f1" />
        </radialGradient>
      </defs>

      <path
        d="M28,14c0,1.49712-.12035,2.96512-.35025,4.3965-.76109,4.73863-4.51466,8.49217-9.25329,9.25324-1.43143,.2299-2.89944,.35025-4.39646,.35025s-2.96503-.12035-4.39646-.35025C4.86491,26.88868,1.11134,23.13513,.35025,18.3965c-.2299-1.43139-.35025-2.89939-.35025-4.3965s.12035-2.96512,.35025-4.3965C1.11134,4.86487,4.86491,1.11132,9.60354,.35025c1.43143-.2299,2.89944-.35025,4.39646-.35025s2.96503,.12035,4.39646,.35025c4.73863,.76107,8.4922,4.51462,9.25329,9.25324,.2299,1.43139,.35025,2.89939,.35025,4.3965Z"
        fill="#0b5cff"
      />
      <path
        d="M28,14c0,1.49712-.12035,2.96512-.35025,4.3965-.76109,4.73863-4.51466,8.49217-9.25329,9.25324-1.43143,.2299-2.89944,.35025-4.39646,.35025s-2.96503-.12035-4.39646-.35025C4.86491,26.88868,1.11134,23.13513,.35025,18.3965c-.2299-1.43139-.35025-2.89939-.35025-4.3965s.12035-2.96512,.35025-4.3965C1.11134,4.86487,4.86491,1.11132,9.60354,.35025c1.43143-.2299,2.89944-.35025,4.39646-.35025s2.96503,.12035,4.39646,.35025c4.73863,.76107,8.4922,4.51462,9.25329,9.25324,.2299,1.43139,.35025,2.89939,.35025,4.3965Z"
        fill="url(#radial-gradient)"
      />
      <path
        d="M28.00004,14.00003c0,1.49707-.12036,2.96509-.35028,4.39648-.76111,4.73865-4.51465,8.49219-9.25329,9.25323-1.4314,.22992-2.89941,.35028-4.39642,.35028s-2.96503-.12036-4.39648-.35028C4.86491,26.8887,1.11137,23.13516,.35027,18.39651,.12035,16.96511,.00005,15.4971,.00005,14.00003s.1203-2.96515,.35022-4.39654C1.11137,4.8649,4.86491,1.1113,9.60356,.35025,11.03502,.12033,12.50297,.00003,14.00004,.00003s2.96503,.1203,4.39642,.35022c4.73865,.76105,8.49219,4.51465,9.25329,9.25323,.22992,1.4314,.35028,2.89941,.35028,4.39654Z"
        fill="url(#radial-gradient-2)"
      />
      <path
        d="M15,6.00001H8c-1.10457,0-2,.89543-2,2v4c0,1.10457,.89543,2,2,2h.00006v1.999c0,.44543,.53855,.66851,.85352,.35354l1.9132-1.9132c.2813-.2813,.66284-.43934,1.06066-.43934h3.17256c1.10457,0,2-.89543,2-2v-4c0-1.10457-.89543-2-2-2Zm7,7.49999v4c0,1.10457-.89543,2-2,2h-.00006v1.999c0,.44543-.53855,.66851-.85352,.35354l-1.91321-1.91321c-.2813-.2813-.66282-.43933-1.06064-.43933h-3.17258c-1.10457,0-2-.89543-2-2v-1.44098c0-.28087,.11158-.55024,.31018-.74884h0c.19861-.19861,.46797-.31018,.74884-.31018h2.94098c1.65685,0,3-1.34315,3-3v-.5h2c1.10457,0,2,.89543,2,2Z"
        fill="#ffffff"
      />
    </svg>
  )
}
