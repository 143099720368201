import React from "react"
import { Paper, Grid, Typography, createStyles, makeStyles, Theme } from "@material-ui/core"
import { Formik, Form } from "formik"
import { useParams } from "react-router"

import { Page } from "@components/Page"
import { FORM_INITIAL_VALUES } from "./initialValues"
import { IssuerInstructions } from "./IssuerInstructions"
import { CommonForm } from "./CommonForm"
import { IssuerForm } from "./IssuerForm"
import { Breadcrumbs, BreadcrumbsNavItem } from "@components/Navigation/Breadcrumbs"
import { getOrgID, pathToCertInventory, pathToOverview } from "@routes"
import { useSupportsMultiClusterSelector } from "@hooks/useSupportsMultiCluster"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.paper,
      margin: "auto",
    },
    sectionTitle: {
      marginBottom: theme.spacing(3),
    },
  }),
)

export const AddIssuerWizard = () => {
  const classes = useStyles()
  const orgID = getOrgID(useParams())

  const { showMultiCluster, supportsMultiCluster } = useSupportsMultiClusterSelector()

  const clusterBreadCrumb = {
    label: showMultiCluster ? "Clusters" : "Cluster",
    href: pathToCertInventory(orgID),
  }
  const parentCrumbs: BreadcrumbsNavItem[] = supportsMultiCluster
    ? [
        {
          label: "Overview",
          href: pathToOverview(orgID),
        },
        clusterBreadCrumb,
      ]
    : [clusterBreadCrumb]

  return (
    <Page title="Add an issuer" fixedWidth reqAuth breadcrumbs={<Breadcrumbs current="Add Issuer" parents={parentCrumbs} />}>
      <Formik initialValues={FORM_INITIAL_VALUES} validateOnMount onSubmit={() => {}}>
        {({ values: { common, issuers }, isValid }) => (
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Define your configuration
                </Typography>
                <Form>
                  <CommonForm common={common} />
                  <IssuerForm issuers={issuers} selectedIssuer={common.selectedIssuer} />
                </Form>
              </Paper>
            </Grid>

            <Grid item>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.sectionTitle}>
                  How to setup the issuer
                </Typography>
                <IssuerInstructions common={common} issuer={issuers[common.selectedIssuer]} isFormValid={isValid} />
              </Paper>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Page>
  )
}
