import React, { ReactElement } from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"

import { CheckMarkCircleIcon, WarningIcon } from "@components/Icons"

type Props = {
  status?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warn: {
      "& path": {
        stroke: theme.palette.warning.light,
      },
    },
    error: {
      "& path": {
        stroke: theme.palette.error.light,
      },
    },
  }),
)

export function StatusIcon({ status = "" }: Props): ReactElement | null {
  const classes = useStyles()
  if (status === "ok") {
    return <CheckMarkCircleIcon data-testid="ok-icon" />
  } else if (status === "warning") {
    return <WarningIcon className={classes.warn} data-testid="warn-icon" />
  } else if (status === "error") {
    return <WarningIcon className={classes.error} data-testid="error-icon" />
  }
  return null
}
