export const tabLinks = [
  {
    id: "tier",
    name: "Tier and Quota",
  },
  {
    id: "alerts",
    name: "Alerts",
  },
  {
    id: "private-images",
    name: "Private images",
  },
  {
    id: "members",
    name: "Members",
  },
  {
    id: "service_accounts",
    name: "Service Accounts",
  },
]
