import React from "react"
import { Paper, createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    step: {
      margin: theme.spacing(2, "auto"),
      padding: theme.spacing(4),
    },
  }),
)
type StepProps = {
  children?: JSX.Element | JSX.Element[]
}

export const Step = ({ children }: StepProps) => {
  const classes = useStyles()
  return <Paper className={classes.step}>{children}</Paper>
}
