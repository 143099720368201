import React from "react"
import { createStyles, Theme, makeStyles, Typography } from "@material-ui/core"

import { Resource } from "@lib/certInventory/resources"
import { StatusIndicator } from "@components/StatusIndicator"
import { Issues } from "../Issues"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: theme.spacing(2),
    },
  }),
)

type HealthProps = {
  resource: Resource
}

function Health({ resource }: HealthProps) {
  const classes = useStyles()
  const { type, message } = resource.status
  return (
    <div className={classes.section}>
      <Typography gutterBottom variant="h5">
        Health
      </Typography>
      <div className={classes.section}>
        <StatusIndicator status={type} message={message} value={resource.rawRuleMessages().length} />
      </div>
      {resource.rawRuleMessages().length > 0 && <Issues messages={resource.rawRuleMessages()} />}
    </div>
  )
}

export { Health }
