import React, { ReactElement } from "react"
import { Field } from "formik"
import { TextField as FormTextField } from "formik-material-ui"

import { validate, validNoTrailingWhitespaces } from "../../validation"
import { FormRow } from "../../FormRow"

export const AwsPCAForm = (): ReactElement => {
  return (
    <>
      <FormRow info="The resource ARN of the AWS private CA">
        <Field
          name="issuers.awspca.arn"
          label="ARN"
          variant="outlined"
          margin="dense"
          placeholder="e.g. arn:aws:acm-pca:<region>:<account_id>:certificate-authority/<resource_id>"
          required
          validate={validate(validNoTrailingWhitespaces)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow info="The region where the AWS private CA is running">
        <Field
          name="issuers.awspca.region"
          label="Region"
          variant="outlined"
          margin="dense"
          placeholder="e.g. us-east1"
          validate={validate(validNoTrailingWhitespaces)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow info="A reference to the AWS access credentials to be used by the AWS Private CA issuer">
        <Field
          name="issuers.awspca.secretRef.namespace"
          label="Secret namespace"
          variant="outlined"
          margin="dense"
          placeholder="e.g. awspca"
          component={FormTextField}
        />
        <Field
          name="issuers.awspca.secretRef.name"
          label="Secret name"
          variant="outlined"
          margin="dense"
          placeholder="e.g. awspca-secret"
          component={FormTextField}
        />
      </FormRow>
    </>
  )
}
