import React from "react"
import CancelIcon from "@material-ui/icons/Cancel"

type CloseButtonProps = {
  onClick: () => void
  className?: string
}

function CloseButton({ onClick, className }: CloseButtonProps) {
  // we need to encapsule the icon in a div so we can have data-tut, as the icon results on an <svg> object and that appears to not work with reactour2.
  return (
    <div data-tut="details-panel-close-btn" onClick={onClick}>
      <CancelIcon className={className} />
    </div>
  )
}

export { CloseButton }
