import React, { ReactElement } from "react"

import classnames from "classnames"
import { Typography, makeStyles, Tooltip, createStyles, Theme } from "@material-ui/core"

import { StatusIndicator } from "@components/StatusIndicator"

import { Certificate, CMCertificate } from "@lib/certInventory/resources"
import { format } from "date-fns"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      borderLeft: `3px solid transparent`,
      fontSize: "0.875rem",
      "&:hover": {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.default,
        boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
      },
      "& td": {
        padding: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        verticalAlign: "middle",
      },
    },
    selectedRow: {
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.default,
      boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
    },
    namespace: {
      fontWeight: 300,
      color: theme.palette.text.secondary,
      fontSize: "0.875rem",
    },
    status: {
      textAlign: "center",
    },
    title: {
      fontSize: "1rem",
      lineHeight: 1.3,
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1024)]: {
        whiteSpace: "nowrap",
      },
    },
    icon: {
      "& svg": {
        marginBottom: theme.spacing(-0.375),
      },
    },
    identity: {
      [theme.breakpoints.down(1024)]: {
        whiteSpace: "nowrap",
      },
      "& strong": {
        color: theme.palette.text.primary,
      },
      "& p": {
        height: "1rem",
        maxWidth: "60ch",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: theme.spacing(1),
        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
    more: {
      display: "inline-block",
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
    cluster: {
      wordWrap: "break-word",
      fontSize: "0.875rem",
      color: theme.palette.text.primary,
      lineHeight: 1.4,
      [theme.breakpoints.down(1024)]: {
        whiteSpace: "nowrap",
      },
      "& wbr": {
        [theme.breakpoints.down(1024)]: {
          display: "none",
        },
      },
    },
  }),
)

type Props = {
  res: Certificate
  handleSelect: (res: Certificate) => void
  selected?: boolean
  icon?: ReactElement
  allResourcesView?: boolean
}

export function CertificateRow({ res, handleSelect, selected, icon, allResourcesView }: Props) {
  const classes = useStyles()
  const type = res.deleted_at ? "deleted" : res.status.type

  const handleClick = () => {
    handleSelect(res)
  }
  const splitByUnderscore = (str: string) => {
    return str.replace(/_/g, "_<wbr/>")
  }
  const rowClass = classnames(classes.row, { [classes.selectedRow]: selected })
  const namespace = res.resource.metadata?.namespace === "" ? undefined : res.resource.metadata?.namespace
  const identities = res.getIdentities()
  const displayIdentities = identities.filter(({ key }) => key === "DNS Name" || key === "URI")
  const commonNameIdentity = displayIdentities.some(({ key }) => key === "DNS Name")
    ? undefined
    : identities.find(({ key }) => key === "commonName")
  const resDecoded = res.decode()[0]
  const expiryDate = resDecoded?.validTo ? new Date(resDecoded?.validTo) : null
  const nextRenewalDate = (res as CMCertificate).resource?.status?.renewalTime
    ? new Date((res as CMCertificate).resource?.status?.renewalTime)
    : null
  return (
    <tr className={rowClass} onClick={handleClick} data-tut="certificate-row">
      <td className={classes.icon}>{icon}</td>
      <td>
        <Typography variant="body1" display="inline" data-testid="resource-row-title" className={classes.title}>
          {namespace && <span className={classes.namespace}>{namespace}</span>}
          <br />
          {res.resource.metadata?.name}
        </Typography>
      </td>
      {allResourcesView && (
        <td className={classes.cluster}>
          <div dangerouslySetInnerHTML={{ __html: splitByUnderscore(res.clusterId) }}></div>
        </td>
      )}
      <td className={classes.identity}>
        {commonNameIdentity && (
          <Tooltip title={<Typography variant="body2">{commonNameIdentity.value}</Typography>}>
            <p>
              Common Name: <strong>{commonNameIdentity.value}</strong>
            </p>
          </Tooltip>
        )}
        {displayIdentities.splice(0, 2).map(({ key, value }, index) => (
          <Tooltip key={key + value + index} title={<Typography variant="body2">{value}</Typography>}>
            <p>
              {key}: <strong>{value}</strong>{" "}
            </p>
          </Tooltip>
        ))}
        <p>{identities.length > 2 && <More value={identities.length - 2} />}</p>
      </td>
      <td>
        <Typography variant="body2" color="textPrimary">
          {expiryDate ? (
            <>
              {format(expiryDate, "HH:mm:ss")}
              <br />
              {format(expiryDate, "yyyy/MM/dd")}
            </>
          ) : (
            "N/A"
          )}
        </Typography>
      </td>
      <td>
        <Typography variant="body2" color="textPrimary">
          {nextRenewalDate ? (
            <>
              {format(nextRenewalDate, "HH:mm:ss")}
              <br />
              {format(nextRenewalDate, "yyyy/MM/dd")}
            </>
          ) : (
            "N/A"
          )}
        </Typography>
      </td>
      <td className={classes.status}>
        <StatusIndicator status={type} value={res.rawRuleMessages().length} />
      </td>
    </tr>
  )
}

const More = ({ value }: { value: number }) => {
  const classes = useStyles()
  return <span className={classes.more}> {value} more</span>
}
