import React, { Fragment, useState } from "react"
import { Typography, Button } from "@material-ui/core"

import { AllCerts } from "@lib/certInventory/resources"
import { CertificateIcon } from "@components/Icons"
import { MessageContainer } from "@components/MessageContainer"

function HistoryList({ list }: { list: AllCerts[] }) {
  const [historyCount, setHistoryCount] = useState(10)
  return (
    <Fragment>
      {list.slice(0, historyCount).map((cert: AllCerts) => (
        <MessageContainer icon={<CertificateIcon color="grey" />} key={cert.id}>
          <Typography color="textPrimary" variant="body2" display="inline">
            {cert.resource.metadata?.name}
          </Typography>
        </MessageContainer>
      ))}
      {list.length > historyCount && (
        <Button color="primary" onClick={() => setHistoryCount(list.length)}>
          See all
        </Button>
      )}
    </Fragment>
  )
}

export { HistoryList }
