import React, { ReactElement, CSSProperties, Dispatch, SetStateAction } from "react"
import { createStyles, makeStyles, Theme, Checkbox } from "@material-ui/core"
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons"
import { ServiceAccount } from "@lib/serviceAccounts"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      letterSpacing: "0.03rem",
      fontWeight: 600,
      fontSize: "0.75rem",
      textTransform: "uppercase",
      "& tr": {
        backgroundColor: theme.palette.background.default,
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
      "& th": {
        boxSizing: "content-box",
        padding: theme.spacing(1.5),
        textAlign: "left",
        "&:last-child": {
          textAlign: "center",
        },
      },
    },
    checkbox: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
    th: {
      display: "flex",
      alignItems: "center",
    },
    headerPositionWrapper: {},
  }),
)

type Order = "asc" | "desc"

type TableHeaderProps = {
  rows: Row[]
  orderByKey?: string
  order?: Order
  setOrderByKey?: Dispatch<SetStateAction<keyof ServiceAccount>>
  setOrder?: Dispatch<SetStateAction<Order>>
  key?: keyof ServiceAccount
  selectAll: () => void
  selectedSvc: string[]
  allSvcSelected: boolean
}
type Row = {
  label: string
  size?: number
  style?: CSSProperties
  key?: keyof ServiceAccount
}

export function ServiceAccountTableHeader({
  rows,
  order,
  setOrder,
  setOrderByKey,
  orderByKey,
  selectAll,
  selectedSvc,
  allSvcSelected,
}: TableHeaderProps): ReactElement {
  const classes = useStyles()

  const handleClick = (key?: keyof ServiceAccount) => {
    if (setOrder && setOrderByKey && key && order) {
      setOrder(order === "asc" ? "desc" : "asc")
      setOrderByKey(key)
    }
  }

  const arrowIcon = (key?: keyof ServiceAccount) => {
    return order === "desc" && key === orderByKey ? <ArrowDropUp /> : <ArrowDropDown />
  }

  return (
    <thead className={classes.root}>
      <tr>
        <th style={{ width: "2rem" }}>
          {selectedSvc.length > 0 && (
            <div className={classes.checkbox}>
              <Checkbox
                style={{ padding: "0 0 0 10px" }}
                disabled={selectedSvc.length === 0}
                color="primary"
                onChange={() => selectAll()}
                indeterminate={selectedSvc.length > 0 && !allSvcSelected}
                checked={allSvcSelected || selectedSvc.length > 0}
              />
            </div>
          )}
        </th>
        {rows.map(({ label, size, style, key }, index) => (
          <th
            key={`${label}-${index}`}
            style={{ cursor: key ? "pointer" : "normal", width: size + "rem", verticalAlign: "middle", ...style }}
            onClick={() => handleClick(key)}
          >
            <div style={{ justifyContent: index === rows.length - 1 ? "flex-end" : "flex-start" }} className={classes.th}>
              {label}
              {key ? arrowIcon(key) : null}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}
