import React, { useState, useMemo, useEffect, useCallback } from "react"
import { Paper, Typography, createStyles, makeStyles, Theme, Button, Dialog, Input } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import { Table } from "@components/ResourcesTable"
import { LoadingMessage } from "@components/LoadingMessage"
import { ServiceAccount, useDeleteServiceAccount, useServiceAccounts } from "@lib/serviceAccounts"
import { ServiceAccountRow } from "./ServiceAccountRow"
import { CreateModal } from "./CreateModal"
import { sortSvcAccountsBy } from "../helpers"
import { ServiceAccountTableHeader } from "./ServiceAccountTableHeader"
import { DataTableWrapper } from "@components/DataTableWrapper"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageSection: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(4),
      marginBottom: theme.spacing(2),
      "& h5": {
        marginBottom: theme.spacing(2),
      },
    },
    section: {
      display: "flex",
      margin: theme.spacing(4, 0),
      justifyContent: "space-between",
      "& h5": {
        marginBottom: 0,
      },
    },
    paragraph: {
      marginBottom: theme.spacing(3),
    },
    container: {
      display: "grid",
      gridTemplateColumns: "1fr 1px 3fr  1px 2fr",
      gridGap: "6rem",
      [theme.breakpoints.down(1360)]: {
        gridGap: "3rem",
      },
      [theme.breakpoints.down(1100)]: {
        gridGap: "2rem",
      },
      [theme.breakpoints.down(1024)]: {
        gridGap: "2.5rem",
        display: "flex",
        flexDirection: "column",
      },
    },
    button: {
      background: theme.palette.error.main,
      marginRight: theme.spacing(1),
    },
    heading: {
      width: "360px",
      marginBottom: theme.spacing(3),
    },
    selectedRow: {
      transition: "all 0.3s ease-in-out",
      fontSize: "0.875rem",
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.default,
      "&:hover": {
        boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
      },
      "& td": {
        position: "relative",
        padding: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        verticalAlign: "middle",
        wordBreak: "break-word",
        [theme.breakpoints.down(1024)]: {
          whiteSpace: "nowrap",
        },
      },
    },
    dialog: {
      padding: theme.spacing(4),
      position: "relative",
    },
    closeBtn: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    dialogBtn: {
      marginTop: theme.spacing(2),
      background: theme.palette.error.main,
    },
    confirmWrapper: {
      display: "inline-flex",
      flexDirection: "column",
      marginTop: theme.spacing(2),
    },
    confirmInput: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
    selectedList: {
      listStyle: "auto",
      marginLeft: "18px",
      paddingBottom: theme.spacing(2),
    },
    selectedItem: {
      paddingBottom: "10px",
    },
    disabledTableWrapper: {
      opacity: 0.3,
      pointerEvents: "none",
    },
    tableWrapper: {
      position: "relative",
    },
    fetching: {
      position: "absolute",
      top: "40%",
      left: "45%",
    },
  }),
)

const VALIDATION_PHRASE = "DELETE PERMANENTLY"

type Order = "asc" | "desc"

export const ServiceAccountsView = () => {
  const [order, setOrder] = React.useState<Order>("desc")
  const [orderByKey, setOrderByKey] = React.useState<keyof ServiceAccount>("created_at")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedSvc, setSelectedSvc] = useState<string[]>([])
  const [confirmMultiDeleteIsOpen, setConfirmMultiDeleteIsOpen] = useState<boolean>(false)
  const [confirmPhrase, setConfirmPhrase] = useState<string>("")
  const { svcAccounts, isFetching, isLoading } = useServiceAccounts()
  const { mutate, isSuccess: isDeleteSuccess, isLoading: isDeleting } = useDeleteServiceAccount()
  const classes = useStyles()

  const svcAccountNames = useMemo(() => svcAccounts?.map(svcAccount => svcAccount.email.split("@")[0]), [svcAccounts])

  const handleChange = async (email: string) => {
    if (selectedSvc.includes(email)) {
      setSelectedSvc(svcs => svcs.filter(uid => uid !== email))
    } else {
      setSelectedSvc(svcs => [...svcs, email])
    }
  }

  const handleInputChange = (val: string) => {
    setConfirmPhrase(val)
  }

  const sortedSvcAccounts: ServiceAccount[] | undefined = useMemo(() => {
    if (svcAccounts) {
      return sortSvcAccountsBy(svcAccounts, order, orderByKey)
    }
    return svcAccounts
  }, [svcAccounts, order, orderByKey])

  useEffect(() => {
    if (isDeleteSuccess) {
      setConfirmMultiDeleteIsOpen(false)
      setSelectedSvc([])
      setConfirmPhrase("")
    }
  }, [isDeleteSuccess])

  const handleMultiDelete = useCallback(() => {
    const svcToDelete = sortedSvcAccounts?.filter(svc => selectedSvc.includes(svc.email)).map(svc => svc.user_id)

    if (svcToDelete) {
      svcToDelete.forEach(uid => {
        mutate(uid)
      })
    }
  }, [sortedSvcAccounts, selectedSvc])

  const handleSelectAll = useCallback(() => {
    if (selectedSvc.length) {
      setSelectedSvc([])
    } else if (sortedSvcAccounts) {
      setSelectedSvc(sortedSvcAccounts?.map(svc => svc.email))
    }
  }, [selectedSvc])

  return (
    <>
      <Paper className={classes.pageSection}>
        <Typography gutterBottom variant="h5">
          What are service accounts?
        </Typography>
        <Typography className={classes.paragraph} gutterBottom color="textSecondary">
          Service accounts are special users in the system intended to be used programmatically by other systems.
        </Typography>
        <Typography gutterBottom variant="h5">
          Service accounts for the TLS Protect for Kubernetes agent
        </Typography>
        <Typography gutterBottom color="textSecondary">
          The TLS Protect for Kubernetes agent uses a service account to authenticate against the service.The mentioned service account is
          created automatically in the "Connect new cluster" wizard. If a cluster has been onboarded, you will see its corresponding service
          account below.
        </Typography>
        <Typography className={classes.paragraph} gutterBottom color="textSecondary">
          <strong>Be cautious</strong> not to delete a service account that is being used by an agent, otherwise you will have to create a
          new service account and update the credentials in the agent configuration.
        </Typography>
        <Typography gutterBottom variant="h5">
          Other uses of service accounts
        </Typography>
        <Typography gutterBottom color="textSecondary">
          Service accounts can also be used for other purposes like Venafi TLS Protect integration or other custom integrations.
        </Typography>
      </Paper>
      <Paper className={classes.pageSection}>
        <div className={classes.section}>
          <Typography variant="h5">List of service accounts</Typography>
          <div>
            {selectedSvc.length > 1 && (
              <Button variant="contained" color="primary" className={classes.button} onClick={() => setConfirmMultiDeleteIsOpen(true)}>
                Remove selected accounts
              </Button>
            )}
            <Button color="primary" variant="contained" onClick={() => setIsModalOpen(true)}>
              Create service account
            </Button>
          </div>
        </div>
        <DataTableWrapper
          noResultsText="You currently have no service accounts."
          isLoading={isLoading}
          data={svcAccounts}
          isFetching={isFetching}
        >
          <Table>
            <ServiceAccountTableHeader
              orderByKey={orderByKey}
              order={order}
              setOrder={setOrder}
              setOrderByKey={setOrderByKey}
              selectedSvc={selectedSvc}
              allSvcSelected={selectedSvc.length === sortedSvcAccounts?.length}
              selectAll={handleSelectAll}
              rows={[
                { label: "Service account email", key: "email" },
                { label: "Creation date", key: "created_at" },
                { label: "Last used date", key: "last_login" },
                { label: "Remove", size: 6, style: { textAlign: "right" } },
              ]}
            />
            <tbody>
              {sortedSvcAccounts?.map((serviceAccount, index) => (
                <ServiceAccountRow
                  onChange={handleChange}
                  selected={selectedSvc.includes(serviceAccount.email)}
                  key={`$serviceAccount.id}${index}`}
                  serviceAccount={serviceAccount}
                />
              ))}
            </tbody>
          </Table>
        </DataTableWrapper>
      </Paper>
      {isModalOpen && <CreateModal svcAccountNames={svcAccountNames} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
      <Dialog
        open={confirmMultiDeleteIsOpen}
        onClose={() => {
          setConfirmMultiDeleteIsOpen(false)
          setConfirmPhrase("")
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.dialog}>
          <CancelIcon
            className={classes.closeBtn}
            onClick={() => {
              setConfirmMultiDeleteIsOpen(false)
              setConfirmPhrase("")
            }}
            data-testid="alerts-close-button"
          />
          {isDeleting ? (
            <LoadingMessage label="Deleting service accounts..." />
          ) : (
            <>
              <div className={classes.heading}>
                <Typography gutterBottom variant="h5">
                  Are you sure you want to delete these accounts?
                </Typography>
              </div>
              <ul className={classes.selectedList}>
                {selectedSvc.map(svc => (
                  <li className={classes.selectedItem} key={`selected-${svc}`}>
                    {svc}
                  </li>
                ))}
              </ul>
              <Typography>
                To confirm please enter <strong>{VALIDATION_PHRASE}</strong> below
              </Typography>
              <Input className={classes.confirmInput} value={confirmPhrase} onChange={e => handleInputChange(e.target.value)} />
              <div className={classes.confirmWrapper}>
                <Button
                  disabled={confirmPhrase !== VALIDATION_PHRASE}
                  variant="contained"
                  color="primary"
                  className={classes.dialogBtn}
                  onClick={handleMultiDelete}
                >
                  Yes, remove
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}
