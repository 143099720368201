import React, { ReactElement, useState } from "react"
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core"

import { TextField as FormTextField } from "formik-material-ui"
import { Field, useField } from "formik"

import { FormRow } from "../../FormRow"
import { validate, validEmail } from "../../validation"

export const LETS_ENCRYPT_PRODUCTION_URL = "https://acme-v02.api.letsencrypt.org/directory"
export const LETS_ENCRYPT_STAGING_URL = "https://acme-staging-v02.api.letsencrypt.org/directory"

type ACMEServerUrlChoices = "letsencrypt_prod" | "letsencrypt_staging" | "other"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginTop: theme.spacing(2),
    },
  }),
)

export const ACMEForm = (): ReactElement => {
  const classes = useStyles()
  const [, , serverUrlHelpers] = useField("issuers.acme.serverUrl")

  const [serverUrlChoice, setServerUrlChoice] = useState<ACMEServerUrlChoices>("letsencrypt_prod")
  const [serverUrlOther, setServerUrlOther] = useState("")

  const handleServerUrlChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const choice = ev.target.value as ACMEServerUrlChoices
    if (choice === "letsencrypt_prod") serverUrlHelpers.setValue(LETS_ENCRYPT_PRODUCTION_URL)
    else if (choice === "letsencrypt_staging") serverUrlHelpers.setValue(LETS_ENCRYPT_STAGING_URL)
    else if (choice === "other") serverUrlHelpers.setValue(serverUrlOther)
    setServerUrlChoice(choice)
  }

  const handleServerUrlOtherChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setServerUrlChoice("other")
    setServerUrlOther(ev.target.value)
    serverUrlHelpers.setValue(ev.target.value)
  }

  const handleServerUrlOtherFocus = () => {
    setServerUrlChoice("other")
    serverUrlHelpers.setValue(serverUrlOther)
  }

  return (
    <>
      <FormRow info="Used by the ACME provider for notifications, such as certificate expirations">
        <Field
          name="issuers.acme.email"
          label="Email"
          variant="outlined"
          margin="dense"
          placeholder="someone@example.org"
          required
          type="email"
          validate={validate(validEmail)}
          component={FormTextField}
        />
      </FormRow>
      <Grid item>
        <FormControl component="fieldset">
          <Typography variant="h6" className={classes.sectionTitle} style={{ marginTop: 0 }}>
            Server URL
          </Typography>
          <RadioGroup name="serverUrl" value={serverUrlChoice} onChange={handleServerUrlChange}>
            <FormControlLabel
              value="letsencrypt_prod"
              control={<Radio color="primary" />}
              label={
                <Tooltip title={LETS_ENCRYPT_PRODUCTION_URL} placement="right" arrow interactive>
                  <Typography>Let's Encrypt Production</Typography>
                </Tooltip>
              }
            />
            <FormControlLabel
              value="letsencrypt_staging"
              control={<Radio color="primary" />}
              label={
                <Tooltip title={LETS_ENCRYPT_STAGING_URL} placement="right" arrow interactive>
                  <Typography>Let's Encrypt Staging</Typography>
                </Tooltip>
              }
            />
            <FormControlLabel
              value="other"
              control={<Radio color="primary" />}
              label={
                <TextField
                  placeholder="Other"
                  value={serverUrlOther}
                  onFocus={handleServerUrlOtherFocus}
                  onChange={handleServerUrlOtherChange}
                  variant="outlined"
                  margin="dense"
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  )
}
