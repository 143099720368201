import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  [x: string]: unknown
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& path": {
        stroke: theme.palette.primary.main,
      },
    },
  }),
)

export function UserIcon(props: Props): ReactElement {
  const classes = useStyles()
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.root} {...props}>
      <path
        d="M9 8.25a3 3 0 100-6 3 3 0 000 6zM4.5 15.75v-1.5a3 3 0 013-3h3a3 3 0 013 3v1.5"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
