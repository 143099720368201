import React from "react"
import { Typography, createStyles, makeStyles, CircularProgress } from "@material-ui/core"
import { LoadingMessage } from "@components/LoadingMessage"

const useStyles = makeStyles(() =>
  createStyles({
    tableWrapper: {
      position: "relative",
    },
    fetching: {
      position: "absolute",
      top: "40%",
      left: "45%",
    },
    disabledTableWrapper: {
      opacity: 0.3,
      pointerEvents: "none",
    },
  }),
)

type DataTableWrapperProps<T> = {
  children: React.ReactNode
  noResultsText: string
  data: T[] | undefined
  isFetching: boolean
  isLoading: boolean
}

export function DataTableWrapper<T>({ children, noResultsText, data, isFetching, isLoading }: DataTableWrapperProps<T>): JSX.Element {
  const classes = useStyles()

  if (isLoading) {
    return <LoadingMessage label="Waiting for data..." />
  } else if (!isLoading && !!data && data?.length) {
    return (
      <div className={classes.tableWrapper}>
        {isFetching && (
          <div className={classes.fetching}>
            <CircularProgress />
          </div>
        )}
        <div className={isFetching ? classes.disabledTableWrapper : ""}>{children}</div>
      </div>
    )
  }

  return (
    <Typography gutterBottom color="textSecondary">
      {noResultsText}
    </Typography>
  )
}
