import React, { useState, ReactElement, Fragment } from "react"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Radio,
  FormGroup,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Typography,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Checkbox,
} from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"

import { ErrorMessage } from "@components/ErrorMessage"
import { currentOrgSelector } from "@selectors/orgs"
import { accessTokenSelector, userSelector, organizationsSelector, isFetchingUserSelector } from "@selectors/auth"
import { questionnaireSelector } from "@selectors/questionnaires"
import { fetchAuthInfo } from "@actions/auth"
import { generateErrorMessage, handleError } from "@utils/errorHandling"
import { LoadingMessage } from "@components/LoadingMessage"
import { addAuth } from "@lib/auth"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "56rem",
      },
    },
    content: {
      maxWidth: "56rem",
      padding: 0,
      position: "relative",
      "&.MuiDialogContent-root:first-child": {
        padding: 0,
      },
    },
    wrapper: {
      position: "relative",
      padding: theme.spacing(5, 5, 2, 5),
      background: "#fff url('/static/questionnaire-graphic.svg') bottom left no-repeat",
    },
    buttons: {
      padding: 0,
    },
    actions: {
      padding: theme.spacing(12, 5, 5, 5),
      height: "12rem",
    },
    formTitle: {
      fontSize: "1.5rem",
      lineHeight: 1.4,
      fontWeight: 500,
      paddingBottom: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    optionContainer: {
      marginBottom: theme.spacing(2.5),
      width: "fit-content",
    },
    option: {
      marginRight: theme.spacing(1),
    },
    steps: {
      background: theme.palette.primary.main,
      color: "#fff",
      position: "absolute",
      top: "1rem",
      right: "1rem",
      padding: theme.spacing(1, 2),
      borderRadius: "2rem",
    },
    loading: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      zIndex: 100,
      background: `rgba(255,255,255, 0.9)`,
      display: "flex",
      alignItems: "center",
    },
    closeBtn: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 100,
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
  }),
)

type QuestionnaireProps = {
  questions: Question[]
  introPage?: ReactElement
}

export type Question = {
  id: string
  title: string
  type?: "checkboxes" | "radio"
  options: {
    value: string
    label: string
    type?: string
  }[]
}

export const testIds = {
  nextFinishButton: "Questionnaire-nextFinishButton",
}

export const Questionnaire = ({ questions, introPage }: QuestionnaireProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const token = useSelector(accessTokenSelector)
  const user = useSelector(userSelector)
  const organizations = useSelector(organizationsSelector)
  const questionnairesByOrg = useSelector(questionnaireSelector)
  const orgID = useSelector(currentOrgSelector)
  const isLoading = useSelector(isFetchingUserSelector)
  const [isOpen, setIsOpen] = useState(true)
  const [optionValue, setOptionValue] = useState("")
  const [checkboxValues, setCheckboxValues] = useState<string[]>([])
  const [otherValue, setOtherValue] = useState("")
  const [page, setPage] = useState<number>(0)
  const [error, setError] = useState("")
  const [showIntro, setShowIntro] = useState(Boolean(introPage))

  const getQuestionId = (questions: Question[]) => (questions.length <= 1 ? questions[0].id : questions[page].id)

  const handleNext = async () => {
    setError("")

    if (
      (!optionValue && page >= 0 && !showIntro && checkboxValues.length < 1) ||
      (optionValue === "other" && !otherValue) ||
      (checkboxValues.includes("other") && !otherValue)
    ) {
      if (optionValue === "other" || checkboxValues.includes("other")) {
        setError("You need to fill in the Other reason.")
      } else {
        setError("You need to select an option.")
      }
      return
    }

    if (page >= 0 && !showIntro) {
      postQuestionnaire({
        questionnaire_id: getQuestionId(questions),
        completed: true,
        questionnaire_response: calculateResponse(),
      })
    } else if (showIntro) {
      setShowIntro(false)
    }
  }

  const calculateResponse = () => {
    if (checkboxValues.length > 0) {
      // Since we are using "," to separate values, we are making sure to remove any user inserted commas
      return checkboxValues.map(checkboxValue => (checkboxValue === "other" ? otherValue.replace(/,/g, "") : checkboxValue)).join()
    }
    return optionValue === "other" ? otherValue : optionValue
  }

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherValue("")
    setError("")
    setOptionValue(event.target.value)
  }

  const handleCheckBoxes = (value: string) => {
    setError("")

    if (checkboxValues.includes(value)) {
      setCheckboxValues(checkboxValues.filter(val => val !== value))
    } else {
      setCheckboxValues([...checkboxValues, value])
    }

    if (checkboxValues.includes("other") && value === "other") {
      setOtherValue("")
    }
  }

  const postQuestionnaire = async (current: QuestionnaireType) => {
    try {
      const response = await axios.post(`/api/v1/org/${orgID}/questionnaire/${current.questionnaire_id}`, current, addAuth(token))

      if (response.status === 200 && !!user && !!organizations && !!questionnairesByOrg) {
        dispatch(fetchAuthInfo(false))

        if (page + 1 < questions.length) {
          setPage(page + 1)
          setOptionValue("")
          setCheckboxValues([])
          setOtherValue("")
        }
      }
    } catch (error) {
      handleError(error, "Post questionnaire error")
      setError(generateErrorMessage(error))
    }
  }

  const handleClose = async () => {
    try {
      const response = await axios.post(
        `/api/v1/org/${orgID}/questionnaire/${getQuestionId(questions)}`,
        {
          completed: true,
        },
        addAuth(token),
      )

      if (response.status === 200 && !!user && !!organizations && !!questionnairesByOrg) {
        setIsOpen(false)
      }
    } catch (error) {
      handleError(error, "Post questionnaire error")
      setError(generateErrorMessage(error))
    }
  }

  const generateLabel = (label: string, type: string | undefined, value: string) => {
    const hasOther = optionValue === value || checkboxValues.includes(value)
    return type === "input" ? (
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        value={otherValue}
        inputProps={{
          "data-testid": "questionnaire-input",
        }}
        onChange={e => {
          setError("")
          setOtherValue(hasOther ? e.target.value : "")
        }}
        disabled={!hasOther}
        size="small"
        style={{ width: "100%" }}
      />
    ) : (
      label
    )
  }

  const currentForm = questions[page]
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="questionnaire-dialog-title"
      aria-describedby="questionnaire-dialog-description"
      onClose={handleClose}
      className={classes.root}
    >
      <DialogContent className={classes.content}>
        <CancelIcon className={classes.closeBtn} onClick={handleClose} data-testid="alerts-close-button" />
        {!showIntro && questions.length > 1 ? (
          <div className={classes.steps}>
            {page + 1} of {questions.length}
          </div>
        ) : null}
        <div className={classes.wrapper}>
          {isLoading && (
            <div className={classes.loading}>
              <LoadingMessage />
            </div>
          )}

          {showIntro && introPage && introPage}

          {!showIntro && currentForm && (
            <Fragment>
              <Typography variant="h2" className={classes.formTitle} data-testid={`Questionnaire-${currentForm.title}-question`}>
                {currentForm.title}
              </Typography>

              <FormControl component="fieldset">
                {currentForm.type === "checkboxes" && (
                  <FormGroup>
                    {currentForm.options.map(({ value, label, type }) => (
                      <FormControlLabel
                        className={classes.optionContainer}
                        style={{ width: "100%" }}
                        control={
                          <Checkbox
                            color="primary"
                            checked={checkboxValues.includes(value)}
                            onChange={() => handleCheckBoxes(value)}
                            name={value}
                          />
                        }
                        label={generateLabel(label, type, value)}
                        key={value}
                        data-testid={`Questionnaire-${label}`}
                      />
                    ))}
                  </FormGroup>
                )}
                {currentForm.type === "radio" && (
                  <RadioGroup
                    aria-label={`questionnaire-${currentForm.id}`}
                    name={`questionnaire-${currentForm.id}`}
                    value={optionValue}
                    onChange={handleFormChange}
                    color="primary"
                  >
                    {currentForm.options.map(({ value, label, type }) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio color="primary" className={classes.option} />}
                        label={generateLabel(label, type, value)}
                        className={classes.optionContainer}
                        data-testid={`Questionnaire-${label}`}
                      />
                    ))}
                  </RadioGroup>
                )}
              </FormControl>
            </Fragment>
          )}
          {error && <ErrorMessage message={error} />}

          <DialogActions className={classes.actions}>
            <Button variant="contained" color="primary" onClick={handleNext} data-testid={testIds.nextFinishButton}>
              {questions.length === page + 1 ? "Finish" : "Next"}
            </Button>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  )
}
