export const CREATE_RECEIVER = "CREATE_RECEIVER"
export const CREATE_RECEIVER_FAILED = "CREATE_RECEIVER_FAILED"
export const CREATE_RECEIVER_SUCCESS = "CREATE_RECEIVER_SUCCESS"
export const FETCH_RECEIVERS = "FETCH_RECEIVERS"
export const FETCH_RECEIVERS_SUCCESS = "FETCH_RECEIVERS_SUCCESS"
export const FETCH_RECEIVERS_FAILED = "FETCH_RECEIVERS_FAILED"
export const UPDATE_RECEIVER = "UPDATE_RECEIVER"
export const UPDATE_RECEIVER_FAILED = "UPDATE_RECEIVER_FAILED"
export const UPDATE_RECEIVER_SUCCESS = "UPDATE_RECEIVER_SUCCESS"
export const DELETE_RECEIVER = "DELETE_RECEIVER"
export const DELETE_RECEIVER_FAILED = "DELETE_RECEIVER_FAILED"
export const DELETE_RECEIVER_SUCCESS = "DELETE_RECEIVER_SUCCESS"

export type ReceiverActions =
  | CreateReceiverAction
  | CreateReceiverFailedAction
  | CreateReceiverSuccessAction
  | FetchReceiversAction
  | FetchReceiversFailedAction
  | FetchReceiversSuccessAction
  | UpdateReceiverAction
  | UpdateReceiverFailedAction
  | UpdateReceiverSuccessAction
  | DeleteReceiverAction
  | DeleteReceiverFailedAction
  | DeleteReceiverSuccessAction

// create receiver actions
export interface CreateReceiverAction {
  type: typeof CREATE_RECEIVER
  orgID: string
  receiver: Receiver
  clusterID?: string
}

export const createReceiver = (orgID: string, receiver: Receiver, clusterID?: string) => {
  const action: CreateReceiverAction = {
    type: CREATE_RECEIVER,
    orgID: orgID,
    receiver: receiver,
    clusterID: clusterID,
  }

  return action
}
export interface CreateReceiverFailedAction {
  type: typeof CREATE_RECEIVER_FAILED
  orgID: string
  errorMessage: string
  clusterID?: string
}

export const createReceiverFailed = (orgID: string, errorMessage: string, clusterID?: string): CreateReceiverFailedAction => ({
  type: CREATE_RECEIVER_FAILED,
  orgID,
  errorMessage,
  clusterID,
})

export interface CreateReceiverSuccessAction {
  type: typeof CREATE_RECEIVER_SUCCESS
  orgID: string
  receiver: Receiver
  clusterID?: string
}
export const createReceiverSuccess = (orgID: string, receiver: Receiver, clusterID?: string): CreateReceiverSuccessAction => ({
  type: CREATE_RECEIVER_SUCCESS,
  orgID,
  receiver,
  clusterID,
})

// fetch receiver actions
export interface FetchReceiversAction {
  type: typeof FETCH_RECEIVERS
  orgID: string
  clusterID?: string
}

export const fetchReceivers = (orgID: string, clusterID?: string): FetchReceiversAction => ({
  type: FETCH_RECEIVERS,
  orgID,
  clusterID,
})
export interface FetchReceiversFailedAction {
  type: typeof FETCH_RECEIVERS_FAILED
  orgID: string
  errorMessage: string
  clusterID?: string
}
export const fetchReceiversFailed = (orgID: string, errorMessage: string, clusterID?: string): FetchReceiversFailedAction => ({
  type: FETCH_RECEIVERS_FAILED,
  orgID,
  errorMessage,
  clusterID,
})

export interface FetchReceiversSuccessAction {
  type: typeof FETCH_RECEIVERS_SUCCESS
  orgID: string
  receivers: Receiver[]
  clusterID?: string
}

export const fetchReceiversSuccess = (orgID: string, receivers: Receiver[], clusterID?: string): FetchReceiversSuccessAction => ({
  type: FETCH_RECEIVERS_SUCCESS,
  orgID,
  receivers,
  clusterID,
})

// update receiver actions
export interface UpdateReceiverAction {
  type: typeof UPDATE_RECEIVER
  orgID: string
  receiver: Receiver
  clusterID?: string
}

export const updateReceiver = (orgID: string, receiver: Receiver, clusterID?: string): UpdateReceiverAction => ({
  type: UPDATE_RECEIVER,
  orgID,
  receiver,
  clusterID,
})
export interface UpdateReceiverFailedAction {
  type: typeof UPDATE_RECEIVER_FAILED
  orgID: string
  errorMessage: string
  clusterID?: string
}

export const updateReceiverFailed = (orgID: string, errorMessage: string, clusterID?: string): UpdateReceiverFailedAction => ({
  type: UPDATE_RECEIVER_FAILED,
  orgID,
  errorMessage,
  clusterID,
})
export interface UpdateReceiverSuccessAction {
  type: typeof UPDATE_RECEIVER_SUCCESS
  orgID: string
  receiver: Receiver
  clusterID?: string
}

export const updateReceiverSuccess = (orgID: string, receiver: Receiver, clusterID?: string): UpdateReceiverSuccessAction => ({
  type: UPDATE_RECEIVER_SUCCESS,
  orgID,
  receiver,
  clusterID,
})

// delete receiver actions
export interface DeleteReceiverAction {
  type: typeof DELETE_RECEIVER
  orgID: string
  receiverID: string | number
  clusterID?: string
}

export const deleteReceiver = (orgID: string, receiverID: string | number, clusterID?: string): DeleteReceiverAction => ({
  type: DELETE_RECEIVER,
  orgID,
  receiverID,
  clusterID,
})
export interface DeleteReceiverFailedAction {
  type: typeof DELETE_RECEIVER_FAILED
  orgID: string
  errorMessage: string
  clusterID?: string
}

export const deleteReceiverFailed = (orgID: string, errorMessage: string, clusterID?: string): DeleteReceiverFailedAction => ({
  type: DELETE_RECEIVER_FAILED,
  orgID,
  errorMessage,
  clusterID,
})
export interface DeleteReceiverSuccessAction {
  type: typeof DELETE_RECEIVER_SUCCESS
  orgID: string
  receiver: Receiver
  clusterID?: string
}

export const deleteReceiverSuccess = (orgID: string, receiver: Receiver, clusterID?: string): DeleteReceiverSuccessAction => ({
  type: DELETE_RECEIVER_SUCCESS,
  orgID,
  receiver,
  clusterID,
})
