import { isEmpty } from "lodash"

// Query Keys are important to React Query
// Moved them all in the same file so we have
// them all in one place in Alphabetical order

// Certificates
const certificates = {
  all: (orgID: string) => [{ entity: "certificates", orgID }] as const,
}

// Cert inventory endpoints
const certInventory = {
  detail: (orgID: string, cluster: string) => [{ entity: "cert-inventory", orgID, cluster }] as const,
}

// Clusters
const clusters = {
  all: (orgID: string) => [{ entity: "clusters", orgID }] as const,
}

// Discovered Certificates
const discoveredCertificates = {
  all: (orgID: string) => [{ entity: "discovered-certificates", orgID }] as const,
  list: (orgID: string, expiryIn: number | null, namespace: string | null, cluster: string | null) => {
    const params: { expiryIn?: number; namespace?: string; cluster?: string } = {}
    if (expiryIn && expiryIn !== 0) {
      params.expiryIn = expiryIn
    }
    if (namespace) {
      params.namespace = namespace
    }
    if (cluster) {
      params.cluster = cluster
    }

    return !isEmpty(params) ? ([...discoveredCertificates.all(orgID), params] as const) : discoveredCertificates.all(orgID)
  },
  detail: (orgID: string, id: string) => [...discoveredCertificates.all(orgID), id] as const,
}

// Issuers
const issuers = {
  all: (orgID: string) => [{ entity: "issuers", orgID }] as const,
}

// Organisation Overview stats
const overview = {
  all: (orgID: string) => [{ entity: "overview", orgID }] as const,
}

// Service Accounts
const svc_accounts = {
  all: (orgID: string) => [{ entity: "svc_accounts", orgID }] as const,
}

// Users
const users = {
  all: (orgID: string) => [{ entity: "users", orgID }] as const,
}

export const QUERYKEYS = {
  certificates,
  certInventory,
  clusters,
  discoveredCertificates,
  issuers,
  overview,
  svc_accounts,
  users,
}
