import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  [x: string]: unknown
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& path": {
        stroke: theme.palette.grey[600],
      },
    },
  }),
)

export function ListIcon(props: Props): ReactElement {
  const classes = useStyles()
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.root} {...props}>
      <path d="M9 6h11M9 12h11M9 18h11M5 6v.01M5 12v.01M5 18v.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
