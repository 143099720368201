import { format } from "date-fns"

export function formatDate(date?: string | Date): string {
  if (!date || date === "") {
    return ""
  }
  try {
    if (date instanceof Date) {
      return format(date, "yyyy/MM/dd HH:mm:ss")
    }
    return format(new Date(date), "yyyy/MM/dd HH:mm:ss")
  } catch {
    return date.toString()
  }
}
