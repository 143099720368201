import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Paper, Typography, createStyles, makeStyles, Theme, Grid } from "@material-ui/core"

import { fetchAuthInfo } from "@actions/auth"
import { userSelector, organizationsSelector, isFetchingUserSelector, errorSelector } from "@selectors/auth"
import { currentOrgSelector } from "@selectors/orgs"

import { Page } from "@components/Page"
import { LoadingMessage } from "@components/LoadingMessage"
import { ErrorMessage } from "@components/ErrorMessage"
import { OrganizationsMenu } from "@components/OrganizationsMenu"
import { PrivateBetaMsg } from "./PrivateBetaMsg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5, 0),
      display: "flex",
    },
    pageSection: {
      height: "100%",
      padding: theme.spacing(4),
      "& h5": {
        marginBottom: theme.spacing(2),
      },
    },
    profilePic: {
      borderRadius: 100,
      overflow: "hidden",
      display: "inline-block",
      lineHeight: 0,
      marginBottom: theme.spacing(2),
      width: "10rem",
      height: "10rem",
      "& img": {
        width: "100%",
      },
    },
    profileSection: {
      textAlign: "center",
    },
    rolesSection: {
      marginTop: theme.spacing(4),
    },
    tag: {
      display: "inline-block",
      border: `2px solid ${theme.palette.primary.main}`,
      margin: theme.spacing(0, 2, 0, 0),
      padding: theme.spacing(1),
      borderRadius: 5,
      textTransform: "capitalize",
    },
  }),
)

export const UserProfile = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(isFetchingUserSelector)
  const user = useSelector(userSelector)
  const organizations = useSelector(organizationsSelector)
  const error = useSelector(errorSelector)
  const currentOrgID = useSelector(currentOrgSelector)

  let currentOrg: OrganizationMembershipType | undefined = undefined
  if (organizations) {
    currentOrg = organizations.find(org => org.id === currentOrgID)
  }

  useEffect(() => {
    if (!isLoading && !user && !error) {
      dispatch(fetchAuthInfo(false))
    }
  }, [isLoading, user, organizations, error, dispatch])

  const renderContent = () => {
    if (error) {
      return <ErrorMessage message={error} />
    }

    if (user && organizations && organizations.length === 0) {
      return <PrivateBetaMsg user={user} />
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item sm={12} lg={4}>
            <Paper className={`${classes.pageSection} ${classes.profileSection}`}>
              {isLoading || !user ? (
                <LoadingMessage label="loading profile" />
              ) : (
                <>
                  <div className={classes.profilePic}>
                    <img src={user!.picture_url} alt={`${user!.name} profile photo`} />
                  </div>
                  <Typography gutterBottom variant="h5">
                    {user!.name}
                  </Typography>
                  <Typography gutterBottom variant="body1" color="textSecondary">
                    {user!.email}
                  </Typography>
                </>
              )}
            </Paper>
          </Grid>
          <Grid item sm={12} lg={8}>
            <Paper className={classes.pageSection}>
              <Typography gutterBottom variant="h5">
                Organizations
              </Typography>
              {isLoading || !organizations || !currentOrg ? (
                <LoadingMessage label="loading profile" />
              ) : (
                <>
                  <OrganizationsMenu />
                  <div className={classes.rolesSection}>
                    <Typography gutterBottom variant="h5">
                      Roles
                    </Typography>
                    {currentOrg.roles.map(role => (
                      <div className={classes.tag} key={role}>
                        {role}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <Page title="User profile" fixedWidth>
      {renderContent()}
    </Page>
  )
}
