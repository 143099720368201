import { dump } from "js-yaml"

import { ACMEYaml } from "./issuers/acme/renderACMEYaml"
import { VaultYaml } from "./issuers/vault/renderVaultYaml"
import { VenafiYaml } from "./issuers/venafi/renderVenafiYaml"
import { GoogleCASYaml } from "./issuers/googlecas/renderGoogleCASYaml"
import { AwsPCAYaml } from "./issuers/awspca/renderAwsPCAYaml"

type IssuerYaml = ACMEYaml | VenafiYaml | VaultYaml | GoogleCASYaml | AwsPCAYaml

export const renderIssuerYaml = (
  name: string,
  useNamespace: boolean,
  namespace: string,
  issuerYaml: IssuerYaml,
  overrideKind = "",
  overrideAPIVersion = "",
): string => {
  return dump({
    apiVersion: overrideAPIVersion ? overrideAPIVersion : "cert-manager.io/v1",
    kind: overrideKind ? overrideKind : useNamespace ? "Issuer" : "ClusterIssuer",
    metadata: {
      name: name,
      namespace: useNamespace ? namespace : undefined,
    },
    spec: issuerYaml,
  })
}

export const renderSecretYaml = (name: string, namespace: string, data: { [key: string]: string }) => {
  return dump({
    apiVersion: "v1",
    kind: "Secret",
    type: "Opaque",
    metadata: {
      name: name,
      namespace: namespace,
    },
    data: data,
  })
}
