import React, { useState, ChangeEvent } from "react"
import { createStyles, makeStyles, Theme as MUITheme, Box, Tab, Tabs, Typography } from "@material-ui/core"
import { TabContext, TabPanel } from "@material-ui/lab"

import { Snippet } from "@components/Snippet"
import { DownloadFile } from "@components/DownloadFile"
import { CreateServiceAccountResponse } from "@lib/serviceAccounts"

const useStyles = makeStyles((theme: MUITheme) =>
  createStyles({
    box: {
      margin: theme.spacing(2, 0),
      width: "100%",
    },
    tabs: {
      borderBottom: "1px solid rgba(0,0,0,0.12)",
    },
  }),
)

export const FormatJSON = "json"
export const FormatFields = "fields"

export type CredentialsFormat = typeof FormatJSON | typeof FormatFields

export type CredentialsProps = {
  data: CreateServiceAccountResponse
  defaultFormat: CredentialsFormat
}

export const Credentials = ({ data, defaultFormat }: CredentialsProps) => {
  const classes = useStyles()
  const [value, setValue] = useState(defaultFormat)
  const handleChange = (event: ChangeEvent<unknown>, newValue: string) => {
    setValue(newValue as CredentialsFormat)
  }

  const jsonData = JSON.stringify(data, null, 2)

  const jsonContent = (
    <>
      <Snippet text={jsonData} language="json" />
      <DownloadFile text="Download credentials" type="text/json" filename="credentials.json" content={jsonData} />
    </>
  )

  const fieldsContent = (
    <>
      <Typography color="textSecondary">User ID</Typography>
      <Snippet text={data.user_id} language="plaintext" />
      <Typography color="textSecondary">User Secret</Typography>
      <Snippet text={data.user_secret} language="plaintext" />
    </>
  )

  return (
    <Box className={classes.box}>
      <TabContext value={value}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="Service account credentials"
          indicatorColor="primary"
        >
          <Tab label="Fields" value={FormatFields} />
          <Tab label="JSON" value={FormatJSON} />
        </Tabs>
        <TabPanel value={FormatJSON}>{jsonContent}</TabPanel>
        <TabPanel value={FormatFields}>{fieldsContent}</TabPanel>
      </TabContext>
    </Box>
  )
}
