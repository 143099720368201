import React, { useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, createStyles, makeStyles, Theme, CircularProgress, Paper } from "@material-ui/core"

import { rudderanalytics as rudderstack } from "@utils/rudderstack"
import { fetchClusters, addPendingCluster } from "@actions/clusters"
import { clustersNetworkSelector } from "@selectors/clustersNetwork"
import { clustersSelector } from "@selectors/clusters"
import { pathToCertInventory } from "@routes"
import { clusterExists } from "../helpers"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "sticky",
      top: theme.spacing(2),
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-start",
      borderRadius: 5,
      background: theme.palette.background.default,
      boxShadow: "none",
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      fontSize: "1.2rem",
      justifyContent: "center",
      margin: theme.spacing(2, 0),
      zIndex: 3,
      "& span": {
        margin: theme.spacing(0, 2),
      },
    },
    successButton: {
      margin: theme.spacing(2, 0),
    },
    success: {
      margin: theme.spacing(3, 0),
    },
    spinner: {
      marginTop: theme.spacing(6),
    },
  }),
)

type CheckingAgentStatusProps = {
  orgID: string
  clusterName: string
}

export const CheckingAgentStatus = ({ orgID, clusterName }: CheckingAgentStatusProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isLoading, loadingError } = useSelector(clustersNetworkSelector)
  const clusters = useSelector(clustersSelector)
  const navigate = useNavigate()
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const hasCluster = clusterExists(clusters, clusterName)

  const clearIntervalRef = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
    }
  }

  const handleViewClick = () => {
    navigate(pathToCertInventory(orgID))
  }

  useEffect(() => {
    dispatch(addPendingCluster(orgID, clusterName))
  }, [])

  useEffect(() => {
    if (hasCluster) {
      clearIntervalRef()
      rudderstack.track("add_cluster_wizard__registered_successfully", {
        cluster_name: clusterName,
      })
    } else if (!isLoading || loadingError) {
      intervalRef.current = setInterval(() => {
        dispatch(fetchClusters(orgID))
      }, 3000)
    }

    return clearIntervalRef
  }, [clusters, dispatch, isLoading, loadingError, clusterName, orgID])

  return (
    <Paper className={classes.container}>
      {!hasCluster && (
        <>
          <CircularProgress size={30} />
          <span>Waiting for the TLS Protect for Kubernetes Agent to send data...</span>
        </>
      )}
      {hasCluster && (
        <>
          <span>Your cluster has been added</span>
          <Button variant="contained" color="primary" onClick={handleViewClick}>
            View Clusters
          </Button>
        </>
      )}
    </Paper>
  )
}
