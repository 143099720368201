import React, { useState, useEffect } from "react"
import { Typography, createStyles, makeStyles, Theme, Button, Dialog, FormHelperText, TextField, Checkbox } from "@material-ui/core"
import { format } from "date-fns"
import CancelIcon from "@material-ui/icons/Cancel"

import { ServiceAccount, useDeleteServiceAccount } from "@lib/serviceAccounts"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      transition: "all 0.3s ease-in-out",
      borderLeft: `3px solid transparent`,
      fontSize: "0.875rem",
      "&:hover": {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.default,
        boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
      },
      "& td": {
        position: "relative",
        padding: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        verticalAlign: "middle",
        wordBreak: "break-word",
        [theme.breakpoints.down(1024)]: {
          whiteSpace: "nowrap",
        },
      },
    },
    rowSelected: {
      transition: "all 0.3s ease-in-out",
      fontSize: "0.875rem",
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.default,
      "&:hover": {
        boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
      },
      "& td": {
        position: "relative",
        padding: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        verticalAlign: "middle",
        wordBreak: "break-word",
        [theme.breakpoints.down(1024)]: {
          whiteSpace: "nowrap",
        },
      },
    },
    email: {
      fontWeight: 300,
      color: theme.palette.text.secondary,
    },
    checkbox: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
    stats: {
      color: theme.palette.text.secondary,
    },
    role: {
      textTransform: "uppercase",
      marginRight: theme.spacing(2),
      fontSize: "0.875rem",
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: 1.6,
      [theme.breakpoints.down(700)]: {
        marginBottom: theme.spacing(2),
      },
    },
    error: {
      color: theme.palette.error.main,
    },
    icon: {
      display: "inline-flex",
      marginRight: theme.spacing(1.25),
      background: theme.palette.primary.main,
      width: 30,
      height: 30,
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      "& svg path": {
        stroke: "#fff",
      },
    },
    button: {
      background: theme.palette.error.main,
    },
    dialog: {
      padding: theme.spacing(4),
      position: "relative",
    },
    closeBtn: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    dialogBtn: {
      marginTop: theme.spacing(2),
      background: theme.palette.error.main,
    },
    serviceAccountInput: {
      minWidth: "20rem",
      marginBottom: theme.spacing(1),
    },
    formSection: {},
  }),
)

type Props = {
  serviceAccount: ServiceAccount
  onChange: (userID: string) => void
  selected: boolean
}

export function ServiceAccountRow({ serviceAccount: { name, created_at, last_login, user_id, email }, onChange, selected }: Props) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [serviceAccountName, setServiceAccountName] = useState("")
  const { isError, mutate, error, isSuccess, isLoading } = useDeleteServiceAccount()

  const handleClose = () => {
    setIsOpen(false)
    setServiceAccountName("")
  }
  const handleDelete = (user_id: string) => {
    mutate(user_id)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceAccountName(event.target.value)
  }

  useEffect(() => {
    if (isSuccess) {
      handleClose()
    }
  }, [isSuccess])

  const lastLogin = last_login ? format(new Date(last_login), "yyyy/MM/dd HH:mm:ss") : "-"

  return (
    <>
      <tr className={selected ? classes.rowSelected : classes.row} data-tut="member-row">
        <td>
          <div className={classes.checkbox}>
            <Checkbox color="primary" onChange={() => onChange(email)} checked={selected} />
          </div>
        </td>
        <td>
          <Typography variant="body1" display="inline" data-testid="member-row-title">
            {name}
          </Typography>
        </td>
        <td>
          <span className={classes.email}> {format(new Date(created_at), "yyyy/MM/dd HH:mm:ss")}</span>
        </td>
        <td className={classes.stats}>
          <span className={classes.role}>{lastLogin}</span>
        </td>
        <td style={{ textAlign: "right" }}>
          <Button variant="contained" color="primary" className={classes.button} onClick={() => setIsOpen(true)} size="small">
            Remove
          </Button>
        </td>
      </tr>

      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <div className={classes.dialog}>
          <CancelIcon className={classes.closeBtn} onClick={handleClose} data-testid="alerts-close-button" />
          <Typography gutterBottom variant="h5">
            {`Are you sure you want to delete ${name}?`}
          </Typography>
          <Typography>
            Deleting a service account might cause problems if another system is using it. Make sure it is safe to delete this service
            account.
          </Typography>
          <br />
          <Typography>
            Enter the service account email to confirm deletion of <strong>{name}</strong>
          </Typography>
          <br />
          <div className={classes.formSection}>
            <TextField
              className={classes.serviceAccountInput}
              label="Service Account Email"
              variant="outlined"
              value={serviceAccountName}
              onChange={handleChange}
              type="text"
              size="small"
              error={Boolean(error)}
              inputProps={{
                "data-testid": "service-account-input",
              }}
              disabled={isLoading}
            />
          </div>
          <Button
            disabled={isLoading || serviceAccountName !== name}
            variant="contained"
            color="primary"
            className={classes.dialogBtn}
            onClick={() => handleDelete(user_id)}
          >
            Delete Service Account
          </Button>

          {isError && <FormHelperText className={classes.error}>{error?.message}</FormHelperText>}
        </div>
      </Dialog>
    </>
  )
}
