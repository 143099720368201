import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { configDemoCluster } from "@actions/settings"
import { currentOrgSelector } from "@selectors/orgs"

import { createStyles, makeStyles, Paper, Typography, Theme, Button, Link } from "@material-ui/core"
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects"
import { NavButton } from "@components/Navigation/NavButton"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(8, 0),
      textAlign: "center",
    },
    button: {
      margin: theme.spacing(2),
    },
    hintIcon: {
      marginBottom: "-0.4rem",
    },
    noClustersContainer: {
      background: theme.palette.grey[100],
      maxWidth: "38rem",
      margin: theme.spacing(0, "auto", 8, "auto"),
      padding: theme.spacing(4),
      borderRadius: 5,
    },
    demoContainer: {
      maxWidth: "38rem",
      padding: theme.spacing(2, 4),
      margin: theme.spacing(0, "auto"),
    },
  }),
)

type Props = {
  pathToNewCluster: string
}

export function NoClusters({ pathToNewCluster }: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const orgID = useSelector(currentOrgSelector)

  const showDemoCluster = () => {
    dispatch(configDemoCluster(orgID, true))
  }

  return (
    <Paper className={classes.root}>
      <Paper className={classes.noClustersContainer}>
        <Typography gutterBottom variant="h5">
          You don't have any clusters yet.
        </Typography>
        <Typography gutterBottom variant="body1">
          Start by installing an agent in a cluster you want to monitor.
        </Typography>
        <Typography gutterBottom variant="body2" color="textSecondary">
          <EmojiObjectsIcon className={classes.hintIcon} />
          Hint: TLS Protect for Kubernetes works best with clusters using{" "}
          <Link underline="always" target="_blank" rel="noreferrer" href="https://cert-manager.io/docs/installation/">
            cert-manager
          </Link>
          .
        </Typography>
        <NavButton className={classes.button} variant="contained" color="primary" href={pathToNewCluster}>
          Connect Cluster
        </NavButton>
      </Paper>
      <div className={classes.demoContainer}>
        <Typography gutterBottom variant="body1">
          Alternatively, you can get access to a demo cluster to start discovering TLS Protect for Kubernetes.
        </Typography>
        <Button className={classes.button} variant="contained" color="primary" onClick={showDemoCluster}>
          Show Demo Cluster
        </Button>
      </div>
    </Paper>
  )
}
