import React, { ReactElement } from "react"
import { SlackIcon, TeamsIcon, PagerDutyIcon, ZoomIcon } from "@components/Icons"
import { Channel } from "./types"

export const slack = "slack"
export const teams = "microsoft-teams"
export const zoom = "zoom-chat"
export const pagerDuty = "pager-duty"

export const getReceiverConfig = (
  type: Channel | undefined,
): {
  icon: ReactElement
  urlExample: string
  docLink: string
  name: string
  label: string
  label2: string
} | null => {
  switch (type) {
    case slack:
      return {
        icon: <SlackIcon />,
        urlExample: "e.g. https://hooks.slack.com/...",
        docLink: "https://slack.com/apps/new/A0F7XDUAZ-incoming-webhooks",
        name: "Slack",
        label: "Slack hook URL",
        label2: "",
      }
    case teams:
      return {
        icon: <TeamsIcon />,
        urlExample: "e.g. https://example.webook.office.com/...",
        docLink: "https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook",
        name: "Microsoft Teams",
        label: "Microsoft Teams hook URL",
        label2: "",
      }
    case pagerDuty:
      return {
        icon: <PagerDutyIcon />,
        urlExample: "e.g. https://events.eu.pagerduty.com/v2/...",
        docLink: "https://support.pagerduty.com/docs/services-and-integrations#create-a-generic-events-api-integration",
        name: "PagerDuty",
        label: "PagerDuty Service Region",
        label2: "PagerDuty Integration Key",
      }
    case zoom:
      return {
        icon: <ZoomIcon />,
        urlExample: "e.g. https://inbots.zoom.us/...",
        docLink: "https://support.zoom.us/hc/en-us/articles/4418193782029-Using-Zoom-s-Incoming-Webhook-Chatbot",
        name: "Zoom Team Chat",
        label: "Zoom Team Chat hook URL",
        label2: "Zoom Team Chat verification key",
      }
    default:
      return null
  }
}

export const getReceiverType = (receiver: Receiver): Channel | undefined => {
  if (receiver.slack_webhook_url) {
    return slack
  } else if (receiver.microsoft_teams_webhook_url) {
    return teams
  } else if (receiver.pager_duty_integration_url) {
    return pagerDuty
  } else if (receiver.zoom_chat_webhook_url) {
    return zoom
  } else {
    return undefined
  }
}

export const extractReceiverUrl = (receiver: Receiver): string | undefined => {
  if (receiver.slack_webhook_url) {
    return receiver.slack_webhook_url
  } else if (receiver.microsoft_teams_webhook_url) {
    return receiver.microsoft_teams_webhook_url
  } else if (receiver.pager_duty_integration_url) {
    return receiver.pager_duty_integration_url
  } else if (receiver.zoom_chat_webhook_url) {
    return receiver.zoom_chat_webhook_url
  } else {
    return undefined
  }
}
