import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  [x: string]: unknown
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& path, & circle": {
        stroke: theme.palette.error.main,
      },
    },
  }),
)

export function CloseCircleIcon(props: Props): ReactElement {
  const classes = useStyles()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classes.root}
      {...props}
    >
      <circle cx="12" cy="12" r="9" />
      <path d="M10 10l4 4m0-4l-4 4" />
    </svg>
  )
}
