import React from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"

import Button from "@material-ui/core/Button"
import Chip from "@material-ui/core/Chip"
import Link from "@material-ui/core/Link"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import { Page } from "./Page"

const useChipStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}))

export const StyleGuide: React.FC = () => {
  const styles = useChipStyles()
  return (
    <Page title="StyleGuide">
      <Paper style={{ padding: "2rem" }}>
        <Typography variant="h4">Buttons</Typography>
        <Button variant="contained" color="primary">
          Primary Contained
        </Button>
        <Button variant="outlined" color="primary">
          Primary Outlined
        </Button>
        <Button variant="text" color="primary">
          Primary Text
        </Button>
        <Button variant="contained" color="secondary">
          Secondary Contained
        </Button>
        <Button variant="outlined" color="secondary">
          Secondary Outlined
        </Button>
        <Button variant="text" color="secondary">
          Secondary Text
        </Button>
        <Button variant="contained" color="primary" size="small">
          Primary Contained
        </Button>
        <Button variant="outlined" color="primary" size="small">
          Primary Outlined
        </Button>
        <Button variant="text" color="primary" size="small">
          Primary Text
        </Button>
        <Button variant="contained" color="secondary" size="small">
          Secondary Contained
        </Button>
        <Button variant="outlined" color="secondary" size="small">
          Secondary Outlined
        </Button>
        <Button variant="text" color="secondary" size="small">
          Secondary Text
        </Button>
      </Paper>
      <Paper style={{ padding: "2rem", marginTop: "2rem" }}>
        <Typography variant="h4">Links</Typography>
        <Link color="primary">Primary</Link>
        <Link color="secondary">Secondary</Link>
      </Paper>
      <Paper style={{ padding: "2rem", marginTop: "2rem" }}>
        <Typography variant="h4">Chips</Typography>
        <Chip color="primary" label="Primary" />
        <Chip color="secondary" label="Secondary" />
        <Chip className={styles.error} label="Error" />
        <Chip className={styles.warning} label="Warning" />
        <Chip className={styles.success} label="Success" />
        <Chip disabled label="Disabled" />
      </Paper>
    </Page>
  )
}
