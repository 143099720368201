import React, { ReactElement, CSSProperties } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      letterSpacing: "0.03rem",
      fontWeight: 600,
      fontSize: "0.75rem",
      textTransform: "uppercase",
      "& tr": {
        backgroundColor: theme.palette.background.default,
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
      "& th": {
        boxSizing: "content-box",
        padding: theme.spacing(1.5),
        textAlign: "left",
        "&:last-child": {
          textAlign: "center",
        },
      },
    },
  }),
)

type TableHeaderProps = { rows: Row[] }
type Row = {
  label: string
  size?: number
  style?: CSSProperties
}

export function TableHeader({ rows }: TableHeaderProps): ReactElement {
  const classes = useStyles()

  return (
    <thead className={classes.root}>
      <tr>
        {rows.map(({ label, size, style }, index) => (
          <th key={`${label}-${index}`} style={{ width: size + "rem", ...style }}>
            {label}
          </th>
        ))}
      </tr>
    </thead>
  )
}
