import React from "react"
import { Typography, makeStyles, Theme, createStyles } from "@material-ui/core"

import { MessageContainer } from "@components/MessageContainer"
import { WarningIcon } from "@components/Icons"
import { getColor } from "@components/StatusIndicator/Badge"

const useStyles = (severity: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& path": {
          stroke: severity ? getColor(theme, severity) : theme.palette.warning.main,
        },
      },
    }),
  )

type Props = {
  messages?: string[]
  severity?: string
  id?: string
}

export function Issues({ messages, severity = "", id = "" }: Props) {
  const classes = useStyles(severity)()

  if (!messages || messages.length === 0) {
    return <Typography>There aren't any issues to report.</Typography>
  }

  return (
    <div className={classes.root}>
      {messages.map((message, index) => (
        <MessageContainer icon={<WarningIcon />} key={index + id}>
          {message}
        </MessageContainer>
      ))}
    </div>
  )
}
