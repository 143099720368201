import React, { ChangeEvent, useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router"
import { useSelector } from "react-redux"
import { Button, Dialog, createStyles, makeStyles, Theme, Typography, TextField } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"

import { pathToCertInventory } from "@routes"
import { accessTokenSelector } from "@selectors/auth"
import { generateErrorMessage } from "@utils/errorHandling"
import { addAuth } from "@lib/auth"
import { LoadingMessage } from "@components/LoadingMessage"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(4),
      position: "relative",
    },
    button: {
      marginRight: theme.spacing(2),
      "&:hover": {
        background: theme.palette.error.main,
        color: "#fff",
      },
    },
    modalButton: {
      display: "block",
      background: theme.palette.error.main,
      color: "#fff",
      marginTop: theme.spacing(3),
      "&:hover": {
        background: theme.palette.error.light,
      },
    },
    successButton: {
      background: theme.palette.success.main,
      "&:hover": {
        background: theme.palette.success.light,
      },
    },
    title: {
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    input: {
      marginTop: theme.spacing(2),
    },
    closeIcon: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
  }),
)

type DeleteClusterProps = {
  cluster: string
  organization: string
}

export function DeleteCluster({ organization, cluster }: DeleteClusterProps) {
  const classes = useStyles()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState("")
  const [clusterValue, setClusterValue] = useState("")

  const accessToken = useSelector(accessTokenSelector)

  const handleClose = () => {
    if (isLoading) return

    setIsOpen(false)
    if (isSuccess) {
      redirect()
    }
  }

  const handleSubmit = async () => {
    if (clusterValue === cluster) {
      setIsLoading(true)
      try {
        await axios.delete(`/api/v1/org/${organization}/clusters/${cluster}`, addAuth(accessToken))
        setIsSuccess(true)
      } catch (error) {
        setError(`${generateErrorMessage(error)}. Try again later or contact support.`)
      } finally {
        setIsLoading(false)
      }
    } else {
      setError("Value does not match the cluster name you are trying to delete")
    }
  }
  const handleUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setClusterValue(event.currentTarget.value)
    setError("")
  }

  const redirect = () => {
    navigate(pathToCertInventory(organization))
  }

  return (
    <>
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="delete-cluster-button"
      >
        Delete cluster
      </Button>

      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="delete-dialog-title" aria-describedby="delete-dialog-description">
        <div className={classes.dialog}>
          <CancelIcon className={classes.closeIcon} onClick={handleClose} data-testid="delete-modal-close-button" />
          {isSuccess ? (
            <>
              <Typography id="delete-dialog-title" variant="h5" className={classes.title}>
                Cluster {cluster} was deleted successfully.
              </Typography>
              <Button variant="contained" className={`${classes.modalButton} ${classes.successButton}`} onClick={redirect}>
                Go back to clusters
              </Button>
            </>
          ) : (
            <>
              <Typography id="delete-dialog-title" variant="h5" className={classes.title}>
                Are you sure you want to delete {cluster}?
              </Typography>
              <Typography id="delete-dialog-description" gutterBottom>
                This will delete your cluster within the TLS Protect for Kubernetes platform, your actual cluster will not be affected.
                Further uploads by the agent will be rejected.
              </Typography>
              <Typography gutterBottom>
                Enter the cluster's name to confirm deletion of <strong>{cluster}</strong>.
              </Typography>
              <TextField
                inputProps={{
                  "data-testid": "delete-cluster-input",
                }}
                error={Boolean(error)}
                helperText={error}
                className={classes.input}
                label="Cluster name"
                variant="outlined"
                onChange={handleUpdate}
                value={clusterValue}
                disabled={isLoading}
                size="small"
              />
              {isLoading ? (
                <LoadingMessage label={`Deleting cluster ${cluster}...`} />
              ) : (
                <Button
                  variant="contained"
                  className={classes.modalButton}
                  onClick={handleSubmit}
                  data-testid="delete-cluster-modal-button"
                >
                  Delete cluster
                </Button>
              )}
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}
