import React, { ReactElement } from "react"
import classnames from "classnames"
import { format } from "date-fns"
import { Typography, makeStyles, createStyles, Theme } from "@material-ui/core"
import { Certificate } from "@lib/discoveredCertificates/types"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      borderLeft: `3px solid transparent`,
      fontSize: "0.875rem",
      "&:hover": {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.default,
        boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
      },
      "& td": {
        padding: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        verticalAlign: "middle",
      },
    },
    selectedRow: {
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.default,
      boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
    },
    namespace: {
      display: "block",
      fontWeight: 300,
      color: theme.palette.text.secondary,
      fontSize: "0.875rem",
    },
    status: {
      textAlign: "center",
    },
    title: {
      fontSize: "1rem",
      lineHeight: 1.3,
      color: theme.palette.text.primary,
      wordBreak: "break-all",
    },
    icon: {
      "& svg": {
        marginBottom: theme.spacing(-0.375),
      },
    },
  }),
)

type Props = {
  certificate: Certificate
  handleSelect: (res: Certificate) => void
  selected?: boolean
  icon?: ReactElement
}

export function ExpiringSoonRow({ certificate, handleSelect, selected }: Props) {
  const classes = useStyles()

  const handleClick = () => {
    handleSelect(certificate)
  }

  const rowClass = classnames(classes.row, { [classes.selectedRow]: selected })

  const ingestTime = certificate.ingest_time ? new Date(certificate.ingest_time) : null

  return (
    <tr className={rowClass} onClick={handleClick} data-tut="issuer-row">
      <td>
        <Typography variant="body1" display="inline" data-testid="resource-row-title" className={classes.title}>
          {certificate.cluster_id || "-"}
        </Typography>
      </td>

      <td>
        <Typography variant="body1" display="inline" data-testid="resource-row-title" className={classes.title}>
          {certificate.issuer}
        </Typography>
      </td>

      <td>
        <Typography variant="body1" display="inline" data-testid="resource-row-title" className={classes.title}>
          {certificate.namespace || "-"}
        </Typography>
      </td>
      <td>
        <Typography variant="body1" display="inline" data-testid="resource-row-title" className={classes.title}>
          {certificate.subject || "-"}
        </Typography>
      </td>
      <td>
        <Typography variant="body2" color="textPrimary" display="inline">
          {ingestTime ? (
            <>
              {format(ingestTime, "HH:mm:ss")}
              <br />
              {format(ingestTime, "yyyy/MM/dd")}
            </>
          ) : (
            "N/A"
          )}
        </Typography>
      </td>
    </tr>
  )
}
