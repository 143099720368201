import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  [x: string]: unknown
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& path": {
        stroke: theme.palette.primary.main,
      },
    },
  }),
)

export function HostIcon(props: Props): ReactElement {
  const classes = useStyles()
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classes.root}
      {...props}
    >
      <path d="M18 4H6a3 3 0 00-3 3v2a3 3 0 003 3h12a3 3 0 003-3V7a3 3 0 00-3-3zM18 12H6a3 3 0 00-3 3v2a3 3 0 003 3h12a3 3 0 003-3v-2a3 3 0 00-3-3zM7 8v.01M7 16v.01" />
    </svg>
  )
}
