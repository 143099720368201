import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles, FormControl, InputLabel, MenuItem, Typography } from "@material-ui/core"
import { Field } from "formik"
import { TextField as FormTextField, Select as FormSelect } from "formik-material-ui"

import { VenafiIssuer } from "../../types"
import {
  validate,
  validBase64,
  validKubernetesName,
  validKubernetesSecretKey,
  validUrl,
  validNoTrailingWhitespaces,
} from "../../validation"
import { FormRow } from "../../FormRow"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formSelect: {
      minWidth: 200,
    },
    sectionTitle: {
      marginTop: theme.spacing(3),
    },
  }),
)

const APIForm = () => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h6" className={classes.sectionTitle}>
        API token details
      </Typography>
      <FormRow info="The name of the 'Secret' resource that contains the API token">
        <Field
          name="issuers.venafi.install.api.secretName"
          label="Secret name"
          variant="outlined"
          margin="dense"
          placeholder="my-secret"
          required
          validate={validate(validKubernetesName)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow info="The key within the 'Secret' resource that holds the API token">
        <Field
          name="issuers.venafi.install.api.secretKey"
          label="Secret key"
          variant="outlined"
          margin="dense"
          placeholder="my-key"
          required
          validate={validate(validKubernetesSecretKey)}
          component={FormTextField}
        />
      </FormRow>
    </>
  )
}

const TPPForm = () => {
  const classes = useStyles()

  return (
    <>
      <Typography variant="h6" className={classes.sectionTitle}>
        Access token details
      </Typography>
      <FormRow info="The location of the TPP instance">
        <Field
          name="issuers.venafi.install.tpp.serverUrl"
          label="URL"
          variant="outlined"
          margin="dense"
          placeholder="https://tpp.example.org"
          required
          validate={validate(validUrl)}
          component={FormTextField}
        />
      </FormRow>

      <FormRow info="Denotes an optional field containing a base64 encoded string of the Certificate Authority to trust the Venafi TPP connection.">
        <Field
          name="issuers.venafi.install.tpp.caBundle"
          label="CA bundle"
          variant="outlined"
          margin="dense"
          placeholder="LS0tLS1CRUBDRVJU..."
          validate={validate(validBase64, false)}
          component={FormTextField}
        />
      </FormRow>

      <FormRow info="The name of the 'Secret' resource that holds the access token" noBottomMargin>
        <Field
          name="issuers.venafi.install.tpp.secretName"
          label="Secret name"
          variant="outlined"
          margin="dense"
          placeholder="my-secret"
          required
          validate={validate(validKubernetesName)}
          component={FormTextField}
        />
      </FormRow>
    </>
  )
}

type VenafiFormProps = {
  issuer: VenafiIssuer
}

export const VenafiForm = ({ issuer }: VenafiFormProps): ReactElement => {
  const classes = useStyles()
  return (
    <>
      <FormRow info="The Venafi policy zone to use">
        <Field
          name="issuers.venafi.zone"
          label="Zone"
          variant="outlined"
          margin="dense"
          placeholder="TLS/SSL/certificates/..."
          required
          validate={validate(validNoTrailingWhitespaces)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow>
        <FormControl variant="outlined" margin="dense">
          <InputLabel htmlFor="issuers.venafi.installType">Instance type</InputLabel>
          <Field
            className={classes.formSelect}
            component={FormSelect}
            name="issuers.venafi.installType"
            inputProps={{ id: "issuers.venafi.installType" }}
            label="Instance type"
          >
            <MenuItem key="venafi-install-tpp" value="tpp">
              Venafi Trust Protection Platform
            </MenuItem>
            <MenuItem key="venafi-install-api" value="api">
              Venafi Cloud
            </MenuItem>
          </Field>
        </FormControl>
      </FormRow>

      {issuer.installType === "api" && <APIForm />}
      {issuer.installType === "tpp" && <TPPForm />}
    </>
  )
}
