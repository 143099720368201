import React from "react"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { currentOrgSelector } from "@selectors/orgs"
import { pathToSettings } from "@routes"

export const Support = () => {
  const orgID = useSelector(currentOrgSelector)

  return <Navigate to={pathToSettings(orgID, "tier")} />
}
