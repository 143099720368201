import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  [x: string]: unknown
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& path": {
        stroke: theme.palette.grey[600],
      },
    },
  }),
)

export function TrashIcon(props: Props): ReactElement {
  const classes = useStyles()
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.root} {...props}>
      <path
        d="M4 7h16M10 11v6M14 11v6M5 7l1 12a2 2 0 002 2h8a2 2 0 002-2l1-12M9 7V4a1 1 0 011-1h4a1 1 0 011 1v3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
