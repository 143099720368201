import axios from "axios"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { getOrgID } from "@routes"
import { accessTokenSelector } from "@selectors/auth"
import { addAuth } from "@lib/auth"
import { handleError } from "@utils/errorHandling"
import { Member } from "./types"
import { QUERYKEYS } from "@lib/queryKeys"

// query all service accounts
export function useUsers() {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)

  const { data, ...rest } = useQuery(QUERYKEYS.users.all(orgID), () => axios.get<Member[]>(`/api/v1/org/${orgID}/users`, addAuth(token)), {
    onError: (error: Error) => handleError(error),
    enabled: true,
    refetchOnWindowFocus: false,
  })

  return {
    members: data?.data,
    ...rest,
  }
}

// create a service account
export function useCreateUser() {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)
  const queryClient = useQueryClient()

  const { isSuccess, isError, error, mutate, isLoading, data } = useMutation(
    (user: { email: string; role: string }) => axios.post(`/api/v1/org/${orgID}/users`, user, addAuth(token)),
    {
      onError: (error: Error) => handleError(error),
      onSuccess: () => {
        // invalidate the list query so it refetches
        queryClient.invalidateQueries(QUERYKEYS.users.all(orgID))
      },
    },
  )
  return { isSuccess, isError, error, mutate, isLoading, data: data?.data }
}

// delete a user
export function useDeleteUser() {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)
  const queryClient = useQueryClient()

  return useMutation((userID: string) => axios.delete(`/api/v1/org/${orgID}/users/${userID}`, addAuth(token)), {
    onError: (error: Error) => handleError(error),
    onSuccess: () => {
      // invalidate the list query so it refetches
      queryClient.invalidateQueries(QUERYKEYS.users.all(orgID))
    },
  })
}

// delete a user
export function useDeleteCurrentUser() {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)
  const queryClient = useQueryClient()

  return useMutation(() => axios.delete(`/api/v1/org/${orgID}/self-user`, addAuth(token)), {
    onError: (error: Error) => handleError(error),
    onSuccess: () => {
      // invalidate the list query so it refetches
      queryClient.invalidateQueries(QUERYKEYS.users.all(orgID))
    },
  })
}
