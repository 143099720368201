import React, { Fragment } from "react"
import { createStyles, Theme, makeStyles, Grid, Divider, Typography, Paper } from "@material-ui/core"

import { Ingress } from "@lib/certInventory/resources"
import { ExternalLinkIcon } from "@components/Icons"
import { MessageContainer } from "@components/MessageContainer"
import { Issues } from "../Issues"
import { HostPathsInfo } from "../HostPathsInfo"
import { AnnotationsInfo } from "../AnnotationsInfo"
import { renderSeverity } from "../../Ingresses/helpers"
import { CloseButton } from "../CloseButton"
import { Overlay } from "../Overlay"

type IngressDetailsProp = {
  ingress: Ingress
  selectedHostname?: string
  onClose: () => void
  showClusterName?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0.5),
      position: "relative",
    },
    panel: {
      minHeight: "100vh",
      width: 400,
      height: "100%",
      overflowX: "auto",
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 100,
      [theme.breakpoints.down(480)]: {
        width: "100vw",
      },
    },
    overlay: {
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 99,
      background: "rgba(24,20,32,0.2)",
      width: "100%",
      height: "100%",
    },
    close: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    loading: {
      marginTop: theme.spacing(10),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    section: {
      margin: theme.spacing(3, 2),
    },
    clusterTitle: {
      fontSize: "1rem",
      fontWeight: 300,
      borderRadius: "0.3rem",
      padding: theme.spacing(1, 0),
    },
    marginAround: {
      margin: theme.spacing(2),
    },
    alert: {
      display: "flex",
      marginTop: theme.spacing(2),
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    subTitleContainer: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    clusterLink: {
      fontWeight: 700,
      color: theme.palette.primary.main,
      "&:hover": {
        textDecoration: "none",
      },
    },
  }),
)

export function IngressDetails({ ingress, onClose, selectedHostname, showClusterName }: IngressDetailsProp) {
  const classes = useStyles()
  const selectedHostnamePaths = ingress.resource.spec?.rules?.find(({ host }) => host === selectedHostname)?.http.paths
  const getOtherHosts = ingress.resource.spec?.rules ? ingress.resource.spec?.rules.filter(other => other.host !== selectedHostname) : []
  const hasTLS = ingress.resource.spec?.tls && ingress.resource.spec?.tls?.length > 0

  return (
    <Overlay onClose={onClose}>
      <Paper className={classes.panel} elevation={3} data-tut="details-panel">
        <div className={classes.root}>
          <CloseButton className={classes.close} onClick={onClose} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h4">
                  Ingress Details
                </Typography>
              </Grid>
            </Grid>
            <Typography color="textPrimary" variant="body1" className={classes.subTitleContainer}>
              {ingress.resource.metadata?.namespace} / {ingress.resource.metadata?.name}
            </Typography>
            {showClusterName && (
              <Typography gutterBottom variant="h6" className={classes.clusterTitle}>
                Cluster: <strong>{ingress.clusterId}</strong>
              </Typography>
            )}
          </div>
          {ingress.rule_messages.length > 0 && (
            <>
              <Divider variant="middle" />
              <div className={classes.marginAround}>
                <Typography gutterBottom variant="h5">
                  Issues
                </Typography>
                <Typography gutterBottom variant="body1" display="inline">
                  Severity:
                </Typography>{" "}
                {renderSeverity(ingress.rule_messages)}
                <br />
                <br />
                <Typography gutterBottom color="textSecondary" variant="body2">
                  These are some potential issues with your ingress:
                </Typography>
                {ingress.rule_messages.map(({ messages, alert_id, rule: { severity } }) => (
                  <Issues key={alert_id} id={alert_id} messages={messages} severity={["warning", "warningMedium", "error"][severity]} />
                ))}
              </div>
            </>
          )}
          <Divider variant="middle" />
          <div className={classes.marginAround}>
            <Typography gutterBottom variant="h5">
              {selectedHostname}
            </Typography>

            {selectedHostnamePaths?.map((p, index) => (
              <HostPathsInfo path={p.path} backend={p.backend} key={p.path + index} />
            ))}
          </div>
          <Divider variant="middle" />
          {getOtherHosts.length > 0 && (
            <>
              <div className={classes.marginAround}>
                <Typography gutterBottom variant="h5">
                  Other Hosts
                </Typography>
                {getOtherHosts.map(other => (
                  <Fragment key={other.host}>
                    <Typography gutterBottom>{other.host}</Typography>
                    {other.http.paths.map((p, index) => (
                      <HostPathsInfo path={p.path} backend={p.backend} key={p.path + index} />
                    ))}
                  </Fragment>
                ))}
              </div>
              <Divider variant="middle" />
            </>
          )}
          <div className={classes.marginAround}>
            <Typography gutterBottom variant="h5">
              TLS
            </Typography>
            {hasTLS ? (
              ingress.resource.spec?.tls?.map(tls => (
                <Fragment key={tls.secretName}>
                  <Typography gutterBottom>{tls.secretName}</Typography>
                  {tls.hosts.map(tlsHost => (
                    <MessageContainer key={tlsHost}>
                      <Typography color="textPrimary" variant="body2" display="block">
                        {tlsHost}
                      </Typography>
                    </MessageContainer>
                  ))}
                </Fragment>
              ))
            ) : (
              <MessageContainer>
                Your ingress is insecure as it does not have a valid TLS configuration.{" "}
                <a href="https://cert-manager.io/docs/">
                  <ExternalLinkIcon width="16" height="16" viewBox="0 0 24 24" />
                </a>
              </MessageContainer>
            )}
          </div>
          {ingress.resource.metadata?.annotations && (
            <>
              <Divider variant="middle" />
              <div className={classes.marginAround}>
                <Typography gutterBottom variant="h5">
                  Annotations
                </Typography>
                <AnnotationsInfo annotations={ingress.resource.metadata?.annotations} />
              </div>
            </>
          )}
        </div>
      </Paper>
    </Overlay>
  )
}
