import React from "react"
import { Typography, makeStyles, Theme, createStyles } from "@material-ui/core"
import k8s from "@kubernetes/client-node"

import { CertificateUsage } from "@lib/certInventory/resources"
import { MessageContainer } from "@components/MessageContainer"
import { ActivityIcon } from "@components/Icons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(0.5),
    },
  }),
)

type Props = {
  // this is the name of the secret related to the certificate
  // it is used to match relevant tls host blocks
  secretName?: string
  usages?: CertificateUsage[]
}

export function Usages({ secretName, usages }: Props) {
  const classes = useStyles()

  if (!usages || usages.length === 0) {
    return <Typography>There aren't any usages to report.</Typography>
  }

  const genUsageMsg = (usage: CertificateUsage): string => {
    switch (usage.resource.kind) {
      case "Pod":
        return `Is mounted as a volume to Pod ${usage.resource.metadata?.name}`
      case "Issuer":
        return `Is used as CA for Issuer ${usage.resource.metadata?.name}`
      case "ClusterIssuer":
        return `Is used as CA for ClusterIssuer ${usage.resource.metadata?.name}`
      case "Gateway":
        return `Is used by the Istio Gateway ${usage.resource.metadata?.name}`
      case "Ingress": {
        const res = usage.resource as k8s.V1Ingress
        if (!secretName) {
          return `Is used by Ingress ${res.metadata?.name} for TLS termination`
        }
        if (!res.spec?.tls || res.spec?.tls.length === 0) {
          return `Unrecognised usage in Ingress ${res.metadata?.name}`
        }
        const hosts: string[] = []
        res.spec.tls.forEach(e => {
          if (e.hosts && e.secretName === secretName) {
            hosts.push(...e.hosts)
          }
        })
        return `Ingress ${res.metadata?.name} for TLS termination of hosts: ${hosts.join(", ")}`
      }
    }

    return `Unrecognised usage in ${usage.resource.kind} ${usage.resource.metadata?.namespace} / ${usage.resource.metadata?.name}.`
  }

  return (
    <div className={classes.root}>
      {usages.map((usage, index) => (
        <MessageContainer icon={<ActivityIcon />} key={index}>
          {genUsageMsg(usage)}
        </MessageContainer>
      ))}
    </div>
  )
}
