import React, { useState } from "react"
import { Typography, createStyles, makeStyles, Theme, Button, Dialog } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      transition: "all 0.3s ease-in-out",
      borderLeft: `3px solid transparent`,
      fontSize: "0.875rem",
      "&:hover": {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.default,
        boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
      },
      "& td": {
        padding: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        verticalAlign: "middle",
      },
    },

    button: {
      background: theme.palette.error.main,
    },
    action: {
      textAlign: "right",
    },
    dialog: {
      padding: theme.spacing(4),
      position: "relative",
    },
    closeBtn: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    dialogBtn: {
      marginTop: theme.spacing(2),
      background: theme.palette.error.main,
    },
  }),
)

type ServiceAccountRowProps = {
  serviceAccount: {
    displayName: string
    id: string
  }
  onDelete: (userID: string) => void
}

export function ServiceAccountRow({ serviceAccount, onDelete }: ServiceAccountRowProps) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleDelete = () => {
    onDelete(serviceAccount.id)
    setIsOpen(false)
  }

  return (
    <>
      <tr className={classes.row} data-tut="member-row">
        <td>
          <Typography variant="body1" display="inline" data-testid="service-account-row-title">
            {serviceAccount.displayName}
          </Typography>
        </td>
        <td className={classes.action}>
          <Button variant="contained" color="primary" className={classes.button} onClick={() => setIsOpen(true)}>
            Delete
          </Button>
        </td>
      </tr>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <div className={classes.dialog}>
          <CancelIcon className={classes.closeBtn} onClick={handleClose} data-testid="alerts-close-button" />
          <Typography gutterBottom variant="h5">
            Are you sure?
          </Typography>
          <Typography>
            You are about to delete <strong>{serviceAccount.displayName}</strong>.
          </Typography>
          <Button variant="contained" color="primary" className={classes.dialogBtn} onClick={handleDelete}>
            Yes, remove
          </Button>
        </div>
      </Dialog>
    </>
  )
}
