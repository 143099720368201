import React, { Fragment } from "react"
import { Link, useParams } from "react-router-dom"
import { createStyles, Theme, makeStyles, Grid, Divider, Typography, Paper } from "@material-ui/core"
import { Certificate, Issuer, Resource, CMCertificate } from "@lib/certInventory/resources"
import { formatDate } from "@utils/date"
import { getOrgID, getClusterID, pathToCertInventoryCluster } from "@routes"
import { WarningIcon } from "@components/Icons"
import { MessageContainer } from "@components/MessageContainer"
import { ResourceList } from "../ResourceList"
import { Usages } from "../Usages"
import { CertificateInfo } from "../CertificateInfo"
import { Identities } from "../Identities"
import { HistoryCerts } from "../HistoryCerts"
import { Health } from "../Health"
import { CloseButton } from "../CloseButton"
import { Overlay } from "../Overlay"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0.5),
      position: "relative",
    },
    panel: {
      minHeight: "100vh",
      width: 400,
      height: "100%",
      overflowX: "auto",
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 100,
      [theme.breakpoints.down(480)]: {
        width: "100vw",
      },
    },
    overlay: {
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 99,
      background: "rgba(24,20,32,0.2)",
      width: "100%",
      height: "100%",
    },
    close: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    loading: {
      marginTop: theme.spacing(10),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    section: {
      margin: theme.spacing(3, 2),
    },
    clusterTitle: {
      fontSize: "1rem",
      fontWeight: 300,
      borderRadius: "0.3rem",
      padding: theme.spacing(1, 0),
    },
    marginAround: {
      margin: theme.spacing(2),
    },
    alert: {
      display: "flex",
      marginTop: theme.spacing(2),
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    subTitleContainer: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    clusterLink: {
      fontWeight: 700,
      color: theme.palette.primary.main,
      "&:hover": {
        textDecoration: "none",
      },
    },
  }),
)

type CertificateDetailsProps = {
  certificate: Certificate
  onClose: () => void
  handleSelect: (res: Resource) => void
  showClusterName?: boolean
}

// renderSectionIssuerRef renders the issuer reference as a link to the issuers page
// TODO: put this in its own file
export const renderSectionIssuerRef = (issuer: Issuer | string, orgID: string, clusterID: string) => {
  if (issuer) {
    return (
      <Fragment>
        <Typography gutterBottom variant="h6">
          Issuer
        </Typography>
        <MessageContainer>
          Issued by {"> "}
          <Typography color="textPrimary" variant="body2" display="inline">
            {issuer instanceof Issuer && issuer.resource.metadata ? (
              <Link to={`${pathToCertInventoryCluster(orgID, clusterID, "issuers")}#${issuer.hash()}`}>
                {issuer.resource.metadata.name}
              </Link>
            ) : (
              <>issuer</>
            )}
          </Typography>
        </MessageContainer>
      </Fragment>
    )
  }
  return undefined
}

export function CertificateDetails({ certificate, onClose, handleSelect, showClusterName }: CertificateDetailsProps) {
  const classes = useStyles()
  const params = useParams()
  const orgID = getOrgID(params)
  const clusterID = getClusterID(params)

  return (
    <Overlay onClose={onClose}>
      <Paper className={classes.panel} elevation={3} data-tut="details-panel">
        <div className={classes.root}>
          <CloseButton className={classes.close} onClick={onClose} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h4">
                  Certificate details
                </Typography>
              </Grid>
            </Grid>
            <Typography color="textPrimary" variant="body1" className={classes.subTitleContainer}>
              {certificate.resource.metadata?.namespace} / {certificate.resource.metadata?.name}
            </Typography>
            {showClusterName && (
              <Typography gutterBottom variant="h6" className={classes.clusterTitle}>
                Cluster:{" "}
                <Link
                  to={`${pathToCertInventoryCluster(orgID, certificate.clusterId, "certificates")}`}
                  onClick={onClose}
                  className={classes.clusterLink}
                >
                  {certificate.clusterId}
                </Link>
              </Typography>
            )}
            {!(certificate instanceof CMCertificate) && (
              <div className={classes.alert}>
                <MessageContainer icon={<WarningIcon opacity={0.5} />}>This certificate is not managed by cert-manager.</MessageContainer>
              </div>
            )}
            {certificate.deleted_at && (
              <div className={classes.alert}>
                <MessageContainer>This certificate was deleted at {formatDate(certificate.deleted_at)}</MessageContainer>
              </div>
            )}
          </div>
          <Divider variant="middle" />
          <Health resource={certificate} />
          <Divider variant="middle" />
          <div className={classes.section}>
            <Identities certificate={certificate} />
          </div>
          <Divider variant="middle" />
          <HistoryCerts certificate={certificate} clusterID={clusterID} />
          <Divider variant="middle" />
          <div className={classes.marginAround}>
            <Typography gutterBottom variant="h5">
              Usage
            </Typography>
            {certificate.usages.length > 0 ? (
              <Fragment>
                <Typography gutterBottom color="textSecondary" variant="body2">
                  These are the different places where this certificate is being used:
                </Typography>
                <Usages usages={certificate.usages} secretName={certificate.secret?.resource?.metadata?.name} />
              </Fragment>
            ) : (
              <Typography gutterBottom color="textSecondary" variant="body2">
                We do not detect a use of this certificate.
              </Typography>
            )}
          </div>
          <Divider variant="middle" />
          {certificate instanceof CMCertificate && (
            <div className={classes.marginAround}>
              <Typography gutterBottom variant="h5">
                cert-manager
              </Typography>
              <Typography gutterBottom color="textSecondary" variant="body2">
                This certificate is managed by cert-manager. These are the related cert-manager resources:
              </Typography>
              {renderSectionIssuerRef(certificate.issuedBy, orgID, clusterID)}
              <ResourceList resourceName="Certificate Requests" resources={certificate.certificateRequests} handleSelect={handleSelect} />
            </div>
          )}
          <Divider variant="middle" />
          <div className={classes.marginAround}>
            <Typography gutterBottom variant="h5">
              Certificate Info
            </Typography>
            <CertificateInfo certificate={certificate} />
          </div>
        </div>
      </Paper>
    </Overlay>
  )
}
