import React, { Fragment } from "react"
import { Link, useParams } from "react-router-dom"
import { createStyles, Theme, makeStyles, Grid, Divider, Typography, Paper } from "@material-ui/core"

import { OwnerlessCertificateRequest, CertificateRequest, Resource } from "@lib/certInventory/resources"
import { getOrgID, getClusterID } from "@routes"
import { WarningIcon, DenyIcon, CheckMarkCircleIcon } from "@components/Icons"
import { MessageContainer } from "@components/MessageContainer"
import { StatusIcon } from "@components/StatusIcon"
import { CertificateInfo } from "../CertificateInfo"
import { renderSectionIssuerRef } from "../CertificateDetails"
import { Identities } from "../Identities"
import { HistoryCerts } from "../HistoryCerts"
import { Health } from "../Health"
import { CloseButton } from "../CloseButton"
import { Overlay } from "../Overlay"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0.5),
      position: "relative",
    },
    panel: {
      minHeight: "100vh",
      width: 400,
      height: "100%",
      overflowX: "auto",
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 100,
      [theme.breakpoints.down(480)]: {
        width: "100vw",
      },
    },
    overlay: {
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 99,
      background: "rgba(24,20,32,0.2)",
      width: "100%",
      height: "100%",
    },
    close: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    loading: {
      marginTop: theme.spacing(10),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    section: {
      margin: theme.spacing(3, 2),
    },
    clusterTitle: {
      fontSize: "1rem",
      fontWeight: 300,
      borderRadius: "0.3rem",
      padding: theme.spacing(1, 0),
    },
    marginAround: {
      margin: theme.spacing(2),
    },
    alert: {
      display: "flex",
      marginTop: theme.spacing(2),
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    subTitleContainer: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    clusterLink: {
      fontWeight: 700,
      color: theme.palette.primary.main,
      "&:hover": {
        textDecoration: "none",
      },
    },
  }),
)

// renderSectionOwningCertificate renders the certificate owner for the CertificateRequest
const renderSectionOwningCertificate = ({ owner }: CertificateRequest, handleSelect: (res: Resource) => void) => {
  if (owner !== undefined) {
    return (
      <Fragment>
        <Typography gutterBottom variant="h6">
          Owning cert-manager Certificate
        </Typography>
        <MessageContainer>
          Owned by {"> "}
          <Typography color="textPrimary" variant="body2" display="inline">
            <Link to="#" onClick={() => handleSelect(owner)}>
              {owner?.resource.metadata?.name}
            </Link>
          </Typography>
        </MessageContainer>
      </Fragment>
    )
  }
}

type CertificateRequestDetailsProps = {
  certificateRequest: OwnerlessCertificateRequest | CertificateRequest
  onClose: () => void
  handleSelect: (res: Resource) => void
  showClusterName?: boolean
}

export function CertificateRequestDetails({ certificateRequest, onClose, handleSelect, showClusterName }: CertificateRequestDetailsProps) {
  const classes = useStyles()
  const params = useParams()
  const orgID = getOrgID(params)
  const clusterID = getClusterID(params)
  const status = certificateRequest.status.type
  const namespace = certificateRequest.resource.metadata?.namespace === "" ? undefined : certificateRequest.resource.metadata?.namespace
  const subTitleText = namespace
    ? `${namespace} / ${certificateRequest.resource.metadata?.name}`
    : certificateRequest.resource.metadata?.name

  return (
    <Overlay onClose={onClose}>
      <Paper className={classes.panel} elevation={3} data-tut="details-panel">
        <div className={classes.root}>
          <CloseButton className={classes.close} onClick={onClose} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h4">
                  Certificate request details
                </Typography>
              </Grid>
            </Grid>
            <Typography color="textPrimary" variant="body1" className={classes.subTitleContainer}>
              {!certificateRequest.deleted_at ? <StatusIcon status={status} /> : <DenyIcon />} {subTitleText}
            </Typography>
            {showClusterName && (
              <Typography gutterBottom variant="h6" className={classes.clusterTitle}>
                Cluster: <strong>{certificateRequest.clusterId}</strong>
              </Typography>
            )}
          </div>
          <Divider variant="middle" />
          {certificateRequest.deleted_at ? (
            <Typography color="textPrimary" variant="body1" className={classes.marginAround}>
              This certificate request was ephemeral. This means it no longer exist in some cluster. In some cases like cert-manager's istio
              integration the Issuers will use ephemeral certificate request.
            </Typography>
          ) : (
            <Health resource={certificateRequest} />
          )}

          {certificateRequest instanceof CertificateRequest && certificateRequest.approvalSet && (
            <div className={classes.marginAround}>
              <Typography gutterBottom variant="h5">
                Approval Status
              </Typography>
              {certificateRequest.approvalStatus === "Denied" && (
                <MessageContainer icon={<WarningIcon />}>
                  Denied by policy approvers with message: "{certificateRequest.approvalMessage}"
                </MessageContainer>
              )}
              {certificateRequest.approvalStatus === "Approved" && (
                <MessageContainer icon={<CheckMarkCircleIcon />}>Approved by configured policy approvers</MessageContainer>
              )}
            </div>
          )}
          <Divider variant="middle" />
          <div className={classes.section}>
            <Identities certificate={certificateRequest} />
          </div>
          <Divider variant="middle" />
          <HistoryCerts certificate={certificateRequest} clusterID={clusterID} />

          <Divider variant="middle" />
          <div className={classes.marginAround}>
            <Typography gutterBottom variant="h5">
              Certificate Info
            </Typography>
            {renderSectionOwningCertificate(certificateRequest, handleSelect)}
            {renderSectionIssuerRef(certificateRequest.issuedBy, orgID, clusterID)}
            <CertificateInfo certificate={certificateRequest} />
          </div>
        </div>
      </Paper>
    </Overlay>
  )
}
