import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"

import { AddClusterOption } from "@components/CertInventoryMultiCluster/AddClusterOption"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      border: `1px solid ${theme.palette.warning.light}`,
      position: "relative",
      paddingRight: theme.spacing(30),
      [theme.breakpoints.down(700)]: {
        paddingRight: 0,
      },
    },
    button: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: "0.5rem",
      [theme.breakpoints.down(700)]: {
        position: "relative",
        top: "unset",
        right: "unset",
        display: "block",
        transform: "translateY(0)",
        marginTop: theme.spacing(2),
      },
    },
  }),
)

export function ClusterLimitWarning() {
  const classes = useStyles()

  return (
    <div>
      <MuiAlert
        severity="warning"
        className={classes.alert}
        action={
          <div className={classes.button}>
            <AddClusterOption limitReached={true} redirectPath="" text="Extend your limit" />
          </div>
        }
      >
        <div>You have reached your cluster limit! Please extend your limit, or delete some clusters.</div>
      </MuiAlert>
    </div>
  )
}
