import React from "react"
import { CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    text: {
      color: "inherit",
      margin: theme.spacing(4, 0),
    },
  }),
)

type LoadingMessageProps = {
  label?: string
}

export const LoadingMessage = ({ label }: LoadingMessageProps) => {
  const classes = useStyles()

  return (
    <div className={classes.loaderRoot}>
      <CircularProgress />
      {label && <span className={classes.text}>{label}</span>}
    </div>
  )
}
