import React from "react"
import { makeStyles, createStyles, withStyles, Theme, Typography, Tooltip } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import { CheckMarkCircleIcon, DenyIcon, ClockIcon, WarningIcon, TrashIcon } from "@components/Icons"

import { Badge } from "./Badge/"

export type Status = "ok" | "info" | "warning" | "warningMedium" | "error" | "unknown" | "pending" | "primary" | "outdated" | "deleted"

export type Props = {
  message?: string
  description?: string
  status: Status
  value?: number | string
  largeBadge?: boolean
  iconsOnly?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-flex",
      alignItems: "center",
    },
    clickable: {
      cursor: "pointer",
      "& p": {
        color: theme.palette.primary.main,
      },
    },
    message: {
      fontWeight: 400,
      padding: theme.spacing(0.6, 1, 0.6, 2.8),
      borderRadius: 5,
      marginLeft: "-1rem",
      zIndex: 0,
      fontSize: "0.75rem",
      transition: "all 0.2s ease-in-out",
    },
    check: {
      marginTop: theme.spacing(0.2),
    },
    warningIcon: {
      "& path": {
        stroke: theme.palette.warning.main,
      },
    },
    errorIcon: {
      "& path": {
        stroke: theme.palette.error.main,
      },
    },
    clockIcon: {
      stroke: theme.palette.warning.main,
    },
    hoverable: {
      cursor: "pointer",
    },
  }),
)

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: theme.palette.text.secondary,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    boxShadow: `rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px`,
    marginTop: "-0.2rem",
    padding: theme.spacing(1.2, 2),
  },
}))(Tooltip)

export function StatusIndicator({ status = "unknown", message, description = "", value, largeBadge, iconsOnly = false }: Props) {
  const classes = useStyles()

  const renderStatusComponent = () => {
    if (status === "ok") {
      return (
        <div className={classes.check}>
          <CheckMarkCircleIcon data-testid="ready-resource" />
        </div>
      )
    }

    if (["warning", "warningMedium", "error", "primary"].includes(status)) {
      if (iconsOnly) {
        return <WarningIcon className={status === "error" ? classes.errorIcon : classes.warningIcon} data-testid="error-resource" />
      } else {
        return <Badge value={value} status={status} largeBadge={largeBadge} />
      }
    }
    if (status === "pending") {
      return <ClockIcon className={classes.clockIcon} />
    }
    if (status === "outdated") {
      return <WarningIcon data-testid="warning-resource" />
    }
    if (status === "info") {
      return <InfoIcon data-testid="info-resource" />
    }
    if (status === "deleted") {
      return <TrashIcon data-testid="deleted-resource" />
    }

    return <DenyIcon data-testid="not-available-resource" />
  }

  const className = `${classes.root} ${description ? classes.hoverable : ""}`

  return (
    <CustomTooltip title={description} aria-label="add">
      <div className={className}>
        {renderStatusComponent()}

        {message && (
          <Typography color="textSecondary" variant="body2" className={classes.message}>
            {message}
          </Typography>
        )}
      </div>
    </CustomTooltip>
  )
}
