import React from "react"
import classnames from "classnames"
import { Typography, makeStyles, createStyles, Theme } from "@material-ui/core"

import { Resource } from "@lib/certInventory/resources"
import { HostIcon, IngressIcon, OpenshiftIcon } from "@components/Icons"
import { Route } from "@lib/certInventory/resources"
import { IngressWithHost } from "../../CertInventory/Ingresses"
import { SeverityTag } from "@components/SeverityTag"
import { renderSeverity } from "../../CertInventory/Ingresses/helpers"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      borderLeft: `3px solid transparent`,
      color: theme.palette.text.primary,
      "&:hover": {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.default,
        boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
      },
      "& td": {
        padding: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        verticalAlign: "middle",
        [theme.breakpoints.down(1024)]: {
          whiteSpace: "nowrap",
        },
      },
    },
    selectedRow: {
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.default,
      boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
    },
    type: {
      fontSize: "0.875rem",
    },
    ingressIcon: {
      display: "inline-block",
      "& svg": {
        marginBottom: theme.spacing(-0.75),
        marginRight: theme.spacing(1),
      },
    },
    status: {
      textAlign: "center",
      lineHeight: 1.6,
    },
    icon: {
      "& svg": {
        marginBottom: theme.spacing(-0.125),
      },
    },
  }),
)

type Props = {
  ingress: IngressWithHost
  handleSelect: (res: Resource, hostName?: string) => void
  selected?: boolean
}

export function IngressRow({ ingress: { hostName, resource }, handleSelect, selected }: Props) {
  const classes = useStyles()
  const handleClick = () => {
    handleSelect(resource, hostName)
  }
  const rowClass = classnames(classes.row, { [classes.selectedRow]: selected })

  return (
    <tr className={rowClass} onClick={handleClick} data-testid="ingress-row" data-tut="ingress-resource">
      <td className={classes.icon}>
        <HostIcon />
      </td>
      <td>
        <Typography variant="body1" display="inline">
          {hostName}
        </Typography>
      </td>
      <td>
        <div className={classes.ingressIcon}>{resource instanceof Route ? <OpenshiftIcon /> : <IngressIcon />}</div>
        <Typography variant="body1" display="inline" className={classes.type}>
          {resource.resource.metadata?.name}
        </Typography>
      </td>
      <td className={classes.status}>
        {resource.rule_messages.length === 0 ? <SeverityTag label="ok" severity="ok" /> : renderSeverity(resource.rule_messages)}
      </td>
    </tr>
  )
}
