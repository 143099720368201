import React, { ReactElement } from "react"
type Props = {
  [x: string]: unknown
}

export function MailForwardIcon(props: Props): ReactElement {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4H19C19.5304 4 20.0391 4.21071 20.4142 4.58579C20.7893 4.96086 21 5.46957 21 6V13.5"
        stroke="#6125DA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 6L12 12L21 6" stroke="#6125DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 18H21" stroke="#6125DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18 15L21 18L18 21" stroke="#6125DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
