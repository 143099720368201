import { useSelector } from "react-redux"
import { propertiesSelector } from "@selectors/features"
import { clustersWithCertInventorySelector } from "@selectors/clusters"
import { appConfigSelector } from "@selectors/appConfig"

export function useSupportsMultiClusterSelector(): {
  supportsMultiCluster: boolean
  clusterLimit?: number
  showMultiCluster: boolean
  clusterLimitReached: boolean
} {
  const appConfig = useSelector(appConfigSelector).appConfig
  if (!appConfig?.global_features.cluster_limit) {
    return { supportsMultiCluster: true, clusterLimit: undefined, showMultiCluster: true, clusterLimitReached: false }
  }
  const properties = useSelector(propertiesSelector)
  const clusters = useSelector(clustersWithCertInventorySelector)

  let clusterLimit = properties?.cluster_limit ? properties.cluster_limit : 1
  const demoClusters = clusters.filter(c => c.isDemoData).length
  const clusterCount = clusters.length

  // increase cluster limit in the same amount as demo clusters so virtually they don't count.
  clusterLimit = clusterLimit + demoClusters
  const supportsMultiCluster = clusterLimit > 1
  const clusterLimitReached = clusterCount >= clusterLimit
  const showMultiCluster = supportsMultiCluster && clusters.length > 1

  return { supportsMultiCluster, clusterLimit, showMultiCluster, clusterLimitReached }
}
