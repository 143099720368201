export const text = `
TERMS OF USE

VENAFI SERVICES

Last updated on November 8, 2022

**IMPORTANT**

PLEASE CAREFULLY READ THIS AGREEMENT IN ITS ENTIRETY BEFORE CONTINUING WITH INSTALLATION, REGISTRATION AND/OR ACTIVATION OF THE SERVICE.  

ANY SIGNED WRITING BETWEEN YOU AND VENAFI THAT RELATES TO THE LICENSING OF THIS SERVICE SHALL TAKE PRECEDENCE OVER THIS AGREEMENT. IF THE FOREGOING DOES NOT APPLY, THEN THIS IS A LEGALLY-BINDING AGREEMENT BETWEEN THE ENTITY YOU REPRESENT OR, IF YOU DO NOT REPRESENT AN ENTITY IN CONNECTION WITH YOUR USE OF THE SERVICE, YOU, AS THE END-USER OF THE SERVICE (“YOU” OR “LICENSEE”), AND VENAFI, INC. (“VENAFI”). BY ACCEPTING THIS AGREEMENT, BY CLICKING A BOX INDICATING YOUR ACCEPTANCE, OR BY ACTIVATING, ACCESSING, OR USING THE SERVICE, YOU AGREE TO THE TERMS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, IN WHICH CASE THE TERMS “YOU” OR “LICENSEE” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICE.

You shall not access the Service if You are a competitor of Venafi or its Affiliates or if You are acting as a representative or agent of a competitor, except with Venafi’s prior written consent. For the purposes of this Agreement, a “competitor” is a provider of machine identity management, public key infrastructure management, certificate lifecycle management, or related compliance monitoring services other than Venafi or its Affiliates. In addition, You shall not use information obtained from the Service to compete with Venafi or its Affiliates and shall not access the Service for purposes of monitoring its availability, performance or functionality, or for any other benchmarking or competitive purposes. You shall not perform security vulnerability assessments or penetration tests without the express written consent of Venafi. You shall not attempt to “hack” the Service or any Venafi system. You agree that You shall only access and use the Service for bona fide machine identity management services and compliance monitoring.

This is a legal agreement between you (the Licensee) and Venafi. This Agreement states the terms and conditions upon which Venafi offers the Service, together with all related Documentation. The Service may include several sub-Services offered by Venafi which Licensee has registered for and uses or accesses. Licensee rights to access and use each Service or subservice shall be set forth in one or more Order Forms or Trial Agreements (as defined below) between Licensee and Venafi. If you are unclear about your rights or any portion of this Agreement, please contact your Venafi account representative or the reseller through whom you purchased licenses.

1. Definitions

1.1 “Affiliate” means an entity owned or controlled by a party, where the term “controlled” means the ownership, directly or indirectly, of more than fifty percent (50%) of the shares entitled to vote for the election of directors.

1.2 “Agreement” means these Terms of Use, as the same may be modified from time to time.

1.3 “Documentation” means the user documentation that accompanies the Service.

1.4 “Free Trial Period” means the period during which Venafi offers Licensee a free trial of the Service, as set forth when Licensee signs up for a trial, or as Venafi may otherwise communicate.  

1.5 “Free Tier” means an offering, if any, provided to Licensee by Venafi or its Affiliate to access a subservice or tier of the Service on a trial or test basis, as set forth when Licensee signs up for a Free Tier or as may be otherwise communicated to Licensee.

1.6“License” means the applicable license to the Service granted to Licensee as expressly set forth herein.

1.7 “License Term” means the applicable term set forth in Section 2.3.1.

1.8 “Licensee Data” means electronic data and information submitted by or for Licensee to the Service or collected and processed by or for Licensee using the Service.

1.9 “Order Form” means each order form, quote, or applicable ordering document agreed upon in writing between Licensee and Venafi relating to the Service.

1.10 “Service” means the software-as-a-service or multitenant application-over-the Internet service or a particular subservice or tier thereof, including any set forth in the applicable Order Form or that Licensee may use, access or for which Licensee has registered.

1.11 “Trial Agreement” means sign-up terms for a free trial or Free Tier, or any agreement between Licensee and Venafi governing Licensee’s use of a Free Tier or use of the Service during a Free Trial Period.

1.12 “Update” means repairs, corrections, fixes, upgrades, updates, modifications or additions to the Service, including those resulting in improvements or new features, processes, functions or performance metrics that are (a) designated by the progressing of the number to the left or the right of the decimal point in a Service release version numbering schema, and (b) generally made available by Venafi at no additional charge.

2. License Grants and Restrictions 

2.1 License Grant by Venafi to Licensee.  Venafi grants to Licensee and its Affiliates a non-exclusive, non-transferable, non-assignable, revocable, limited license to access and use the Service or a particular subservice thereof as set forth on an applicable Order Form or Trial Agreement, during the applicable License Term, subject to the payment of fees and the license metrics set forth in one or more Order Forms, license certificates, or other applicable written agreements between Licensee and Venafi, and in accordance with the instructions contained in the Documentation. This license grant is limited to internal use by Licensee, and Licensee is prohibited from distributing, copying, or sharing the Service for any purpose other than internal use for bona fide machine identity management services and compliance monitoring. This License is conditioned upon Licensee compliance with all of Licensee obligations under this Agreement. Except for the express licenses granted in this Section, no other rights or licenses are granted by Venafi, expressly, by implication, by way of estoppel or otherwise, including, without limitation, with respect to modifying, distributing, copying, displaying, or printing the Documentation. The Service and Documentation are licensed to Licensee and are not sold.  Rights not granted in this Agreement are reserved by Venafi.

2.2 Affiliates. Unless Licensee’s Affiliates enter into an independent Agreement with Venafi, Licensee shall be responsible and liable for the acts and omissions of its Affiliates exercising rights under this Agreement or otherwise accessing or using the Service as if such acts and omissions were the acts and omissions of Licensee, and Licensee shall be responsible for paying the fees due under this Agreement for use of the Service or receipt of Maintenance Services by Licensee’s Affiliates.

2.3 Term.

2.3.1 License Term. Licensee right to access and use the Service shall continue during (a) the term set forth in the applicable Order Form, (b) the Free Trial Period for a Trial Agreement, or (c) the time period during which Licensee accesses a Free Tier of the Service, unless and until Venafi discontinues the Free Tier or Licensee’s access to the Free Tier. Licensee’s right to access and use the Service during the applicable term shall apply solely with respect to the subservices or products set forth in such Order Form or Trial Agreement or to the Free Tier of the Service, if applicable. Such term shall, in each case, be subject to the termination rights set forth in Section 2.3.2. Except as expressly set forth in the applicable Order Form, the License Term of an Order Form shall be specified therein and shall automatically renew for successive terms with any price changes communicated by Venafi and agreed to by Licensee, unless Licensee gives Venafi at least 60 days’ written notice of nonrenewal before the end of the applicable term.

2.3.2 Termination. This Agreement is effective until terminated as set forth herein or until each applicable License Term expires and is not otherwise renewed by the parties. 

(a) Termination for Breach. Either party may terminate this Agreement and the License if the other party fails to comply with any term or condition of this Agreement in a material manner and such failure to comply is not cured within 30 days of notice thereof. In the event that Licensee breaches Section 2.4 (Restrictions on Use) by using the Service or information obtained from the Service to compete with Venafi or provide information to a Venafi competitor, then Venafi may terminate this Agreement immediately and suspend the License and access to the Service without providing notice or 30 days to cure such violation. If Licensee terminates due to Venafi’s breach, Licensee will receive a refund of any amounts that were prepaid pursuant to an Order Form, pro-rated for the remaining unused term thereunder. If Venafi terminates due to Licensee’s uncured breach, Licensee will not receive a refund of any amounts prepaid under any Order Form, nor will Licensee be relieved of the obligation to pay any unpaid amounts set forth in any Order Form. For purposes of this section and the Agreement, failure to meet support service levels or uptime service levels provided in this Agreement or otherwise communicated by Venafi is not a breach allowing for termination, but is subject only to the sole and exclusive remedy set forth in Section 4.2. 

(b) Effect of Termination. Upon any termination or expiration of this Agreement or the License, Licensees agree to cease all use of the Service if the License is not otherwise renewed or reinstated, including if Licensee does not have an outstanding Order Form and is not within a Free Trial Period.  If, upon termination, Licensee later subscribes to the Service, including by entering into a Free Trial Period, Licensee shall have deemed to have agreed to the form of this Agreement as then in effect upon the beginning of such subscription. Upon termination, Venafi may also enforce any rights provided by law. The provisions of this Agreement that protect the proprietary rights of Venafi will continue in force after termination.  If Licensee is in a Free Trial Period, Venafi may terminate this Agreement and any License granted to Licensee with respect to such Free Trial Period at any time and for any reason.

2.3.3 Free Trial and Free Tier. If Licensee is within the Free Trial Period, notwithstanding anything to the contrary in this Section 2, Licensee’s right to access and use the Service shall expire at the conclusion of the Free Trial Period unless Licensee enters into an Order Form with Venafi. If Licensee accesses a Free Tier, notwithstanding anything to the contrary in this Section 2, Licensee’s right to access and use the Service shall expire when Venafi discontinues Licensee’s access to the Free Tier unless Licensee enters into an Order Form with Venafi. ANY DATA LICENSEE MAY PROVIDE IN CONNECTION WITH A FREE TIER OR FREE TRIAL PERIOD WILL BE PERMANENTLY LOST UNLESS LICENSEE PURCHASES A SUBSCRIPTION TO AN APPLICABLE TIER OF SERVICE PRIOR TO THE EXPIRATION OF SUCH FREE TRIAL PERIOD OR DISCONTINUATION OF THE FREE TIER. NOTWITHSTANDING THE TERMS HEREOF, DURING ANY FREE TRIAL PERIOD OR PERIOD OF ACCESS TO THE FREE TIER, SERVICE IS PROVIDED “AS-IS” WITHOUT ANY WARRANTY AND VENAFI SHALL HAVE NO LIABILITY NOR INDEMNIFICATION OBLIGATIONS OF ANY TYPE WITH RESPECT TO THE FREE TIER OR THE FREE TRIAL PERIOD UNLESS SUCH EXCLUSION OF LIABILITY IS NOT ENFORCEABLE UNDER APPLICABLE LAW IN WHICH CASE VENAFI’S TOTAL LIABILITY WITH RESPECT TO THE FREE TIER OR FREE TRIAL PERIOD SHALL IN NO EVENT EXCEED $1,000.00 UNDER ANY THEORY OF LIABILITY AND IN AGGREGATING ALL CLAIMS LICENSEE OR LICENSEE AFFILIATES MAY HAVE AGAINST VENAFI OR ITS AFFILIATES.

2.4 Restrictions on Use.  The grant of rights to Licensee pursuant to Section 2.1 is subject to the following restrictions and limitations:

2.4.1 If Licensee is registered with a public Certification Authority (“CA”) supported by the Service and has valid credentials issued by such CA with which Licensee can subscribe to such CA’s SSL/TLS certificates on a fee bearing basis for use in any environment, Licensee may request such certificates through the applicable interface present in the Service by using such credentials.  In such instance, the fee bearing certificate(s) will be issued to Licensee by the CA and any access to or use of such certificates by Licensee will be subject to the terms and conditions set out by the CA.  No fees will be paid to or processed by Venafi with respect to such CA.

2.4.2 Licensee shall not use (or cause to be used) the Service for the benefit of any third party, including without limitation by rental, in the operation of an Applications Service Provider (ASP) service offering or as a service bureau, or any similar means. 

2.4.3 Licensee shall not distribute access to the Service, in whole or in any part, to any third party or parties, and shall not permit sublicensing, leasing, or other transfer of the Service.

2.4.4 Licensee shall not (a) access or use the Service or Documentation if Licensee is a Venafi competitor or if Licensee is acting as a representative or agent of a competitor, except with Venafi prior written consent, or (b) use information obtained from the Service and the Documentation to compete with Venafi, or for any purpose other than bona fide machine identity management services and compliance monitoring.

2.4.5 Licensee shall not (a) interfere with or disrupt the integrity or performance of the Service or third-party data contained therein, (b) attempt to gain unauthorized access to the Service or its related systems or networks, (c) permit direct or indirect access to or use of the Service in a way that circumvents a contractual usage limit, or (d) access or use the Service in order to build a competitive product or service.

2.4.6 Licensee may not copy, reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Service, any Updates, or any part thereof (except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open-sourced components included with the Service).

2.5 Use of Data.

2.5.1 License Grant by Licensee.   Licensee grants to Venafi and its affiliates, as applicable, a worldwide, royalty-free and irrevocable license to host, copy, transmit and display Licensee Data as necessary for Venafi to provide the Service in accordance with this Agreement. Subject to the limited licenses granted herein, Venafi acquires no right, title or interest from Licensee or any Licensee suppliers or licensors under this Agreement in or to Licensee Data.  

2.5.2 Consent to Data Collection and Use: As part of Venafi’s provision of the Service, Licensee agrees that Venafi, its Affiliates, and its authorized service providers may process technical and telemetry data that enable the analysis of product use and deployment, license consumption monitoring, and the provision of Updates, improvements, and technical support. Licensee agrees that Venafi, its Affiliates, and its service providers may collect and process user information relating to an identified or identifiable natural person (“Personal Data”) where doing so enables Venafi to provide the Service, comply with this agreement, ensure license compliance, communicate with Licensee about the Service, and analyze and improve Service navigation and functionality. The collection of Personal Data necessary to fulfill these purposes is limited to: names and business contact information of Licensee employees and users, contact information or Personal Data submitted with user support requests, user names, user emails, and user IP addresses.

2.5.3 No Other Personally Identifiable Information: Licensee agrees that Licensee will not provide personally identifiable information to Venafi or to the Service except for the information described in Section 2.5.2. Licensee will not provide extraneous personally identifiable information to the Service or to Venafi.

2.5.4 External Service. The Service may enable access to third-party Services and websites (collectively, the “External Service”) including, without limitation, those of any CA. Licensee agrees to use the External Service at Licensee’s sole risk and expense. Venafi is not responsible for examining or evaluating the content or accuracy of any External Service and shall not be liable for any such External Service. Licensee will not use the External Service in any manner that is inconsistent with the terms of this Agreement and the agreements governing the uses of such External Service, or that infringes the intellectual property rights of Venafi or any third party. Venafi reserves the right to change, suspend, remove, disable or impose access restrictions or limits on any External Service at any time without notice or liability to Licensee. 

2.5.5 Venafi Privacy Policy. Licensee’s use of the Service is governed by the Venafi Privacy Policy and any data privacy policies specific to the Service as in effect from time to time, available at https://www.venafi.com/privacy. By accessing or using the Service, Licensee agrees to be bound by the privacy policies in effect at such time of access or use and to review https://www.venafi.com/privacy for updates. If Licensee does not agree to those terms, Licensee must immediately discontinue any access to or use of the Service.

3. Ownership 

3.1 Ownership of Venafi Materials. Venafi and/or its suppliers or licensors have and shall retain ownership of all right, title and interest in and to the Service and all intellectual property rights embodied in the Service and the Documentation, including without limitation any patents, copyrights, trademarks and trade secrets in and any modifications and/or derivatives thereof, whether or not made at Licensee request, and all know-how, concepts, methods, programming tools, inventions, and computer source code embodied in the Service.

3.2 Limited Feedback License.  Licensee hereby grants to Venafi, at no charge, a non-exclusive, royalty-free, worldwide, perpetual, irrevocable license under Licensee intellectual property rights in and to suggestions, comments and other forms of feedback (“Feedback”) regarding the Service provided by or on behalf of Licensee to Venafi, including Feedback regarding features, usability and use, and bug reports, to reproduce, perform, display, create derivative works of the Feedback and distribute such Feedback and/or derivative works in the Service.  Feedback is provided “as is” without warranty of any kind and shall not include any of Licensee’s confidential information. Venafi shall be free to disclose and use such Feedback as it sees fit, but shall not publicize the source of any Feedback from Licensee without Licensee’s consent.

4. Support and Service Levels

4.1 Customer Support. Licensee’s subscription to the Service includes access to the Venafi Customer Support Help Desk and web portal, support.venafi.com. Licensee agrees to submit requests for support pursuant to instructions provided by Venafi in the web portal or by emailing the Help Desk at support@venafi.cloud. Venafi support providers will make commercially reasonable efforts to address support requests in accordance with the service levels provided at support.venafi.com.  

4.2 Service Availability Levels; Sole Remedy. Venafi will use commercially reasonable efforts to make the Service available for Licensee use least 99.9% of the time in each month, excluding downtime for emergency maintenance (not to exceed 8 hours per month) and scheduled downtime for maintenance that is preceded by reasonable notice from Venafi (not to exceed 12 hours per month). If, excepting emergency and scheduled downtime and circumstances beyond Venafi’s reasonable control, the availability of service falls below 99.9% in a given calendar month of Licensee’s License Term, then Venafi’s sole obligation in relation thereto and Licensee’s sole and exclusive remedy therefor, is that Licensee shall be eligible to request service credits for future subscriptions of the same Service, according to a credit percentage schedule set by Venafi. Licensee must file a support ticket within 30 days setting out the date(s) and time(s) of unavailability and Venafi must validate the unavailability in order to issue such a credit. Credits are not available during any Free Trial Period.

5. Limited Releases

Licensee understands and agrees that from time-to-time Venafi may offer versions of software licensed as part of the Service on a limited pre-release or “beta” basis (each, a “Beta Service”), marked as such, and, in such cases, the functionality in a full release version of the Service or any interim version of the Service may differ significantly from the Beta Service. Licensee further acknowledges and agrees that a full release version of a Beta Service may never be completed or provided to Licensee. The collection, use and treatment of Licensee Data pursuant to a Beta Service may vary significantly in future versions of the Service. Without limiting the general disclaimer of warranty in Section 6 below, VENAFI EXPRESSLY DISCLAIMS ANY WARRANTIES, EXPRESS OR IMPLIED, THAT ANY BETA SERVICES AS PROVIDED TO LICENSEE, OR AS MAY BE PROVIDED TO LICENSEE IN THE FUTURE, ARE COMPLETE, VERIFIED, TESTED OR WITHOUT BUGS OR ERRORS, OR FIT FOR ANY PURPOSE, WHETHER OR NOT LICENSEE IS WITHIN THE FREE TRIAL PERIOD. THE SERVICE IS PROVIDED AT ALL RELEVANT TIMES AS IS, INCLUDING ALL OF ITS FAULTS, AS AVAILABLE WITH RESPECT TO ANY BETA SERVICES AND RELATED DOCUMENTION PROVIDED TO LICENSEE. Venafi may modify a Beta Service for any reason or without any specific reason, at any time and at its entire discretion, in particular for technical reasons such as updates, maintenance operations and/or resets to improve and/or optimize the Service. Without limiting the general limitation of liability in Section 6 below, Venafi may terminate a Beta Service at any time and for any reason, including for the business convenience of Venafi. In the event of Venafi’s termination for convenience, Venafi shall refund to Licensee a pro-rated portion of the license fee paid to Venafi by Licensee for such Beta Service, if any, under each applicable Order Form.

6. Limitation of Liability; Disclaimer of Warranty

6.1 Limitation of Liability.  IN NO EVENT WILL VENAFI OR ITS SUPPLIERS BE LIABLE FOR ANY LOST REVENUE, PROFIT, OR DATA, OR FOR DIRECT, SPECIAL, INDIRECT, CONSEQUENTIAL, INCIDENTAL, OR PUNITIVE DAMAGES HOWEVER CAUSED AND REGARDLESS OF THE THEORY OF LIABILITY ARISING OUT OF THE USE OF OR INABILITY TO USE THE SERVICE EVEN IF VENAFI OR ITS SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. A PARTY’S LIABILITY WITH RESPECT TO ANY SINGLE INCIDENT ARISING OUT OF OR RELATED TO THE AGREEMENT WILL NOT EXCEED THE AMOUNT PAID BY LICENSEE FOR THE SERVICE IN THE 12 MONTHS PRECEDING THE INCIDENT, PROVIDED THAT IN NO EVENT WILL A PARTY’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THE AGREEMENT (REGARDLESS OF THE NUMBER OF INDIVIDUAL INCIDENTS GIVING RISE TO LIABILITY) EXCEED THE TOTAL AMOUNT PAID BY LICENSEE FOR THE SERVICE UNDER ANY THEORY OF LIABILITY. HOWEVER, THE ABOVE LIMITATIONS WILL NOT LIMIT LICENSEE PAYMENT OBLIGATIONS FOR SERVICE FEES OR LICENSEE LIABILITY UNDER SECTION 7 (INDEMNIFICATION). Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages and to the extent that is the case the above limitation or exclusion shall apply to the maximum extent allowable in such jurisdiction. 

6.2 Disclaimer of Warranty. THE SERVICE AND DOCUMENTATION ARE PROVIDED “AS-IS,” WITH “ALL FAULTS” AND “AS AVAILABLE,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY, RELIABILITY, OR NONINFRINGEMENT WHETHER ARISING FROM COURSE OF DEALING, USAGE, TRADE PRACTICE OR ANY OTHER MANNER. VENAFI IS NOT OBLIGATED TO PROVIDE ANY UPDATES, UPGRADES OR TECHNICAL SUPPORT FOR THE SERVICE. VENAFI DISCLAIMS ALL LIABILITY AND INDEMNIFICATION OBLIGATIONS FOR ANY HARM OR DAMAGES CAUSED BY ANY THIRD-PARTY HOSTING PROVIDERS.   In no event does Venafi warrant that the Service is error free or that Licensee will be able to operate the Service without problems or interruptions. Some jurisdictions do not allow the exclusion of implied warranties and to the extent that is the case the above exclusion shall apply to the maximum extent allowable in such jurisdiction.  

6.3 Prior Versions.  If any component, plug-in or similar related software, including, without limitation, Licensee operating system(s), used in conjunction with the Service requires updating, including, without limitation, patching or reinstalling, per the instructions of Venafi or the provider of such software, Licensee expressly acknowledges and agrees that Licensee is responsible for making or allowing such updates and in no event will Venafi be liable for any damages under any theory of liability caused by or relating to such failure to update.

7. Indemnification 

Licensee shall indemnify, defend, and hold Venafi and Venafi’s directors, officers, agents, affiliates, contractors and employees harmless from any and all third-party claims, losses and liabilities accruing or resulting by reason of (a) Licensee or Licensee affiliates’ gross negligence or willful misconduct (i) in the performance of this Agreement or (ii) with respect to Licensee accessing or using the Service or (b) Licensee or Licensee affiliates’ breach of Section 3.1 (Ownership of Venafi Materials) or Section 9 (Confidentiality) hereof.

8. Compliance With Laws

8.1 Violation of Laws.  Licensee shall not knowingly take any action or omit to take any action where the reasonably predictable result would be to cause Venafi or its suppliers or Affiliates to violate any applicable law, rule, regulation or policy and, to the extent not inconsistent therewith, any other applicable law, rule, regulation and policy.

8.2 Export and Access Controls. Licensee’s use of the Service is conditioned on Licensee’s compliance with applicable export and import restrictions and regulations of the United States or foreign agency or authority, as well as additional restrictions imposed by this Section. Notwithstanding anything else in this Agreement to the contrary, the Service and any technical data related to or derivative of the Service may not be accessed from, exported to, re-exported to, or otherwise used by or accessed from: (i) any country to which the United States has embargoed goods; (ii) Cuba, the Democratic People’s Republic of Korea, Iran, Syria, Sudan, or the Crimea region; (iii) anyone on the U.S. Treasury Department’s List of Specially Designated Nationals or the U.S. Commerce Department’s Table of Denial Orders; (iv) any country or destination for which an export license or import license or other governmental approval is required without first having obtained such license or other approval; or (v) Belarus, Russia, or territory determined by the United States to be controlled by Russia, unless Licensee first obtains written permission from Venafi. Licensee represents and warrants that Licensee and Licensee financial institutions, or any party that owns or controls Licensee or Licensee financial institutions, are not subject to sanctions or otherwise designated on any applicable list of prohibited or restricted parties, including but not limited to the lists maintained by the United Nations Security Council, the U.S. Government (e.g., the Specially Designated Nationals List and Foreign Sanctions Evaders List of the U.S. Department of Treasury, and the Entity List of the U.S. Department of Commerce), the U.K. government, the European Union or its Member States, or other applicable government authority.

9. Confidentiality

9.1 Definition.  “Confidential Information” as used in this Agreement shall mean any and all information intended by a party to be treated as confidential that either (a) is marked conspicuously as “Confidential” or “Proprietary” or with another similarly restrictive legend; (b) is disclosed orally or is visually identified as confidential at the time of disclosure and designated in a written memorandum as such within 30 days of disclosure; or (c) is, due to its character or nature, information that a reasonable person in a like position and under like circumstances would treat as confidential or proprietary. From time to time, Venafi or Licensee may find it necessary to provide to the other party such Confidential Information.  Notwithstanding the foregoing, Confidential Information of a party shall not include information that the other party can establish by written documentation (a) to have been publicly known prior to disclosure of such information by the disclosing party to the receiving party; (b) to have become publicly known, without the fault of the receiving party, subsequent to disclosure of such information by the disclosing party to the receiving party; (c) to have been received by the receiving party at any time from a source, other than the disclosing party, rightfully having possession of and the right to disclose such information; (d) to have been otherwise known by the receiving party prior to disclosure of such information by the disclosing party to the receiving party; or (e) to have been independently developed by employees or agents on behalf of the receiving party without access to or use of such information disclosed by the disclosing party to the receiving party.

9.2 Obligations.  During the term of this Agreement, and for a period of five (5) years following the expiration or earlier termination hereof, each party shall maintain in confidence all Confidential Information disclosed by the other party and shall not use, grant the use of or disclose to any third party the Confidential Information of the other party other than for the purpose of exercising rights under this Agreement or as expressly permitted hereby.  Without limiting the foregoing, each of the parties shall use at least the same degree of care it uses to prevent the disclosure of its own confidential information of like importance, which care shall be no less than reasonable care, to prevent the disclosure of Confidential Information of the other party.  Each party shall notify the other promptly upon discovery of any unauthorized use or disclosure of the other party’s Confidential Information.

10. Governing Law; Injunctive Relief

10.1 Applicable Law. This Agreement shall be governed by the laws of the State of New York, excluding (a) its conflicts of laws principles; (b) the United Nations Convention on Contracts for the International Sale of Goods; (c) the 1974 Convention on the Limitation Period in the International Sale of Goods; and (d) the Protocol amending the 1974 Convention, done at Vienna April 11, 1980. Any dispute, claim or controversy arising out of or relating to this Agreement or the breach, termination, enforcement, interpretation or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall be determined by arbitration in San Jose, California before one arbitrator. The arbitration shall be administered by JAMS pursuant to its Comprehensive Arbitration Rules and Procedures and in accordance with the Expedited Procedures in those Rules. Judgment on the Award may be entered in any court having jurisdiction. This clause shall not preclude parties from seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction.

10.2 Injunctive Relief. Notwithstanding the provisions of Section 10.1 above, or anything contained in this Agreement to the contrary, Venafi and Licensee hereby acknowledge and agree that damages at law may be an inadequate remedy for the breach of any of the covenants, promise and contracts contained in Section 2.4 (Restrictions on Use), Section 3.1 (Ownership of Venafi Materials) and Section 9 (Confidentiality).  Accordingly, Venafi and Licensee hereby agree that Venafi and/or Licensee shall be entitled to temporary and permanent injunctive relief with respect to any such breach without the necessity of proving actual damages, including specific performance of such covenants, promises or contracts or any order enjoining Venafi or Licensee from any threatened, or from the continuation of any actual, breach of covenants, promises or contracts, and may seek provision or enforcement of any such injunctive relief in any competent court having jurisdiction. The rights set forth in this Section 10.2 shall be in addition to any other rights which the parties may have at law or in equity, subject to the terms and conditions in this Agreement.

11. General

11.1 Binding Agreement; Enforceability. This Agreement is binding on Licensee as well as Licensee employees, employers, contractors and agents, and on any permitted successors and assignees. Except if otherwise superseded in writing by a separately executed agreement, this Agreement, together with any applicable Order Forms and the Venafi Privacy Policy, is the entire agreement between Licensee and Venafi with regard to the License granted hereunder, and Licensee agrees that Venafi will not have any liability for any statement or representation made by it, its agents or anyone else (whether innocently or negligently) upon which Licensee relied in entering into this Agreement. This Agreement supersedes any other understandings or agreements, including, but not limited to, advertising, with respect to the Service. If any provision of this Agreement is deemed invalid or unenforceable by any court or arbiter, that particular provision will be deemed modified to the extent necessary to make the provision valid and enforceable and the remaining provisions will remain in full force and effect. Venafi’s breach of any Service Level Agreement or similar agreement between Licensee and Venafi, or otherwise issued by Venafi, shall not constitute a breach of this Agreement or of any Order Form.

11.2 Survival.  The parties agree that the rights and obligations set forth in the above-referenced Sections 1 (Definitions), 3.1 (Ownership of Venafi Materials), 4.2 (Limited Releases), 6 (Limitation of Liability; Disclaimer of Warranty), 7 (Indemnification), 8 (Compliance with Laws), 9 (Confidentiality), 10 (Governing Law; Injunctive Relief), and 11 (General) shall survive the termination of this Agreement for any reason and enforcement thereof shall not be subject to any conditions precedent.

11.3 Notice.  Any notice shall be considered delivered two business days after delivery by email or two business days after depositing as first-class mail with the United States Postal Service, or with a domestic carrier service. All notices required hereunder (or under any Order Form or any related agreement) must be made in writing, which may be by email, at the respective address or email address shown on the relevant Order Form. In the absence of such an address or email address, Licensee may make notice to Venafi by writing to Venafi, Inc. at 175 E. 400 South, Suite 300, Salt Lake City, Utah 84111, Attn: Legal Department and Venafi may make notice to Licensee at any email address or physical address Licensee may have provided to Venafi in connection with Licensee use of or access to the Service.

11.4 Assignment.  This Agreement shall be binding upon and inure to the benefit of the parties’ respective successors and permitted assigns.  Licensee shall not assign this Agreement or any of Licensee rights or obligations hereunder without the prior written consent of Venafi and any such attempted assignment shall be void.  For questions concerning this Agreement, please contact Venafi at 175 E. 400 South, Suite 300, Salt Lake City, Utah 84111 USA.

For questions concerning this Agreement, please contact Venafi at 175 E. 400 South, Suite 300, Salt Lake City, Utah 84111 USA or contact your Venafi account representative or the reseller through whom you purchased licenses.
`
