import React, { useMemo } from "react"
import { useRequestFilters } from "./filters"
import { useGetCertificateRequests } from "@lib/certificateRequests"
import { CertificateRequestWidget } from "./CertficateRequestWidget"

const CertificateRequestsLoader = () => {
  const { requests, isError, isSuccess } = useGetCertificateRequests()
  const { onFilter, filterOptions, filteredRequests, filtersState } = useRequestFilters(requests)
  const totalRequests = useMemo(() => requests?.length || 0, [requests])

  const props = { onFilter, filterOptions, filteredRequests, filtersState, totalRequests, isError, isSuccess }

  return <CertificateRequestWidget {...props} />
}

export const CertificateRequests = CertificateRequestsLoader
