import React, { ReactElement, PropsWithChildren } from "react"
import { Grid, Typography } from "@material-ui/core"

type InstructionStepProps = PropsWithChildren<{
  step: number
}>

export function InstructionStep({ step, children }: InstructionStepProps): ReactElement {
  return (
    <Grid item>
      <Typography variant="h5" color="textPrimary">
        Step {step}
      </Typography>
      {children}
    </Grid>
  )
}
