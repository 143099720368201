import { K8SGenericResource } from "../types"
import { IssuerStatus, SecretKeySelector } from "../cert-manager"

export enum Issuers {
  GoogleCASIssuer = "GoogleCASIssuer",
  GoogleCASClusterIssuer = "GoogleCASClusterIssuer",
  AWSPCAIssuer = "AWSPCAIssuer",
  AWSPCAClusterIssuer = "AWSPCAClusterIssuer",
  VenafiEnhancedIssuer = "VenafiIssuer",
  VenafiEnhancedClusterIssuer = "VenafiClusterIssuer",
}

export enum IssuerTypes {
  GoogleCas = 1,
  AwsPca = 2,
  VenafiEnhanced = 3,
}

// Google Certificate Authority Service (CAS) Issuer & ClusterIssuer
export type GoogleCasSpec = {
  // caPoolId is The ID of the Google Private certificate authority that will sign certificates
  caPoolId?: string
  // Location is the Google Cloud Project Location
  location?: string
  // Project is the Google Cloud Project ID
  project?: string
  // Credentials is a reference to a Kubernetes Secret Key that contains Google Service Account Credentials
  credentials?: SecretKeySelector
}

export type GoogleCasIssuer = K8SGenericResource & {
  spec?: GoogleCasSpec
  status: IssuerStatus
}

export type GoogleCasClusterIssuer = K8SGenericResource & {
  spec: GoogleCasSpec
  status: IssuerStatus
}

// AWS Private Certificate Authority Issuer & ClusterIssuer
export type AwsPcaSpec = {
  // Specifies the ARN of the PCA resource
  arn: string
  // Should contain the AWS region if it cannot be inferred
  region?: string
  // Needs to be specified if you want to authorize with AWS using an access and secret key
  secretRef?: SecretKeySelector
}

export type AwsPcaIssuer = K8SGenericResource & {
  spec?: AwsPcaSpec
  status: IssuerStatus
}

export type AwsPcaClusterIssuer = K8SGenericResource & {
  spec?: AwsPcaSpec
  status: IssuerStatus
}

// Venafi Enhanced Issuer & ClusterIssuer
export type VenafiEnhancedSpec = {
  tpp?: TppCertificateIssuer
  vaas?: VaasCertificateIssuer
}

export type TppCertificateIssuer = {
  policyDN: string
  url?: string
  accessToken?: SecretSource[]
}

export type VaasCertificateIssuer = {
  // Application is the name of the Application in VaaS.
  application: string

  // Template is the name of the Issuing Template as shown in VaaS.
  template: string

  // The list of steps to retrieve the API key that will be used to connect to
  // VaaS.
  // +listType=atomic
  // +optional
  apiKey?: SecretSource[]
}

export type SecretSource = {
  // +optional
  secret?: Secret

  // +optional
  serviceAccountToken?: ServiceAccountToken

  // +optional
  hashicorpVaultOAuth?: HashicorpVaultOAuth

  // +optional
  hashicorpVaultSecret?: HashicorpVaultSecret

  // +optional
  tppOAuth?: TppOAuth
}

export type Secret = {
  // The name of the Kubernetes secret.
  name: string

  // The names of the fields we want to extract from the Kubernetes secret.
  // These fields are passed to the next step in the chain.
  fields: string[]
}

export type ServiceAccountToken = {
  // The name of the Kubernetes service account.
  name: string

  // Audiences are the intendend audiences of the token. A recipient of a
  // token must identify themself with an identifier in the list of
  // audiences of the token, and otherwise should reject the token. A
  // token issued for multiple audiences may be used to authenticate
  // against any of the audiences listed but implies a high degree of
  // trust between the target audiences.
  audiences: string[]

  // ExpirationSeconds is the requested duration of validity of the request. The
  // token issuer may return a token with a different validity duration so a
  // client needs to check the 'expiration' field in a response.
  // +optional
  expirationSeconds?: number
}

export type HashicorpVaultOAuth = {
  // AuthInputType is the authentication method to be used to authenticate
  // with HashiCorp Vault. The only supported value is "OIDC".
  authInputType: "OIDC" | string

  // The login URL used for obtaining the Vault token. Example:
  // "https://vault:8200/v1/auth/oidc/login".
  authPath: string

  // The role defined in Vault that we want to use when authenticating to
  // Vault.
  role: string

  // The Url to connect to your HashiCorp Vault instance.
  url?: string
}

export type HashicorpVaultSecret = {
  // The full HTTP path to the secret in Vault. Example:
  // /v1/secret/data/application-team-a/tpp-username-password
  secretPath: string

  // The fields are Vault keys pointing to the secrets passed to the next
  // SecretSource step.
  //
  // Example 1 (TPP, username and password): imagining that you have stored
  // the username and password for TPP under the keys "username" and
  // "password", you will want to set this field to `["username",
  // "password"]`. The username is expected to be given first, the password
  // second.
  fields: string[]

  // The Url to connect to your HashiCorp Vault instance.
  url?: string
}

export type TppOAuth = {
  // AuthInputType is the authentication method to be used to authenticate
  // with TPP. The only supported values is "UsernamePassword".
  authInputType: "UsernamePassword"

  // ClientId is the clientId used to authenticate with TPP.
  // +kubebuilder:default="cert-manager.io"
  clientId?: string

  // The Url to connect to the Venafi TPP instance. The two URLs
  // https://tpp.example.com and https://tpp.example.com/vedsdk are
  // equivalent. The ending `/vedsdk` is optional and is stripped out by
  // venafi-enhanced-issuer.
  url?: string
}

export type VenafiEnhancedIssuer = K8SGenericResource & {
  spec?: VenafiEnhancedSpec
  status: IssuerStatus
}

export type VenafiEnhancedClusterIssuer = K8SGenericResource & {
  spec?: VenafiEnhancedSpec
  status: IssuerStatus
}
