import React, { useMemo } from "react"
import { Paper, Typography } from "@material-ui/core"
import { Table, TableHeader } from "@components/ResourcesTable"
import { ErrorMessage } from "@components/ErrorMessage"
import { FiltersBar } from "@components/FiltersBar"
import { StatusBar } from "@components/StatusBar"
import { useGetAllCertManagerIssuers } from "@lib/certManagerIssuers/queries"
import { IssuerRow } from "./IssuerRow"
import { useStyles } from "./useStyles"
import { SummaryTitle } from "../SummaryTitle"
import { useIssuerFilters } from "./filters"

function IssuerSummary() {
  const classes = useStyles()
  const { issuers, isError, isSuccess } = useGetAllCertManagerIssuers()
  const { onFilter, filterOptions, filteredIssuers, filtersState } = useIssuerFilters(issuers)

  const totalIssuers = useMemo(() => issuers?.length || 0, [issuers])
  const totalReadyIssuers = useMemo(() => issuers?.filter(issuer => issuer.status.ready).length || 0, [issuers])

  const issuersCount = filteredIssuers?.length || 0

  return (
    <Paper className={classes.paper}>
      {isError ? (
        <div className={classes.error}>
          <ErrorMessage message="Something went wrong while loading the Cert Manager Issuers. Please try again or contact support." />
        </div>
      ) : (
        <>
          <SummaryTitle title="Issuer Summary" />
          <div data-testid="cluster-overview">
            <StatusBar
              staticStats={[
                { label: "Total issuers", value: totalIssuers },
                { label: "Ready issuers", value: totalReadyIssuers },
              ]}
            />
          </div>
          <div className={classes.filterContainer}>
            <FiltersBar filtersState={filtersState} filterOptions={filterOptions} onFilter={onFilter} isDisabled={!isSuccess} />
          </div>
          <div className={classes.pageSection}>
            {issuersCount > 0 && (
              <Table>
                <TableHeader rows={[{ label: "Name" }, { label: "Kind" }, { label: "Type" }, { label: "Status" }]} />
                <tbody>
                  {filteredIssuers?.map((issuer, index) => (
                    <IssuerRow key={`${issuer.name}-${index}`} issuer={issuer} handleSelect={() => {}} />
                  ))}
                </tbody>
              </Table>
            )}
            {issuersCount === 0 && (
              <div className={classes.errorContainer}>
                <Typography>No Issues found with this filter.</Typography>
              </div>
            )}
          </div>
        </>
      )}
    </Paper>
  )
}

export { IssuerSummary }
