import { takeEvery, put, call, select } from "redux-saga/effects"
import axios from "axios"
import ReactGA from "react-ga"
import { rudderanalytics as rudderstack } from "@utils/rudderstack"

import { extractGroupsFromJWT, extractEmailVerifiedFromJWT } from "@utils/jwt"
import { AuthClient, addAuth } from "@lib/auth"
import * as pendo from "@lib/pendo"

import {
  START_CLIENT,
  clientReady,
  authFailure,
  redirectLogin,
  REDIRECT_LOGIN,
  REDIRECT_CALLBACK_RECEIVED,
  redirectCallbackProcessed,
  gotAccessToken,
  FETCH_AUTH_INFO,
  fetchAuthInfoSuccess,
  LOGOUT_STARTED,
  logoutFinished,
  FetchAuthInfoAction,
  CHANGE_USER,
  ChangeUserAction,
  RedirectCallbackReceivedAction,
} from "@actions/auth"
import { clientSelector } from "@selectors/auth"
import { generateErrorMessage, handleError } from "@utils/errorHandling"
import { currentOrgSelector } from "@selectors/orgs"
import { updateCurrentOrg } from "@actions/orgs"
import { AppConfigAuth } from "@lib/appConfig"
import { createAuthClient } from "@lib/auth"
import { appConfigSelector } from "@selectors/appConfig"

function cleanURL() {
  const uri = window.location.toString()
  return uri.substring(0, uri.indexOf("?"))
}

function getClient(authConfig: AppConfigAuth) {
  return createAuthClient(authConfig).initialize()
}

function redirectToLogin(client: AuthClient) {
  const targetUrl = window.location.pathname + window.location.search + window.location.hash
  return client.redirectToLogin({ targetUrl })
}

function handleCallback(client: AuthClient) {
  return client.handleRedirectCallback()
}

function getToken(client: AuthClient, force: boolean) {
  return client.getToken(force)
}

function getAuthInfo(token: string) {
  return axios.get("/api/v1/auth", addAuth(token))
}

function logout(client: AuthClient, returnTo?: string) {
  return client.logout(returnTo || window.location.origin)
}

function isAuthenticated(client: AuthClient) {
  return client.isAuthenticated()
}

function* startClientSaga() {
  try {
    const { appConfig } = yield select(appConfigSelector)
    if (!appConfig?.auth) {
      throw new Error("auth config not available. Unexpected error.")
    }
    const client: ReturnType<typeof clientSelector> = yield call(getClient, appConfig?.auth)
    // @ts-expect-error: TODO: Fix this
    const alreadyAuth = yield call(isAuthenticated, client)
    // @ts-expect-error: TODO: Fix this
    yield put(clientReady(client, alreadyAuth, appConfig?.auth?.auth0?.domain || ""))
  } catch (error) {
    handleError(error, `Unable to start auth client`)
    yield put(authFailure(`Unable to start auth client - ${generateErrorMessage(error)}`))
  }
}

function* redirectLoginSaga() {
  try {
    const client: ReturnType<typeof clientSelector> = yield select(clientSelector)
    // @ts-expect-error: TODO: Fix this
    const alreadyAuth = yield call(isAuthenticated, client)
    if (!alreadyAuth) {
      // @ts-expect-error: TODO: Fix this
      yield call(redirectToLogin, client)
    } else {
      try {
        // @ts-expect-error: TODO: Fix this
        const token = yield call(getToken, client, false)
        yield put(gotAccessToken(token))
        // @ts-expect-error: TODO: Fix this
        const authInfoRes = yield call(getAuthInfo, token)

        // Retrieve previous currentOrg from localStorage and if existed, use it as current org.
        const prevCurrentOrgID = localStorage.getItem("currentOrg")
        const authInfo = authInfoRes.data as AuthInfoType
        const currentOrg = authInfo.organizations.find(org => org.id === prevCurrentOrgID) || authInfo.organizations[0]

        // TODO: remove check from JWT and rely on the info returned by the auth endpoint.
        // for now we assume it if verified if any of the methods returns true.
        let emailVerified = false
        if (token) {
          emailVerified = extractEmailVerifiedFromJWT(token)
        } else {
          emailVerified = authInfoRes.data.user.verified
        }

        yield put(fetchAuthInfoSuccess(authInfoRes.data, currentOrg ? currentOrg.id : "", emailVerified))
      } catch (error) {
        handleError(error, `Unable to fetch user from auth provider`)
        yield put(authFailure(`Unable to fetch user from auth provider - ${generateErrorMessage(error)}`))
      }
    }
  } catch (error) {
    handleError(error, `Unable to redirect to login`)
    yield put(authFailure(`Unable to redirect to login - ${generateErrorMessage(error)}`))
  }
}

function* redirectCallbackReceivedSaga(action: RedirectCallbackReceivedAction) {
  try {
    const client: ReturnType<typeof clientSelector> = yield select(clientSelector)
    const params = new URLSearchParams(window.location.search)
    const message = params.get("message")
    if (message) {
      // assume this is a callback from password reset, or email verification like:
      // http://______/?supportSignUp=true&supportForgotPassword=true&email=user%40jetstack.io&message=Your%20email%20was%20verified.%20You%20can%20continue%20using%20the%20application.&success=true&code=success
      const success = params.get("success")
      if (success === "true") {
        window.history.replaceState({}, document.title, cleanURL())
        yield put(redirectLogin())
      } else {
        const msg = `Unable to handle login callback (success=${success}): ${message}`
        handleError({ name: "login-callback-unsuccessful", message: msg }, msg)
        yield put(authFailure(msg))
      }
    } else {
      // assume it is a login redirect
      // @ts-expect-error: TODO: Fix this
      const { appState } = yield call(handleCallback, client)
      yield put(redirectCallbackProcessed())
      if (appState && appState.targetUrl) {
        action.navigate(appState.targetUrl)
      } else {
        window.history.replaceState({}, document.title, cleanURL())
      }
    }
  } catch (error) {
    handleError(error, `Unable to handle callback from login`)
    yield put(authFailure(`Unable to handle callback from login - ${generateErrorMessage(error)}`))
  }
}

function* fetchAuthInfoSaga(action: FetchAuthInfoAction) {
  try {
    const client: ReturnType<typeof clientSelector> = yield select(clientSelector)
    // @ts-expect-error: TODO: Fix this
    let token: string = yield call(getToken, client, action.forceNewToken)
    yield put(gotAccessToken(token))
    // @ts-expect-error: TODO: Fix this
    const authInfoRes = yield call(getAuthInfo, token)
    const authInfo = authInfoRes.data as AuthInfoType

    // If token had 0 groups, it was a new user and getAuthInfo should have created a new org for the user,
    // which is not reflected in the current token, so we need a new token:
    const groups = extractGroupsFromJWT(token)
    if (authInfo?.organizations?.length > 0 && groups.length === 0) {
      ReactGA.event({
        category: "User",
        action: "New signup",
      })
      // @ts-expect-error: TODO: Fix this
      token = yield call(getToken, client, true)
      yield put(gotAccessToken(token))
    }

    // If current org not set, read from localStorage. Fallback to first org in the list.
    let currentOrgID: string = yield select(currentOrgSelector)
    if (currentOrgID === "") {
      currentOrgID = localStorage.getItem("currentOrg") || ""
    }
    const currentOrg = authInfo.organizations.find(org => org.id === currentOrgID) || authInfo.organizations[0]
    currentOrgID = currentOrg.id
    yield put(updateCurrentOrg(currentOrgID))

    // TODO: remove check from JWT and rely on the info returned by the auth endpoint.
    // for now we assume it if verified if any of the methods returns true.
    let emailVerified = false
    if (token) {
      emailVerified = extractEmailVerifiedFromJWT(token)
    } else {
      emailVerified = authInfoRes.data.user.verified
    }

    pendo.initialize(authInfoRes.data.user, currentOrgID)

    yield put(fetchAuthInfoSuccess(authInfoRes.data, currentOrgID, emailVerified))
  } catch (error) {
    handleError(error, `Unable to fetch user from auth provider`)
    yield put(authFailure(`Unable to fetch user from auth provider - ${generateErrorMessage(error)}`))
  }
}

function* logoutSaga() {
  try {
    const client: ReturnType<typeof clientSelector> = yield select(clientSelector)
    // @ts-expect-error: TODO: Fix this
    yield call(logout, client)
    yield put(logoutFinished())
    rudderstack.reset()
  } catch (error) {
    handleError(error, `Error while logging out`)
    yield put(authFailure(`Error while logging out - ${generateErrorMessage(error)}`))
  }
}

function* changeUserSaga(action: ChangeUserAction) {
  try {
    const client: ReturnType<typeof clientSelector> = yield select(clientSelector)
    // @ts-expect-error: TODO: Fix this
    yield call(logout, client, window.location.origin + "/login" + "?destination=" + encodeURIComponent(action.destinationPath))
    yield put(logoutFinished())
    rudderstack.reset()
  } catch (error) {
    handleError(error, `Error while changing user`)
    yield put(authFailure(`Error while changing user - ${generateErrorMessage(error)}`))
  }
}

export function* authSaga() {
  yield takeEvery(START_CLIENT, startClientSaga)
  yield takeEvery(REDIRECT_LOGIN, redirectLoginSaga)
  yield takeEvery(REDIRECT_CALLBACK_RECEIVED, redirectCallbackReceivedSaga)
  yield takeEvery(FETCH_AUTH_INFO, fetchAuthInfoSaga)
  yield takeEvery(LOGOUT_STARTED, logoutSaga)
  yield takeEvery(CHANGE_USER, changeUserSaga)
}
