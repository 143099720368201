import { AppState } from "../reducers"
import { organizationsSelector } from "./auth"

export const currentOrgSelector = (state: AppState) => state.orgs.currentOrg

export const organizationExistsSelector =
  (orgId: string) =>
  (state: AppState): boolean => {
    const allOrgs = organizationsSelector(state)
    return Boolean(allOrgs && allOrgs?.some(org => org.id === orgId))
  }
