import React, { ReactElement } from "react"
import { createStyles, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core"

import { IssuerFields, IssuerCommonFields } from "../types"
import { ACMEInstructions } from "../issuers/acme/ACMEInstructions"
import { VenafiInstructions } from "../issuers/venafi/VenafiInstructions"
import { VaultInstructions } from "../issuers/vault/VaultInstructions"
import { GoogleCASInstructions } from "../issuers/googlecas/GoogleCASInstructions"
import { AwsPCAInstructions } from "../issuers/awspca/AwsPCAInstructions"
import { ButtonSeeDoc } from "../ButtonSeeDoc"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonSeeDoc: {
      marginBottom: theme.spacing(6),
    },
    documentationLink: {
      marginLeft: "0.2em",
      marginRight: "0.2em",
    },
  }),
)

type IssuerConfigurationProps = {
  common: IssuerCommonFields
  issuer: IssuerFields
  isFormValid: boolean
}

export const IssuerInstructions = ({ common, issuer, isFormValid }: IssuerConfigurationProps): ReactElement => {
  const classes = useStyles()
  return (
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          {isFormValid ? (
            <>
              <Grid className={classes.buttonSeeDoc}>
                <ButtonSeeDoc link={issuer.documentationUrl}>See {issuer.label} documentation</ButtonSeeDoc>
              </Grid>
              {issuer.key === "acme" && <ACMEInstructions common={common} issuer={issuer} />}
              {issuer.key === "venafi" && <VenafiInstructions common={common} issuer={issuer} />}
              {issuer.key === "vault" && <VaultInstructions common={common} issuer={issuer} />}
              {issuer.key === "googlecas" && <GoogleCASInstructions common={common} issuer={issuer} />}
              {issuer.key === "awspca" && <AwsPCAInstructions common={common} issuer={issuer} />}
            </>
          ) : (
            <>
              <Typography>Please fill the form with valid values to get usage instructions.</Typography>
              <Typography>
                Or check
                <Link href={issuer.documentationUrl} rel="noreferrer" target="_blank" className={classes.documentationLink}>
                  cert-manager documentation
                </Link>
                for the issuer {issuer.label}.
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
