import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  [x: string]: unknown
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& path": {
        stroke: theme.palette.primary.main,
      },
    },
  }),
)

export function Mist(props: Props): ReactElement {
  const classes = useStyles()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      data-testid="mist-icon"
      className={classes.root}
      {...props}
    >
      <path d="M5 5h3m4 0h9M3 10h11m4 0h1M5 15h5m4 0h7M3 20h9m4 0h3" />
    </svg>
  )
}
