import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { makeStyles, createStyles, Theme, Typography } from "@material-ui/core"

import { ClusterData } from "@lib/certInventory"
import { Resource, Issuer, calculateIssuers } from "@lib/certInventory/resources"
import { CollatedResources } from "@utils/certificate"
import { getOrgID, pathToNewIssuer } from "@routes"

import { ErrorMessage } from "@components/ErrorMessage"
import { LoadingMessage } from "@components/LoadingMessage"
import { CertificateIcon } from "@components/Icons"
import { NavButton } from "@components/Navigation/NavButton"
import { Table, TableHeader, IssuerRow } from "@components/ResourcesTable"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
    },
    topLine: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    error: {
      marginTop: theme.spacing(4),
    },
    noIssuers: {
      background: theme.palette.grey[200],
      borderRadius: 5,
      display: "inline-block",
      padding: theme.spacing(1, 2),
      margin: theme.spacing(2, 0),
      marginLeft: "50%",
      transform: "translateX(-50%)",
    },
  }),
)

type Props = {
  data?: ClusterData
  isLoading: boolean
  error: string
  handleSelect: (res: Resource) => void
  activeResource?: Resource
  // activeResourceHash: if provided and different than activeResource.hash() it will call handleSelect.
  activeResourceHash?: string
  allResourcesView?: boolean

  allClustersResources?: CollatedResources
}

export function Issuers({
  data,
  handleSelect,
  activeResource,
  isLoading,
  error,
  activeResourceHash,
  allResourcesView,
  allClustersResources,
}: Props) {
  const classes = useStyles()
  const orgId = getOrgID(useParams())
  const [issuers, setIssuers] = useState<Issuer[]>([])

  useEffect(() => {
    if (data) {
      setIssuers(calculateIssuers(data))
    } else if (allResourcesView && allClustersResources) {
      setIssuers(allClustersResources.issuers)
    }
  }, [data])

  useEffect(() => {
    if (
      (data || (allClustersResources && allClustersResources.issuers.length > 0)) &&
      issuers.length > 0 &&
      activeResourceHash &&
      (!activeResource || activeResourceHash !== activeResource.hash())
    ) {
      const issuer = issuers.filter(issuer => issuer.hash() === activeResourceHash)[0]
      if (issuer) {
        handleSelect(issuer)
      }
    }
  }, [activeResource, activeResourceHash, data, issuers])

  const getIssuers = (): JSX.Element[] => {
    if (isLoading || (!allResourcesView && (!data || !data.kind_index))) {
      return [<div key="loading">Loading...</div>]
    }

    const generateRow = (issuer: Issuer, index: number) => {
      const activeResourceId = activeResource ? activeResource?.id + activeResource?.clusterId : undefined
      const selected = issuer.id + issuer.clusterId === activeResourceId
      return <IssuerRow key={issuer.id + index} res={issuer} selected={selected} handleSelect={handleSelect} icon={<CertificateIcon />} />
    }

    return issuers.map(generateRow)
  }

  const generatedIssuers = getIssuers()
  return (
    <div className={classes.root} data-tut="issuers">
      {isLoading ? (
        <LoadingMessage label="loading issuers..." />
      ) : error ? (
        <div className={classes.error}>
          <ErrorMessage message={error} />
        </div>
      ) : (
        <>
          <div className={classes.topLine}>
            <NavButton variant="contained" color="primary" href={pathToNewIssuer(orgId)}>
              Add an Issuer
            </NavButton>
          </div>
          {generatedIssuers.length > 0 && (
            <Table>
              <TableHeader rows={[{ label: "", size: 1.5 }, { label: "Name" }, { label: "Status", size: 3.75 }]} />
              <tbody>{generatedIssuers}</tbody>
            </Table>
          )}
        </>
      )}
      {!isLoading && generatedIssuers.length < 1 && (
        <div className={classes.noIssuers}>
          <Typography>{allResourcesView ? "Organization has issuers setup." : "This cluster has no issuers."}</Typography>
        </div>
      )}
    </div>
  )
}
