import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { currentOrgSelector } from "@selectors/orgs"
import { clustersStateSelector } from "@selectors/clusters"
import { fetchClusters } from "@actions/clusters"

export const useFetchClusters = () => {
  const dispatch = useDispatch()
  const orgID = useSelector(currentOrgSelector)
  const { clusters, loadingError, isLoading } = useSelector(clustersStateSelector)
  useEffect(() => {
    if (!isLoading && !loadingError) {
      dispatch(fetchClusters(orgID))
    }
  }, [orgID, dispatch])

  return { clusters, isClusterError: loadingError, isClusterLoading: isLoading }
}
