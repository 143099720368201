import React from "react"
import { Navigate } from "react-router-dom"
import { currentOrgSelector } from "@selectors/orgs"
import { useSelector } from "react-redux"
import { useLocation } from "react-router"
import { pathToRoot } from "@routes"

// A component that redirects from /org/something/ to the current org,
// preserving the path, including search and hash parameters.
// This allows for sharing links to particular views without knowing
// the current org of the user, useful for documentation and support
// This shouldn't be used from the app itself, which should know the
// current org and use specific URLs

export const CurrentOrg = () => {
  const orgID = useSelector(currentOrgSelector)
  const location = useLocation()
  const path = location.pathname
  const suffix = path.split("/").slice(3).join("/") + location.search + location.hash

  return <Navigate to={pathToRoot(orgID) + suffix} />
}
