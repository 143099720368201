import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { get } from "lodash"
import { getOrgID } from "@routes"
import { accessTokenSelector } from "@selectors/auth"
import { ClustersOverviewData, OverviewResponseData, OverviewResponseDataCluster } from "./types"
import { addAuth } from "@lib/auth"
import { handleError } from "@utils/errorHandling"
import { QUERYKEYS } from "@lib/queryKeys"

function mapData(data?: OverviewResponseData): ClustersOverviewData {
  const clusters = get(data, "clusters", []).map(
    ({
      clusterId,
      active,
      isDemo,
      lastUpdate,
      certificateWarningCount,
      highRiskCertificateCount,
      certManagerInstalled,
      certManagerUpdateAvailable,
      CertManagerIssues,
      certManagerStatus,
      certificateCount,
      clusterType,
      agentStatus,
    }: OverviewResponseDataCluster) => ({
      id: clusterId,
      active,
      isDemo,
      lastUpdate,
      certManagerInstalled,
      certManagerStatus,
      certManagerUpdateAvailable,
      warnings: certificateWarningCount,
      errors: highRiskCertificateCount,
      certificateCount,
      certManagerIssues: CertManagerIssues,
      clusterType,
      agentLastUploadTime: get(agentStatus, "last_upload_time", ""),
    }),
  )

  return {
    total: data?.clusterCount || 0,
    totalCertificates: data?.certificateCount || 0,
    warnings: data?.clusterWarningCount || 0,
    errors: data?.highRiskClusterCount || 0,
    clusters,
  }
}

// Get all Discovered Certificate queries
export function useGetClustersData(isDemo: boolean | undefined) {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)

  const { data, ...rest } = useQuery(
    QUERYKEYS.clusters.all(orgID),
    () => axios.get<OverviewResponseData>(`/api/v1/org/${orgID}/overview?include_demo=${isDemo}`, addAuth(token)),
    { onError: error => handleError(error), enabled: true },
  )

  return {
    clustersData: mapData(data?.data),
    ...rest,
  }
}
