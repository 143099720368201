import FuzzySearch from "fuzzy-search"
import { CMCertificate, NonCMCertificate, OwnerlessCertificateRequest, Certificate, Issuer, AllCerts } from "@lib/certInventory/resources"

export const getSortedFilteredCerts = (
  filtersState: { [x: string]: string },
  certs: Certificate[],
  noncmcerts: Certificate[],
  cmcerts: Certificate[],
) => {
  let filteredSortedCerts: Certificate[] = [...certs]

  // Filter certificates by status
  const filterByStatus = filtersState["filterByStatus"]
  if (filterByStatus) {
    if (filterByStatus === "notManagedByCM") filteredSortedCerts = sortByStatus([...noncmcerts])
    if (filterByStatus === "managedByCM") filteredSortedCerts = sortByStatus([...cmcerts])
    if (filterByStatus === "withIssues")
      filteredSortedCerts = filteredSortedCerts.filter(({ status: { type } }) => type === "warning" || type === "error")
    if (filterByStatus === "withErrors") filteredSortedCerts = filteredSortedCerts.filter(({ status: { type } }) => type === "error")
    if (filterByStatus === "withWarnings") filteredSortedCerts = filteredSortedCerts.filter(({ status: { type } }) => type === "warning")
  }

  // Filter certificates by namespace
  const filterByNamespace = filtersState["filterByNamespace"]
  if (filterByNamespace) {
    filteredSortedCerts = filteredSortedCerts.filter(({ resource: { metadata } }) => metadata?.namespace === filterByNamespace)
  }

  // Filter certificates by issuer
  const filterByIssuer = filtersState["filterByIssuer"]
  if (filterByIssuer) {
    filteredSortedCerts = filteredSortedCerts.filter(cert => {
      if (cert instanceof CMCertificate || cert instanceof OwnerlessCertificateRequest) {
        return cert.issuedBy instanceof Issuer
          ? cert.issuedBy.resource.metadata?.namespace + " > " + cert.issuedBy.resource.metadata?.name === filterByIssuer
          : false
      }
      return false
    })
  }

  // Filter out the system certificates
  // Defined in https://gitlab.com/venafi/vaas/applications/tls-protect-for-k8s/cloud-services/blob/master/backend/internal/evaluator/evaluator_cluster_overview.go#L107
  const showSystemCerts = filtersState["showSystemCerts"]
  const systemCertsAnnotations = [
    // Openshift
    "service.alpha.openshift.io",
    "service.beta.openshift.io",
    "auth.openshift.io",
    "release.openshift.io",
    "include.release.openshift.io",
    "etcd-operator.alpha.openshift.io",
    // Rancher
    "listener.cattle.io",
  ]

  if (showSystemCerts !== "true") {
    filteredSortedCerts = filteredSortedCerts.filter(({ secret }) => {
      const annotations = secret?.resource.metadata?.annotations

      if (annotations) {
        const keys = Object.keys(annotations)
        return !systemCertsAnnotations.some(s => keys.filter(k => k.includes(s)).length > 0)
      }
      return true
    })
  }

  // Filter certificates based on the search term
  const searchTerm = filtersState["search"]
  if (searchTerm) {
    // Inside the array we pass in where the FuzzySearch should look into when we have a search term
    const searchProps = [
      "id",
      "resource",
      "resource.metadata.name",
      "resource.metadata.namespace",
      "resource.metadata.secretName",
      "resource.metadata.annotations",
      "resource.spec.commonName",
      "resource.spec.dnsNames",
      "resource.spec.issuerRef.name",
      "resource.spec.issuerRef.group",
      "resource.spec.username",
      "resource.spec.uris",
    ]
    const searcher = new FuzzySearch(filteredSortedCerts, searchProps, {
      caseSensitive: false,
    })
    filteredSortedCerts = searcher.search(searchTerm)
  }

  //Sort certificates
  const sortBy = filtersState["sortBy"]
  if (sortBy) {
    if (sortBy === "asc") filteredSortedCerts = filteredSortedCerts.sort(sortByName)
    if (sortBy === "desc") filteredSortedCerts = filteredSortedCerts.sort(sortByName).reverse()
    if (sortBy === "expiryAsc") filteredSortedCerts = filteredSortedCerts.sort(sortByExpiryDate)
    if (sortBy === "expiryDesc") filteredSortedCerts = filteredSortedCerts.sort(sortByExpiryDate).reverse()
  }

  return filteredSortedCerts
}

type SortItem = CMCertificate | NonCMCertificate

export const sortByName = (a: SortItem, b: SortItem) =>
  `${a.resource?.metadata?.namespace}/${a.resource?.metadata?.name}`.localeCompare(
    `${b.resource?.metadata?.namespace}/${b.resource?.metadata?.name}`,
  )

export const sortByExpiryDate = (a: SortItem, b: SortItem) => {
  const aDate = a.decode()[0]?.validTo
  const bDate = b.decode()[0]?.validTo
  const aDateObj = aDate ? new Date(aDate).getTime() : 0
  const bDateObj = bDate ? new Date(bDate).getTime() : 0
  return aDateObj - bDateObj
}

const statusWeight: { [x: string]: number } = {
  error: 1,
  warning: 2,
  ok: 3,
}

const sortByNumberOfIssues = (a: SortItem, b: SortItem) => {
  return b.rawRuleMessages().length - a.rawRuleMessages().length
}

const sortByStatusName = (a: SortItem, b: SortItem) => {
  return statusWeight[a.status.type] - statusWeight[b.status.type]
}

export const sortByStatus = (data: AllCerts[]) => [...data].sort(sortByNumberOfIssues).sort(sortByStatusName)
