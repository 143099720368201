import React, { PropsWithChildren } from "react"
import { Button } from "@material-ui/core"

type ButtonSeeDocProps = {
  link: string
}

export const ButtonSeeDoc = ({ link, children }: PropsWithChildren<ButtonSeeDocProps>) => (
  <Button color="primary" variant="contained" href={link} target="_blank" rel="noreferrer">
    {children}
  </Button>
)
