import React from "react"

import { makeStyles, Theme } from "@material-ui/core"
import { Pagination as MUIPagination, PaginationProps } from "@material-ui/lab"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: "inline-block",
    margin: theme.spacing(2, "auto", 0, "auto"),
    "& .Mui-selected": {
      background: theme.palette.primary.main,
      color: "#fff",

      "&:hover": {
        background: theme.palette.primary.main,
        color: "#fff",
      },
    },
    "& li": {
      margin: theme.spacing(0, 1),
    },
    "& li button": {
      background: "transparent",
      fontSize: "1rem",
      borderRadius: 5,
      "&:hover": {
        background: "transparent",
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
      },
    },
    "& li:first-child button, & li:last-child button": {
      background: theme.palette.primary.main,
      color: "#fff",
      "&:hover": {
        background: theme.palette.primary.main,
        color: "#fff",
      },
    },
  },
}))

export const Pagination = (props: PaginationProps) => {
  const classes = useStyles()
  return <MUIPagination className={classes.root} {...props} />
}
