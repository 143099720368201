import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  [x: string]: unknown
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& path": {
        stroke: theme.palette.grey[600],
      },
    },
  }),
)

export function LinkIcon(props: Props): ReactElement {
  const classes = useStyles()
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.root} {...props}>
      <path d="M10 14a3.5 3.5 0 005 0l4-4a3.536 3.536 0 00-5-5l-.5.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 10a3.501 3.501 0 00-5 0l-4 4a3.535 3.535 0 105 5l.5-.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
