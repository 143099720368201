import { OverviewData, OverviewFeaturedItems } from "./types"

type GenerateClustersProps = {
  count: number
  errorCount: number
  updateCount: number
  noCmCount: number
  resourcesAreLoading: boolean
  errorClusters: OverviewFeaturedItems[]
  updateClusters: OverviewFeaturedItems[]
  noCmClusters: OverviewFeaturedItems[]
  url?: string
  inactiveClusters: OverviewFeaturedItems[]
  inactiveClustersCount: number
}

const generateClustersData = ({
  count,
  errorCount,
  updateCount,
  noCmCount,
  errorClusters,
  updateClusters,
  noCmClusters,
  resourcesAreLoading,
  inactiveClusters,
  inactiveClustersCount,
  url,
}: GenerateClustersProps): OverviewData => ({
  title: "Clusters",
  value: count,
  resourcesAreLoading,
  sections: [
    {
      isLoading: false,
      title: "Clusters with high risk",
      allOkMessage: "No clusters at risk",
      withIssues: errorCount > 0,
      value: errorCount,
      seeAllUrl: url + "?filterByStatus=withErrors",
      featured: {
        description: "Clusters with issues:",
        items: getItemsList(errorClusters),
      },
    },
    {
      isLoading: false,
      title: "Inactive cluster(s)",
      value: inactiveClustersCount,
      allOkMessage: "All clusters are actively sending data",
      seeAllUrl: url,
      featured: {
        description: "Clusters that have not uploaded data within the last hour:",
        items: getItemsList(inactiveClusters),
      },
    },
    {
      isLoading: resourcesAreLoading,
      title: "Clusters requiring cert-manager updates",
      value: updateCount,
      allOkMessage: "All clusters are up to date",
      seeAllUrl: url + "?filterByStatus=requiresUpdate",
      featured: {
        description: "Clusters that require cert-manager update:",
        items: getItemsList(updateClusters),
      },
    },
    {
      isLoading: false,
      title: "Clusters with no cert-manager",
      value: noCmCount,
      allOkMessage: "All clusters use cert-manager",
      seeAllUrl: url + "?filterByStatus=missingCM",
      featured: {
        description: "Clusters that dont have cert-manager installed:",
        items: getItemsList(noCmClusters),
      },
    },
  ],
})

type GenerateCertificatesProps = {
  count: number
  errorCount: number
  noCmCount: number
  resourcesAreLoading: boolean
  errorCertificates: OverviewFeaturedItems[]
  noCmCertificates: OverviewFeaturedItems[]
  url?: string
}

const generateCertificatesData = ({
  count,
  errorCount,
  noCmCount,
  errorCertificates,
  noCmCertificates,
  url,
  resourcesAreLoading,
}: GenerateCertificatesProps): OverviewData => ({
  title: "Certificates",
  value: count,
  resourcesAreLoading,
  sections: [
    {
      isLoading: resourcesAreLoading,
      title: "Certificates with high risk",
      withIssues: true,
      value: errorCount,
      allOkMessage: "No certificates at risk",
      seeAllUrl: url + "?filterByStatus=withErrors",
      featured: {
        description: "Certificates with issues:",
        items: getItemsList(errorCertificates),
      },
    },
    {
      isLoading: resourcesAreLoading,
      title: "Certificates not managed by cert-manager",
      value: noCmCount,
      allOkMessage: "All certificates use cert-manager",
      seeAllUrl: url + "?filterByStatus=notManagedByCM",
      featured: {
        description: "Certificates that require update:",
        items: getItemsList(noCmCertificates),
      },
    },
  ],
})

type GenerateIngressesProps = {
  count: number
  errorCount: number
  resourcesAreLoading: boolean
  errorIngresses: OverviewFeaturedItems[]
  url?: string
}

const generateIngressesData = ({ count, errorCount, errorIngresses, url, resourcesAreLoading }: GenerateIngressesProps): OverviewData => ({
  title: "Ingresses",
  value: count,
  resourcesAreLoading,
  sections: [
    {
      isLoading: resourcesAreLoading,
      title: "Ingresses at risk",
      withIssues: true,
      value: errorCount,
      allOkMessage: "No ingresses at risk",
      seeAllUrl: url + "?filterBySeverity=high",
      featured: {
        description: "Ingresses with issues:",
        items: getItemsList(errorIngresses),
      },
    },
  ],
})

const sortByTimeStamp = (a: OverviewFeaturedItems, b: OverviewFeaturedItems) => {
  const aDateObj = a.timestamp ? new Date(a.timestamp).getTime() : 0
  const bDateObj = b.timestamp ? new Date(b.timestamp).getTime() : 0
  return bDateObj - aDateObj
}

const getItemsList = (clusters: OverviewFeaturedItems[]) => {
  return clusters.sort(sortByTimeStamp).slice(0, 5)
}
export { generateClustersData, generateCertificatesData, generateIngressesData }
