import React, { useState } from "react"
import SyntaxHighlighter from "react-syntax-highlighter"

import { Paper, Box, IconButton, createStyles, makeStyles, Theme as MUITheme } from "@material-ui/core"
import TickIcon from "@material-ui/icons/Done"
import AssignmentIcon from "@material-ui/icons/Assignment"

import { a11yDark, a11yLight } from "react-syntax-highlighter/dist/cjs/styles/hljs"

type Theme = "dark" | "light"

type ClipboardCopyProps = {
  text: string
  theme: Theme
}

const ClipboardCopy = ({ text, theme }: ClipboardCopyProps) => {
  const style: React.CSSProperties = {
    color: theme === "dark" ? "#d6d6d6" : "black",
  }
  const clickedStyle: React.CSSProperties = { color: theme === "dark" ? "#76e476" : "black" }

  const [clicked, setClicked] = useState(false)
  const handleClick = () => {
    if (clicked) return
    navigator.clipboard.writeText(text)
    setClicked(true)
    setTimeout(() => setClicked(false), 1500)
  }
  return (
    <IconButton size="small" onClick={handleClick} style={clicked ? clickedStyle : style}>
      {clicked ? <TickIcon /> : <AssignmentIcon />}
    </IconButton>
  )
}

const useStyles = makeStyles((theme: MUITheme) =>
  createStyles({
    root: {
      position: "relative",
      marginBottom: theme.spacing(2),
    },
    copyButton: {
      background: theme.palette.grey[800],
      borderRadius: "3px",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  }),
)

type SnippetProps = {
  text: string
  language: "plaintext" | "shell" | "yaml" | "json"
  copyText?: string
  fileName?: string
  showLineNumbers?: boolean
  showCopyButton?: boolean
  theme?: Theme
}

export const Snippet = ({
  text,
  language,
  showLineNumbers = false,
  theme = "dark",
  showCopyButton = true,
  copyText = "",
}: SnippetProps) => {
  const classes = useStyles()
  const style = theme === "dark" ? a11yDark : a11yLight
  const styles: React.CSSProperties = {
    fontFamily: "monospace",
    padding: "1rem",
    borderRadius: "5px",
    lineHeight: "1.6",
    fontSize: "0.875rem",
  }

  return (
    <>
      <Paper variant="outlined" className={classes.root}>
        {showCopyButton && (
          <Box position="absolute" right={10} top={10} zIndex="tooltip" className={classes.copyButton}>
            <ClipboardCopy text={copyText || text} theme={theme} />
          </Box>
        )}

        <SyntaxHighlighter language={language} showLineNumbers={showLineNumbers} style={style} customStyle={styles}>
          {language === "shell" ? "$ " + text : text}
        </SyntaxHighlighter>
      </Paper>
    </>
  )
}
