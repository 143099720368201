import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  [x: string]: unknown
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& path": {
        fill: theme.palette.primary.main,
      },
    },
  }),
)

export function LogoIcon(props: Props): ReactElement {
  const classes = useStyles()
  return (
    <svg width="59" height="64" viewBox="0 0 59 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.root} {...props}>
      <path
        d="M53.798 2.769A133.62 133.62 0 0 0 29.026 0 133.685 133.685 0 0 0 4.254 2.767 5.266 5.266 0 0 0 1.2 4.612 5.366 5.366 0 0 0 0 7.997v15.994a41.873 41.873 0 0 0 .29 4.95l2.884-2.917a2.277 2.277 0 0 1 1.612-.676 2.258 2.258 0 0 1 1.613.676 2.303 2.303 0 0 1 .669 1.63 2.324 2.324 0 0 1-.669 1.63l-5.066 5.128c.445 1.679 1 3.326 1.664 4.93l16.334-16.504a2.276 2.276 0 0 1 1.613-.675 2.259 2.259 0 0 1 1.613.675 2.306 2.306 0 0 1 .668 1.63 2.327 2.327 0 0 1-.669 1.63L5.125 43.711a42.49 42.49 0 0 0 2.769 4.276l26.662-26.944a2.269 2.269 0 0 1 1.612-.675c.605 0 1.185.243 1.613.675.428.433.668 1.019.668 1.63s-.24 1.198-.668 1.63L10.803 51.566A49.74 49.74 0 0 0 14.2 54.98L32.78 36.2a2.28 2.28 0 0 1 1.613-.675 2.26 2.26 0 0 1 1.613.675 2.307 2.307 0 0 1 .667 1.63 2.326 2.326 0 0 1-.667 1.63L17.733 57.927a54.132 54.132 0 0 0 4.102 2.852l7.78-7.87a2.28 2.28 0 0 1 1.613-.675 2.26 2.26 0 0 1 1.612.675 2.306 2.306 0 0 1 .668 1.63 2.326 2.326 0 0 1-.668 1.63l-6.863 6.935c.299.145.46.215.46.215h.009a5.218 5.218 0 0 0 5.15 0h.006S58.047 51.728 58.047 24V7.998a5.366 5.366 0 0 0-1.199-3.382 5.266 5.266 0 0 0-3.05-1.846Z"
        fill="#6326D9"
      />
    </svg>
  )
}
