import { AppState } from "../reducers"
import { currentOrgSelector } from "./orgs"

export const questionnaireSelector = (state: AppState) => {
  if (state.auth.authInfo) {
    return state.auth.authInfo.questionnaires_by_org
  }
}

export const isAllQuestionnairesCompleted =
  (questionnaires: string[]) =>
  (state: AppState): boolean => {
    const orgID = currentOrgSelector(state)
    if (state.auth.authInfo) {
      const questionnairesByOrg = state.auth.authInfo.questionnaires_by_org[orgID]
      if (questionnairesByOrg) {
        if (questionnairesByOrg.length === questionnaires.length) {
          return (
            questionnairesByOrg.filter(({ questionnaire_id }) => questionnaires.includes(questionnaire_id)).length === questionnaires.length
          )
        } else {
          // Considering we have merged an old questionnaire into this, we don't want people that have filled the old one to be questioned again.
          return questionnairesByOrg.filter(({ questionnaire_id }) => questionnaire_id === "cert-manager-environment").length > 0
        }
      }
    }
    return false
  }
