import { AppConfig } from "@lib/appConfig"

export const FETCH_APP_CONFIG = "FETCH_APP_CONFIG"
export const FETCH_APP_CONFIG_FAILED = "FETCH_APP_CONFIG_FAILED"
export const FETCH_APP_CONFIG_SUCCESS = "FETCH_APP_CONFIG_SUCCESS"

export interface FetchAppConfigAction {
  type: typeof FETCH_APP_CONFIG
}

export interface FetchAppConfigFailedAction {
  type: typeof FETCH_APP_CONFIG_FAILED
  errorMessage: string
}

export interface FetchAppConfigSuccessAction {
  type: typeof FETCH_APP_CONFIG_SUCCESS
  appConfig: AppConfig
}

export const fetchAppConfig = (): FetchAppConfigAction => ({
  type: FETCH_APP_CONFIG,
})

export const fetchAppConfigFailed = (errorMessage: string): FetchAppConfigFailedAction => ({
  type: FETCH_APP_CONFIG_FAILED,
  errorMessage,
})

export const fetchAppConfigSuccess = (appConfig: AppConfig): FetchAppConfigSuccessAction => ({
  type: FETCH_APP_CONFIG_SUCCESS,
  appConfig,
})

export type AppConfigActions = FetchAppConfigAction | FetchAppConfigFailedAction | FetchAppConfigSuccessAction
