import { createStyles, makeStyles, Theme } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
    collapsible: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(1.5, 3),
      margin: 0,
      width: "100%",
      zIndex: 1,
      position: "relative",
    },
    clickable: {
      cursor: "pointer",
      userSelect: "none",
    },
    rowClickable: {
      cursor: "pointer",
      userSelect: "none",
      "&:hover": {
        background: theme.palette.grey[100],
      },
    },
    collapsibleContent: {
      background: theme.palette.grey[50],
      padding: theme.spacing(2),
      borderBottomLeftRadius: "0.3rem",
      borderBottomRightRadius: "0.3rem",
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    icon: {
      transform: "scale(1.4)",
      transition: "all ease-in-out 0.3s",
      "& path": {
        stroke: theme.palette.primary.main,
      },
    },
    buttonArea: {
      width: "100%",
      textAlign: "right",
    },
    collapsed: {
      transform: "scale(1.4) rotate(90deg)",
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      alignItems: "center",
      gridGap: "0.5rem",
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(1),
      textDecoration: "none",
    },
    tableContent: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      marginBottom: theme.spacing(2),
    },
    tags: {
      marginLeft: "auto",
    },
    allOk: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
      "& svg": {
        transform: "scale(1.2)",
        marginRight: theme.spacing(1.5),
      },
      "& h2": {
        fontSize: "1rem",
        color: theme.palette.success.main,
        marginTop: "-3px",
      },
    },
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    more: {
      marginTop: -theme.spacing(1),
    },
  }),
)
