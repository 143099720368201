import React, { ReactElement } from "react"
import { Grid, Typography } from "@material-ui/core"

import { Snippet } from "@components/Snippet"
import { InstructionStep } from "../../InstructionStep"
import { AWSPCAIssuer, IssuerCommonFields } from "../../types"
import { renderIssuerYaml } from "../../renderYaml"
import { renderAwsPCAYaml } from "./renderAwsPCAYaml"
import { VerifyIssuerInitialized } from "../../VerifyIssuerInitialized"

type AwsPCAInstructionsProps = {
  common: IssuerCommonFields
  issuer: AWSPCAIssuer
}

export const AwsPCAInstructions = ({ common, issuer }: AwsPCAInstructionsProps): ReactElement => {
  const issuerFilename = "awspca-issuer.yml"
  const isUsingNamespace = common.useNamespace === "use-namespace"
  const namespace = isUsingNamespace ? common.namespace : "cert-manager"
  const AwsPCAYaml = renderAwsPCAYaml(common.name, issuer)
  const kind = isUsingNamespace ? "AWSPCAIssuer" : "AWSPCAClusterIssuer"
  const issuerYaml = renderIssuerYaml(common.name, isUsingNamespace, namespace, AwsPCAYaml, kind, "awspca.cert-manager.io/v1beta1")

  return (
    <Grid container direction="column" spacing={4}>
      <InstructionStep step={1}>
        <Typography gutterBottom>
          If you haven't already done so, you must install the{" "}
          <a href="https://github.com/cert-manager/aws-privateca-issuer/blob/master/README.md">Aws PCA issuer</a>. Following this guide will
          also install the AWS PCA Custom Resource Definitions used in the next step.
        </Typography>
      </InstructionStep>

      <InstructionStep step={2}>
        <Typography gutterBottom>
          Copy the following configuration and save it in a file <strong>{issuerFilename}</strong>.
        </Typography>
        <Snippet showLineNumbers language="yaml" text={issuerYaml} />
      </InstructionStep>

      <InstructionStep step={3}>
        <Typography gutterBottom>
          Create the <strong>Issuer</strong>.
        </Typography>
        <Snippet language="shell" text={`kubectl create -f ${issuerFilename}`} />
      </InstructionStep>

      <InstructionStep step={4}>
        <VerifyIssuerInitialized name={common.name} namespace={namespace} label={issuer.label} kind={kind} />
      </InstructionStep>
    </Grid>
  )
}
