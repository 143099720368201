import { AppConfigAuth, AppConfigAuthKind } from "@lib/appConfig"
import { NoopAuthClient } from "./noopClient"
import { Auth0AuthClient } from "./auth0Client"
import { OAuth2AuthClient } from "./oauth2Client"
import { RedirectResult, AppState } from "./types"
import { AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios"

export function createAuthClient(authConfig: AppConfigAuth): AuthClient {
  switch (authConfig?.kind) {
    case AppConfigAuthKind.auth0:
      return new Auth0AuthClient(authConfig) as AuthClient
    case AppConfigAuthKind.oauth2:
      return new OAuth2AuthClient(authConfig) as AuthClient
    case AppConfigAuthKind.disabled:
      return new NoopAuthClient() as AuthClient
    default:
      throw new Error(`cannot create auth client, unsupported auth kind ${authConfig.kind}`)
  }
}

// addAuth adds the authorization header if the token is not empty.
export function addAuth(token: string, config?: InternalAxiosRequestConfig): InternalAxiosRequestConfig | undefined {
  if (!!token && token !== "") {
    return {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${token}`,
      } as AxiosRequestHeaders,
    }
  } else {
    return config
  }
}

export interface AuthClient {
  initialize(): Promise<AuthClient>
  redirectToLogin(appState: AppState): Promise<void>
  handleRedirectCallback(): Promise<RedirectResult>
  getToken(force: boolean): Promise<string | undefined>
  isAuthenticated(): Promise<boolean | undefined>
  logout(returnTo?: string): Promise<void>
}
