import React from "react"

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"

import { InstallationComponent, InstallationStatus } from "@lib/certInventory"
import { getComponentStatus } from "@utils/utils"
import { StatusIndicator } from "@components/StatusIndicator"
import { UpdateAvailable } from "../UpdateAvailable"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newWindowIcon: {
      fontSize: "1rem",
      marginBottom: "-0.2rem",
      marginLeft: "0.2rem",
    },
    missingNoteCompact: {
      paddingBottom: "0.2rem",
    },
    status: {
      "& > div": {
        marginRight: theme.spacing(2),
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  }),
)

type Props = {
  data?: InstallationStatus
  error: string
  isLoading: boolean
}

export function CertManagerStatusCompact({ data, error, isLoading }: Props) {
  const classes = useStyles()

  if (isLoading) return null
  if (error) {
    return (
      <div>
        <Typography variant="body2" color="textSecondary">
          {error}
        </Typography>
      </div>
    )
  }

  return (
    <>
      {!data || data.missing ? (
        <div className={classes.missingNoteCompact}>
          <Typography variant="body2" color="textSecondary" data-testid="cert-manager-status-data-missing">
            We could not find a cert-manager installation in your cluster
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://cert-manager.io/docs/installation/"
              onClick={event => event.stopPropagation()}
              className={classes.link}
            >
              Install cert-manager
              <OpenInNewIcon className={classes.newWindowIcon} />
            </a>
          </Typography>
        </div>
      ) : (
        <div className={classes.status}>{generateStatusIndicator({ data })}</div>
      )}
    </>
  )
}

type GenerateProps = {
  data: InstallationStatus
}

const generateStatusIndicator = ({ data }: GenerateProps) => {
  const { controller, cainjector, webhook } = data
  const allReady = controller.status === "Running" && cainjector.status === "Running" && webhook.status === "Running"

  const updateLink = "https://cert-manager.io/docs/installation/upgrading/"
  const items = [
    ["Controller", controller],
    ["CA Injector", cainjector],
    ["Webhook", webhook],
  ] as [string, InstallationComponent][]

  if (allReady) {
    return (
      <>
        <StatusIndicator status="ok" message="All ready" iconsOnly />
        <UpdateAvailable items={items} updateLink={updateLink} />
      </>
    )
  } else {
    return (
      <>
        <StatusIndicator status={getComponentStatus(controller.status)} message="Controller" iconsOnly />
        <StatusIndicator status={getComponentStatus(cainjector.status)} message="CA Injector" iconsOnly />
        <StatusIndicator status={getComponentStatus(webhook.status)} message="Webhook" iconsOnly />
        <UpdateAvailable items={items} updateLink={updateLink} />
      </>
    )
  }
}
