import React from "react"
import { Typography } from "@material-ui/core"
import { MessageContainer } from "@components/MessageContainer"

export const IssuerSection = ({ issuer }: { issuer?: string }) => {
  return issuer ? (
    <>
      <Typography gutterBottom>Issuer</Typography>
      <MessageContainer>
        Issued by {"> "}
        <Typography color="textPrimary" variant="body2" display="inline">
          {issuer}
        </Typography>
      </MessageContainer>
    </>
  ) : null
}
