import React, { ReactElement } from "react"
import { Field } from "formik"
import { TextField as FormTextField, Switch } from "formik-material-ui"

import { validate, validNoTrailingWhitespaces } from "../../validation"
import { FormRow } from "../../FormRow"
import { GoogleCASIssuer } from "../../types"

type GoogleCASFormProps = {
  issuer: GoogleCASIssuer
}

export const GoogleCASForm = ({ issuer }: GoogleCASFormProps): ReactElement => {
  return (
    <>
      <FormRow info="The Google Cloud project where the CA is configured">
        <Field
          name="issuers.googlecas.project"
          label="Google Cloud Project"
          variant="outlined"
          margin="dense"
          placeholder="Project ID"
          required
          validate={validate(validNoTrailingWhitespaces)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow info="The location where the CAS private CA is running">
        <Field
          name="issuers.googlecas.location"
          label="Location"
          variant="outlined"
          margin="dense"
          placeholder="e.g. us-east1"
          required
          validate={validate(validNoTrailingWhitespaces)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow info="The ID of the CAS pool of private CAs">
        <Field
          name="issuers.googlecas.caPoolId"
          label="CA pool ID"
          variant="outlined"
          margin="dense"
          placeholder="e.g. my-intermediate-ca-pool"
          required
          validate={validate(validNoTrailingWhitespaces)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow info="Use workload identity">
        <Field component={Switch} type="checkbox" name="issuers.googlecas.usingWorkloadID" label="Using workload ID" />
      </FormRow>
      {!issuer.usingWorkloadID && (
        <FormRow info="A reference for the Google Cloud credentials to be used by CAS issuer pod">
          <Field
            name="issuers.googlecas.credentials.name"
            label="Credentials Reference"
            variant="outlined"
            margin="dense"
            required={!issuer.usingWorkloadID}
            placeholder="e.g. google-svc-acc"
            component={FormTextField}
          />
          <Field
            name="issuers.googlecas.credentials.key"
            label="Credentials Path"
            variant="outlined"
            margin="dense"
            placeholder="e.g. google-key.json"
            required={!issuer.usingWorkloadID}
            component={FormTextField}
          />
        </FormRow>
      )}
    </>
  )
}
