import { isValidEmail } from "@utils/validation"

export type ValidationFunc = (value: string) => string | undefined

export const validate = (func: ValidationFunc, required = true): ValidationFunc => {
  return value => {
    if (required && !value) return "required"
    if (!required && !value) return undefined
    return func(value)
  }
}

export const validKubernetesName = (value: string): string => {
  // Format defined at https://kubernetes.io/docs/concepts/overview/working-with-objects/names/
  // - contain no more than 253 characters
  // - start with an alphanumeric character
  // - end with an alphanumeric character
  // - contain only lowercase alphanumeric characters, '-' or '.'
  if (value.length > 253) {
    return "too long, max 253 chars"
  } else if (!/[a-z0-9]/.test(value[0])) {
    return "should start with lowercase alphanumeric"
  } else if (!/[a-z0-9]/.test(value[value.length - 1])) {
    return "should end with lowercase alphanumeric"
  } else if (/[^a-z0-9\-.]/.test(value)) {
    return "only lowercase alphanumeric, -, or ."
  }
  return ""
}

export const validKubernetesSecretKey = (value: string): string => {
  // Format defined at https://kubernetes.io/docs/reference/kubernetes-api/config-and-storage-resources/secret-v1/#Secret
  // - contain only alphanumeric characters, '-', '_', or '.'
  if (/[^a-z0-9\-._]/.test(value)) {
    return "only lowercase alphanumeric, -, _, or ."
  }
  return ""
}

export const validNoTrailingWhitespaces = (value: string): string => {
  const match = value.startsWith(" ") || value.endsWith(" ")
  return match ? "invalid front or trailing whitespace in the string" : ""
}

export const validEmail = (value: string): string => {
  if (!isValidEmail(value) && value) {
    return "invalid format, e.g: someone@example.org"
  }
  return ""
}

export const validUrl = (value: string): string => {
  if (!value) return ""
  try {
    new URL(value)
  } catch (e) {
    return "invalid URl, e.g: https://www.example.org"
  }
  return ""
}

export const validUUID = (value: string): string => {
  if (!value) return ""
  // https://stackoverflow.com/a/13653180/709884
  if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)) {
    return "must be valid UUID"
  }
  return ""
}

export const validPath = (value: string): string => {
  // the path can only contain alphanumeric characters, /, ., -, and _
  if (/[^a-z0-9\-/_.]/.test(value)) {
    return "only alphanumeric, /, -, or _"
  }
  return ""
}

export const validBase64 = (value: string): string => {
  if (!value) return ""
  if (!/[A-Za-z0-9+/=]/.test(value)) {
    return "invalid format, should be base64"
  }
  return ""
}
