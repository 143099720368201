import React from "react"
import { createRoot } from "react-dom/client"
import ReactGA from "react-ga"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from "react-redux"
import * as pendo from "@lib/pendo"
import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction"
import { createBrowserHistory } from "history"
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios"
import { getCookieConsentValue } from "react-cookie-consent"

import { rudderanalytics as rudderstack } from "@utils/rudderstack"

import { reducers as createRootReducer } from "./reducers"
import { rootSaga } from "./sagas"

import { AppConfig } from "@components/AppConfig"
import { AppWithLDProvider } from "@components/App"
import { Auth } from "@components/Auth"
import "./reset.css"
import "./fonts.css"
import { AppConfigAnalytics } from "@lib/appConfig"

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const rootReducer = createRootReducer()

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSaga)
const isProduction = process.env.NODE_ENV === "production"
const optionalCookiesEnabled = getCookieConsentValue() === "true"

history.listen(({ location }) => {
  if (optionalCookiesEnabled && isProduction) {
    ReactGA.ga("set", "page", location.pathname + location.search)
    ReactGA.ga("send", "pageview")
    rudderstack.page()
  }
})

const enableAnalytics = (config?: AppConfigAnalytics) => {
  if (config && isProduction) {
    // Google Analytics
    if (config.ga) {
      ReactGA.initialize(config.ga.tracking_code)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    // Rudderstack
    if (config.rudderstack) {
      rudderstack.load(config.rudderstack.write_key, config.rudderstack.data_plane_url, { logLevel: "WARN" })
    }

    // Pendo
    pendo.load("22160c60-3357-4ff6-67bf-7282bfab81d4")
  }
}

axios.interceptors.response.use(
  response => response,
  function (error: AxiosError): Promise<AxiosError> {
    // users of axios can specify config.isBackground = true to skip the redirect in case of 401
    const config = error.config as InternalAxiosRequestConfig & { isBackground: boolean }
    if (config?.isBackground && error.response?.status === 401) {
      // Whenever a 401 Unauthorized response is received, we redirect to the login page
      window.location.replace("/login")
    }
    return Promise.reject(error)
  },
)

const container = document.getElementById("root")
if (container === null) throw new Error("Root container missing")
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <AppConfig>
      <Router>
        <Auth>
          <AppWithLDProvider enableAnalytics={enableAnalytics} />
        </Auth>
      </Router>
    </AppConfig>
  </Provider>,
)
