import React, { useEffect } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import * as queryString from "query-string"

import { redirectLogin } from "@actions/auth"
import { HOME_PATH } from "@routes"

export const Login = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const query = queryString.parse(location.search)

  useEffect(() => {
    dispatch(redirectLogin())
  }, [])

  return <Navigate to={query.destination || HOME_PATH} />
}
