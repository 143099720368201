import { VenafiIssuer } from "../../types"

type CloudYaml = {
  apiTokenSecretRef: {
    name: string
    key: string
  }
}

type TppYaml = {
  url: string
  caBundle?: string
  credentialsRef: {
    name: string
  }
}

export type VenafiYaml = {
  venafi: {
    zone: string
    cloud?: CloudYaml
    tpp?: TppYaml
  }
}

const renderVenafiYaml = (issuer: VenafiIssuer): VenafiYaml => {
  const cloudYaml = (): CloudYaml => ({
    apiTokenSecretRef: {
      name: issuer.install.api.secretName,
      key: "apikey",
    },
  })

  const tppYaml = (): TppYaml => ({
    url: issuer.install.tpp.serverUrl,
    caBundle: issuer.install.tpp.caBundle ? issuer.install.tpp.caBundle : undefined,
    credentialsRef: {
      name: issuer.install.tpp.secretName,
    },
  })

  return {
    venafi: {
      zone: issuer.zone,
      cloud: issuer.installType === "api" ? cloudYaml() : undefined,
      tpp: issuer.installType === "tpp" ? tppYaml() : undefined,
    },
  }
}

export { renderVenafiYaml }
