import { AuthActions, FETCH_AUTH_INFO_SUCCESS, LOGOUT_FINISHED, LogoutFinishedAction } from "@actions/auth"
import { OrgActions, UPDATE_CURRENT_ORG } from "@actions/orgs"

export type OrgsState = {
  currentOrg: string
}

const initialState = {
  currentOrg: "",
}

export function orgsReducer(state: OrgsState = initialState, action: AuthActions | LogoutFinishedAction | OrgActions): OrgsState {
  switch (action.type) {
    case FETCH_AUTH_INFO_SUCCESS: {
      return {
        ...state,
        currentOrg: action.currentOrg,
      }
    }

    case LOGOUT_FINISHED: {
      return { ...initialState }
    }

    case UPDATE_CURRENT_ORG: {
      return {
        ...state,
        currentOrg: action.orgID,
      }
    }

    default:
      return state
  }
}
