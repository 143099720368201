import React, { Dispatch, SetStateAction, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  InputAdornment,
  FormLabel,
} from "@material-ui/core"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Visibility from "@material-ui/icons/Visibility"
import { pathToCertInventory } from "@routes"
import { Snippet } from "@components/Snippet"
import { useCreateInstallationCommand } from "../hooks/useCreateInstallationCommand"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
    },
    disabled: {
      opacity: 0.6,
      pointerEvents: "none",
    },
    select: {
      minWidth: "20rem",
    },
    visibility: {
      cursor: "pointer",
    },
    commands: {
      marginTop: theme.spacing(3),
    },
    formContainer: {
      marginBottom: theme.spacing(1),
    },
    form: {
      maxWidth: "30rem",
      display: "grid",
      gridTemplateColumns: "1fr",
      gridGap: theme.spacing(2),
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
)

export const COMPONENT_INSTALLATION = "COMPONENT_INSTALLATION"

type ComponentInstallationProps = {
  currentStep: string
  orgID: string
  setStep: Dispatch<SetStateAction<number>>
}

export function ComponentInstallation({ currentStep, orgID, setStep }: ComponentInstallationProps) {
  const classes = useStyles()
  const isCurrentStep = currentStep === COMPONENT_INSTALLATION
  const [showPassword, setShowPassword] = useState(false)
  const [showRedactedText, setShowRedactedText] = useState(true)

  const {
    installationCommand,
    redactedInstallationCommand,
    url,
    setUrl,
    zone,
    setZone,
    userName,
    setUserName,
    password,
    setPassword,
    accessToken,
    setAccessToken,
    installVenafiOAuthHelper,
    setInstallVenafiOAuthHelper,
    installCertDiscoveryVenafi,
    setInstallCertDiscoveryVenafi,
    selectedProfile,
    setSelectedProfile,
  } = useCreateInstallationCommand()

  const navigate = useNavigate()
  const handleViewClick = () => {
    navigate(pathToCertInventory(orgID))
  }

  return (
    <div className={`${classes.container} ${!isCurrentStep ? classes.disabled : ""}`}>
      <Typography variant="h5" gutterBottom>
        Step 6. Component Installation
      </Typography>

      {isCurrentStep && (
        <>
          <Typography color="textSecondary" gutterBottom>
            Before continuing, please select an installation profile.
          </Typography>
          <div className={classes.formContainer}>
            <FormControl>
              <RadioGroup
                defaultValue={selectedProfile}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedProfile((event.target as HTMLInputElement).value)
                }}
              >
                <FormControlLabel
                  value="cert-manager"
                  control={<Radio />}
                  label={
                    <span>
                      <Typography>cert-manager</Typography>
                      <Typography color="textSecondary">Install a best practice cert-manager installation only</Typography>
                    </span>
                  }
                />
                <FormControlLabel
                  value="venafi-tpp"
                  control={<Radio />}
                  label={
                    <span>
                      <Typography>cert-manager & enterprise components for Venafi TLS Protect</Typography>
                      <Typography color="textSecondary">
                        Install a best practice cert-manager installation and enterprise components for integrations for Venafi TLS Protect
                        for data center
                      </Typography>
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>

          <br />
          {selectedProfile === "venafi-tpp" && (
            <div className={classes.formContainer}>
              <div className={classes.form}>
                <Typography color="textSecondary" gutterBottom>
                  Please complete the following additional fields to enable the integration with your Venafi TLS Protect installation.
                </Typography>
                <TextField
                  name="url"
                  label="TLS Protect instance URL"
                  variant="outlined"
                  onChange={event => setUrl(event.target.value)}
                  value={url}
                  size="small"
                  placeholder="https://tpp.example.com"
                />
                <TextField
                  name="zone"
                  label="TLS Protect Zone"
                  variant="outlined"
                  onChange={event => setZone(event.target.value)}
                  value={zone}
                  size="small"
                  placeholder="TLS/SSL/certificates/..."
                />
                <>
                  <TextField
                    name="userName"
                    label="TLS Protect Username"
                    variant="outlined"
                    onChange={event => setUserName(event.target.value)}
                    value={userName}
                    size="small"
                  />
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPassword ? (
                            <Visibility className={classes.visibility} onClick={() => setShowPassword(!showPassword)} />
                          ) : (
                            <VisibilityOff className={classes.visibility} onClick={() => setShowPassword(!showPassword)} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    label="TLS Protect Password"
                    variant="outlined"
                    onChange={event => setPassword(event.target.value)}
                    value={password}
                    size="small"
                  />
                </>
                <FormLabel color="secondary">
                  Approver Policy Enterprise component, will be automatically installed in your cluster.
                </FormLabel>
                <FormControlLabel
                  label={
                    <>
                      (Optional) Install Venafi OAuth Helper
                      <Typography color="textSecondary" gutterBottom>
                        Venafi OAuth Helper is a component to refresh Venafi TLS Protect access tokens used by Venafi Issuers.
                      </Typography>
                    </>
                  }
                  control={
                    <Checkbox
                      checked={installVenafiOAuthHelper}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setInstallVenafiOAuthHelper((event.target as HTMLInputElement).checked)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                />

                <FormControlLabel
                  label={
                    <>
                      (Optional) Install Certificate Discovery Venafi (For versions of TLS Protect prior to 22.4)
                      <Typography color="textSecondary" gutterBottom>
                        Cert Discovery Venafi is a component which can push X.509 certificates found in Kubernetes into Venafi TLS Protect.
                        Installation is optional.
                      </Typography>
                    </>
                  }
                  control={
                    <Checkbox
                      checked={installCertDiscoveryVenafi}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setInstallCertDiscoveryVenafi((event.target as HTMLInputElement).checked)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                />
                {installCertDiscoveryVenafi && (
                  <TextField
                    name="accesstoken"
                    label="TPP Access Token"
                    variant="outlined"
                    onChange={event => setAccessToken(event.target.value)}
                    value={accessToken}
                    size="small"
                  />
                )}
              </div>
            </div>
          )}
          {selectedProfile === "venafi-tpp" && installationCommand.length > 0 && (
            <Button variant="outlined" color="primary" onClick={() => setShowRedactedText(!showRedactedText)}>
              {!showRedactedText ? "Reveal text" : "Hide text"}
            </Button>
          )}
          <br />
          <br />
          {!showRedactedText &&
            redactedInstallationCommand.map((command, index) => {
              return <Snippet key={index} text={command} language={"shell"} copyText={installationCommand[index]} />
            })}
          {showRedactedText &&
            installationCommand.map((command, index) => {
              return <Snippet key={index} text={command} language={"shell"} copyText={command} />
            })}

          <div className={classes.buttonWrapper}>
            {installationCommand.length > 0 && (
              <Button variant="contained" color="primary" onClick={handleViewClick}>
                View Clusters
              </Button>
            )}
            <Button variant="contained" color="secondary" disabled={false} onClick={() => setStep(step => step - 1)}>
              Back
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
