import FuzzySearch from "fuzzy-search"
import { FilterProps } from "@components/FiltersBar"
import { OverviewCluster } from "./hooks"

export const sortFilterClusters = (clusters: OverviewCluster[], filtersState: FilterProps) => {
  if (clusters?.length < 1) return clusters
  let filteredSortedClusters: OverviewCluster[] = sortByStatus(clusters)

  if (filtersState["filterByStatus"]) {
    const filterByStatus = filtersState["filterByStatus"]
    if (filterByStatus === "withIssues") {
      filteredSortedClusters = filteredSortedClusters.filter(({ warnings, errors }) => warnings > 0 || errors > 0)
    }
    if (filterByStatus === "withErrors") {
      filteredSortedClusters = filteredSortedClusters.filter(({ errors }) => errors > 0)
    }
    if (filterByStatus === "withWarnings") {
      filteredSortedClusters = filteredSortedClusters.filter(({ warnings }) => warnings > 0)
    }
    if (filterByStatus === "requiresUpdate") {
      filteredSortedClusters = filteredSortedClusters.filter(({ certManagerStatus }) => {
        return !certManagerStatus.cainjector.is_latest || !certManagerStatus.controller.is_latest || !certManagerStatus.webhook.is_latest
      })
    }
    if (filterByStatus === "missingCM") {
      filteredSortedClusters = filteredSortedClusters.filter(({ certManagerStatus }) => certManagerStatus.missing)
    }
  }
  if (filtersState["search"]) {
    const searchTerm = filtersState["search"]
    // Inside the array we pass in where the FuzzySearch should look into when we have a search term
    filteredSortedClusters = new FuzzySearch(filteredSortedClusters, ["id"], {
      caseSensitive: false,
    }).search(searchTerm)
  }
  if (filtersState["sortBy"]) {
    const sortBy = filtersState["sortBy"]
    if (sortBy === "asc") filteredSortedClusters = filteredSortedClusters.sort(sortByName)
    if (sortBy === "desc") filteredSortedClusters = filteredSortedClusters.sort(sortByName).reverse()
  }
  return filteredSortedClusters
}

const sortByName = (a: OverviewCluster, b: OverviewCluster) => a.id.localeCompare(b.id)

const getStatusWeight = (clusterDetails: OverviewCluster) => {
  const statusWeight: { [x: string]: number } = {
    both: 30,
    error: 10,
    warning: 1,
  }
  return clusterDetails.warnings > 0 && clusterDetails.errors > 0
    ? statusWeight["both"]
    : clusterDetails.errors > 0
    ? statusWeight["error"]
    : statusWeight["warning"]
}

const sortByStatus = (clusters: OverviewCluster[]) => {
  const clustersCopy = [...clusters]
  return clustersCopy
    .sort((a, b) => b.warnings - a.warnings)
    .sort((a, b) => b.errors - a.errors)
    .sort((a, b) => {
      const aIssueWeight = getStatusWeight(a)
      const bIssueWeight = getStatusWeight(b)
      return bIssueWeight - aIssueWeight
    })
}
