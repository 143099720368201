import React, { useState } from "react"
import { createStyles, Theme, makeStyles, FormControlLabel, Switch, Grid, Divider, Typography, Paper } from "@material-ui/core"
import { CloseButton } from "@components/CertInventory/DetailsPanel/CloseButton"
import { Overlay } from "@components/CertInventory/DetailsPanel/Overlay"
import { formatInTimeZone } from "date-fns-tz"
import { useGetDiscoveredCertificate } from "@lib/discoveredCertificates"
import { formatDate } from "@utils/date"
import { MessageContainer } from "@components/MessageContainer"
import { IssuerSection } from "./IssuerSection"
import { IngestTimeSection } from "./IngestTimeSection"
import { SubjectSection } from "./SubjectSection"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0.5),
      position: "relative",
    },
    inner: {
      marginBottom: theme.spacing(1),
    },
    panel: {
      minHeight: "100vh",
      width: 400,
      height: "100%",
      overflowX: "auto",
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 100,
      [theme.breakpoints.down(480)]: {
        width: "100vw",
      },
    },
    overlay: {
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 99,
      background: "rgba(24,20,32,0.2)",
      width: "100%",
      height: "100%",
    },
    close: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    loading: {
      marginTop: theme.spacing(10),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    section: {
      margin: theme.spacing(3, 2),
    },
    clusterTitle: {
      fontSize: "1rem",
      fontWeight: 300,
      borderRadius: "0.3rem",
      padding: theme.spacing(1, 0),
    },
    marginAround: {
      margin: theme.spacing(2),
    },
    alert: {
      display: "flex",
      marginTop: theme.spacing(2),
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    subTitleContainer: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    clusterLink: {
      fontWeight: 700,
      color: theme.palette.primary.main,
      "&:hover": {
        textDecoration: "none",
      },
    },
  }),
)

export const CertificateDetails = ({ certificateId, onClose }: { certificateId: string; onClose: () => void }) => {
  const { certificate, isSuccess } = useGetDiscoveredCertificate(certificateId)
  const classes = useStyles()

  const [timesUTC, setTimesUTC] = useState(true)

  const handleChangeTimeFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimesUTC(event.target.checked)
  }

  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const renderDate = (dateLabel: string, date?: Date): string => {
    if (date) {
      if (timesUTC) {
        return formatInTimeZone(date, "UTC", "yyyy/MM/dd HH:mm:ss")
      }

      return formatDate(date)
    }
    return `Unable to determine the certificate ${dateLabel}`
  }

  const timeUtcText = timesUTC ? "Showing dates in UTC" : `Showing dates in local timezone (${localTimeZone})`

  const issueDateText = "issue date"
  const expiryDateText = "expiry date"

  return isSuccess ? (
    <Overlay onClose={onClose}>
      <Paper className={classes.panel} elevation={3} data-tut="details-panel">
        <div className={classes.root}>
          <CloseButton className={classes.close} onClick={onClose} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h4">
                  Certificate details
                </Typography>
              </Grid>
            </Grid>
            <Typography color="textPrimary" variant="body1" className={classes.subTitleContainer}>
              {certificate?.namespace} / {certificate?.cluster_id}
            </Typography>
          </div>
          <Divider variant="middle" />

          <div className={classes.marginAround}>
            <Typography gutterBottom variant="h5">
              Certificate Info
            </Typography>
            <div className={classes.inner}>
              {certificate?.not_after && (
                <>
                  <Typography gutterBottom>Not after</Typography>
                  <MessageContainer>
                    <Typography color="textPrimary" variant="body2" display="inline">
                      {certificate?.not_after && renderDate(expiryDateText, new Date(certificate?.not_after))}
                    </Typography>
                  </MessageContainer>
                </>
              )}
              {certificate?.not_before && (
                <>
                  <Typography gutterBottom>Not before</Typography>
                  <MessageContainer>
                    <Typography color="textPrimary" variant="body2" display="inline">
                      {renderDate(issueDateText, new Date(certificate?.not_before))}
                    </Typography>
                  </MessageContainer>
                </>
              )}
              <FormControlLabel
                control={<Switch checked={timesUTC} onChange={handleChangeTimeFormat} name="Switch timestamp format" color="primary" />}
                label={timeUtcText}
              />
            </div>
            <Divider variant="middle" />
            <br />
            <IngestTimeSection ingestTime={certificate?.ingest_time && renderDate("Ingest Time", new Date(certificate?.ingest_time))} />
            <SubjectSection subject={certificate?.subject} />
            <IssuerSection issuer={certificate?.issuer} />
          </div>
        </div>
      </Paper>
    </Overlay>
  ) : null
}
