import { LETS_ENCRYPT_PRODUCTION_URL } from "./issuers/acme/ACMEForm"
import { IssuerCommonFields, Issuers } from "./types"

export type FormValues = {
  common: IssuerCommonFields
  issuers: Issuers
}

export const FORM_INITIAL_VALUES: FormValues = {
  common: {
    name: "",
    namespace: "",
    useNamespace: "no-namespace",
    selectedIssuer: "acme",
  },
  issuers: {
    acme: {
      key: "acme",
      label: "ACME",
      description:
        "The ACME Issuer type represents a single account registered with the Automated Certificate Management Environment (ACME) Certificate Authority server, such as Let’s Encrypt. When you create a new ACME Issuer, cert-manager will generate a private key which is used to identify you with the ACME server.",
      documentationUrl: "https://cert-manager.io/docs/configuration/acme/",
      email: "",
      serverUrl: LETS_ENCRYPT_PRODUCTION_URL,
    },
    awspca: {
      key: "awspca",
      label: "AWS Private CA",
      description:
        'AWS Private CA Issuer is an <a href="https://cert-manager.io/docs/contributing/external-issuers/">external issuer</a> for cert-manager that issues certificates using AWS ACM Private CA. You will need to deploy the <a href="https://github.com/cert-manager/aws-privateca-issuer">aws-pca-issuer</a> in order for this to work.',
      documentationUrl: "https://github.com/cert-manager/aws-privateca-issuer/blob/master/README.md",
      arn: "",
    },
    googlecas: {
      key: "googlecas",
      label: "Google CAS",
      description:
        'Google CAS issuer is an <a href="https://cert-manager.io/docs/contributing/external-issuers/">External Issuer</a> for cert-manager that issues certificates using Google Cloud Certificate Authority Service (CAS), using managed private CAs to issue certificates. You will need to deploy the <a href="https://github.com/jetstack/google-cas-issuer">google-cas-issuer</a> in order for this to work.',
      documentationUrl: "https://github.com/jetstack/google-cas-issuer/blob/main/README.md",
      usingWorkloadID: true,
      project: "",
      location: "",
      caPoolId: "",
    },
    venafi: {
      key: "venafi",
      label: "Venafi Cloud & TPP",
      description:
        'The Venafi Issuer type allows you to obtain certificates from <a href="https://www.venafi.com/cloud" target="_blank">Venafi Cloud</a> and <a href="https://venafi.com/" target="_blank">Venafi Trust Protection Platform</a> instances.',
      documentationUrl: "https://cert-manager.io/docs/configuration/venafi/",
      zone: "",
      installType: "tpp",
      install: {
        api: {
          secretName: "",
          secretKey: "",
        },
        tpp: {
          caBundle: "",
          serverUrl: "",
          secretName: "",
        },
      },
    },
    vault: {
      key: "vault",
      label: "Vault",
      description: `The Vault Issuer represents the certificate authority <a href="https://www.vaultproject.io/" target="_blank">Vault</a> - a multi-purpose secret store that can be used to sign certificates for your Public Key Infrastructure (PKI). `,
      documentationUrl: "https://cert-manager.io/docs/configuration/vault/",
      serverUrl: "",
      caBundle: "",
      path: "",
      authType: "appRole",
      auth: {
        appRole: {
          roleId: "",
          secretKey: "",
          secretName: "",
        },
        token: {
          secretKey: "",
          secretName: "",
        },
        kubernetesServiceAccount: {
          mountPath: "",
          roleName: "",
          secretKey: "",
          secretName: "",
        },
      },
    },
  },
}
