import React, { useState, ReactNode } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core"

import ExpandIcon from "@material-ui/icons/ArrowRight"
import ExpandedIcon from "@material-ui/icons/ArrowDropDown"
import { ReactElement } from "react-markdown/lib/react-markdown"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    toggle: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(2),
    },
    content: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.black,
      borderRadius: "5px",
    },
  })
})

type ExpandableProps = {
  title: string
  children: ReactNode
}

export const Expandable = ({ title, children }: ExpandableProps): ReactElement => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)

  return (
    <div>
      <div className={classes.toggle} onClick={() => setExpand(!expand)}>
        <p>{title}</p>
        {expand ? <ExpandedIcon /> : <ExpandIcon />}
      </div>
      {expand && <div className={classes.content}>{children}</div>}
    </div>
  )
}
