import { useEffect, MutableRefObject } from "react"

export function useHandleOutsideClick(ref: MutableRefObject<HTMLDivElement | null>, handle: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        handle && handle()
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [ref])
}
