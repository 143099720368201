import React, { useState, useEffect, PropsWithChildren } from "react"
import { useDebouncedCallback } from "use-debounce"
import {
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Link,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core"

import { FilterData } from "@selectors/filters"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    filters: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      minWidth: 160,
      margin: theme.spacing(1, 2, 1, 0),
      position: "relative",
    },
    search: {
      minWidth: 160,
      margin: theme.spacing(1, 0),
    },
    disabled: {
      opacity: 0.5,
    },
  })
})

export type FilterProps = { [x: string]: string }

type Props = {
  filterOptions: FilterData[]
  filtersState: FilterProps
  onFilter: (filters: FilterProps) => void
  isDisabled?: boolean
}

export const FiltersBar = ({ filterOptions, filtersState, onFilter, isDisabled = false }: Props) => {
  const classes = useStyles()
  const [filters, setFilters] = useState<FilterProps>({})
  const [search, setSearch] = useState<string>("")

  useEffect(() => {
    setFilters(filtersState)
    if (filtersState?.search) {
      setSearch(filtersState.search)
    }
  }, [filtersState])

  const debounced = useDebouncedCallback(onFilter, 500)

  const onChange = (value: string, id: string) => {
    const copyFilter = { ...filters } as FilterProps
    if (value === "") {
      copyFilter[id] ? delete copyFilter[id] : null
    } else {
      copyFilter[id] = value
    }

    if (id === "search") {
      setSearch(value)
    }

    setFilters(
      id === "search" && value !== ""
        ? {
            search,
            ...copyFilter,
          }
        : copyFilter,
    )

    debounced(copyFilter)
  }

  const onResetFilters = () => {
    setFilters({})
    setSearch("")
    onFilter({})
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.filters}>
          {filterOptions.map(data =>
            data.type === "checkbox" ? (
              <TooltipWrapper text={data.tooltip} key={data.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={e => onChange(String(e.target.checked), data.id)}
                      value={filters[data.id] ? filters[data.id] : data.default}
                    />
                  }
                  label={data.label}
                />
              </TooltipWrapper>
            ) : (
              data.options.length > 0 && (
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${isDisabled ? classes.disabled : ""}`}
                  key={data.id}
                  size="small"
                >
                  <InputLabel id={data.id}>{data.label}</InputLabel>
                  <Select
                    labelId={data.id}
                    id={data.id}
                    value={filters[data.id] ? filters[data.id] : data.default}
                    onChange={e => onChange(e.target.value as string, data.id)}
                    label={data.label}
                    disabled={isDisabled}
                  >
                    {filters[data.id] && data.options.filter(i => i.value === filters[data.id]).length < 1 ? (
                      <MenuItem key={filters[data.id]} value={filters[data.id]}>
                        {filters[data.id]}
                      </MenuItem>
                    ) : null}
                    {data.options.map((option, index) => (
                      <MenuItem key={option.value + index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            ),
          )}
        </div>
        <FormControl variant="outlined" className={`${classes.search} ${isDisabled ? classes.disabled : ""}`}>
          <TextField
            size="small"
            inputProps={{
              "data-testid": "filters-bar-search",
            }}
            label="Search"
            variant="outlined"
            onChange={e => onChange(e.target.value, "search")}
            value={search}
            disabled={isDisabled}
          />
        </FormControl>
      </div>
      <Link href="#" variant="body2" onClick={onResetFilters}>
        Reset all filters
      </Link>
    </div>
  )
}

function TooltipWrapper({ text, children }: PropsWithChildren<{ text?: string }>) {
  if (text) {
    return (
      <Tooltip placement="bottom" title={text}>
        <div style={{ display: "flex" }}>{children}</div>
      </Tooltip>
    )
  } else {
    return <>{children}</>
  }
}
