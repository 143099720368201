import React, { useState } from "react"
import { makeStyles, createStyles, Theme, Paper, Typography, Button } from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"
import { accessTokenSelector, userSelector } from "@selectors/auth"
import { fetchAuthInfo } from "@actions/auth"
import axios from "axios"
import { addAuth } from "@lib/auth"
import { generateErrorMessage, handleError } from "@utils/errorHandling"
import { ErrorMessage } from "@components/ErrorMessage"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    overview: {
      backgroundColor: theme.palette.background.paper,
      margin: "1rem 0",
      padding: theme.spacing(4),
    },
    button: {
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(1),
    },
    emailSent: {
      marginTop: theme.spacing(3),
      color: theme.palette.primary.main,
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
)

export function EmailNotVerified() {
  const classes = useStyles()
  const token = useSelector(accessTokenSelector)
  const user = useSelector(userSelector)
  const [error, setError] = useState("")
  const [emailSent, setEmailSent] = useState(false)

  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(fetchAuthInfo(true))
  }

  const sendVerificationEmail = async () => {
    setError("")
    try {
      const response = await axios.post(`/api/v1/verification-email`, null, addAuth(token))
      if (response.status === 200) {
        setError("")
        setEmailSent(true)
      }
    } catch (error) {
      handleError(error, "Send verification email error")
      setError(generateErrorMessage(error))
      setEmailSent(false)
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.overview}>
        <Typography gutterBottom variant="h5">
          We need you to verify your email
        </Typography>
        <Typography gutterBottom variant="body1">
          We have sent you a verification link, please check your inbox ({user?.email}).
        </Typography>
        <div className={classes.actions}>
          <Button variant="contained" color="primary" className={classes.button} onClick={onClick}>
            I have verified my email
          </Button>
          <Button variant="contained" color="primary" disabled={emailSent} className={classes.button} onClick={sendVerificationEmail}>
            Send new email verification
          </Button>
        </div>
        {emailSent && <p className={classes.emailSent}>Verification email was sent.</p>}
        {error && <ErrorMessage message={error} />}
      </Paper>
    </div>
  )
}
