import React, { ReactElement } from "react"
import { useDispatch } from "react-redux"
import ReactGA from "react-ga"
import { makeStyles, createStyles, Theme, Typography, Button } from "@material-ui/core"
import { redirectLogin } from "@actions/auth"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      margin: theme.spacing(1, 0),
      background: theme.palette.primary.main,
      marginTop: theme.spacing(-5),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3, 3, 2, 3),
      color: "white",
      borderRadius: "0.3rem",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(1200)]: {
        flexDirection: "column",
        gridGap: theme.spacing(2),
        "& br": {
          display: "none",
        },
      },
      [theme.breakpoints.down(768)]: {
        width: "calc(100% + 4rem)",
        marginLeft: "-2rem",
      },
    },
    button: {
      color: `${theme.palette.primary.main} !important`,
      background: "white",
      fontWeight: 600,
    },
    wrapper: {
      display: "flex",
      gridGap: theme.spacing(4),
      alignItems: "center",
      [theme.breakpoints.down(1200)]: {
        display: "grid",
        gridTemplateColumns: "1fr 10rem",
      },
      [theme.breakpoints.down(480)]: {
        gridTemplateColumns: "1fr",
        gridGap: theme.spacing(1),
        textAlign: "center",
      },
      "& h5": {
        fontSize: "1.375rem",
      },
    },
  }),
)

export function SignupBanner(): ReactElement {
  const classes = useStyles()

  const dispatch = useDispatch()

  const handleClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Get Started Banner",
    })
    dispatch(redirectLogin())
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography variant="h5">Get started with TLS Protect for Kubernetes</Typography>
        <Button variant="contained" onClick={handleClick} className={classes.button}>
          Get Started
        </Button>
      </div>
      <div className={classes.wrapper}>
        <Typography variant="body2">
          Need help deciding? <br /> Let us help you with your requirements.
        </Typography>
        <Button variant="contained" href="https://www.jetstack.io/get-in-touch/" className={classes.button}>
          Talk to us
        </Button>
      </div>
    </div>
  )
}
