import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Select, FormControl, MenuItem, InputLabel, createStyles, makeStyles, Theme } from "@material-ui/core"

import { updateCurrentOrg } from "@actions/orgs"
import { organizationsSelector } from "@selectors/auth"
import { currentOrgSelector } from "@selectors/orgs"

import { HOME_PATH } from "@routes"
import { fetchSettings } from "@actions/settings"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      padding: theme.spacing(0, 0),
      display: "flex",
      maxWidth: 240,
    },
    menu: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
  }),
)

type OrganizationsMenuProps = {
  redirectOnChange?: boolean
}

export const OrganizationsMenu = ({ redirectOnChange }: OrganizationsMenuProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const organizations = useSelector(organizationsSelector)
  const currentOrgID = useSelector(currentOrgSelector)

  const handleOrgChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const orgID = event.target.value as string
    if (orgID !== currentOrgID) {
      dispatch(updateCurrentOrg(orgID))
      dispatch(fetchSettings(orgID))
      redirectOnChange && navigate(HOME_PATH)
    }
  }

  return (
    <div className={classes.menu}>
      <FormControl variant="outlined">
        <InputLabel id="organisation-select-label">Current organization</InputLabel>
        {organizations ? (
          <Select
            id="organisation-select-label"
            onChange={handleOrgChange}
            value={currentOrgID}
            label="Current organization"
            className={classes.select}
          >
            {organizations.map(org => (
              <MenuItem value={org.id} key={org.id}>
                {org.id}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Select id="organisation-select-label" label="Organization" />
        )}
      </FormControl>
    </div>
  )
}
