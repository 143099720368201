import React, { useEffect, useState, useCallback } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router"
import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { pathToSettings } from "@routes"
import { organizationsSelector, errorSelector } from "@selectors/auth"
import { currentOrgSelector } from "@selectors/orgs"
import { appConfigSelector } from "@selectors/appConfig"
import { featuresSelector } from "@selectors/features"
import { useSupportsMultiClusterSelector } from "@hooks/useSupportsMultiCluster"
import { useGetQueryString } from "@hooks/useGetQueryString"
import { Page } from "@components/Page"
import { ErrorMessage } from "@components/ErrorMessage"
import { Breadcrumbs } from "@components/Navigation/Breadcrumbs"
import { NavBar } from "@components/CertInventory/NavBar"
import { supportInfo, tierName } from "./helpers"
import { tabLinks } from "./constants"
import { TierQuotaView } from "./TierQuotaView"
import { ManageTeam } from "./ManageTeam"
import { PrivateImagesView } from "./PrivateImagesView"
import { TierFeatures } from "@components/OrganizationSettings/TierFeatures"
import { useFetchClusters } from "@hooks/useFetchClusters"
import { ServiceAccountsView } from "./ServiceAccounts"
import { useServiceAccountsEnabled } from "@hooks/useServiceAccountsEnabled"
import { Alerts } from "./Alerts"
import { useFlags } from "launchdarkly-react-client-sdk"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  }),
)

export const OrganizationSettings = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const flags = useFlags()
  const appConfig = useSelector(appConfigSelector).appConfig
  const customerPortalEnabled = appConfig?.global_features.customer_portal
  const serviceAccountsEnabled = useServiceAccountsEnabled()
  const currentOrgID = useSelector(currentOrgSelector)
  const organizations = useSelector(organizationsSelector)
  const authError = useSelector(errorSelector)
  const features = useSelector(featuresSelector)
  const { manageView: manageViewParam } = useParams<{ manageView: string }>()
  const { clusterLimit } = useSupportsMultiClusterSelector()
  const { hasQueryString: hasDefaultFormatParam, queryString: defaultFormatParam } = useGetQueryString("defaultFormat")

  const { clusters, isClusterError, isClusterLoading } = useFetchClusters()

  const [tab, setTab] = useState(customerPortalEnabled ? "tier" : "members")

  const isAuth0 = appConfig?.auth.kind === "auth0"

  useEffect(() => {
    if (manageViewParam) {
      onChangeTab(customerPortalEnabled ? manageViewParam : "members")
    } else {
      onChangeTab(customerPortalEnabled ? "tier" : "members")
    }
  }, [manageViewParam, defaultFormatParam])

  const currentOrg = organizations?.find(o => o.id === currentOrgID)
  const tier = tierName(currentOrg?.tier)
  const { supportText, supportLink } = supportInfo(currentOrg?.properties.support_level, currentOrg?.tier)
  const clusterCount = clusters.filter(cluster => !cluster.isDemoData).length
  const loading = !currentOrg || isClusterLoading
  const error = authError || isClusterError

  const onChangeTab = useCallback(
    (tab: string) => {
      setTab(tab)
      const path = `${pathToSettings(currentOrgID, tab)}${hasDefaultFormatParam ? `?${defaultFormatParam}` : ``}`
      navigate(path)
    },
    [navigate, pathToSettings, currentOrgID, hasDefaultFormatParam, defaultFormatParam],
  )

  const tabsEnabled = {
    tier: customerPortalEnabled,
    members: isAuth0,
    "private-images": customerPortalEnabled && features?.includes("private_images"),
    service_accounts: isAuth0 && serviceAccountsEnabled,
    alerts: isAuth0 && flags?.tlspkStandaloneGlobalAlerts,
  } as { [key: string]: boolean }

  const renderContent = () => {
    if (error) {
      return <ErrorMessage message={error} />
    }

    return (
      <div className={classes.root}>
        {tab === "tier" && tabsEnabled["tier"] && (
          <TierQuotaView
            tier={tier}
            clusterLimit={clusterLimit}
            clusterCount={clusterCount}
            supportText={supportText}
            supportLink={supportLink}
            isLoading={loading}
          />
        )}
        {tab === "tier" && tabsEnabled["tier"] && <TierFeatures tier={tier} />}
        {tab === "alerts" && tabsEnabled["alerts"] && <Alerts orgID={currentOrgID} />}
        {tab === "members" && tabsEnabled["members"] && isAuth0 && <ManageTeam />}
        {tab === "private-images" && tabsEnabled["private-images"] && <PrivateImagesView orgID={currentOrgID} />}
        {tab === "service_accounts" && tabsEnabled["service_accounts"] && isAuth0 && <ServiceAccountsView />}
      </div>
    )
  }

  return (
    <Page title="Manage organization" fixedWidth reqAuth breadcrumbs={<Breadcrumbs current="Manage" parents={["Organization"]} />}>
      <NavBar selected={tab} onChange={onChangeTab} links={tabLinks.filter(({ id }) => !!tabsEnabled[id])} />
      {renderContent()}
    </Page>
  )
}
