import React from "react"

import { DiagonalArrowIcon } from "@components/Icons"
import { StatBadge as StatBadgeType } from "../types"
import { useStyles } from "./useStyles"

type StatBadgeProps = {
  badge: StatBadgeType
}

function StatBadge({ badge: { value, type, reverse } }: StatBadgeProps) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={`${classes.badge} ${value > 0 && type ? classes[type] : ""} ${reverse ? classes.reverse : ""}`}>
        {value} <DiagonalArrowIcon />
      </div>
    </div>
  )
}

export { StatBadge }
