import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import * as queryString from "query-string"
import { isEmpty } from "lodash"

import { Paper, Typography, createStyles, makeStyles, Theme } from "@material-ui/core"

import { featuresSelector } from "@selectors/features"
import { appConfigSelector } from "@selectors/appConfig"
import { organizationExistsSelector } from "@selectors/orgs"
import { settingsSelector } from "@selectors/settings"
import { filterOptionsSelector, filterSelector, FilterType } from "@selectors/filters"
import { clustersWithCertInventorySelector } from "@selectors/clusters"
import { updateFilters } from "@actions/filters"

import { useSupportsMultiClusterSelector } from "@hooks/useSupportsMultiCluster"
import { FeatureCertInventory } from "@utils/features"
import { sliceListByPage } from "@utils/utils"
import { getOrgID, HOME_PATH, pathToCertInventoryCluster, pathToNewCluster } from "@routes"

import { Page } from "@components/Page"
import { ErrorMessage } from "@components/ErrorMessage"
import { NotFound } from "@components/NotFound"
import { LoadingMessage } from "@components/LoadingMessage"
import { NoClusters } from "@components/NoClusters"
import { Breadcrumbs } from "@components/Navigation/Breadcrumbs"
import { StatusBar } from "@components/StatusBar"
import { FilterProps, FiltersBar } from "@components/FiltersBar"
import { Pagination } from "@components/Pagination"
import { AddClusterOption } from "@components/CertInventoryMultiCluster/AddClusterOption"
import { ClusterLimitWarning } from "@components/CertInventoryMultiCluster/ClusterLimitWarning"
import { IntroQuestionnaire } from "@components/Onboarding/IntroQuestionnaire"
import { Table, TableHeader, ClusterRow } from "@components/ResourcesTable"

import { sortFilterClusters } from "./helpers"
import { useGetClustersData } from "@lib/clusters"
import { fetchClusters } from "@actions/clusters"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clusterSummary: {
      padding: theme.spacing(2, 0),
      display: "flex",
    },
    pageSection: {
      marginTop: theme.spacing(2),
    },
    clusterGrid: {
      width: "100%",
    },
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down(480)]: {
        padding: theme.spacing(3),
      },
    },
    error: {
      marginTop: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    tableTitles: {
      width: "100%",
      padding: theme.spacing(0, 2, 2, 2),
      "& span": {
        [theme.breakpoints.down(768)]: {
          display: "none",
        },
      },
    },
    status: {
      float: "right",
      minWidth: "252px",
      textAlign: "right",
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
    CMstatus: {
      float: "right",
      [theme.breakpoints.down(1100)]: {
        display: "none",
      },
    },
    errorContainer: {
      background: theme.palette.grey[200],
      borderRadius: 5,
      display: "inline-block",
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(2),
    },
    clusterLimit: {
      display: "block",
      width: "22rem",
      textAlign: "center",
      marginLeft: "50%",
      marginTop: theme.spacing(4),
      transform: "translate(-50%, 0)",
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
    filterContainer: {
      marginBottom: theme.spacing(4),
    },
    paginationContainer: {
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
  }),
)

export const CertInventoryMultiCluster = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const perPage = 10

  const [currentPage, setCurrentPage] = useState(1)

  const orgID = getOrgID(useParams())
  const features = useSelector(featuresSelector)
  const { settings } = useSelector(settingsSelector())
  const { clustersData, isLoading, error } = useGetClustersData(settings?.show_demo_cluster)
  const orgExists = useSelector(organizationExistsSelector(orgID))
  const filtersState = useSelector(filterSelector(FilterType.clusterSummary))
  const filterOpts = useSelector(filterOptionsSelector(FilterType.clusterSummary))
  const appConfig = useSelector(appConfigSelector).appConfig
  const clusters = useSelector(clustersWithCertInventorySelector)
  const { clusterLimitReached } = useSupportsMultiClusterSelector()

  const clusterCount = clustersData?.total || 0

  useEffect(() => {
    const queryFilters = queryString.parse(location.search)
    if (!isLoading && !isEmpty(queryFilters)) {
      dispatchFilter(queryFilters)
    } else if (!isEmpty(filtersState)) {
      updateUrlWithQueryString(filtersState)
    }
  }, [history])

  useEffect(() => {
    dispatch(fetchClusters(orgID))
  }, [dispatch, orgID])

  // effect that redirects to home if CertInventory is not enabled for the org
  useEffect(() => {
    if (!features?.includes(FeatureCertInventory)) {
      navigate(HOME_PATH)
    }
  }, [features, history])

  const dispatchFilter = (filter: FilterProps) => dispatch(updateFilters({ [FilterType.clusterSummary]: filter }))

  const onFilter = (filter: FilterProps) => {
    dispatchFilter(filter)
    updateUrlWithQueryString(filter)
  }

  const updateUrlWithQueryString = (filter: FilterProps) => {
    const url = new URL(document.URL)
    const query = queryString.stringify(filter)
    navigate({
      pathname: url.pathname,
      search: `?${query}`,
    })
  }

  const handleRowClick = (cluster: string) => navigate(pathToCertInventoryCluster(orgID, cluster, "certificates"))

  const renderContent = () => {
    const filteredClusters = sortFilterClusters(clustersData?.clusters || [], filtersState)
    const totalPages = Math.ceil(filteredClusters.length / perPage)
    const pageOfClusters = sliceListByPage(filteredClusters, currentPage, perPage)
    if (totalPages < currentPage) {
      setCurrentPage(totalPages)
    }

    return (
      <div className={classes.clusterSummary}>
        <div className={classes.clusterGrid}>
          <Paper className={classes.paper}>
            {error ? (
              <div className={classes.error}>
                <ErrorMessage message="Something went wrong while loading the certificates. Please try again or contact support." />
              </div>
            ) : (
              <>
                {clusterLimitReached && clusters.length > 4 && <ClusterLimitWarning />}
                <div data-testid="cluster-overview">
                  <StatusBar
                    statusList={[
                      {
                        status: "error",
                        message: "With errors",
                        value: clustersData?.errors || "",
                      },
                      {
                        status: "warning",
                        message: "With warnings",
                        value: clustersData?.warnings || "",
                      },
                    ]}
                    staticStats={[
                      { label: "Total clusters", value: clusterCount || "" },
                      { label: "Total certificates", value: clustersData?.totalCertificates || "" },
                    ]}
                  />
                </div>
                <div className={classes.filterContainer}>
                  <FiltersBar filtersState={filtersState} filterOptions={filterOpts} onFilter={onFilter} isDisabled={isLoading} />
                </div>
                <div className={classes.pageSection}>
                  {pageOfClusters.length > 0 && (
                    <Table>
                      <TableHeader
                        rows={[
                          { label: "", size: 1.5 },
                          { label: "Name" },
                          { label: "cert-manager status" },
                          { label: "cert-manager versions" },
                          { label: "Last updated", size: 10 },
                          { label: "Status", size: 5 },
                        ]}
                      />
                      <tbody>
                        {pageOfClusters.map(cluster => (
                          <ClusterRow key={cluster.id} cluster={cluster} onClick={handleRowClick} />
                        ))}
                      </tbody>
                    </Table>
                  )}
                  {pageOfClusters.length === 0 && (
                    <div className={classes.errorContainer}>
                      <Typography>No clusters found with this filter.</Typography>
                    </div>
                  )}
                  {clusterLimitReached && clusters.length < 5 && (
                    <div className={`${classes.errorContainer} ${classes.clusterLimit}`}>
                      {clusterCount === 1 ? (
                        <Typography>You have reached the free tier cluster limit.</Typography>
                      ) : (
                        <Typography>You have reached your cluster limit.</Typography>
                      )}
                      <AddClusterOption
                        limitReached={clusterLimitReached}
                        redirectPath={pathToNewCluster(orgID)}
                        text="Extend your limit"
                      />
                    </div>
                  )}
                  {pageOfClusters.length > 0 && !clusterLimitReached && (
                    <div className={classes.paginationContainer}>
                      <Pagination count={totalPages} page={currentPage} onChange={(_, value) => setCurrentPage(value)} />
                    </div>
                  )}
                </div>
              </>
            )}
          </Paper>
        </div>
      </div>
    )
  }

  if (!orgExists) {
    return <NotFound />
  }

  return (
    <Page
      title="Clusters Summary"
      reqAuth
      fixedWidth
      breadcrumbs={<Breadcrumbs current="Clusters" parents={["Organization"]} />}
      customSideComponent={
        !clusterLimitReached && clusterCount && clusterCount > 0 ? (
          <AddClusterOption limitReached={clusterLimitReached} redirectPath={pathToNewCluster(orgID)} text="Extend cluster limit" />
        ) : undefined
      }
    >
      {appConfig?.global_features.questionnaires && <IntroQuestionnaire />}
      {isLoading ? (
        <LoadingMessage label="Loading clusters" />
      ) : clusterCount && clusterCount > 0 ? (
        renderContent()
      ) : (
        <NoClusters pathToNewCluster={pathToNewCluster(orgID)} />
      )}
    </Page>
  )
}
