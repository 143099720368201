import { AuthClient } from "@lib/auth"

import {
  AuthActions,
  START_CLIENT,
  CLIENT_READY,
  REDIRECT_LOGIN,
  REDIRECT_CALLBACK_RECEIVED,
  REDIRECT_CALLBACK_PROCESSED,
  GOT_ACCESS_TOKEN,
  FETCH_AUTH_INFO,
  FETCH_AUTH_INFO_SUCCESS,
  LOGOUT_STARTED,
  LOGOUT_FINISHED,
  AUTH_FAILURE,
  UPDATE_AUTH_INFO,
} from "../actions/auth"

export type AuthState = {
  isStartingClient: boolean
  isFetchingUser: boolean
  isLoggedIn: boolean
  isLoggingIn: boolean
  isLoggingOut: boolean
  client?: AuthClient
  authInfo?: AuthInfoType
  error: string
  accessToken: string
  emailVerified: boolean
  domain: string
}

const initialState = {
  isStartingClient: false,
  isFetchingUser: false,
  isLoggedIn: false,
  isLoggingIn: false,
  isLoggingOut: false,
  authInfo: undefined,
  error: "",
  accessToken: "",
  emailVerified: false,
  domain: "",
}

export function authReducer(state: AuthState = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case START_CLIENT: {
      return {
        ...state,
        isStartingClient: true,
        isLoggingIn: false,
      }
    }

    case CLIENT_READY: {
      return {
        ...state,
        isStartingClient: false,
        client: action.client,
        isLoggedIn: action.isLoggedIn,
        isLoggingIn: false,
        domain: action.domain,
      }
    }

    case REDIRECT_LOGIN: {
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: false,
        error: "",
      }
    }

    case REDIRECT_CALLBACK_RECEIVED: {
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: true,
      }
    }

    case REDIRECT_CALLBACK_PROCESSED: {
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
        error: "",
      }
    }

    case GOT_ACCESS_TOKEN: {
      return {
        ...state,
        accessToken: action.token,
      }
    }

    case FETCH_AUTH_INFO: {
      return {
        ...state,
        isFetchingUser: true,
        error: "",
      }
    }

    case FETCH_AUTH_INFO_SUCCESS: {
      return {
        ...state,
        isFetchingUser: false,
        isLoggedIn: true,
        isLoggingIn: false,
        authInfo: action.authInfo,
        error: "",
        emailVerified: action.emailVerified,
      }
    }

    case UPDATE_AUTH_INFO: {
      return {
        ...state,
        authInfo: action.authInfo,
      }
    }

    case LOGOUT_STARTED: {
      return {
        ...state,
        isLoggingOut: true,
        isLoggingIn: false,
      }
    }

    case LOGOUT_FINISHED: {
      return {
        ...initialState,
      }
    }

    case AUTH_FAILURE: {
      return {
        ...state,
        isFetchingUser: false,
        isLoggedIn: false,
        isLoggingIn: false,
        error: action.error,
      }
    }

    default:
      return state
  }
}
