import React, { PropsWithChildren, ReactNode } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: "flex",
      alignItems: "center",
      gridGap: "0.5rem",
      textDecoration: "underline",
      textTransform: "none",
      fontSize: "1.2rem",
      color: theme.palette.primary.main,
      "&:hover": {
        textDecoration: "none",
      },
      "& svg": {
        marginBottom: "-0.2rem",
        "& path": {
          stroke: theme.palette.primary.main,
        },
      },
    },
  }),
)

type LinkWithIconProps = PropsWithChildren<{
  href: string
  icon: ReactNode
}>

const LinkWithIcon = ({ href, icon, children }: LinkWithIconProps) => {
  const classes = useStyles()
  return (
    <a href={href} target="_blank" rel="noreferrer" className={classes.link}>
      {icon}
      {children}
    </a>
  )
}

export { LinkWithIcon }
