import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { useParams, Navigate } from "react-router-dom"
import axios, { AxiosResponse } from "axios"

import { accessTokenSelector } from "@selectors/auth"
import { appConfigSelector } from "@selectors/appConfig"
import { currentOrgSelector } from "@selectors/orgs"
import { organizationsSelector } from "@selectors/auth"
import { AppConfigAuthAgentKind } from "@lib/appConfig"
import { getOrgID, pathToCertInventory, pathToConnectCluster, pathToOverview } from "@routes"
import { handleError } from "@utils/errorHandling"
import { addAuth } from "@lib/auth"
import { rudderanalytics as rudderstack } from "@utils/rudderstack"
import { useSupportsMultiClusterSelector } from "@hooks/useSupportsMultiCluster"

import { Page } from "@components/Page"
import { ErrorMessage } from "@components/ErrorMessage"
import { ClusterNameStep } from "./ClusterNameStep"
import { ConfigurationStep } from "./ConfigurationStep/"
import { TroubleshootingStep } from "./TroubleshootingStep"
import { CheckingAgentStatus } from "./CheckingAgentStatus"
import { Breadcrumbs, BreadcrumbsNavItem } from "@components/Navigation/Breadcrumbs"
import { SvcAccountCreds, APIToken } from "../common"
import { Step } from "../common/Step/"

export const AddClusterWizard = () => {
  const navigate = useNavigate()

  const [clusterName, setClusterName] = useState("")
  const [showAgentStatus, setShowAgentStatus] = useState(false)
  const [credentialsError, setCredentialsError] = useState("")
  const [credentials, setCredentials] = useState<SvcAccountCreds | APIToken | null>(null)
  const orgID = getOrgID(useParams())
  const accessToken = useSelector(accessTokenSelector)
  const { showMultiCluster, clusterLimitReached, supportsMultiCluster } = useSupportsMultiClusterSelector()
  const { appConfig } = useSelector(appConfigSelector)

  const currentOrgID = useSelector(currentOrgSelector)
  const organizations = useSelector(organizationsSelector)
  const currentOrg = organizations?.find(o => o.id === currentOrgID)
  const features = currentOrg?.features

  // users with access to private images should be taken to the operator-based onboarding flow
  if (features && features.includes("private_images")) {
    useEffect(() => {
      navigate(pathToConnectCluster(orgID))
    })
  }

  useEffect(() => {
    rudderstack.page("Add cluster")
  })

  if (clusterLimitReached) {
    return <Navigate to={pathToCertInventory(orgID)} />
  }

  const handleClusterNameInput = (name: string) => {
    setClusterName(name)
    setCredentialsError("")
    // even if appConfig.agent_installation.disable_auth is active, we try to create the
    // service account, as that gives us validation for the cluster ID.
    const agentCredentialsURL =
      appConfig?.agent_installation.auth_type === AppConfigAuthAgentKind.api_token
        ? `/api/v1/org/${orgID}/tokens`
        : `/api/v1/org/${orgID}/svc_accounts`

    axios
      .post<{ name: string }, AxiosResponse<SvcAccountCreds | APIToken>>(agentCredentialsURL, { name: name }, addAuth(accessToken))
      .then(res => {
        setCredentials(res.data)
      })
      .catch(error => {
        if (error.response && error.response.data) {
          setCredentialsError(error.response.data.error)
        } else {
          setCredentialsError("unknown error")
        }
        handleError(error)
      })
    rudderstack.track("add_cluster_wizard__entered_name", {
      cluster_name: name,
    })
  }

  const getParentCrumbs = (): BreadcrumbsNavItem[] => {
    const clusterBreadCrumb = {
      label: showMultiCluster ? "Clusters" : "Cluster",
      href: pathToCertInventory(orgID),
    }
    return supportsMultiCluster
      ? [
          {
            label: "Overview",
            href: pathToOverview(orgID),
          },
          clusterBreadCrumb,
        ]
      : [clusterBreadCrumb]
  }

  const hasCredentialsError = credentialsError !== ""
  const renderContent = () => (
    <div>
      <ClusterNameStep
        hasError={hasCredentialsError}
        onValidName={handleClusterNameInput}
        showServiceLoader={Boolean(!credentials && clusterName)}
      />
      {hasCredentialsError && <ErrorMessage message={`Cannot generate agent credentials for the new cluster: ${credentialsError}`} />}
      <ConfigurationStep
        orgID={orgID}
        clusterName={clusterName}
        credentials={credentials ? credentials : undefined}
        useNoAuth={appConfig?.agent_installation.disable_auth}
        callback={() => setShowAgentStatus(true)}
        checkStatusComponent={credentials && showAgentStatus ? <CheckingAgentStatus orgID={orgID} clusterName={clusterName} /> : undefined}
      />

      <TroubleshootingStep />
    </div>
  )

  return (
    <Page title="Connect a cluster" reqAuth fixedWidth breadcrumbs={<Breadcrumbs current="Connect Cluster" parents={getParentCrumbs()} />}>
      <Step>{renderContent()}</Step>
    </Page>
  )
}
