import React, { ReactElement } from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core"
import { Status } from "@components/StatusIndicator"

const useStyles = (isLarge: boolean, status: Status) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        background: getColor(theme, status),
        border: `2px solid ${getColor(theme, status)}`,
        color: status === "warning" ? "inherit" : "white",
        borderRadius: "50%",
        width: isLarge ? "36px" : "28px",
        height: isLarge ? "36px" : "28px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "2px",
        fontSize: isLarge ? "0.875rem" : "0.75rem",
        fontWeight: 600,
        zIndex: 1,
        transition: "all 0.2s ease-in-out",
      },
    }),
  )

export type TBadgeProps = {
  value?: number | string
  status: Status
  largeBadge?: boolean
}

export function Badge({ value, status, largeBadge = false }: TBadgeProps): ReactElement {
  const classes = useStyles(largeBadge, status)()
  return (
    <div className={classes.root} data-testid={`${status}-badge`}>
      {typeof value === "number" && value > 99 ? "99+" : value}
    </div>
  )
}

export const getColor = ({ palette }: Theme, status: string) => {
  if (status === "error") {
    return palette.error.main
  }
  if (status === "warningMedium") {
    return palette.warning.dark
  }
  if (status === "warning") {
    return palette.warning.light
  }
  if (status === "primary") {
    return palette.primary.main
  }
}
