export const FETCH_CLUSTERS = "FETCH_CLUSTERS"
export const FETCH_CLUSTERS_FAILED = "FETCH_CLUSTERS_FAILED"
export const FETCH_CLUSTERS_SUCCESS = "FETCH_CLUSTERS_SUCCESS"
export const ADD_PENDING_CLUSTER = "ADD_PENDING_CLUSTER"

export interface FetchClustersAction {
  type: typeof FETCH_CLUSTERS
  orgID: string
}

export interface FetchClusterFailedAction {
  type: typeof FETCH_CLUSTERS_FAILED
  orgID: string
  errorMessage: string
}

export interface FetchClusterSuccessAction {
  type: typeof FETCH_CLUSTERS_SUCCESS
  orgID: string
  clusters: ClusterOverview[]
}

export interface AddPendingClusterAction {
  type: typeof ADD_PENDING_CLUSTER
  orgID: string
  clusterID: string
}

export const fetchClusters = (orgID: string): FetchClustersAction => ({
  type: FETCH_CLUSTERS,
  orgID,
})

export const fetchClustersFailed = (orgID: string, errorMessage: string): FetchClusterFailedAction => ({
  type: FETCH_CLUSTERS_FAILED,
  orgID,
  errorMessage,
})

export const fetchClustersSuccess = (orgID: string, clusters: ClusterOverview[]): FetchClusterSuccessAction => ({
  type: FETCH_CLUSTERS_SUCCESS,
  orgID,
  clusters,
})

export const addPendingCluster = (orgID: string, clusterID: string): AddPendingClusterAction => ({
  type: ADD_PENDING_CLUSTER,
  orgID,
  clusterID,
})

export type ClustersActions = FetchClustersAction | FetchClusterFailedAction | FetchClusterSuccessAction | AddPendingClusterAction
