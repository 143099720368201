import { ACMEIssuer } from "../../types"

type Solver = {
  http01: {
    ingress: {
      class: "nginx"
    }
  }
}

export type ACMEYaml = {
  acme: {
    email: string
    server: string
    privateKeySecretRef: {
      name: string
    }
    solvers: Solver[]
  }
}

const renderAcmeYaml = (name: string, issuer: ACMEIssuer): ACMEYaml => {
  return {
    acme: {
      email: issuer.email,
      server: issuer.serverUrl,
      privateKeySecretRef: {
        name: `${name}-acme-account-key`,
      },
      solvers: [
        {
          http01: {
            ingress: {
              class: "nginx",
            },
          },
        },
      ],
    },
  }
}

export { renderAcmeYaml }
