import React from "react"
import { Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core"
import ErrorIcon from "@material-ui/icons/Error"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: "5rem",
    },
    link: {
      color: "white",
    },
  })
})

type FatalErrorProps = {
  message: string
}

export const FatalError = ({ message }: FatalErrorProps) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <ErrorIcon fontSize="inherit" />
      </div>
      <Typography gutterBottom variant="body1">
        {message}
      </Typography>
      <Button variant="contained" color="primary" href="/" className={classes.link}>
        Reload
      </Button>
    </div>
  )
}
