import { isEmpty, get } from "lodash"
import { useSelector } from "react-redux"
import { ClusterData, OverviewResponseDataCluster } from "@lib/certInventory"
import { settingsSelector } from "@selectors/settings"
import { pathToCertInventoryCluster, pathToCertInventory, pathToAllResources } from "@routes"
import {
  calculateCertificateRequests,
  calculateCMCertificates,
  calculateIngresses,
  calculateNonCMCertificates,
  calculateOwnerlessCertificateRequests,
  CertificateRequest,
  CMCertificate,
  NonCMCertificate,
  OwnerlessCertificateRequest,
} from "@lib/certInventory/resources"

import { generateClustersData, generateCertificatesData, generateIngressesData } from "./helpers"
import { OverviewFeaturedItems, OverviewFeaturedTags } from "./types"
import { useGetOverviewStats } from "@lib/organisationOverview"

export function useGetOverviewData(orgID: string) {
  const { settings } = useSelector(settingsSelector())
  const { overviewStats, /*allClustersData, clusterError,*/ isLoading, error } = useGetOverviewStats(settings?.show_demo_cluster)

  const clusterStats = {
    count: get(overviewStats, "clusterCount", 0),
    errorCount: get(overviewStats, "highRiskClusterCount", 0),
    updateCount: get(overviewStats, "certManagerUpgradeCount", 0),
    noCmCount: get(overviewStats, "certManagerNotInstalledCount", 0),
    inactiveClustersCount: get(overviewStats, "inactiveClustersCount", 0),
    url: pathToCertInventory(orgID),
  }

  const certificateStats = {
    count: get(overviewStats, "certificateCount", 0),
    errorCount: get(overviewStats, "highRiskCertificateCount", 0),
    noCmCount: get(overviewStats, "unmanagedCertificateCount", 0),
    url: pathToAllResources(orgID, "certificates"),
  }

  const ingressStats = {
    count: get(overviewStats, "ingressCount", 0),
    errorCount: get(overviewStats, "highRiskIngressCount", 0),
    url: pathToAllResources(orgID, "ingresses"),
  }

  // //Generate cluster section info from OverviewStats
  const errorClustersInfo = [] as OverviewFeaturedItems[]
  const nonCmClustersInfo = [] as OverviewFeaturedItems[]
  const inactiveClustersInfo = [] as OverviewFeaturedItems[]

  if (overviewStats && overviewStats.clusters.length > 0) {
    overviewStats?.clusters.forEach((cluster: OverviewResponseDataCluster) => {
      const currentClusterInfo = {
        name: cluster.clusterId,
        url: pathToCertInventoryCluster(orgID, cluster.clusterId, "certificates"),
      }
      if (!cluster.active) {
        inactiveClustersInfo.push(currentClusterInfo)
      }
      if (!cluster.certManagerInstalled) {
        nonCmClustersInfo.push(currentClusterInfo)
      }
      if (cluster.highRiskCertificateCount > 0) {
        errorClustersInfo.push(currentClusterInfo)
      }
    })
  }

  /*if (!isEmpty(allClustersData)) {
    const certInventorKeys = Object.keys(allClustersData)
    const finishedLoading = certInventorKeys.length > 0 ? !certInventorKeys.some(cluster => allClustersData[cluster].isLoading) : false

    if (finishedLoading) {
      const clustersInfo = {
        errorClusters: [] as OverviewFeaturedItems[],
        updateClusters: [] as OverviewFeaturedItems[],
        noCmClusters: [] as OverviewFeaturedItems[],
        inactiveClusters: [] as OverviewFeaturedItems[],
      }

      const certificatesInfo = {
        errorCertificates: [] as OverviewFeaturedItems[],
        noCmCertificates: [] as OverviewFeaturedItems[],
      }

      const ingressesInfo = {
        errorIngresses: [] as OverviewFeaturedItems[],
      }

      certInventorKeys.forEach(key => {
        if (allClustersData[key]?.certInventory) {
          const currentCluster = allClustersData[key]
          const CMStatus = currentCluster.certInventory?.cert_manager_status
          const clusterTimeStamp = currentCluster.certInventory?.timestamp
          const cmcerts = calculateCMCertificates(currentCluster.certInventory as ClusterData)
          const noncmcerts = calculateNonCMCertificates(currentCluster.certInventory as ClusterData)
          const certReqs = calculateCertificateRequests(currentCluster.certInventory as ClusterData)
          const ownerlessCertReqs = calculateOwnerlessCertificateRequests(currentCluster.certInventory as ClusterData)

          const clusterCerts = [...cmcerts, ...noncmcerts, ...certReqs, ...ownerlessCertReqs]

          const currentClusterInfo = {
            name: key,
            url: pathToCertInventoryCluster(orgID, key, "certificates"),
            timestamp: clusterTimeStamp,
          }

          clusterCerts.forEach((cert: CMCertificate | NonCMCertificate | CertificateRequest | OwnerlessCertificateRequest | undefined) => {
            if (!cert) return
            const type = cert.status.type
            if (type === "error") {
              certificatesInfo.errorCertificates.push({
                name: `${cert.resource.metadata?.namespace} > ${cert.resource.metadata?.name}`,
                url: `${pathToCertInventoryCluster(orgID, key, "certificates")}#${cert.hash()}`,
                timestamp: cert.resource.metadata?.creationTimestamp,
              })
            }
          })

          const nonCmCertData = noncmcerts.map(cert => ({
            name: `${cert.resource.metadata?.namespace} > ${cert.resource.metadata?.name}`,
            url: `${pathToCertInventoryCluster(orgID, key, "certificates")}#${cert.hash()}`,
            timestamp: cert.resource.metadata?.creationTimestamp,
          }))

          certificatesInfo.noCmCertificates.push(...nonCmCertData)

          // Generate data for clusters needing updating
          if (!(CMStatus?.cainjector.is_latest && CMStatus?.controller.is_latest && CMStatus?.webhook.is_latest)) {
            clustersInfo.updateClusters.push({
              ...currentClusterInfo,
              controller: currentCluster.certInventory?.cert_manager_status.controller,
            })
          }

          const ingresses = calculateIngresses(currentCluster.certInventory as ClusterData)
          ingresses.map(ingress => {
            const isInsecure = ingress.rule_messages.some(x => x.rule.id === "ingress_tls_missing")
            const isHighSeverity = ingress.rule_messages.some(x => x.rule.severity === 3) || isInsecure
            const tags = []
            if (isInsecure)
              tags.push({
                label: "Insecure",
                severity: "high",
              })
            if (isHighSeverity || isInsecure) {
              ingressesInfo.errorIngresses.push({
                name: ingress.resource.metadata?.name || "Unnamed ingress",
                url: `${pathToCertInventoryCluster(orgID, key, "ingresses")}#${ingress.hash()}`,
                timestamp: ingress.resource.metadata?.creationTimestamp,
                tags: tags as OverviewFeaturedTags[],
              })
            }
          })
        }
      })

      return {
        clusterData: generateClustersData({
          ...clusterStats,
          resourcesAreLoading: false,
          updateClusters: clustersInfo.updateClusters,
          errorClusters: errorClustersInfo,
          noCmClusters: nonCmClustersInfo,
          inactiveClusters: inactiveClustersInfo,
        }),
        certificateData: generateCertificatesData({
          ...certificateStats,
          resourcesAreLoading: false,
          errorCertificates: certificatesInfo.errorCertificates,
          noCmCertificates: certificatesInfo.noCmCertificates,
        }),
        ingressData: generateIngressesData({
          ...ingressStats,
          resourcesAreLoading: false,
          errorIngresses: ingressesInfo.errorIngresses,
        }),
        isLoading,
        error,
        clusterError,
      }
    }
  }*/
  return {
    clusterData: generateClustersData({
      ...clusterStats,
      resourcesAreLoading: false,
      errorClusters: errorClustersInfo,
      updateClusters: [],
      noCmClusters: nonCmClustersInfo,
      inactiveClusters: inactiveClustersInfo,
    }),
    certificateData: generateCertificatesData({
      ...certificateStats,
      resourcesAreLoading: false,
      errorCertificates: [],
      noCmCertificates: [],
    }),
    ingressData: generateIngressesData({
      ...ingressStats,
      resourcesAreLoading: false,
      errorIngresses: [],
    }),
    isLoading,
    error,
    //clusterError,
  }
}
