import { combineReducers } from "redux"
import { clustersReducer as clusters, ClustersState } from "@reducers/clusters"
import { authReducer as auth, AuthState } from "@reducers/auth"
import { orgsReducer as orgs, OrgsState } from "@reducers/orgs"
import { certInventoryReducer as certInventory, CertInventoryState } from "@reducers/certInventory"
import { receiversReducer as receivers, ReceiversState } from "@reducers/receivers"
import { filtersReducer as filters, FiltersState } from "@reducers/filters"
import { settingsReducer as settings, SettingsState } from "@reducers/settings"
import { appConfigReducer as appConfig, AppConfigState } from "@reducers/appConfig"

export type AppState = {
  clusters: ClustersState
  auth: AuthState
  orgs: OrgsState
  certInventory: CertInventoryState
  receivers: ReceiversState
  filters: FiltersState
  settings: SettingsState
  appConfig: AppConfigState
}

const reducers = () => {
  const reducers = {
    clusters,
    auth,
    orgs,
    certInventory,
    receivers,
    filters,
    settings,
    appConfig,
  }

  return combineReducers<AppState>(reducers)
}

export { reducers }
