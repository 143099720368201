import React, { Fragment } from "react"

import { useSelector } from "react-redux"
import { createStyles, Theme, makeStyles, Typography } from "@material-ui/core"

import { certsGroupedByIdentity } from "@selectors/certInventory"
import { Certificate, OwnerlessCertificateRequest, CertificateRequest } from "@lib/certInventory/resources"
import { HistoryList } from "../HistoryList"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: theme.spacing(2),
    },
  }),
)

type HistoryCertsProps = {
  certificate: Certificate | OwnerlessCertificateRequest | CertificateRequest
  clusterID: string
}

function HistoryCerts({ certificate, clusterID }: HistoryCertsProps) {
  const classes = useStyles()
  const { identities } = useSelector(certsGroupedByIdentity(clusterID))

  const certIdentities = certificate.getIdentities()

  const historyCerts = certIdentities
    .map(({ value }) => {
      if (identities[value] && identities[value].group.length > 0) {
        return {
          id: value,
          group: identities[value].group,
        }
      } else {
        return undefined
      }
    })
    .filter(Boolean)

  if (historyCerts.length < 1) return null
  return (
    <div className={classes.section}>
      <Typography gutterBottom variant="h5">
        History
      </Typography>
      {historyCerts.map(history =>
        history ? (
          <Fragment key={history.id}>
            <Typography variant="body2" color="textSecondary">
              By identity:
            </Typography>
            <Typography color="textPrimary" variant="body2" gutterBottom>
              {history.id}
            </Typography>
            <br />
            <HistoryList list={history.group} />
          </Fragment>
        ) : null,
      )}
    </div>
  )
}

export { HistoryCerts }
