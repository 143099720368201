import { ClusterData, RuleMessage, ResourceStatus } from "../types"
import { Resource, collectResources } from "./resource"
import * as openshift from "../openshift"

export function calculateRoutes(data: ClusterData): Route[] {
  const generateRoute = (data: ClusterData, id: string) => new Route(data, id)
  return collectResources(data, generateRoute, "route.openshift.io/v1/Route")
}

export class Route extends Resource {
  id: string
  resource: openshift.Route
  rule_messages: RuleMessage[]
  status: ResourceStatus
  clusterId: string
  deleted_at?: string

  constructor(data: ClusterData, id: string) {
    super()
    const item = data.resource_index[id]
    this.id = id
    this.resource = item.resource
    this.rule_messages = item.rule_messages
    this.status = item.status ? item.status : { type: "unknown", message: "Unknown" }
    this.deleted_at = item.deleted_at
    this.clusterId = data.cluster_id
  }
}
