import { useCallback } from "react"
import axios, { AxiosError } from "axios"
import { useQuery } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { getOrgID } from "@routes"
import { accessTokenSelector } from "@selectors/auth"
import { DiscoveredCertificate, DiscoveredCertificatesFilterState, DiscoveredCertificatesRequest } from "./types"
import { addAuth } from "@lib/auth"
import { handleError } from "@utils/errorHandling"
import { calculateHoursForQuery } from "./calculateHours"
import { QUERYKEYS } from "@lib/queryKeys"

// Get all Discovered Certificate queries
export function useGetDiscoveredCertificates(filterState: DiscoveredCertificatesFilterState) {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)

  const fetchCertificates = useCallback(() => {
    // build url params
    const params = new URLSearchParams()
    if (filterState.expiryIn) {
      // need to calculate the hours from the stored state
      const expiry_in = calculateHoursForQuery(filterState.expiryIn)
      if (expiry_in) {
        params.append("expiry_in", `${expiry_in.toString()}h`)
      }
    }
    if (filterState.cluster) {
      params.append("clusters", filterState.cluster.toString())
    }
    return () =>
      axios.get<DiscoveredCertificatesRequest>(
        `/api/resources/v1/org/${orgID}/discovered-certificates${params.toString() ? `?${params.toString()}` : ""}`,
        addAuth(token),
      )
  }, [filterState.expiryIn, filterState.cluster])

  const { data, isError, error, ...rest } = useQuery(
    QUERYKEYS.discoveredCertificates.list(orgID, filterState.expiryIn, filterState.namespace, filterState.cluster),
    fetchCertificates(),
    {
      onError: (error: AxiosError) => {
        const errorData = error.response?.data as { error: string }
        if (!errorData?.error.includes("no results were found as part of the query")) {
          handleError(error)
        }
      },
      retry: false,
    },
  )

  const errorData = error?.response?.data as { error: string }

  return {
    certificates: data?.data.data || [],
    links: data?.data.links,
    isError: isError && !errorData?.error.includes("no results were found as part of the query") ? true : false,
    ...rest,
  }
}

// Get Discovered Certificate
export function useGetDiscoveredCertificate(id: string) {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)

  const { data, ...rest } = useQuery(
    QUERYKEYS.discoveredCertificates.detail(orgID, id),
    () => axios.get<DiscoveredCertificate>(`/api/resources/v1/org/${orgID}/discovered-certificates/${id}`, addAuth(token)),
    { onError: error => handleError(error), enabled: true },
  )

  return {
    certificate: data?.data.certificates[0],
    ...rest,
  }
}
