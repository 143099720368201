import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core"
import ReactGA from "react-ga"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(2),
      position: "relative",
    },
    contactBtn: {
      marginRight: theme.spacing(2),
    },
  }),
)

type ContactSalesProps = {
  open: boolean
  closeHandler: () => void
}

export const ContactSales = ({ open, closeHandler }: ContactSalesProps) => {
  const classes = useStyles()

  const handleContact = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Contact Sales",
    })
    closeHandler()
  }

  const handleCancel = () => {
    ReactGA.event({
      category: "User",
      action: "Did not click Contact Sales",
    })
    closeHandler()
  }
  return (
    <Dialog open={open} onClose={handleCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <div className={classes.dialog}>
        <DialogTitle id="alert-dialog-title">Add Additional Clusters</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>To add additional clusters contact the Jetstack sales team:</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            href="https://www.jetstack.io/get-in-touch/"
            target="_blank"
            rel="noreferrer"
            onClick={handleContact}
            variant="contained"
            color="primary"
            className={classes.contactBtn}
          >
            Contact sales
          </Button>
          <Button onClick={handleCancel} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
