import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { IconButton, Avatar, MenuItem, MenuList, Popover, Divider, createStyles, makeStyles, Theme, Typography } from "@material-ui/core"
import LoginIcon from "@material-ui/icons/ExitToApp"

import { OrganizationsMenu } from "@components/OrganizationsMenu"
import { logoutStarted, redirectLogin } from "@actions/auth"
import { userSelector, isLoggedInSelector } from "@selectors/auth"
import { configDemoCluster } from "@actions/settings"
import { settingsSelector } from "@selectors/settings"
import { USER_PROFILE_PATH } from "@routes"
import { ChevronRight } from "components/Icons"

import { ToolbarNavItem } from "../ToolbarNavItem"
import { currentOrgSelector } from "@selectors/orgs"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    definitionsItem: {
      padding: theme.spacing(0, 2),
      margin: theme.spacing(2, 0, 0, 0),
      "& dt": {
        fontSize: "0.825rem",
        fontWeight: 300,
        marginBottom: "0.25rem",
      },
      "& dd": {
        fontWeight: 400,
        marginBottom: theme.spacing(1),
      },
    },
    avatar: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(2, 0, 2, 2.7),
      padding: theme.spacing(0, 2, 0, 0),
      color: "white",
      cursor: "pointer",
      [theme.breakpoints.down(768)]: {
        paddingBottom: 0,
        marginLeft: theme.spacing(4),
      },
      "& .MuiAvatar-root": {
        width: "50px",
        height: "50px",
      },
      "&::after": {
        content: "''",
        color: "white",
        position: "absolute",
        top: 0,
        right: 0,
        display: "block",
        width: "calc(100% - 25px)",
        height: "100%",
        background: "rgba(255,255,255, 0.05)",
        zIndex: -1,
        transition: " all 0.3s ease-in-out",
      },
      "&:hover": {
        "&::after": {
          background: theme.palette.primary.main,
        },
      },
    },
    selectedAvatar: {
      "&::after": {
        background: theme.palette.primary.main,
      },
    },
    logout: {
      width: "50px",
      height: "50px",
    },
    profileLabel: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      padding: theme.spacing(0, 2, 0, 2),
    },
    login: {
      cursor: "pointer",
    },
  }),
)

export const UserMenu = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(userSelector)
  const isLoggedIn = useSelector(isLoggedInSelector)

  const orgSettings = useSelector(settingsSelector())
  const orgID = useSelector(currentOrgSelector)
  const showOrHideDemoCluster = orgSettings?.settings?.show_demo_cluster ? "Hide" : "Show"

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    dispatch(logoutStarted())
  }

  const handleLogin = () => {
    dispatch(redirectLogin())
  }

  const goToProfile = () => {
    navigate(USER_PROFILE_PATH)
  }

  const toggleDemoCluster = () => {
    dispatch(configDemoCluster(orgID, !orgSettings?.settings?.show_demo_cluster))
  }

  const genAvatar = () => {
    if (!user) {
      return <Avatar>?</Avatar>
    }
    return <Avatar src={user!.picture_url}>{user!.name.charAt(0).toUpperCase()}</Avatar>
  }

  const genPopoverContent = () => {
    const loadingContent = (
      <dl>
        <div className={classes.definitionsItem}>
          <dd>loading...</dd>
        </div>
      </dl>
    )

    if (!user) {
      return loadingContent
    }
    return (
      <dl>
        <div className={classes.definitionsItem}>
          <OrganizationsMenu redirectOnChange />
        </div>
        <MenuList>{orgSettings && <MenuItem onClick={toggleDemoCluster}>{showOrHideDemoCluster} demo cluster</MenuItem>}</MenuList>
        <Divider />
        <div className={classes.definitionsItem}>
          <dt>Account:</dt>
          <dd>{user!.name}</dd>
          <dd>{"(" + user!.email + ")"}</dd>
        </div>
        <Divider />
        <MenuList>
          <MenuItem onClick={goToProfile}>See full profile</MenuItem>
          <MenuItem onClick={handleLogout}>Sign out</MenuItem>
        </MenuList>
      </dl>
    )
  }

  return (
    <div>
      {isLoggedIn ? (
        <>
          <div className={`${classes.avatar} ${anchorEl ? classes.selectedAvatar : ""}`} onClick={handleClick}>
            <IconButton id="user-menu" aria-controls="user-menu" aria-haspopup="true" className={classes.logout}>
              {genAvatar()}
            </IconButton>
            <div className={classes.profileLabel}>
              <Typography>Settings</Typography>
              <ChevronRight />
            </div>
          </div>

          <Popover
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            keepMounted
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
          >
            {genPopoverContent()}
          </Popover>
        </>
      ) : (
        <div className={classes.login} data-testid="login-button">
          <ToolbarNavItem id="login" onClick={handleLogin} Icon={LoginIcon} text="Login" />
        </div>
      )}
    </div>
  )
}
