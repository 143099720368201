import React, { useState } from "react"
import { Typography, createStyles, makeStyles, Theme, Button, Dialog, Chip } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"

import { Member } from "../types"
import { UserIcon } from "@components/Icons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      transition: "all 0.3s ease-in-out",
      borderLeft: `3px solid transparent`,
      fontSize: "0.875rem",
      "&:hover": {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.default,
        boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
      },
      "& td": {
        position: "relative",
        padding: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        verticalAlign: "middle",
        wordBreak: "break-word",
        [theme.breakpoints.down(1024)]: {
          whiteSpace: "nowrap",
        },
      },
    },
    email: {
      fontWeight: 300,
      color: theme.palette.text.secondary,
    },
    stats: {
      color: theme.palette.text.secondary,
    },
    role: {
      fontSize: "0.875rem",
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: 1.6,
      [theme.breakpoints.down(700)]: {
        marginBottom: theme.spacing(2),
      },
    },
    icon: {
      display: "inline-flex",
      marginRight: theme.spacing(1.25),
      background: theme.palette.primary.main,
      width: 30,
      height: 30,
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      "& svg path": {
        stroke: "#fff",
      },
    },
    button: {
      background: theme.palette.error.main,
    },
    dialog: {
      padding: theme.spacing(4),
      position: "relative",
    },
    closeBtn: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    dialogBtn: {
      marginTop: theme.spacing(2),
      background: theme.palette.error.main,
    },
    currentUserText: {
      fontWeight: 300,
      color: theme.palette.text.secondary,
    },
  }),
)

type Props = {
  member: Member
  onDelete: (userID: string) => void
  isCurrentUser?: boolean
  isUserAdmin?: boolean
}

export function MemberRow({ member: { name, email, roles, user_id }, onDelete, isCurrentUser, isUserAdmin }: Props) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }
  const handleDelete = () => {
    onDelete(isCurrentUser ? "current" : user_id)
    setIsOpen(false)
  }

  const generateRole = () => {
    if (roles.includes("admin")) {
      return <Chip variant="outlined" label="admin" size="small" />
    }
    return null
  }

  const hasNoName = name === email || name === `<Error: Not Found>`
  return (
    <>
      <tr className={classes.row} data-tut="member-row">
        <td>
          <div className={classes.icon}>
            <UserIcon />
          </div>
        </td>
        <td>
          <Typography variant="body1" display="inline" data-testid="member-row-title">
            {hasNoName ? email : name}{" "}
          </Typography>
          {isCurrentUser && (
            <Typography variant="body1" display="inline" className={classes.currentUserText}>
              you
            </Typography>
          )}
        </td>
        <td>
          <span className={classes.email}> {email}</span>
        </td>
        <td className={classes.stats}>
          <span className={classes.role}>{generateRole()}</span>
        </td>
        <td style={{ textAlign: "right" }}>
          {(isUserAdmin || isCurrentUser) && (
            <Button variant="contained" color="primary" className={classes.button} onClick={() => setIsOpen(true)} size="small">
              Remove
            </Button>
          )}
        </td>
      </tr>

      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <div className={classes.dialog}>
          <CancelIcon className={classes.closeBtn} onClick={handleClose} data-testid="alerts-close-button" />
          <Typography gutterBottom variant="h5">
            Are you sure?
          </Typography>
          <Typography>You are about to delete user {name}.</Typography>
          {isCurrentUser && <Typography>If you delete yourself you will no longer be able to access this organization</Typography>}
          <Button variant="contained" color="primary" className={classes.dialogBtn} onClick={handleDelete}>
            Yes, remove
          </Button>
        </div>
      </Dialog>
    </>
  )
}
