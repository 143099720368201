export type Feature = {
  name: string
  description?: string
  tier: string
  documentationURL?: string
}

export type Section = {
  title: string
  features: Array<Feature>
}

const TIER_FREE = "Free"
const TIER_ENTERPRISE = "Enterprise"
const TIER_ENTERPRISE_PLUS = "Enterprise+"

const FEATURES: Array<Feature> = [
  // Free tier features
  {
    tier: TIER_FREE,
    name: "Single cluster dashboard",
    description: "Web-based interface for monitoring the health and status of cert-manager and viewing managed/unmanaged certificates.",
  },

  // Enterprise tier features
  {
    tier: TIER_ENTERPRISE,
    name: "Multi-cluster dashboard",
    description: "Visibility and proactive security posture analysis across multiple clusters",
  },
  {
    tier: TIER_ENTERPRISE,
    name: "Signed and verifiable builds",
    description: "Access to signed builds of cert-manager direct from Jetstack.",
  },
  { tier: TIER_ENTERPRISE, name: "Untrusted CA detection", description: "Detection of CAs that are not allowed in your organisation." },

  // Enterprise plus tier features
  { tier: TIER_ENTERPRISE_PLUS, name: "FIPS compliant builds", description: "Access to FIPS 140-2 compliant builds of cert-manager." },
  {
    tier: TIER_ENTERPRISE_PLUS,
    name: "Enhanced Venafi TPP integration",
    description:
      "Integration with Venafi TPP that includes in-cluster discovery and reporting of certificates, as well as secure token-based authentication.",
  },
  {
    tier: TIER_ENTERPRISE_PLUS,
    name: "Out of cluster signing with TPP and Vault",
    description: "Sign certificates with cert-manager outside of clusters to protect sensitive CA key material.",
  },
  {
    tier: TIER_ENTERPRISE_PLUS,
    name: "Policy engine integration",
    description: "Integration with Venafi TPP and OPA policy engines for in-cluster policy enforcement.",
  },
]

export const sectionsForTier = (tier: string): Array<Section> => {
  if (!tier) return []

  const sections: Array<Section> = []
  const yourTierTitle = "Features available at your tier"
  const upgradeTitle = "Upgrade to gain access to these features"

  if (tier === TIER_FREE) {
    sections.push({
      title: yourTierTitle,
      features: FEATURES.filter(feature => feature.tier === TIER_FREE),
    })

    sections.push({
      title: upgradeTitle,
      features: FEATURES.filter(feature => feature.tier === TIER_ENTERPRISE || feature.tier === TIER_ENTERPRISE_PLUS),
    })

    return sections
  }

  if (tier === TIER_ENTERPRISE) {
    sections.push({
      title: yourTierTitle,
      features: FEATURES.filter(feature => feature.tier === TIER_FREE || feature.tier === TIER_ENTERPRISE),
    })

    sections.push({
      title: upgradeTitle,
      features: FEATURES.filter(feature => feature.tier === TIER_ENTERPRISE_PLUS),
    })

    return sections
  }

  if (tier === TIER_ENTERPRISE_PLUS) {
    sections.push({
      title: yourTierTitle,
      features: FEATURES,
    })

    return sections
  }

  return sections
}
