import { handleError } from "./errorHandling"

export function extractGroupsFromJWT(jwt: string): string[] {
  let groups: string[] = []
  try {
    const value = getClaimFromJWT(jwt, "https://jetstack.io/claims/groups")
    if (value) {
      groups = value as string[]
    }
  } catch (error) {
    // it for some reason the token cannot be decoded, just print the error and assume
    // it constains no groups.
    handleError(error)
  }
  return groups
}

export function extractEmailVerifiedFromJWT(jwt: string): boolean {
  let verified = false
  try {
    const value = getClaimFromJWT(jwt, "https://jetstack.io/claims/email_verified")
    if (value) {
      verified = value as boolean
    }
  } catch (error) {
    // it for some reason the token cannot be decoded, assume email not verified
    handleError(error)
  }
  return verified
}

// helpers

// claimValue groups all the possible types for the claims in the functions of this file. When adding more claims, make sure you add new types as needed.
type claimValue = string[] | boolean | undefined

function getClaimFromJWT(jwt: string, claimKey: string): claimValue {
  // jwt is a list of base64 tokens separated by '.'
  const tokens = jwt.split(".")
  let result: claimValue = undefined
  const BreakException = {}
  try {
    tokens.forEach(tokenBase64 => {
      const token = JSON.parse(atob(tokenBase64))
      const gotClaim = token[claimKey]
      if (gotClaim !== undefined) {
        result = gotClaim
        throw BreakException
      }
    })
  } catch (error) {
    if (error !== BreakException) {
      handleError(error)
      throw error
    }
  }
  return result
}
