import React, { Dispatch, SetStateAction, useState } from "react"
import { Button, Typography, createStyles, makeStyles, RadioGroup, FormControlLabel, Radio, FormControl } from "@material-ui/core"
import { Snippet } from "@components/Snippet"

export const INSTALL_CLI = "INSTALL_CLI"

type InstallCLIProps = {
  currentStep: string
  setStep: Dispatch<SetStateAction<number>>
}

const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      pointerEvents: "none",
      opacity: 0.6,
    },
  }),
)

export const InstallCLI = ({ setStep, currentStep }: InstallCLIProps) => {
  const isCurrentStep = currentStep === INSTALL_CLI
  const classes = useStyles()

  const [operatingSystem, setOperatingSystem] = useState("unix")

  return (
    <div className={!isCurrentStep ? classes.disabled : ""}>
      <Typography variant="h5" gutterBottom>
        Step 1. Install the CLI
      </Typography>

      {isCurrentStep && (
        <div>
          <Typography color="textSecondary" gutterBottom>
            Before anything you need to install the CLI tool for TLS Protect for Kubernetes, <code>jsctl</code>. If you are already familiar
            with&ensp;
            <code>jsctl</code> then you can skip this step by clicking 'Next'.
          </Typography>

          <FormControl>
            <RadioGroup
              defaultValue={operatingSystem}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOperatingSystem((event.target as HTMLInputElement).value)
              }}
            >
              <FormControlLabel value="unix" control={<Radio />} label="Linux, macOS, or Windows Subsystem for Linux (WSL)" />
              <FormControlLabel value="windows" control={<Radio />} label="Microsoft Windows" />
            </RadioGroup>
          </FormControl>

          <br />
          {operatingSystem === "unix" ? (
            <div>
              <Typography color="textSecondary" gutterBottom>
                Run the following commands to download and install the latest version of <code>jsctl</code> for your operating system.
                Alternatively, you can visit the{" "}
                <a target="_blank" rel="noopener noreferrer" href={`https://github.com/jetstack/jsctl/releases/latest`}>
                  GitHub releases page
                </a>
                &ensp; and perform the installation yourself.
              </Typography>
              <Snippet text={`curl -fsSL https://raw.githubusercontent.com/jetstack/jsctl/main/install.sh | sh`} language="shell" />
            </div>
          ) : (
            <div>
              <Typography color="textSecondary" gutterBottom>
                Run the following commands to download and install the latest version of <code>jsctl</code> for your operating system.
                Alternatively, you can visit the{" "}
                <a target="_blank" rel="noopener noreferrer" href={`https://github.com/jetstack/jsctl/releases/latest`}>
                  GitHub releases page
                </a>
                &ensp; and perform the installation yourself.
              </Typography>
              <Snippet text={`irm https://raw.githubusercontent.com/jetstack/jsctl/main/install.ps1 | iex`} language="shell" />
            </div>
          )}

          <Button variant="contained" color="primary" onClick={() => setStep(step => step + 1)}>
            Next
          </Button>
        </div>
      )}
    </div>
  )
}
