import { createStyles, makeStyles, Theme } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summariesWrapper: {
      padding: theme.spacing(2, 0),
      display: "flex",
    },
    pageSection: {
      marginTop: theme.spacing(2),
    },
    summariesGrid: {
      width: "100%",
    },
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down(480)]: {
        padding: theme.spacing(3),
      },
    },
    error: {
      marginTop: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    tableTitles: {
      width: "100%",
      padding: theme.spacing(0, 2, 2, 2),
      "& span": {
        [theme.breakpoints.down(768)]: {
          display: "none",
        },
      },
    },
    status: {
      float: "right",
      minWidth: "252px",
      textAlign: "right",
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
    CMstatus: {
      float: "right",
      [theme.breakpoints.down(1100)]: {
        display: "none",
      },
    },
    errorContainer: {
      background: theme.palette.grey[200],
      borderRadius: 5,
      display: "inline-block",
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(2),
    },
    clusterLimit: {
      display: "block",
      width: "22rem",
      textAlign: "center",
      marginLeft: "50%",
      marginTop: theme.spacing(4),
      transform: "translate(-50%, 0)",
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
    filterContainer: {
      marginBottom: theme.spacing(4),
    },
    paginationContainer: {
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
  }),
)
