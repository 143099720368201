import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Button, CircularProgress, Paper, Typography } from "@material-ui/core"

import { ChevronRight, CheckMarkCircleIcon } from "@components/Icons"
import { SeverityTag } from "@components/SeverityTag"
import { OverviewTitle } from "../OverviewTitle"
import { OverviewSection } from "../types"
import { useStyles } from "./useStyles"

type OverviewAccordionProps = {
  data: OverviewSection
  seeAllUrl?: string
  isLoading?: boolean
}

function OverviewAccordion({ data, seeAllUrl, isLoading }: OverviewAccordionProps) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const isClickable = data.featured && data.featured.items.length > 0

  const onClick = () => {
    isClickable && setIsOpen(!isOpen)
  }

  return (
    <div className={classes.root}>
      <Paper className={`${classes.collapsible} ${isClickable ? classes.clickable : ""}`} elevation={2} onClick={onClick}>
        {data.value ? (
          <>
            <OverviewTitle value={data.value} title={data.title} isNegative={data.withIssues} badge={data.badge} variant="small" />
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              isClickable && (
                <ChevronRight className={`${classes.icon} ${isOpen ? classes.collapsed : ""}`} data-testid="overview-accordion-chevron" />
              )
            )}
          </>
        ) : (
          <div className={classes.allOk}>
            <CheckMarkCircleIcon />
            <Typography variant="h2" color="textPrimary">
              {data.allOkMessage}
            </Typography>
          </div>
        )}
      </Paper>

      {isOpen && data.featured && (
        <div className={classes.collapsibleContent}>
          <div className={classes.tableContent}>
            {data.featured.items.map(({ name, tags, url, controller }, index) => (
              <a key={`${name}-${index}`} className={`${classes.row} ${url ? classes.rowClickable : ""}`} href={url}>
                <Typography color="textPrimary">{name}</Typography>
                {controller && (
                  <Typography color="textPrimary">
                    <span className={classes.error}>{controller?.version}</span> {"→"}{" "}
                    <span className={classes.success}>{controller?.latest_available_version}</span>
                  </Typography>
                )}
                {tags && tags.length > 0 && (
                  <div className={classes.tags}>
                    {tags.map((tag, tagIndex) => (
                      <SeverityTag label={tag.label} severity={tag.severity} key={tag.label + tagIndex} />
                    ))}
                  </div>
                )}
              </a>
            ))}
          </div>

          {data.value > data.featured.items.length && data.featured.items.length === 5 && seeAllUrl && (
            <>
              <Typography variant="body2" className={classes.more}>
                ... and {data.value - data.featured.items.length} more
              </Typography>
              <div className={classes.buttonArea}>
                <Link to={seeAllUrl}>
                  <Button variant="contained" color="primary" size="small">
                    See all
                  </Button>
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export { OverviewAccordion }
