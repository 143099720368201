import { AppConfigActions, FETCH_APP_CONFIG, FETCH_APP_CONFIG_SUCCESS, FETCH_APP_CONFIG_FAILED } from "@actions/appConfig"
import { AppConfig } from "@lib/appConfig"

export type AppConfigState = {
  isLoading: boolean
  appConfig: AppConfig | null
  errorMessage: string | null
}

const initialState = {
  isLoading: false,
  appConfig: null,
  errorMessage: null,
}

export function appConfigReducer(state: AppConfigState = initialState, action: AppConfigActions): AppConfigState {
  switch (action.type) {
    case FETCH_APP_CONFIG: {
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      }
    }

    case FETCH_APP_CONFIG_SUCCESS: {
      return {
        isLoading: false,
        appConfig: action.appConfig,
        errorMessage: null,
      }
    }

    case FETCH_APP_CONFIG_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      }
    }

    default:
      return state
  }
}
