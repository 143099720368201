import { useState, useMemo } from "react"
import { IssuerSummaryResponseData } from "@lib/certManagerIssuers"
import { FilterProps } from "@components/FiltersBar"
import { FilterData } from "@selectors/filters"
import { getFilteredIssuers, getIssuerFilterOptions } from "./helpers"

type UserIssuerFilters = {
  onFilter: (filter: FilterProps) => void
  filterOptions: FilterData[]
  filteredIssuers: IssuerSummaryResponseData[]
  filtersState: FilterProps
}

export const useIssuerFilters = (issuers: IssuerSummaryResponseData[] | undefined): UserIssuerFilters => {
  const [filtersState, setFiltersState] = useState<FilterProps>({})

  const onFilter = (filter: FilterProps) => {
    setFiltersState(filter)
  }

  const filterOptions = useMemo(() => getIssuerFilterOptions(issuers), [issuers])

  const filteredIssuers = useMemo(() => getFilteredIssuers(issuers, filtersState), [issuers, filtersState])

  return {
    onFilter,
    filterOptions,
    filteredIssuers,
    filtersState,
  }
}
