import { spawn } from "redux-saga/effects"

import { clustersSaga } from "./clusters"
import { authSaga } from "./auth"
import { certInventorySaga } from "./certInventory"
import { receiverSaga } from "./receivers"
import { settingsSaga } from "./settings"
import { localStorageSaga } from "./localStorage"
import { appConfigSaga } from "./appConfig"

export function* rootSaga() {
  yield spawn(clustersSaga)
  yield spawn(authSaga)
  yield spawn(certInventorySaga)
  yield spawn(receiverSaga)
  yield spawn(settingsSaga)
  yield spawn(localStorageSaga)
  yield spawn(appConfigSaga)
}
