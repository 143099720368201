import { takeEvery, put, call, select, take } from "redux-saga/effects"
import axios, { AxiosRequestHeaders } from "axios"
import { rudderanalytics as rudderstack } from "@utils/rudderstack"

import { FETCH_CLUSTERS, FetchClustersAction, fetchClustersSuccess, fetchClustersFailed } from "@actions/clusters"

import { accessTokenSelector } from "@selectors/auth"
import { generateErrorMessage, handleError } from "@utils/errorHandling"
import { DemoOptions, selectDemoParams, genDemoParamsFromOptions } from "@utils/demo"
import { settingsSelector } from "@selectors/settings"
import { fetchSettings, FETCH_SETTINGS_SUCCEEDED, UPDATE_SETTINGS_SUCCEEDED } from "@actions/settings"
import { noopAlreadyHappening } from "@actions/noop"
import { addAuth } from "@lib/auth"

function getClusters(accessToken: string, orgID: string, demoOpts: DemoOptions) {
  return axios.get(
    `/api/v1/org/${orgID}/clusters`,
    addAuth(accessToken, {
      params: selectDemoParams(new URLSearchParams(window.location.search), genDemoParamsFromOptions(demoOpts)),
      headers: {} as AxiosRequestHeaders,
    }),
  )
}

function* fetchClusters(action: FetchClustersAction) {
  // fetchClusters is the common action that happens everytime selected org changes
  // and also on first load, so this is the right place to identify the org.
  rudderstack.reset()
  rudderstack.identify(action.orgID)
  try {
    let orgSettings: { isLoading: boolean; settings: Settings } = yield select(settingsSelector())
    // fetch settings if not already fetched
    if (!orgSettings.settings) {
      if (orgSettings.isLoading) {
        yield put(noopAlreadyHappening(action))
      } else {
        yield put(fetchSettings(action.orgID))
      }
      yield take(FETCH_SETTINGS_SUCCEEDED)
      orgSettings = yield select(settingsSelector())
    }

    const token: string = yield select(accessTokenSelector)
    const { data } = yield call(getClusters, token, action.orgID, { includeDemo: orgSettings?.settings?.show_demo_cluster })
    yield put(fetchClustersSuccess(action.orgID, data as ClusterOverview[]))
  } catch (error) {
    handleError(error, `Unable to fetch clusters`)
    yield put(fetchClustersFailed(action.orgID, `Unable to fetch clusters - ${generateErrorMessage(error)}`))
  }
}

export function* clustersSaga() {
  yield takeEvery(FETCH_CLUSTERS, fetchClusters)
  yield takeEvery(UPDATE_SETTINGS_SUCCEEDED, fetchClusters)
}
