import React from "react"
import { createStyles, Theme, makeStyles, Grid, Divider, Typography, Paper } from "@material-ui/core"
import { Issuer } from "@lib/certInventory/resources"
import { StatusIcon } from "@components/StatusIcon"
import { IssuerInfo } from "./IssuerInfo"
import { Health } from "../Health"
import { CloseButton } from "../CloseButton"
import { Overlay } from "../Overlay"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0.5),
      position: "relative",
    },
    panel: {
      minHeight: "100vh",
      width: 400,
      height: "100%",
      overflowX: "auto",
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 100,
      [theme.breakpoints.down(480)]: {
        width: "100vw",
      },
    },
    overlay: {
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 99,
      background: "rgba(24,20,32,0.2)",
      width: "100%",
      height: "100%",
    },
    close: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    loading: {
      marginTop: theme.spacing(10),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    section: {
      margin: theme.spacing(3, 2),
    },
    clusterTitle: {
      fontSize: "1rem",
      fontWeight: 300,
      borderRadius: "0.3rem",
      padding: theme.spacing(1, 0),
    },
    marginAround: {
      margin: theme.spacing(2),
    },
    alert: {
      display: "flex",
      marginTop: theme.spacing(2),
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    subTitleContainer: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    clusterLink: {
      fontWeight: 700,
      color: theme.palette.primary.main,
      "&:hover": {
        textDecoration: "none",
      },
    },
  }),
)

type IssuerDetailsProps = {
  issuer: Issuer
  onClose: () => void
  showClusterName?: boolean
}

export function IssuerDetails({ issuer, onClose, showClusterName }: IssuerDetailsProps) {
  const classes = useStyles()
  const status = issuer.status.type
  const namespace = issuer.resource.metadata?.namespace === "" ? undefined : issuer.resource.metadata?.namespace
  const subTitleText = namespace ? `${namespace} / ${issuer.resource.metadata?.name}` : issuer.resource.metadata?.name

  return (
    <Overlay onClose={onClose}>
      <Paper className={classes.panel} elevation={3} data-tut="details-panel">
        <div className={classes.root}>
          <CloseButton className={classes.close} onClick={onClose} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h4">
                  Issuer details
                </Typography>
              </Grid>
            </Grid>
            <Typography color="textPrimary" variant="body1" className={classes.subTitleContainer}>
              <StatusIcon status={status} /> {subTitleText}
            </Typography>
            {showClusterName && (
              <Typography gutterBottom variant="h6" className={classes.clusterTitle}>
                Cluster: <strong>{issuer.clusterId}</strong>
              </Typography>
            )}
          </div>
          <Divider variant="middle" />
          <Health resource={issuer} />
          <Divider variant="middle" />
          <div className={classes.marginAround}>
            <Typography gutterBottom variant="h5">
              Issuer Info
            </Typography>
            <IssuerInfo issuer={issuer} />
          </div>
        </div>
      </Paper>
    </Overlay>
  )
}
