import React from "react"

type Props = {
  [x: string]: unknown
}

export function PagerDutyIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <title>icon-pd-green</title>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <rect x="0" y="0" width="24" height="24" fill="rgb(1.568627%,67.45098%,21.960784%)" fillOpacity="1" stroke="none" />
          <path
            stroke="none"
            fillRule="nonzero"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 6.761719 15.839844 L 9.160156 15.839844 L 9.160156 20.238281 L 6.761719 20.238281 Z M 6.761719 15.839844 "
          />

          <path
            stroke="none"
            fillRule="nonzero"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 15.859375 4.566406 C 14.566406 3.875 13.660156 3.761719 11.542969 3.761719 L 6.761719 3.761719 L 6.761719 13.761719 L 11.519531 13.761719 C 13.410156 13.761719 14.820312 13.644531 16.066406 12.816406 C 17.425781 11.917969 18.128906 10.417969 18.128906 8.691406 C 18.125 6.824219 17.261719 5.328125 15.859375 4.566406 Z M 12.054688 11.640625 L 9.160156 11.640625 L 9.160156 5.878906 L 11.890625 5.855469 C 14.378906 5.835938 15.625 6.710938 15.625 8.691406 C 15.625 10.832031 14.078125 11.640625 12.054688 11.640625 Z M 12.054688 11.640625 "
          />
        </g>
      </g>
    </svg>
  )
}
