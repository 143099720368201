import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  [x: string]: unknown
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stroke: {
      stroke: theme.palette.grey[600],
    },
    fill: {
      fill: theme.palette.grey[600],
    },
  }),
)

export function QuarterPieChartIcon(props: Props): ReactElement {
  const classes = useStyles()
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path className={classes.stroke} d="M12 21a9 9 0 100-18 9 9 0 000 18z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <mask id="a" maskUnits="userSpaceOnUse" x="6" y="6" width="12" height="12">
        <circle cx="12" cy="12" r="6" fill="white" />
      </mask>
      <g mask="url(#a)">
        <path d="M18.857 5.143H12V12H5.143v6.857h13.714V5.143z" className={classes.fill} />
      </g>
    </svg>
  )
}
