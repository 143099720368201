import React, { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"
import { Theme, Typography, Slider, createStyles, makeStyles } from "@material-ui/core"
import { DiscoveredCertificatesFilterState } from "@lib/discoveredCertificates"
import { calculateLabel } from "@lib/discoveredCertificates/calculateHours"

export type FilterProps = { [x: string]: string }

type Props = {
  filterState: DiscoveredCertificatesFilterState
}

const marks = [
  {
    value: 20,
    label: "24h",
  },
  {
    value: 40,
    label: "3d",
  },
  {
    value: 60,
    label: "1w",
  },
  {
    value: 80,
    label: "1m",
  },
  {
    value: 100,
    label: "3m",
  },
]

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    formControl: {
      minWidth: 160,
      margin: theme.spacing(1, 2, 1, 0),
      position: "relative",
    },
    slider: {
      display: "flex",
      flex: "0 0 400px",
      flexDirection: "column",
      margin: theme.spacing(1, 0),
    },
    valueLabel: {
      "& *": {
        backgroundColor: "hsl(14, 100%, 60%)",
        color: "white",
      },
    },
  })
})

export const ExpirySlider = ({ filterState }: Props) => {
  const classes = useStyles()
  const [_expiryIn, _setExpiryIn] = useState(filterState.expiryIn)

  const handleChange = (newValue: number | number[]) => {
    filterState.setExpiryIn(newValue as number)
  }

  const handleSliderChange = useDebouncedCallback(handleChange, 1000)

  const labelFormat = (val: number) => {
    return calculateLabel(val)
  }

  useEffect(() => {
    if (_expiryIn !== filterState.expiryIn) {
      _setExpiryIn(filterState.expiryIn)
    }
  }, [filterState.expiryIn])

  return (
    <div className={classes.slider}>
      <Typography>Filter results by expiry time</Typography>
      <Slider
        value={_expiryIn || 24}
        classes={{ valueLabel: classes.valueLabel }}
        aria-label="Default"
        valueLabelDisplay="auto"
        valueLabelFormat={labelFormat}
        onChange={(_, newValue) => {
          _setExpiryIn(newValue as number)
          handleSliderChange(newValue as number)
        }}
        marks={marks}
        max={100}
      />
    </div>
  )
}
