import React from "react"
import columnify from "columnify"
import { Typography } from "@material-ui/core"

import { Snippet } from "@components/Snippet"

type VerifyIssuerInitializedProps = {
  name: string
  label: string
  namespace: string
  kind: string
}

export const VerifyIssuerInitialized = ({ name, label, namespace, kind }: VerifyIssuerInitializedProps) => {
  return (
    <div>
      <Typography gutterBottom>
        Verify that the <strong>Issuer</strong> has been initialized correctly.
      </Typography>
      <Snippet
        language="shell"
        text={`kubectl get ${kind} ${name} --namespace='${namespace}' -o wide
${outputToColumns(name, label)}`} //do not align this line
        copyText={`kubectl get ${kind} ${name} --namespace='${namespace}' -o wide`}
      />
    </div>
  )
}

const outputToColumns = (name: string, issuer: string): string => {
  const output = [
    {
      NAME: name,
      READY: "True",
      STATUS: `${issuer} issuer started`,
      AGE: "2m",
    },
  ]
  return columnify(output, { columnSplitter: "   " })
}
