import React from "react"
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    step: {
      margin: theme.spacing(2, 0),
    },
    stepTitle: {
      display: "grid",
      gridTemplateColumns: "2rem 1fr",
      alignItems: "center",
      gridGap: "1rem",
    },
    stepNumber: {
      background: theme.palette.primary.main,
      width: "2rem",
      height: "2rem",
      color: "white",
      display: "flex",
      alignItems: "center",
      borderRadius: 50,
      justifyContent: "center",
      fontSize: "1rem",
      fontWeight: 700,
    },
    stepContent: {
      margin: theme.spacing(2, 0, 2, 6),
    },
  }),
)

type StepProps = {
  number: number
  title: string
  children?: React.ReactNode
}

export const Step = ({ children, number, title }: StepProps) => {
  const classes = useStyles()
  return (
    <div className={classes.step}>
      <div className={classes.stepTitle}>
        <div className={classes.stepNumber}>{number}</div>
        <Typography>{title}</Typography>
      </div>
      {children && <div className={classes.stepContent}>{children}</div>}
    </div>
  )
}
