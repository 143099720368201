import { K8SGenericResource } from "../types"

// TODO: figure out how to generate these types from the openapi definition.
// based on https://cert-manager.io/docs/reference/api-docs

// Certificate

export type Certificate = K8SGenericResource & {
  spec: CertificateSpec
  status: CertificateStatus
  // TODO: add certificate `spec`
}

export type CertificateStatus = {
  conditions: CertificateCondition[]
  renewalTime: string
  // TODO: add other parts of the status
}

export type CertificateSpec = {
  issuerRef: IssuerRef
  // TODO: add other parts of the spec
}

export type IssuerRef = {
  // https://github.com/jetstack/cert-manager/blob/528305b5edd3e582a0996d20556a33d5b795564a/pkg/apis/meta/v1/types.go#L52
  group?: string
  kind?: string
  name: string
}

export type CertificateCondition = ComponentCondition & {
  // Type is the type of the condition.
  type: "Ready" | "Issuing"
}

// CertificateRequest

export type CertificateRequest = K8SGenericResource & {
  spec: CertificateRequestSpec
  status: CertificateRequestStatus
}

export type CertificateRequestStatus = {
  conditions: CertificateRequestCondition[]
  certificate?: string
  // TODO: here we could add the x509 certificate and the CA
}

export type CertificateRequestCondition = ComponentCondition & {
  // Type is the type of the condition.
  type: "Ready" | "InvalidRequest" | "Approved" | "Denied"
}

export type CertificateRequestSpec = {
  issuerRef: IssuerRef
  // TODO: add other parts of the spec
}

// Issuer & ClusterIssuer

export type Issuer = K8SGenericResource & {
  spec?: IssuerSpec
  status: IssuerStatus
}

export type ClusterIssuer = K8SGenericResource & {
  spec?: IssuerSpec
  status: IssuerStatus
}

export type IssuerStatus = {
  conditions?: IssuerCondition[]
  acme?: ACMEIssuerStatus
}

export type IssuerSpec = {
  acme?: ACMEIssuerSpec
  vault?: VaultIssuerSpec
  venafi?: VenafiIssuerSpec
  ca?: CAIssuerSpec
  selfSigned?: SelfSignedIssuerSpec
}

export type IssuerCondition = ComponentCondition & {
  type: "Ready"
}

export type SecretKeySelector = {
  name: string
  key?: string
}

export type ACMEIssuerStatus = {
  // URI is the unique account identifier, which can also be used to retrieve account details from the CA.
  uri?: string
  // LastRegisteredEmail is the email associated with the latest registered ACME account, in order to track changes made to registered account associated with the Issuer
  lastRegisteredEmail?: string
}

export type ACMEIssuerSpec = {
  email?: string
  server: string
  preferredChain: string
  privateKeySecretRef: SecretKeySelector
}

export type VaultIssuerSpec = {
  // Name of the vault namespace. Namespaces is a set of features within Vault Enterprise that allows Vault environments to support Secure Multi-tenancy. e.g: "ns1"
  namespace?: string
  // Server is the connection address for the Vault server, e.g: "https://vault.example.com:8200".
  server: string
  // Path is the mount path of the Vault PKI backend's `sign` endpoint, e.g:
  path: string
}

export type VenafiIssuerSpec = {
  // Zone is the Venafi Policy Zone to use for this issuer.
  zone: string
  tpp?: VenafiTPP
  cloud?: VenafiCloud
}

export type VenafiTPP = {
  url: string
  credentialsRef: SecretKeySelector
}

export type VenafiCloud = {
  url?: string
  apiTokenSecretRef: SecretKeySelector
}

export type CAIssuerSpec = {
  secretName: string
}

export type SelfSignedIssuerSpec = Record<string, never>

// utils

export type ComponentCondition = {
  // Status is the status of the condition.
  status: "True" | "False" | "Unknown"
  // Reason is a brief machine readable explanation for the condition’s last transition.
  reason: string
  // Message is a human readable description of the details of the last transition, complementing reason.
  message: string
  // LastTransitionTime is the timestamp corresponding to the last status change of this condition.
  lastTransitionTime: string
}

// Ingress

export type Ingress = K8SGenericResource & {
  spec?: IngressSpec
}

// https://pkg.go.dev/k8s.io/api/networking/v1#IngressSpec
type IngressSpec = {
  rules?: {
    host: string
    http: {
      paths: IngressPaths[]
    }
  }[]
  tls?: {
    secretName: string
    hosts: string[]
  }[]
  defaultBackend?: DefaultBackend
}

type DefaultBackend = {
  service?: {
    name: string
    port: string
  }
  resource?: TypedLocalObjectReference
}

type IngressPaths = {
  path: string
  backend: {
    serviceName: string
    servicePort: string
  }
}

type TypedLocalObjectReference = {
  apiGroup?: string
  kind: string
  name: string
}

export enum Issuers {
  ClusterIssuer = "ClusterIssuer",
  Issuer = "Issuer",
}

export enum IssuerTypes {
  internal,
}
