import { AllCertsGroupedByIdentity } from "@lib/certInventory/resources"
import { collateAllResources } from "@utils/certificate"
import { AppState } from "../reducers"

export function certInventorySelector(state: AppState) {
  return state.certInventory.dataByCluster
}

export function allResourcesByClusterSelector(cluster: string | undefined, allResourcesView = false) {
  return (state: AppState) => {
    const emptyResources = {
      certManagerCertificates: [],
      nonCertManagerCertificates: [],
      ephemeralCertificates: [],
      certificateRequests: [],
      issuers: [],
      ingresses: [],
    }

    if (!cluster || allResourcesView) return emptyResources
    const allResources = state.certInventory.dataByCluster[cluster]?.calculatedResources
    if (!allResources) return emptyResources
    return { ...allResources }
  }
}

export function allResourcesSelector() {
  return (state: AppState) => {
    const emptyResources = {
      certManagerCertificates: [],
      nonCertManagerCertificates: [],
      ephemeralCertificates: [],
      certificateRequests: [],
      issuers: [],
      ingresses: [],
    }

    const allResources = collateAllResources(state.certInventory.dataByCluster)

    if (!allResources) return emptyResources
    return { ...allResources }
  }
}

export function certsGroupedByIdentity(cluster: string | undefined, allResourcesView = false) {
  return (state: AppState) => {
    const emptyCerts: AllCertsGroupedByIdentity = {
      filteredByIdentities: [],
      identities: {},
    }

    const certInventory = state.certInventory.dataByCluster
    let groupedCerts
    if (!cluster) {
      if (allResourcesView && certInventory) {
        const allGroupedResources: AllCertsGroupedByIdentity = {
          filteredByIdentities: [],
          identities: {},
        }
        Object.keys(certInventory).forEach(key => {
          if (certInventory[key].isLoading) return
          if (certInventory[key]?.allCertsGroupedByIdentity?.filteredByIdentities) {
            allGroupedResources.filteredByIdentities.push(...certInventory[key].allCertsGroupedByIdentity.filteredByIdentities)
          }
          allGroupedResources.identities = { ...allGroupedResources.identities, ...certInventory[key].allCertsGroupedByIdentity.identities }
        })
        groupedCerts = allGroupedResources
      } else {
        return emptyCerts
      }
    } else if (certInventory[cluster] && !certInventory[cluster].isLoading) {
      groupedCerts = certInventory[cluster].allCertsGroupedByIdentity
    }

    if (!groupedCerts) return emptyCerts
    return { ...groupedCerts }
  }
}
