import { createStyles, makeStyles, Theme } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "1.8rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    small: {
      fontSize: "1rem",
      "& strong": {
        fontSize: "1.8rem",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      "& span": {
        display: "flex",
        alignItems: "center",
      },
      "& strong": {
        marginRight: theme.spacing(1),
      },
    },
    isNegative: {
      color: theme.palette.error.main,
    },
  }),
)
