import { takeEvery, call, select, put } from "redux-saga/effects"
import axios from "axios"
import { AxiosError } from "axios"

import {
  CREATE_RECEIVER,
  FETCH_RECEIVERS,
  UPDATE_RECEIVER,
  CreateReceiverAction,
  createReceiverSuccess,
  createReceiverFailed,
  UpdateReceiverAction,
  updateReceiverSuccess,
  updateReceiverFailed,
  FetchReceiversAction,
  fetchReceiversSuccess,
  fetchReceiversFailed,
  DELETE_RECEIVER,
  deleteReceiverSuccess,
  deleteReceiverFailed,
  DeleteReceiverAction,
} from "../actions/receivers"

import { accessTokenSelector } from "../selectors/auth"
import { generateErrorMessage, handleError } from "@utils/errorHandling"
import { addAuth } from "@lib/auth"

function createReceiver(accessToken: string, receiver: Receiver) {
  const endpoint = receiver.cluster
    ? `/api/v1/org/${receiver.organization}/clusters/${receiver.cluster}/receivers`
    : `/api/v1/org/${receiver.org_name}/receivers`
  return axios.post(endpoint, [receiver], addAuth(accessToken)).catch((error: AxiosError) => {
    if (error.request) {
      handleError(error, "request error")
    } else {
      handleError(error, "axios error")
    }
  })
}
function* createReceiverSaga(action: CreateReceiverAction) {
  try {
    const token: string = yield select(accessTokenSelector)
    const { data } = yield call(createReceiver, token, action.receiver)

    if (data.length > 0) {
      yield put(createReceiverSuccess(action.orgID, data[0] as Receiver, action.clusterID))
    } else {
      yield put(createReceiverFailed(action.orgID, `Unable to create alert receiver - missing data in response`, action.clusterID))
    }
  } catch (error) {
    handleError(error, `Unable to create alert receiver`)
    yield put(createReceiverFailed(action.orgID, `Unable to create alert receiver - ${generateErrorMessage(error)}, action.clusterID,`))
  }
}

function updateReceiver(accessToken: string, receiver: Receiver) {
  const endpoint = receiver.cluster
    ? `/api/v1/org/${receiver.organization}/clusters/${receiver.cluster}/receivers/${receiver.id}`
    : `/api/v1/org/${receiver.org_name}/receivers/${receiver.id}`

  return axios.patch(endpoint, receiver, addAuth(accessToken)).catch((error: AxiosError) => {
    if (error.request) {
      handleError(error, "request error")
    } else {
      handleError(error, "axios error")
    }
  })
}
function* updateReceiverSaga(action: UpdateReceiverAction) {
  try {
    const token: string = yield select(accessTokenSelector)
    const { data } = yield call(updateReceiver, token, action.receiver)

    if (data as Receiver) {
      yield put(updateReceiverSuccess(action.orgID, data, action.clusterID))
    } else {
      yield put(updateReceiverFailed(action.orgID, `Unable to update alert receiver - missing data in response`, action.clusterID))
    }
  } catch (error) {
    handleError(error, `Unable to update alert receiver`)
    yield put(updateReceiverFailed(action.orgID, `Unable to update alert receiver - ${generateErrorMessage(error)}`, action.clusterID))
  }
}

function deleteReceiver(accessToken: string, actionData: DeleteReceiverAction) {
  const endpoint = actionData.clusterID
    ? `/api/v1/org/${actionData.orgID}/clusters/${actionData.clusterID}/receivers/${actionData.receiverID}`
    : `/api/v1/org/${actionData.orgID}/receivers/${actionData.receiverID}`

  return axios.delete(endpoint, addAuth(accessToken)).catch((error: AxiosError) => {
    if (error.request) {
      handleError(error, "request error")
    } else {
      handleError(error, "axios error")
    }
  })
}
function* deleteReceiverSaga(action: DeleteReceiverAction) {
  try {
    const token: string = yield select(accessTokenSelector)
    const { data } = yield call(deleteReceiver, token, action)

    if (data as Receiver) {
      yield put(deleteReceiverSuccess(action.orgID, data, action.clusterID))
    } else {
      yield put(deleteReceiverFailed(action.orgID, `Unable to delete alert receiver - missing data in response`, action.clusterID))
    }
  } catch (error) {
    handleError(error, `Unable to delete alert receiver`)
    yield put(deleteReceiverFailed(action.orgID, `Unable to delete alert receiver - ${generateErrorMessage(error)}`, action.clusterID))
  }
}

function fetchReceivers(accessToken: string, orgID: string, clusterID?: string) {
  const endpoint = clusterID ? `/api/v1/org/${orgID}/clusters/${clusterID}/receivers` : `/api/v1/org/${orgID}/receivers`
  const data = axios.get(endpoint, addAuth(accessToken)).catch((error: AxiosError) => {
    if (error.request) {
      handleError(error, "request error")
    } else {
      handleError(error, "axios error")
    }
  })

  return data
}
function* fetchReceiversSaga(action: FetchReceiversAction) {
  try {
    // @ts-expect-error: TODO: Fix this
    const token = yield select(accessTokenSelector)
    const { data } = yield call(fetchReceivers, token, action.orgID, action.clusterID)
    yield put(fetchReceiversSuccess(action.orgID, data, action.clusterID))
  } catch (error) {
    handleError(error, `Unable to fetch alert receivers`)
    yield put(fetchReceiversFailed(action.orgID, `Unable to fetch alert receivers - ${generateErrorMessage(error)}`, action.clusterID))
  }
}

export function* receiverSaga() {
  yield takeEvery(CREATE_RECEIVER, createReceiverSaga)
  yield takeEvery(FETCH_RECEIVERS, fetchReceiversSaga)
  yield takeEvery(UPDATE_RECEIVER, updateReceiverSaga)
  yield takeEvery(DELETE_RECEIVER, deleteReceiverSaga)
}
