export const NOOP_ALREADY_HAPPENING = "NOOP_ALREADY_HAPPENING"

export type NoopActions = NoopAlreadyHappeningAction

// NoopAlreadyHappeningAction indicates that an action has resulted in noop because there was a previous one already in progress.
export interface NoopAlreadyHappeningAction {
  type: typeof NOOP_ALREADY_HAPPENING
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const noopAlreadyHappening = (action: any): NoopAlreadyHappeningAction => ({
  type: NOOP_ALREADY_HAPPENING,
  action,
})
