import { createStyles, makeStyles, Theme } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0.6, 0, 0, 2),
      display: "inline-flex",
      alignItems: "center",
    },
    badge: {
      background: theme.palette.grey[600],
      fontSize: "0.87rem",
      padding: theme.spacing(0.3, 1.2, 0.6, 1.5),
      borderRadius: "5rem",
      fontWeight: 700,
      verticalAlign: "middle",
      color: theme.palette.grey[600],
    },
    positive: {
      background: "#E0FAEF",
      color: "#3D6253",
      "& path": {
        stroke: "#3D6253",
      },
    },
    negative: {
      background: "#FFEDEC",
      color: "#8D3335",
      "& path": {
        stroke: "#8D3335",
      },
    },
    reverse: {
      "& svg": {
        transform: "rotate(90deg)",
      },
    },
  }),
)
