import React, { PropsWithChildren } from "react"
import { createStyles, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() =>
  createStyles({
    overlay: {
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 99,
      background: "rgba(24,20,32,0.2)",
      width: "100%",
      height: "100%",
    },
  }),
)

export const Overlay = ({ children, onClose }: PropsWithChildren<{ onClose: () => void }>) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.overlay} onClick={onClose}></div>
      {children}
    </>
  )
}
