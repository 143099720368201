import {
  CMCertificate,
  NonCMCertificate,
  OwnerlessCertificateRequest,
  CertificateRequest,
  Issuer,
  Ingress,
  Route,
} from "@lib/certInventory/resources"
import { DataByCluster } from "@reducers/certInventory"

export function removeCamelCase(attrName: string): string {
  const result = attrName.replace(/([A-Z])/g, " $1")
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}

export const checkIsCertInventoryLoading = (certInventory: DataByCluster) =>
  Object.keys(certInventory).length < 1 || Object.keys(certInventory).some(key => certInventory[key].isLoading)

export type CollatedResources = {
  certManagerCertificates: CMCertificate[]
  nonCertManagerCertificates: NonCMCertificate[]
  ephemeralCertificates: OwnerlessCertificateRequest[]
  certificateRequests: CertificateRequest[]
  issuers: Issuer[]
  ingresses: Ingress[]
  routes: Route[]
}

export function collateAllResources(certInventory: DataByCluster): CollatedResources {
  const certInventoryKeys = Object.keys(certInventory)
  const certManagerCertificates: CMCertificate[] = []
  const nonCertManagerCertificates: NonCMCertificate[] = []
  const ephemeralCertificates: OwnerlessCertificateRequest[] = []
  const certificateRequests: CertificateRequest[] = []
  const issuers: Issuer[] = []
  const ingresses: Ingress[] = []
  const routes: Route[] = []
  if (!checkIsCertInventoryLoading(certInventory)) {
    certInventoryKeys.forEach(key => {
      const inventory = certInventory[key]?.calculatedResources
      if (inventory) {
        certManagerCertificates.push(...inventory.certManagerCertificates)
        nonCertManagerCertificates.push(...inventory.nonCertManagerCertificates)
        ephemeralCertificates.push(...inventory.ephemeralCertificates)
        certificateRequests.push(...inventory.certificateRequests)
        issuers.push(...inventory.issuers)
        ingresses.push(...inventory.ingresses)
        routes.push(...inventory.routes)
      }
    })
  }

  return { certManagerCertificates, nonCertManagerCertificates, ephemeralCertificates, certificateRequests, issuers, ingresses, routes }
}
