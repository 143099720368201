import React, { useState } from "react"
import { Typography, FormControlLabel, Switch, Grid, makeStyles, createStyles, Theme } from "@material-ui/core"
import { formatInTimeZone } from "date-fns-tz"
import * as x509 from "@fidm/x509"
import { pki } from "node-forge"

import { Certificate, OwnerlessCertificateRequest } from "@lib/certInventory/resources"
import { formatDate } from "@utils/date"
import { MessageContainer } from "@components/MessageContainer"
import { LinkIcon, Algorithm, KeyIcon, RewindIcon, QuarterPieChartIcon, CheckMarkCircleIcon, CloseCircleIcon } from "@components/Icons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginBottom: theme.spacing(2),
    },
    inner: {
      marginBottom: theme.spacing(1),
    },
    keySize: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  }),
)

type Props = {
  certificate?: Certificate | OwnerlessCertificateRequest
}

export function CertificateInfo({ certificate }: Props) {
  const classes = useStyles()
  const [timesUTC, setTimesUTC] = useState(true)

  if (!certificate) {
    return <Typography>No details were found.</Typography>
  }

  const decodedCerts = certificate.decode()
  const lastDecodedCert = decodedCerts.length === 0 ? undefined : decodedCerts[0]

  const handleChangeTimeFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimesUTC(event.target.checked)
  }

  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const renderDate = (dateLabel: string, date?: Date): string => {
    if (date) {
      if (timesUTC) {
        return formatInTimeZone(date, "UTC", "yyyy/MM/dd HH:mm:ss")
      }

      return formatDate(date)
    }
    return `Unable to determine the certificate ${dateLabel}`
  }

  // renderCaChain renders the issuer info for all certificates that were decoded
  const renderCaChain = (cert: Certificate | OwnerlessCertificateRequest) => {
    if (cert.issuers().length === 0) {
      return renderFailedParsing("No CA chain information was found")
    }
    return cert.issuers().map((issuer, index) => (
      <MessageContainer icon={<LinkIcon />} key={index}>
        <Typography color="textPrimary" variant="body2">
          {issuer}
        </Typography>
      </MessageContainer>
    ))
  }

  const renderSignature = (cert?: x509.Certificate) => {
    if (cert) {
      const algorithm: string = cert.signatureAlgorithm
      if (algorithm) {
        return (
          <MessageContainer icon={<Algorithm />}>
            Algorithm {"> "}
            <Typography color="textPrimary" variant="body2" display="inline">
              {algorithm}
            </Typography>
          </MessageContainer>
        )
      }
      return renderFailedParsing("Unable to parse the certificate's algorithm id")
    }
    return renderFailedParsing("Unable to parse the certificate's algorithm id")
  }

  const renderKeyInfo = (cert?: x509.Certificate) => {
    if (cert) {
      try {
        const { n } = pki.publicKeyFromPem(cert.publicKey.toPEM()) as pki.rsa.PublicKey
        if (n && n.bitLength) {
          const keySize = n.bitLength()
          const keyThreshold = 2048

          return (
            <>
              <Typography gutterBottom>Key Info</Typography>
              <div className={classes.inner}>
                <MessageContainer icon={<KeyIcon />}>
                  <div className={classes.keySize}>
                    <div>
                      Key Size {"> "}
                      <Typography color="textPrimary" variant="body2" display="inline">
                        {keySize}
                      </Typography>
                    </div>
                    {keySize >= keyThreshold ? <CheckMarkCircleIcon /> : <CloseCircleIcon />}
                  </div>
                </MessageContainer>
              </div>
            </>
          )
        }
      } catch (error) {
        return undefined
      }
    }
    return undefined
  }

  const renderFailedParsing = (message: string) => <MessageContainer>{message}</MessageContainer>
  const timeUtcText = timesUTC ? "Showing dates in UTC" : `Showing dates in local timezone (${localTimeZone})`

  const issueDateText = "issue date"
  const expiryDateText = "expiry date"

  return (
    <div>
      <div className={classes.section}>
        <Typography gutterBottom>CA Chain Info</Typography>
        <div className={classes.inner}>{renderCaChain(certificate)}</div>

        <Typography gutterBottom>Signature Info</Typography>
        <div className={classes.inner}>{renderSignature(lastDecodedCert)}</div>

        {renderKeyInfo(lastDecodedCert)}
      </div>
      <div className={classes.section}>
        <div className={classes.inner}>
          <Typography>Dates</Typography>

          <FormControlLabel
            control={<Switch checked={timesUTC} onChange={handleChangeTimeFormat} name="Switch timestamp format" color="primary" />}
            label={timeUtcText}
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MessageContainer icon={<RewindIcon />}>
              <Typography color="textPrimary" variant="body2">
                Not before
              </Typography>
              {renderDate(issueDateText, lastDecodedCert?.validFrom)}
            </MessageContainer>
          </Grid>
          <Grid item xs={6}>
            <MessageContainer icon={<QuarterPieChartIcon />}>
              <Typography color="textPrimary" variant="body2">
                Not after
              </Typography>
              {renderDate(expiryDateText, lastDecodedCert?.validTo)}
            </MessageContainer>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
