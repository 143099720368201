export const UPDATE_CURRENT_ORG = "UPDATE_CURRENT_ORG"

export interface UpdateCurrentOrgAction {
  type: typeof UPDATE_CURRENT_ORG
  orgID: string
}

export const updateCurrentOrg = (orgID: string): UpdateCurrentOrgAction => ({
  type: UPDATE_CURRENT_ORG,
  orgID,
})

export type OrgActions = UpdateCurrentOrgAction
