import React from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router"

import { useDocumentTitle } from "@hooks/useDocumentTitle"
import { useSupportsMultiClusterSelector } from "@hooks/useSupportsMultiCluster"
import { currentOrgSelector } from "@selectors/orgs"
import { isFetchingUserSelector, isLoggingInSelector, organizationsSelector } from "@selectors/auth"
import { featuresSelector } from "@selectors/features"
import { FeatureCertInventory } from "@utils/features"
import { pathToCertInventory, pathToOverview, USER_PROFILE_PATH } from "@routes"

import { LoadingMessage } from "@components/LoadingMessage"
import { Page } from "@components/Page"
import { BlurbPanel } from "./BlurbPanel"

export const Welcome = () => {
  useDocumentTitle("Home")
  const isLoggingIn = useSelector(isLoggingInSelector)
  const isFetchingUser = useSelector(isFetchingUserSelector)
  const features = useSelector(featuresSelector)
  const currentOrg = useSelector(currentOrgSelector)
  const organizations = useSelector(organizationsSelector)
  const { supportsMultiCluster } = useSupportsMultiClusterSelector()

  if (isLoggingIn || isFetchingUser) {
    return (
      <div style={{ padding: "5rem 0" }}>
        <LoadingMessage label="Fetching user details..." />
      </div>
    )
  }

  // decide landing page based on enabled features
  if (features?.includes(FeatureCertInventory)) {
    return supportsMultiCluster ? <Navigate to={pathToOverview(currentOrg)} /> : <Navigate to={pathToCertInventory(currentOrg)} />
  }

  if (!!organizations) {
    return <Navigate to={USER_PROFILE_PATH} />
  }

  return (
    <Page>
      <BlurbPanel />
    </Page>
  )
}
