import { useState, SetStateAction, Dispatch } from "react"

export type DiscoveredCertificatesFilterState = {
  expiryIn: number | null
  setExpiryIn: Dispatch<SetStateAction<number | null>>
  namespace: string | null
  setNamespace: Dispatch<SetStateAction<string | null>>
  cluster: string | null
  setCluster: Dispatch<SetStateAction<string | null>>
}

export const useDiscoveredCertificatesFilters = (): DiscoveredCertificatesFilterState => {
  const [expiryIn, setExpiryIn] = useState<number | null>(100)
  const [namespace, setNamespace] = useState<string | null>(null)
  const [cluster, setCluster] = useState<string | null>("")

  return {
    expiryIn,
    setExpiryIn,
    namespace,
    setNamespace,
    cluster,
    setCluster,
  }
}
