import React from "react"
import { NavLink } from "react-router-dom"
import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  const navItemCommonStyles = {
    color: "white",
    textDecoration: "none",
    margin: theme.spacing(1, 0, 0, 0),
    display: "flex",
    alignItems: "center",
  }

  const commonNavItemBreakStyles = {
    margin: theme.spacing(0.5, 0),
  }

  return createStyles({
    navItem: {
      padding: theme.spacing(1, 2, 1, 2.8),
      position: "relative",
      transition: "all 0.3s ease-in-out",
      ...navItemCommonStyles,
      "&:link, &:visited": {
        position: "relative",
        ...navItemCommonStyles,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up(768)]: {
        ...commonNavItemBreakStyles,
        padding: theme.spacing(1, 1.5),
        "&:link, &:visited": commonNavItemBreakStyles,
        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
    navItemActive: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1, 2, 1, 2.8),
      position: "relative",
      transition: "all 0.3s ease-in-out",
      ...navItemCommonStyles,
      [theme.breakpoints.up(768)]: {
        ...commonNavItemBreakStyles,
        padding: theme.spacing(1, 1.5),
      },
    },
    navItemLabel: {
      paddingLeft: theme.spacing(1),
      fontSize: "0.875rem",
    },
    navItemIcon: {
      alignItems: "center",
      justifyContent: "center",
      width: "40px",
      height: "40px",
      display: "inline-flex",
      borderRadius: "12px",
      "& img": {
        width: "22px",
        height: "22px",
      },
    },
    container: {
      display: "inline-flex",
      alignItems: "center",
    },
  })
})

type ToolbarNavItemProps = {
  path?: string
  href?: string
  Icon: React.ComponentType
  text: string
  onClick?: () => void
  exact?: boolean
  target?: string
  id: string
}

export const ToolbarNavItem = ({ id, path, href, Icon, text, onClick, target }: ToolbarNavItemProps) => {
  const classes = useStyles()
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  const renderItem = () => (
    <span className={classes.container}>
      <span className={classes.navItemIcon}>
        <Icon />
      </span>
      <span className={classes.navItemLabel}>{text}</span>
    </span>
  )

  return path ? (
    <NavLink
      data-testid={"navbar-" + id}
      to={path}
      className={navData => (navData.isActive ? classes.navItemActive : classes.navItem)}
      onClick={handleClick}
    >
      {renderItem()}
    </NavLink>
  ) : (
    <a data-testid={"navbar-" + id} href={href} className={classes.navItem} onClick={handleClick} target={target}>
      {renderItem()}
    </a>
  )
}
