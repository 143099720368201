import { ClustersActions, FETCH_CLUSTERS, FETCH_CLUSTERS_FAILED, FETCH_CLUSTERS_SUCCESS, ADD_PENDING_CLUSTER } from "@actions/clusters"

import { UpdateCurrentOrgAction, UPDATE_CURRENT_ORG } from "@actions/orgs"

import { LOGOUT_FINISHED, LogoutFinishedAction } from "@actions/auth"

export type ClustersState = {
  clusters: ClusterOverview[]
  pendingClusters: {
    [key: string]: string
  }
  isLoading: boolean
  loadingError: string
  orgID: string
  reportsByCluster: {
    [key: string]: {
      isLoading: boolean
      loadingError: string
      reports: ClusterReport[]
    }
  }
}

const initialState = {
  clusters: [],
  pendingClusters: {},
  isLoading: false,
  loadingError: "",
  orgID: "",
  reportsByCluster: {},
}

export function clustersReducer(
  state: ClustersState = initialState,
  action: ClustersActions | LogoutFinishedAction | UpdateCurrentOrgAction,
): ClustersState {
  switch (action.type) {
    case FETCH_CLUSTERS: {
      return {
        ...state,
        orgID: action.orgID,
        isLoading: true,
        loadingError: "",
      }
    }

    case FETCH_CLUSTERS_FAILED: {
      return {
        ...state,
        isLoading: false,
        loadingError: action.errorMessage,
      }
    }

    case FETCH_CLUSTERS_SUCCESS: {
      const remainingPendingClusters = { ...state.pendingClusters }
      action.clusters.forEach(cluster => {
        if (cluster.cluster in remainingPendingClusters) {
          delete remainingPendingClusters[cluster.cluster]
        }
      })

      return {
        ...state,
        isLoading: false,
        loadingError: "",
        clusters: action.clusters,
        pendingClusters: remainingPendingClusters,
      }
    }
    case ADD_PENDING_CLUSTER: {
      const clusterID = action.clusterID

      return {
        ...state,
        pendingClusters: {
          ...state.pendingClusters,
          [clusterID]: new Date().toLocaleTimeString(),
        },
      }
    }

    case UPDATE_CURRENT_ORG: {
      if (action.orgID === state.orgID) {
        return { ...state }
      }
      return { ...initialState }
    }

    case LOGOUT_FINISHED: {
      return { ...initialState }
    }

    default:
      return state
  }
}
