import React from "react"
import { useParams } from "react-router-dom"

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

import { InstallationComponent, AgentStatus } from "@lib/certInventory"
import { StatusIndicator } from "@components/StatusIndicator"
import { UpdateAvailable } from "../UpdateAvailable"
import { getOrgID, getClusterID } from "@routes"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newWindowIcon: {
      fontSize: "1rem",
      marginBottom: theme.spacing(-0.2),
      marginLeft: theme.spacing(0.2),
    },
    missingNoteCompact: {
      paddingBottom: theme.spacing(0.2),
    },
    status: {
      "& > div": {
        marginRight: theme.spacing(2),
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  }),
)

type Props = {
  data?: AgentStatus
  error: string
  isLoading: boolean
  compact?: boolean
}

export function AgentStatusCompact({ data, error, isLoading, compact = false }: Props) {
  const classes = useStyles()

  if (isLoading) return null
  if (error) {
    return (
      <div>
        <Typography variant="body2" color="textSecondary">
          {error}
        </Typography>
      </div>
    )
  }

  return (
    <div>
      {!data || !data.agent.version ? (
        <div className={!compact ? classes.missingNoteCompact : ""}>
          <Typography variant="body2" color="textSecondary" data-testid="agent-status-data-missing">
            can't determine current agent status
          </Typography>
        </div>
      ) : (
        <div className={classes.status}>{generateStatusIndicator({ data, compact })}</div>
      )}
    </div>
  )
}

type GenerateProps = {
  data: AgentStatus
  compact: boolean
}

const generateStatusIndicator = ({ data, compact }: GenerateProps) => {
  const { is_supported, warnings, agent } = data
  const items = [["Agent", agent]] as [string, InstallationComponent][]

  const params = useParams<{ orgId: string; clusterID: string; clusterView: string }>()
  const orgID = getOrgID(params)
  const clusterID = getClusterID(params)
  const agentUpdateLink = `https://platform.jetstack.io/org/${orgID}/cluster/${clusterID}/update-agent`

  if (is_supported) {
    return (
      <>
        <StatusIndicator status="ok" message="Supported" iconsOnly />
        <UpdateAvailable items={items} updateLink={agentUpdateLink} />
      </>
    )
  } else {
    return (
      <>
        {compact ? (
          <>
            <StatusIndicator status="warning" message="Unsupported agent version" iconsOnly />
          </>
        ) : (
          <>
            <StatusIndicator status="warning" message={warnings.join("\n")} iconsOnly />
          </>
        )}
        <UpdateAvailable items={items} updateLink={agentUpdateLink} />
      </>
    )
  }
}
