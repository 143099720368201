import React from "react"

type Props = {
  [x: string]: unknown
}

export function SlackIcon(props: Props) {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.042 15.166a2.527 2.527 0 0 1-2.52 2.521A2.527 2.527 0 0 1 0 15.166a2.527 2.527 0 0 1 2.521-2.521h2.521v2.52ZM6.313 15.166a2.527 2.527 0 0 1 2.52-2.521 2.527 2.527 0 0 1 2.522 2.52v6.314a2.527 2.527 0 0 1-2.521 2.52 2.527 2.527 0 0 1-2.521-2.52v-6.313Z"
        fill="#E01E5A"
      />
      <path
        d="M8.834 5.042a2.527 2.527 0 0 1-2.521-2.52A2.527 2.527 0 0 1 8.832 0a2.527 2.527 0 0 1 2.522 2.521v2.521H8.834ZM8.834 6.313a2.527 2.527 0 0 1 2.521 2.52 2.527 2.527 0 0 1-2.521 2.522H2.52A2.527 2.527 0 0 1 0 8.834a2.527 2.527 0 0 1 2.521-2.521h6.313Z"
        fill="#36C5F0"
      />
      <path
        d="M18.958 8.834a2.527 2.527 0 0 1 2.521-2.521A2.527 2.527 0 0 1 24 8.833a2.527 2.527 0 0 1-2.52 2.522h-2.522V8.834ZM17.688 8.834a2.527 2.527 0 0 1-2.521 2.521 2.527 2.527 0 0 1-2.521-2.521V2.52A2.527 2.527 0 0 1 15.165 0a2.527 2.527 0 0 1 2.522 2.521v6.313Z"
        fill="#2EB67D"
      />
      <path
        d="M15.167 18.957a2.527 2.527 0 0 1 2.52 2.522 2.527 2.527 0 0 1-2.52 2.52 2.527 2.527 0 0 1-2.521-2.52v-2.521h2.52ZM15.167 17.687a2.527 2.527 0 0 1-2.521-2.521 2.527 2.527 0 0 1 2.52-2.521h6.313A2.527 2.527 0 0 1 24 15.165a2.527 2.527 0 0 1-2.52 2.522h-6.313Z"
        fill="#ECB22E"
      />
    </svg>
  )
}
