import React, { ReactElement } from "react"
import { createStyles, FormControl, Grid, InputLabel, makeStyles, MenuItem } from "@material-ui/core"
import { Field } from "formik"
import { TextField as FormTextField, Select as FormSelect } from "formik-material-ui"

import { IssuerCommonFields } from "../types"
import { validate, validKubernetesName } from "../validation"
import { FormRow } from "../FormRow"
import { FormWrapper } from "../FormWrapper"

const useStyles = makeStyles(() =>
  createStyles({
    formSelect: {
      minWidth: 200,
    },
  }),
)

type CommonFormProps = {
  common: IssuerCommonFields
}

export const CommonForm = ({ common }: CommonFormProps): ReactElement => {
  const classes = useStyles()
  return (
    <FormWrapper title="Metadata">
      <FormRow info="Must be unique accross all issuers in the cluster">
        <Field
          name="common.name"
          label="Issuer name"
          variant="outlined"
          margin="dense"
          placeholder={common.selectedIssuer}
          required
          validate={validate(validKubernetesName)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow>
        <Grid container spacing={2}>
          <Grid item>
            <FormControl variant="outlined" margin="dense">
              <InputLabel htmlFor="common.useNamespace">Issuer kind</InputLabel>
              <Field
                className={classes.formSelect}
                component={FormSelect}
                name="common.useNamespace"
                inputProps={{ id: "common.useNamespace" }}
                label="Issuer kind"
              >
                <MenuItem value="no-namespace">Cluster-wide issuer</MenuItem>
                <MenuItem value="use-namespace">Namespaced issuer</MenuItem>
              </Field>
            </FormControl>
          </Grid>
          <Grid item>
            {common.useNamespace === "use-namespace" && (
              <Field
                name="common.namespace"
                label="Namespace"
                variant="outlined"
                margin="dense"
                placeholder="my-namespace"
                required
                validate={validate(validKubernetesName)}
                component={FormTextField}
              />
            )}
          </Grid>
        </Grid>
      </FormRow>
    </FormWrapper>
  )
}
