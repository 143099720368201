import React, { ReactNode, ReactElement, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { makeStyles, Theme, createStyles, Box, Container, Typography } from "@material-ui/core"

import { useDocumentTitle } from "@hooks/useDocumentTitle"
import { errorSelector, isFetchingUserSelector, isLoggedInSelector, userSelector } from "@selectors/auth"
import { Toolbar } from "@components/Toolbar"
import { ErrorMessage } from "@components/ErrorMessage"
import { SignupBanner } from "@components/SignupBanner"
import { fetchAuthInfo } from "@actions/auth"
import { AppNotificationBanner } from "@components/AppNotificationBanner"

const IS_NOTIFICATION_BANNER_ENABLED = false
const NOTIFICATION_BANNER_MESSAGE =
  "A maintenance window has been scheduled on June 14th from 08:00am to 09:00am UTC. TLS Protect for Kubernetes will not be accessible during that time"

const useStyles = makeStyles((theme: Theme) => {
  const smallerTitleCommon = {
    fontSize: "2.4rem !important",
    textTransform: "uppercase" as const,
    fontWeight: 500,
    margin: theme.spacing(0, 2, 0, 0),
  }

  return createStyles({
    root: {
      height: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up(768)]: {
        paddingLeft: "220px",
      },
    },
    main: {
      position: "relative",
      display: "flex",
      flex: 1,
    },
    noToolbar: {
      paddingLeft: 0,
    },
    page: {
      background: theme.palette.background.default,
      flex: 1,
      maxWidth: "100%",
      minHeight: "100vh",
      padding: theme.spacing(4),
      position: "relative",
    },
    mediumTitle: {
      ...smallerTitleCommon,
    },
    smallTitle: {
      ...smallerTitleCommon,
      fontSize: "2rem !important",
    },
    headingContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      [theme.breakpoints.down(600)]: {
        display: "block",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      margin: theme.spacing(1, 1, 1, 0),
      "& h1": {
        fontSize: "3rem",
      },
    },
    titleAddon: {
      margin: theme.spacing(2, 0),
    },
    sideContainer: {
      display: "flex",
      alignItems: "center",
      "& >div": {
        marginRight: theme.spacing(4),
        "&:last-child": {
          marginRight: 0,
        },
      },
      [theme.breakpoints.down(600)]: {
        display: "block",
      },
    },
  })
})

type PageProps = {
  children: ReactNode
  title?: string
  reqAuth?: boolean
  fixedWidth?: boolean
  sidePanel?: ReactNode
  stickyPanel?: boolean
  breadcrumbs?: ReactNode
  titleVariant?: "medium-uppercase" | "small-uppercase"
  showSignUpBanner?: boolean
  customSideComponent?: ReactElement
  titleAddonComponent?: ReactElement
  titleComponent?: ReactElement
}

export const Page = ({
  children,
  title,
  reqAuth,
  fixedWidth,
  sidePanel,
  breadcrumbs,
  titleVariant,
  customSideComponent,
  titleAddonComponent,
  showSignUpBanner,
  titleComponent,
}: PageProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const error = useSelector(errorSelector)
  const isLoggedIn = useSelector(isLoggedInSelector)
  const user = useSelector(userSelector)
  const isFetchingUser = useSelector(isFetchingUserSelector)
  const isHomepage = location.pathname === "/"

  useDocumentTitle(title)

  useEffect(() => {
    if (isLoggedIn && !user && !isFetchingUser) {
      dispatch(fetchAuthInfo(false))
    }
  }, [dispatch, isLoggedIn, user, isFetchingUser])

  const getTitleClass = () => {
    if (titleVariant === "medium-uppercase") {
      return classes.mediumTitle
    }
    if (titleVariant === "small-uppercase") {
      return classes.smallTitle
    }
    return ""
  }

  return (
    <div className={`${classes.root} ${isHomepage ? classes.noToolbar : ""}`}>
      {!isHomepage && <Toolbar />}

      <main className={classes.main}>
        <Box className={classes.page}>
          {IS_NOTIFICATION_BANNER_ENABLED && <AppNotificationBanner message={NOTIFICATION_BANNER_MESSAGE} />}
          {showSignUpBanner && !isLoggedIn && <SignupBanner />}
          <Container maxWidth={fixedWidth ? "lg" : false} disableGutters>
            {breadcrumbs}
            {title && (
              <div className={classes.headingContainer}>
                <div className={classes.titleContainer}>
                  {titleComponent ? (
                    <>{titleComponent}</>
                  ) : (
                    <Typography variant="h1" data-testid="page-title" className={getTitleClass()}>
                      {title}
                    </Typography>
                  )}

                  <div className={classes.titleAddon}>{titleAddonComponent}</div>
                </div>
                {customSideComponent && <div className={classes.sideContainer}>{customSideComponent}</div>}
              </div>
            )}

            {reqAuth && error ? <ErrorMessage message={error} /> : children}
          </Container>
        </Box>
        {!!sidePanel && sidePanel}
      </main>
    </div>
  )
}
