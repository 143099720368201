import React, { PropsWithChildren } from "react"
import { Typography } from "@material-ui/core"

import { StatBadge } from "../StatBadge"
import { StatBadge as StatBadgeType } from "../types"
import { useStyles } from "./useStyles"

type OverviewTitleProps = {
  isNegative?: boolean
  title: string
  value: number
  badge?: StatBadgeType
  variant?: "small"
}

function OverviewTitle({ value, title, isNegative = false, badge, variant, children }: PropsWithChildren<OverviewTitleProps>) {
  const classes = useStyles()

  return (
    <Typography variant="h2" color="textPrimary" className={`${classes.title} ${variant === "small" ? classes.small : ""}`}>
      <div className={classes.titleContainer}>
        <span>
          <strong className={isNegative ? classes.isNegative : ""}>{value}</strong> {title}
        </span>
        {badge && <StatBadge badge={badge} />}
      </div>
      {children}
    </Typography>
  )
}

export { OverviewTitle }
