import { useState, useMemo } from "react"
import { FilterProps } from "@components/FiltersBar"
import { FilterData } from "@selectors/filters"
import { getRequestFilterOptions, getFilteredRequests } from "./helpers"
import { CertificateRequest } from "@lib/certificateRequests/types"

type UseRequestFilters = {
  onFilter: (filter: FilterProps) => void
  filterOptions: FilterData[]
  filteredRequests: CertificateRequest[]
  filtersState: FilterProps
}

export const useRequestFilters = (requests: CertificateRequest[] | undefined): UseRequestFilters => {
  const [filtersState, setFiltersState] = useState<FilterProps>({})

  const onFilter = (filter: FilterProps) => {
    setFiltersState(filter)
  }

  const filterOptions = useMemo(() => getRequestFilterOptions(requests), [requests])

  const filteredRequests = useMemo(() => getFilteredRequests(requests, filtersState), [requests, filtersState])

  return {
    onFilter,
    filterOptions,
    filteredRequests,
    filtersState,
  }
}
