import { AuthClient } from "@lib/auth"
import { NavigateFunction } from "react-router"

export const START_CLIENT = "START_CLIENT"
export const CLIENT_READY = "CLIENT_READY"
export const REDIRECT_LOGIN = "REDIRECT_LOGIN"
export const REDIRECT_CALLBACK_RECEIVED = "REDIRECT_CALLBACK_RECEIVED"
export const REDIRECT_CALLBACK_PROCESSED = "REDIRECT_CALLBACK_PROCESSED"
export const GOT_ACCESS_TOKEN = "GOT_ACCESS_TOKEN"
export const FETCH_AUTH_INFO = "FETCH_AUTH_INFO"
export const FETCH_AUTH_INFO_SUCCESS = "FETCH_AUTH_INFO_SUCCESS"
export const LOGOUT_STARTED = "LOGOUT_STARTED"
export const LOGOUT_FINISHED = "LOGOUT_FINISHED"
export const AUTH_FAILURE = "AUTH_FAILURE"
export const UPDATE_AUTH_INFO = "UPDATE_AUTH_INFO"
export const CHANGE_USER = "CHANGE_USER"

// StartClientAction represents the fact that the auth client is being started.
export interface StartClientAction {
  type: typeof START_CLIENT
}

// StartClientAction represents the fact that the auth client is ready to be used.
export interface ClientReadyAction {
  type: typeof CLIENT_READY
  client: AuthClient
  isLoggedIn: boolean
  domain: string
}

// RedirectLoginAction sends the user to the login page.
export interface RedirectLoginAction {
  type: typeof REDIRECT_LOGIN
}

// RedirectCallbackReceivedAction reflects that the page is loading
// as a result of a redirection from the auth provider.
export interface RedirectCallbackReceivedAction {
  type: typeof REDIRECT_CALLBACK_RECEIVED
  navigate: NavigateFunction
}

// RedirectCallbackProcessedAction is invoked once the login callback
// has been processed and the user is logged in.
export interface RedirectCallbackProcessedAction {
  type: typeof REDIRECT_CALLBACK_PROCESSED
}

// GotAccessTokenAction reflects that we got an access token.
export interface GotAccessTokenAction {
  type: typeof GOT_ACCESS_TOKEN
  token: string
}

// FetchAuthInfoAction reflects that the auth information is being retrieved.
export interface FetchAuthInfoAction {
  type: typeof FETCH_AUTH_INFO
  forceNewToken: boolean
}

// FetchAuthInfoSuccessAction reflects that the auth information has been retrieved.
export interface FetchAuthInfoSuccessAction {
  type: typeof FETCH_AUTH_INFO_SUCCESS
  authInfo: AuthInfoType
  currentOrg: string
  emailVerified: boolean
}

// LogoutStartedAction reflects that the user is logging out.
export interface LogoutStartedAction {
  type: typeof LOGOUT_STARTED
}

// LogoutFinishedAction reflects that the user has been logged out.
export interface LogoutFinishedAction {
  type: typeof LOGOUT_FINISHED
}

// AuthFailureAction reflects that there is some problem during the authentication process.
export interface AuthFailureAction {
  type: typeof AUTH_FAILURE
  error: string
}

// UpdateAuthInfoAction updates the state of the authInfo
export interface UpdateAuthInfoAction {
  type: typeof UPDATE_AUTH_INFO
  authInfo: AuthInfoType
}

// ChangeUser changes user
export interface ChangeUserAction {
  type: typeof CHANGE_USER
  destinationPath: string
}

export const startClient = (): StartClientAction => ({
  type: START_CLIENT,
})

export const clientReady = (client: AuthClient, isLoggedIn: boolean, domain: string): ClientReadyAction => ({
  type: CLIENT_READY,
  client,
  isLoggedIn,
  domain,
})

export const redirectLogin = (): RedirectLoginAction => ({
  type: REDIRECT_LOGIN,
})

export const redirectCallbackReceived = (navigate: NavigateFunction): RedirectCallbackReceivedAction => ({
  type: REDIRECT_CALLBACK_RECEIVED,
  navigate,
})

export const redirectCallbackProcessed = (): RedirectCallbackProcessedAction => ({
  type: REDIRECT_CALLBACK_PROCESSED,
})

export const gotAccessToken = (token: string): GotAccessTokenAction => ({
  type: GOT_ACCESS_TOKEN,
  token,
})

export const fetchAuthInfo = (forceNewToken: boolean): FetchAuthInfoAction => ({
  type: FETCH_AUTH_INFO,
  forceNewToken,
})

export const fetchAuthInfoSuccess = (authInfo: AuthInfoType, currentOrg: string, emailVerified: boolean): FetchAuthInfoSuccessAction => ({
  type: FETCH_AUTH_INFO_SUCCESS,
  authInfo,
  currentOrg,
  emailVerified,
})

export const updateAuthInfo = (authInfo: AuthInfoType): UpdateAuthInfoAction => ({
  type: UPDATE_AUTH_INFO,
  authInfo,
})

export const logoutStarted = (): LogoutStartedAction => ({
  type: LOGOUT_STARTED,
})

export const logoutFinished = (): LogoutFinishedAction => ({
  type: LOGOUT_FINISHED,
})

export const authFailure = (error: string): AuthFailureAction => ({
  type: AUTH_FAILURE,
  error,
})

export const changeUser = (destinationPath: string): ChangeUserAction => ({
  type: CHANGE_USER,
  destinationPath,
})

export type AuthActions =
  | StartClientAction
  | ClientReadyAction
  | RedirectLoginAction
  | RedirectCallbackReceivedAction
  | RedirectCallbackProcessedAction
  | GotAccessTokenAction
  | FetchAuthInfoAction
  | FetchAuthInfoSuccessAction
  | LogoutStartedAction
  | LogoutFinishedAction
  | AuthFailureAction
  | UpdateAuthInfoAction
  | ChangeUserAction
