import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import * as queryString from "query-string"

export const useGetQueryString = (key: string) => {
  const location = useLocation()
  const [value, setValue] = useState(null)
  useEffect(() => {
    const query = queryString.parse(location.search)
    setValue(query[key])
  }, [key, history])

  return { value, hasQueryString: !!value, queryString: `${key}=${value}` }
}
