import React from "react"

import { makeStyles, Theme, createStyles, FormControl, InputLabel, MenuItem } from "@material-ui/core"
import { TextField as FormTextField, Select as FormSelect } from "formik-material-ui"
import { Field } from "formik"

import {
  validate,
  ValidationFunc,
  validKubernetesName,
  validKubernetesSecretKey,
  validPath,
  validUrl,
  validUUID,
  validBase64,
} from "../../validation"
import { FormRow } from "../../FormRow/"
import { VaultIssuer } from "../../types"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginTop: theme.spacing(2),
    },
    formSelect: {
      minWidth: 200,
    },
  }),
)

const validSigningPath: ValidationFunc = (value: string) => {
  let error = validPath(value)
  if (!error) {
    const hasSignPath = value.split("/").find(chunk => chunk === "sign")
    if (!hasSignPath) error = "must have /sign in its path"
  }
  return error
}

const AuthAppRoleForm = () => {
  return (
    <>
      <FormRow>
        <Field
          name="issuers.vault.auth.appRole.roleId"
          label="Role ID"
          variant="outlined"
          margin="dense"
          placeholder="291b9d21-8ff5-..."
          required
          validate={validate(validUUID)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow>
        <Field
          name="issuers.vault.auth.appRole.secretName"
          label="Secret name"
          variant="outlined"
          margin="dense"
          placeholder="my-secret"
          required
          validate={validate(validKubernetesName)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow noBottomMargin>
        <Field
          name="issuers.vault.auth.appRole.secretKey"
          label="Secret key"
          variant="outlined"
          margin="dense"
          placeholder="my-key"
          required
          validate={validate(validKubernetesSecretKey)}
          component={FormTextField}
        />
      </FormRow>
    </>
  )
}

const AuthTokenForm = () => {
  return (
    <>
      <FormRow>
        <Field
          name="issuers.vault.auth.token.secretName"
          label="Secret name"
          variant="outlined"
          margin="dense"
          placeholder="my-secret"
          required
          validate={validate(validKubernetesName)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow noBottomMargin>
        <Field
          name="issuers.vault.auth.token.secretKey"
          label="Secret key"
          variant="outlined"
          margin="dense"
          placeholder="my-key"
          required
          validate={validate(validKubernetesSecretKey)}
          component={FormTextField}
        />
      </FormRow>
    </>
  )
}

const AuthKubernetesServiceAccountForm = () => {
  return (
    <>
      <FormRow>
        <Field
          name="issuers.vault.auth.kubernetesServiceAccount.roleName"
          label="Role name"
          variant="outlined"
          margin="dense"
          placeholder="my-app-1"
          required
          validate={validate(validKubernetesName)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow>
        <Field
          name="issuers.vault.auth.kubernetesServiceAccount.mountPath"
          label="Mount path"
          variant="outlined"
          margin="dense"
          placeholder="/v1/auth/kubernetes"
          validate={validate(validPath, false)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow>
        <Field
          name="issuers.vault.auth.kubernetesServiceAccount.secretName"
          label="Secret name"
          variant="outlined"
          margin="dense"
          placeholder="my-secret"
          required
          validate={validate(validKubernetesName)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow noBottomMargin>
        <Field
          name="issuers.vault.auth.kubernetesServiceAccount.secretKey"
          label="Secret key"
          variant="outlined"
          margin="dense"
          placeholder="my-key"
          required
          validate={validate(validKubernetesSecretKey)}
          component={FormTextField}
        />
      </FormRow>
    </>
  )
}

type VaultFormProps = {
  issuer: VaultIssuer
}

export const VaultForm = ({ issuer }: VaultFormProps) => {
  const classes = useStyles()

  return (
    <>
      <FormRow>
        <Field
          name="issuers.vault.serverUrl"
          label="Server URL"
          variant="outlined"
          margin="dense"
          placeholder="https://vault.example.org"
          required
          validate={validate(validUrl)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow info="The Vault path that will be used for signing. Note that the path must use the 'sign' endpoint.">
        <Field
          name="issuers.vault.path"
          label="Signing path"
          variant="outlined"
          margin="dense"
          placeholder="pki_int/sign/example-dot-com"
          required
          validate={validate(validSigningPath)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow info="Denotes an optional field containing a base64 encoded string of the Certificate Authority to trust the Vault connection.">
        <Field
          name="issuers.vault.caBundle"
          label="CA bundle"
          variant="outlined"
          margin="dense"
          placeholder="LS0tLS1CRUBDRVJU..."
          validate={validate(validBase64, false)}
          component={FormTextField}
        />
      </FormRow>
      <FormRow>
        <FormControl variant="outlined" margin="dense">
          <InputLabel htmlFor="issuers.vault.authType">Auth</InputLabel>
          <Field
            className={classes.formSelect}
            component={FormSelect}
            name="issuers.vault.authType"
            inputProps={{ id: "issuers.vault.authType" }}
            label="Auth"
          >
            <MenuItem key="vault-auth-appRole" value="appRole">
              App Role
            </MenuItem>
            <MenuItem key="vault-auth-token" value="token">
              Token
            </MenuItem>
            <MenuItem key="vault-auth-kubernetesServiceAccount" value="kubernetesServiceAccount">
              Kubernetes Service Account
            </MenuItem>
          </Field>
        </FormControl>
      </FormRow>
      {issuer.authType === "appRole" && <AuthAppRoleForm />}
      {issuer.authType === "token" && <AuthTokenForm />}
      {issuer.authType === "kubernetesServiceAccount" && <AuthKubernetesServiceAccountForm />}
    </>
  )
}
