import React, { useState } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { Page } from "@components/Page"
import { Breadcrumbs } from "@components/Navigation/Breadcrumbs"
import { IssuerSummary } from "./IssuerSummary"
import { CertificateRequests } from "./CertificateRequests"
import { ExpiringSoon } from "./ExpiringSoon"
import { CertificateDetails } from "./ExpiringSoon/CertificateDetails"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summariesWrapper: {
      padding: theme.spacing(2, 0),
      display: "flex",
    },
    summariesGrid: {
      display: "grid",
      gridTemplateColumns: "1fr",
      placeContent: "stretch center",
      boxSizing: "border-box",
      width: "100%",
      gap: "2rem",
    },
  }),
)

function OrganizationSummaries() {
  const classes = useStyles()
  const [certificateId, setCertificateId] = useState("")

  const renderContent = () => (
    <div className={classes.summariesWrapper}>
      <div className={classes.summariesGrid}>
        <IssuerSummary />
        <CertificateRequests />
        <ExpiringSoon setCertificateId={setCertificateId} />
      </div>
    </div>
  )

  const renderSidePanel = () => {
    if (certificateId) {
      return <CertificateDetails onClose={() => setCertificateId("")} certificateId={certificateId} />
    }
    return undefined
  }

  return (
    <Page
      titleVariant="small-uppercase"
      title="Organization summaries"
      sidePanel={renderSidePanel()}
      reqAuth
      fixedWidth
      breadcrumbs={<Breadcrumbs current="Summaries" parents={["Organization"]} />}
    >
      {renderContent()}
    </Page>
  )
}

export { OrganizationSummaries }
