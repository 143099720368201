import React from "react"

type Props = {
  [x: string]: unknown
}

export function TeamsIcon(props: Props) {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.677 7.883c1.9 0 3.441-1.544 3.441-3.45A3.445 3.445 0 0 0 12.677.986a3.445 3.445 0 0 0-3.442 3.449 3.445 3.445 0 0 0 3.442 3.449Z"
        fill="#7B83EB"
      />
      <path
        opacity=".1"
        d="M12.431 5.173H9.314l.016.069.001.005.017.064a3.438 3.438 0 0 0 4.067 2.492V6.158a.99.99 0 0 0-.984-.985Z"
        fill="#000"
      />
      <path opacity=".2" d="M11.694 5.912H9.567a3.447 3.447 0 0 0 3.11 1.97v-.985a.99.99 0 0 0-.983-.985Z" fill="#000" />
      <path
        d="M20.42 8.129A2.584 2.584 0 0 0 23 5.542a2.584 2.584 0 0 0-2.58-2.586 2.584 2.584 0 0 0-2.581 2.586 2.584 2.584 0 0 0 2.58 2.587ZM22.632 8.868h-5.797a.471.471 0 0 0-.472.473v5.993a3.79 3.79 0 0 0 .798 2.398 3.775 3.775 0 0 0 2.127 1.359 3.686 3.686 0 0 0 4.09-2.029c.237-.496.36-1.04.36-1.59V9.977a1.11 1.11 0 0 0-1.106-1.108Z"
        fill="#5059C9"
      />
      <path
        d="M18.576 9.976v7.02c0 1.301-.427 2.566-1.217 3.599a5.884 5.884 0 0 1-6.935 1.863 5.9 5.9 0 0 1-2.85-2.506 5.305 5.305 0 0 1-.369-.739 6.196 6.196 0 0 1-.243-.739 6.102 6.102 0 0 1-.185-1.478v-7.02a1.109 1.109 0 0 1 1.106-1.108h9.587a1.102 1.102 0 0 1 1.106 1.108Z"
        fill="#7B83EB"
      />
      <path opacity=".2" d="M11.694 5.912H9.567a3.447 3.447 0 0 0 3.11 1.97v-.985a.99.99 0 0 0-.983-.985Z" fill="#000" />
      <path
        opacity=".1"
        d="M13.414 8.868v9.362a.977.977 0 0 1-.767.954.714.714 0 0 1-.214.03H7.205a6.196 6.196 0 0 1-.243-.74 6.102 6.102 0 0 1-.185-1.478v-7.02a1.11 1.11 0 0 1 1.106-1.108h5.531Z"
        fill="#000"
      />
      <path
        opacity=".2"
        d="M12.677 8.868v10.101a.72.72 0 0 1-.03.215.976.976 0 0 1-.951.768H7.574a5.305 5.305 0 0 1-.369-.739 6.196 6.196 0 0 1-.243-.739 6.102 6.102 0 0 1-.185-1.478v-7.02a1.109 1.109 0 0 1 1.106-1.108h4.794Z"
        fill="#000"
      />
      <path
        opacity=".2"
        d="M12.677 8.868v8.623a.988.988 0 0 1-.981.983H6.962a6.102 6.102 0 0 1-.185-1.478v-7.02a1.109 1.109 0 0 1 1.106-1.108h4.794Z"
        fill="#000"
      />
      <path
        opacity=".2"
        d="M7.883 8.868a1.102 1.102 0 0 0-1.106 1.108v7.02c.001.499.063.995.185 1.478h3.996a.984.984 0 0 0 .981-.983V8.868H7.883Z"
        fill="#000"
      />
      <path
        d="M1.125 5.912h9.832a.982.982 0 0 1 .983.985v9.853a.986.986 0 0 1-.983.985H1.125a.982.982 0 0 1-.983-.985V6.897a.986.986 0 0 1 .983-.985Z"
        fill="#4B53BC"
      />
      <path d="M8.975 9.588H6.77v5.927H5.324V9.588H3.105V8.132h5.87v1.456Z" fill="#fff" />
    </svg>
  )
}
