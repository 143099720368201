import React from "react"
import { Button, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import { Feature, Section, sectionsForTier } from "@components/OrganizationSettings/TierFeatures/helpers"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageSection: {
      position: "relative",
      width: "100%",
      padding: theme.spacing(4),
      margin: theme.spacing(1, 0),
    },
    tierBadge: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      position: "absolute",
      top: 0,
      right: 0,
      borderBottomLeftRadius: 10,
      padding: theme.spacing(1, 2),
    },
    documentationButton: {
      marginTop: theme.spacing(1),
    },
    header: {
      margin: theme.spacing(4, 0, 2, 0),
    },
    description: {
      marginTop: theme.spacing(2),
    },
    sectionTitle: {
      fontSize: "1.3rem",
    },
  }),
)

type TierFeaturesProps = {
  tier?: string
}

export const TierFeatures = ({ tier = "" }: TierFeaturesProps) => {
  const sections = sectionsForTier(tier)
  const classes = useStyles()

  const renderFeature = (feature: Feature, index: number) => {
    return (
      <Paper className={classes.pageSection} key={`${feature.tier}=${index}`}>
        <div className={classes.tierBadge}>{feature.tier}</div>
        <Typography variant="h6" className={classes.sectionTitle}>
          {feature.name}
        </Typography>
        {feature.description && (
          <Typography variant="body1" className={classes.description}>
            {feature.description}
          </Typography>
        )}
        {feature.documentationURL && (
          <Button
            className={classes.documentationButton}
            variant="contained"
            size="small"
            color="primary"
            href={feature.documentationURL}
            target="_blank"
            rel="noopener"
          >
            More Info
          </Button>
        )}
      </Paper>
    )
  }

  const renderSection = (section: Section, i: number) => {
    return (
      <span key={`section-${i}`}>
        <Typography variant="h5" gutterBottom className={classes.header}>
          {section.title}
        </Typography>
        {section.features.map(renderFeature)}
      </span>
    )
  }

  return <>{sections.map(renderSection)}</>
}
