import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles, createStyles, Theme, Grid, Typography } from "@material-ui/core"

import { ClusterData } from "@lib/certInventory"
import { Certificate, Resource, CMCertificate, OwnerlessCertificateRequest } from "@lib/certInventory/resources"
import { sliceListByPage } from "@utils/utils"
import { CollatedResources } from "@utils/certificate"
import { updateFilters } from "@actions/filters"
import { filterOptionsSelector, filterSelector, FilterType } from "@selectors/filters"
import { allResourcesByClusterSelector, certsGroupedByIdentity } from "@selectors/certInventory"

import { CertManagerIconSimple, WarningIcon } from "@components/Icons"
import { StatusBar } from "@components/StatusBar"
import { ErrorMessage } from "@components/ErrorMessage"
import { LoadingMessage } from "@components/LoadingMessage"
import { FiltersBar, FilterProps } from "@components/FiltersBar"
import { Pagination } from "@components/Pagination"
import { Table, TableHeader, CertificateRow } from "@components/ResourcesTable"

import { sortByStatus, getSortedFilteredCerts } from "./helpers"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
    },
    topLine: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "baseline",
      marginBottom: "1rem",
    },
    status: {
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    error: {
      margin: theme.spacing(2, 0),
    },
    errorContainer: {
      background: theme.palette.grey[200],
      borderRadius: 5,
      display: "inline-block",
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(2),
    },
    paginationContainer: {
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
  }),
)

type Props = {
  data?: ClusterData
  cluster?: string
  isLoading: boolean
  error: string
  handleSelect: (res: Resource) => void
  activeResource?: Resource
  activeResourceHash?: string //if provided and different than activeResource.hash() it will call handleSelect.
  allResourcesView?: boolean
  allClustersResources?: CollatedResources
}

export function Certificates({
  data,
  handleSelect,
  activeResource,
  activeResourceHash,
  isLoading,
  error,
  cluster,
  allResourcesView,
  allClustersResources,
}: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [warnCounter, setWarnCounter] = useState(0)
  const [errorCounter, setErrorCounter] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const resourcesByCluster = useSelector(allResourcesByClusterSelector(cluster, allResourcesView))
  const { filteredByIdentities } = useSelector(certsGroupedByIdentity(cluster, allResourcesView))

  const allCerts = filteredByIdentities

  const filtersState = useSelector(filterSelector(FilterType.certInventory))
  const filterOpts = useSelector(filterOptionsSelector(FilterType.certInventory, cluster, allResourcesView))
  const perPage = 10
  let totalPages = 1

  const { certManagerCertificates, nonCertManagerCertificates, ephemeralCertificates, certificateRequests } =
    allResourcesView && allClustersResources ? allClustersResources : { ...resourcesByCluster }

  useEffect(() => {
    if (activeResourceHash && (!activeResource || activeResourceHash !== activeResource.hash())) {
      const matchID = (cert: Certificate) => cert.hash() === activeResourceHash
      const cert =
        certManagerCertificates.find(matchID) ||
        nonCertManagerCertificates.find(matchID) ||
        ephemeralCertificates.find(matchID) ||
        certificateRequests.find(matchID)
      if (cert) {
        handleSelect(cert)
      }
    }
  }, [activeResource, activeResourceHash, certManagerCertificates, nonCertManagerCertificates, ephemeralCertificates, certificateRequests])

  useEffect(() => {
    let warnCount = 0
    let errorCount = 0

    const counting = (cert: Certificate) => {
      const status = cert.status.type
      if (status === "warning") {
        warnCount += 1
      } else if (status === "error") {
        errorCount += 1
      }
    }

    allCerts?.forEach(counting)
    setWarnCounter(warnCount)
    setErrorCounter(errorCount)
  }, [allCerts])

  function getTimeZone() {
    const offset = (new Date().getTimezoneOffset() / 60) * -1
    const UTC = offset < 0 ? `${offset}` : `+${offset}`

    return `(UTC ${UTC})`
  }

  const onFilter = (filter: FilterProps) => {
    dispatch(updateFilters({ [FilterType.certInventory]: { ...filter } }))
  }

  const getCertificates = () => {
    const genCertificateRow = (cert: Certificate, index: number) => {
      const activeResourceId = activeResource ? activeResource?.id + activeResource?.clusterId : undefined
      const selected = cert.id + cert.clusterId === activeResourceId
      const isCertManager = cert instanceof CMCertificate || cert instanceof OwnerlessCertificateRequest

      return (
        <CertificateRow
          key={cert.id + index}
          res={cert}
          selected={selected}
          handleSelect={handleSelect}
          icon={isCertManager ? <CertManagerIconSimple /> : <WarningIcon opacity={0.5} />}
          allResourcesView={allResourcesView}
        />
      )
    }

    const allSortedByStatus = sortByStatus([...allCerts])

    const filteredSortedCerts = getSortedFilteredCerts(filtersState, allSortedByStatus, nonCertManagerCertificates, certManagerCertificates)

    const hasFilters = filteredSortedCerts.length > 0 || Object.keys(filtersState).length > 0

    totalPages = hasFilters ? Math.ceil(filteredSortedCerts.length / perPage) : Math.ceil(allSortedByStatus.length / perPage) || 1

    if (totalPages < currentPage) {
      setCurrentPage(totalPages)
    }

    // When there are filtered/sorted certificates and there is a filter/sorting term set generate the sorted/filtered certificates
    return hasFilters
      ? getSortedCerts(sliceListByPage(filteredSortedCerts, currentPage, perPage), genCertificateRow)
      : sliceListByPage(allSortedByStatus, currentPage, perPage).map(genCertificateRow)
  }

  const getSortedCerts = (filteredCerts: Certificate[], genCertificateRow: (cert: Certificate, index: number) => JSX.Element) =>
    filteredCerts.length > 0 ? (
      filteredCerts.map(genCertificateRow)
    ) : (
      <div className={classes.errorContainer}>
        <Typography>No certificates found with this filter.</Typography>
      </div>
    )

  const certificateRows = getCertificates()

  const tableHeaders = [
    { label: "", size: 1.5 },
    { label: "Name" },
    { label: "Identity" },
    { label: `Expires ${getTimeZone()}`, size: 8 },
    { label: `Next renewal ${getTimeZone()}`, size: 8 },
    { label: "Status", size: 3.75 },
  ]
  const allViewTableHeaders = [
    { label: "", size: 1.5 },
    { label: "Name" },
    { label: "Cluster", size: 9 },
    { label: "Identity" },
    { label: `Expires ${getTimeZone()}`, size: 8 },
    { label: `Next renewal ${getTimeZone()}`, size: 8 },
    { label: "Status", size: 3.75 },
  ]

  return (
    <div className={classes.root}>
      {isLoading ? (
        <>
          <br />
          <br />
          <LoadingMessage label="loading certificates..." />
        </>
      ) : error ? (
        <div className={classes.error}>
          <ErrorMessage message={error} />
        </div>
      ) : (
        <>
          <Grid item className={classes.status}>
            <StatusBar
              statusList={[
                {
                  status: "primary",
                  message: "Not managed by cert-manager",
                  value: nonCertManagerCertificates.length,
                },
                {
                  status: "error",
                  message: "With errors",
                  value: errorCounter,
                },
                {
                  status: "warning",
                  message: "With warnings or errors",
                  value: errorCounter + warnCounter,
                },
              ]}
              staticStats={[{ label: "Total certificates", value: allCerts.length }]}
              lastUpdated={data && data.timestamp}
            />
          </Grid>

          <FiltersBar filtersState={filtersState} filterOptions={filterOpts} onFilter={onFilter} />
          <div>
            {(data || allResourcesView) && allCerts.length > 0 ? (
              Array.isArray(certificateRows) ? (
                <Table>
                  <TableHeader rows={allResourcesView ? allViewTableHeaders : tableHeaders} />
                  <tbody>{certificateRows}</tbody>
                </Table>
              ) : (
                certificateRows
              )
            ) : (
              <div className={classes.errorContainer}>
                <Typography>{allResourcesView ? "Organization has no cluster setup." : "This cluster has no certificates."}</Typography>
              </div>
            )}
          </div>
          {totalPages > 0 && allCerts.length > 0 && (
            <div className={classes.paginationContainer}>
              <Pagination count={totalPages} page={currentPage} onChange={(_, value) => setCurrentPage(value)} />
            </div>
          )}
        </>
      )}
    </div>
  )
}
