import React, { useState, SyntheticEvent, useMemo, useCallback, Dispatch, SetStateAction } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button, createStyles, makeStyles, Theme, Grid } from "@material-ui/core"
import ReactGA from "react-ga"
import { logoutStarted } from "@actions/auth"
import { domainSelector } from "@selectors/auth"
import { text } from "./eulaText"
import { CountrySelect, EXPRESS_COUNTRIES } from "./countrySelect"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "10px",
      background: "hsl(260, 27%, 10%)",
      color: "white",
      padding: theme.spacing(4),
      width: "95%",
      height: "95%",
      "& h1": {
        fontSize: "3rem",
        marginBottom: theme.spacing(4),
      },
    },
    grid: {
      height: "100%",
    },
    column: {
      height: "100%",
      margin: theme.spacing(0, 1),
      [theme.breakpoints.up("lg")]: {
        margin: theme.spacing(0, 2),
      },
    },
    subtitle: {
      marginBottom: theme.spacing(4),
    },
    text: {
      width: "100%",
      overflowY: "scroll",
      marginBottom: theme.spacing(4),
      height: "calc(100% - 48px)",
      resize: "none",
    },
    textFullHeight: {
      width: "100%",
      overflowY: "scroll",
      marginBottom: theme.spacing(4),
      height: "100%",
      resize: "none",
    },
    formField: {
      display: "flex",
      flexDirection: "column",
    },
    checkboxField: {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: theme.spacing(2),
      "& input": {
        height: "24px",
        marginRight: theme.spacing(2),
      },
    },
    buttons: {
      marginTop: theme.spacing(4),
      display: "flex",
      justifyContent: "flex-start",
      "& button": {
        marginRight: theme.spacing(2),
        "&:disabled": {
          background: theme.palette.grey[200],
        },
      },
    },
    input: {
      margin: theme.spacing(2, 0),
      height: "36px",
    },
  }),
)

const ExpressTerms = ({ acceptedTos, setAcceptedTos }: { acceptedTos: boolean; setAcceptedTos: Dispatch<SetStateAction<boolean>> }) => {
  const classes = useStyles()

  return (
    <div className={classes.checkboxField}>
      <input
        id="marketing__accept"
        type="checkbox"
        name="confirm_marketing"
        checked={acceptedTos}
        value={acceptedTos ? "yes" : "no"}
        onChange={e => setAcceptedTos(e.target.checked)}
      />
      <label htmlFor="marketing__accept">
        By checking the box, I agree to Venafi's Terms of Use for cloud services. I acknowledge and agree to Venafi's Privacy Policy and
        agree to receive information from Venafi related to Venafi products, services, events and any special offers by email, post, or
        other forms of communication. I may unsubscribe at any time by following the instructions in the communications received.
      </label>
    </div>
  )
}

const InferredTerms = () => {
  const classes = useStyles()
  return (
    <div className={classes.checkboxField}>
      <input id="marketing__accept" type="checkbox" name="confirm_marketing" checked={true} value={"yes"} hidden />
      <label htmlFor="marketing__accept">
        By submitting this form, I agree to Venafi's Terms of Use for cloud services. I acknowledge and agree to Venafi's Privacy Policy and
        agree to receive information from Venafi related to Venafi products, services, events and any special offers by email, post, or
        other forms of communication. I may unsubscribe at any time by following the instructions in the communications received.
      </label>
    </div>
  )
}

export const Tos = () => {
  const classes = useStyles()
  const params = new URLSearchParams(window.location.search)
  const domain = useSelector(domainSelector)
  const state = params.get("state")
  const [acceptedTos, setAcceptedTos] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [country, setCountry] = useState("")
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logoutStarted())
  }

  const acceptedTerms = useMemo(() => acceptedTos || (!EXPRESS_COUNTRIES.includes(country) && country !== ""), [acceptedTos, country])

  const canSubmit = useMemo(
    () => acceptedTerms && firstName && lastName && companyName && country,
    [acceptedTos, companyName, country, firstName, lastName],
  )

  const handleAccepted = useCallback(
    (e: SyntheticEvent) => {
      if (canSubmit) {
        ReactGA.event({
          category: "User",
          action: "Accepted TOS",
        })
      } else {
        e.preventDefault()
      }
    },
    [canSubmit],
  )

  const showForm = !!state

  return (
    <div className={classes.root}>
      <form className={classes.form} action={`https://${domain}/continue?state=${state}`} method="post">
        <h1>Terms of Service</h1>
        <Grid className={classes.grid} container spacing={2}>
          <Grid item xs={12} md={showForm ? 8 : 12}>
            <div className={classes.column}>
              {domain && state && (
                <div className={classes.subtitle}>Please review the terms for TLS Protect for Kubernetes before continuing.</div>
              )}
              <textarea readOnly className={!!domain && !!state ? classes.text : classes.textFullHeight} value={text} />
            </div>
          </Grid>
          {showForm && (
            <Grid item xs={12} md={4}>
              <div className={classes.column}>
                <div className={classes.formField}>
                  <label htmlFor="first__name">Your name*</label>
                  <input
                    placeholder="First Name"
                    onChange={e => setFirstName(e.target.value)}
                    id="first__name"
                    type="text"
                    name="first_name"
                    value={firstName}
                    className={classes.input}
                  />
                  <input
                    placeholder="Last Name"
                    onChange={e => setLastName(e.target.value)}
                    id="last__name"
                    type="text"
                    name="last_name"
                    value={lastName}
                    className={classes.input}
                  />
                </div>
                <div className={classes.formField}>
                  <label htmlFor="company__name">Your company name*</label>
                  <input
                    placeholder="Company"
                    onChange={e => setCompanyName(e.target.value)}
                    id="company__name"
                    type="text"
                    name="company_name"
                    value={companyName}
                    className={classes.input}
                  />
                </div>
                <div className={classes.formField}>
                  <label htmlFor="country">Your country*</label>
                  <CountrySelect
                    name="country"
                    value={country}
                    onChange={(val: string) => {
                      if (!EXPRESS_COUNTRIES.includes(val) && val !== "") {
                        setAcceptedTos(true)
                      } else {
                        setAcceptedTos(false)
                      }
                      setCountry(val)
                    }}
                  />
                </div>
                {country !== "" && (
                  <>
                    {EXPRESS_COUNTRIES.includes(country) ? (
                      <ExpressTerms acceptedTos={acceptedTos} setAcceptedTos={setAcceptedTos} />
                    ) : (
                      <InferredTerms />
                    )}
                  </>
                )}
                <div className={classes.formField}>
                  <input id="confirm__eula" type="checkbox" name="confirm_eula" checked={true} value={"yes"} hidden />
                </div>

                <div className={classes.buttons}>
                  <Button variant="contained" type="submit" color="primary" onClick={handleAccepted} disabled={!canSubmit}>
                    Submit
                  </Button>
                  <Button variant="contained" color="secondary" onClick={handleLogout}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  )
}
