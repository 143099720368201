import React from "react"
import { Typography, makeStyles, Theme, createStyles } from "@material-ui/core"

import { formatDate } from "@utils/date"
import { CertificateRequest, Resource } from "@lib/certInventory/resources"
import { MessageContainer } from "@components/MessageContainer/"
import { StatusIcon } from "@components/StatusIcon/"
import { DenyIcon } from "@components/Icons"

interface Props {
  resourceName: string
  explanation?: string
  // TODO: at the moment it only works with CertificateRequest but we
  // might want something more generic later.
  resources?: CertificateRequest[]
  handleSelect: (res: Resource) => void
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(0.5),
    },
    subTitle: {
      fontSize: "1.2rem",
      marginTop: theme.spacing(1),
    },
  }),
)

export function ResourceList({ resourceName, resources, handleSelect }: Props) {
  const classes = useStyles()

  const sortByDate = (a: CertificateRequest, b: CertificateRequest) => {
    const dateA = a?.resource?.metadata?.creationTimestamp ? new Date(a?.resource?.metadata?.creationTimestamp).getTime() : 0
    const dateB = b?.resource?.metadata?.creationTimestamp ? new Date(b?.resource?.metadata?.creationTimestamp).getTime() : 0
    return dateA - dateB
  }

  const renderList = () => {
    if (resources && resources.length > 0) {
      return resources.sort(sortByDate).map((certRequest, index) => {
        const { type, message, long_message } = certRequest.status

        return (
          <MessageContainer
            onClick={() => {
              handleSelect(certRequest)
            }}
            icon={certRequest.deleted_at ? <DenyIcon /> : <StatusIcon status={type} />}
            key={index}
            variant="iconAlignTop"
          >
            <Typography variant="body2" color="textPrimary">
              <b>{certRequest.resource.metadata?.name}</b>
            </Typography>
            {!certRequest.deleted_at ? (
              <p>
                {message}
                {long_message && ` - ${long_message}`}
              </p>
            ) : (
              "deleted"
            )}
            <Typography variant="subtitle2" color="textSecondary">
              <b>Created at: </b>
              {formatDate(certRequest.resource.metadata?.creationTimestamp)}
            </Typography>
          </MessageContainer>
        )
      })
    }

    return (
      <Typography variant="body2" color="error">
        There aren't any resources of this type. Is the agent in this cluster configured to gather {resourceName}?
      </Typography>
    )
  }

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="h6" className={classes.subTitle}>
        {resourceName}
      </Typography>
      {/* TODO: add help icon that shows `explanation` */}
      {renderList()}
    </div>
  )
}
