import { takeEvery } from "redux-saga/effects"

import { UpdateCurrentOrgAction, UPDATE_CURRENT_ORG } from "@actions/orgs"
import { LOGOUT_STARTED } from "@actions/auth"

// eslint-disable-next-line require-yield
function* updateCurrentOrgSaga(action: UpdateCurrentOrgAction) {
  const prevOrgID = localStorage.getItem("currentOrg")
  if (prevOrgID !== action.orgID) {
    localStorage.setItem("currentOrg", action.orgID)
  }
}

// eslint-disable-next-line require-yield
function* clearSaga() {
  localStorage.clear()
}

export function* localStorageSaga() {
  yield takeEvery(UPDATE_CURRENT_ORG, updateCurrentOrgSaga)
  yield takeEvery(LOGOUT_STARTED, clearSaga)
}
