import { AppState } from "../reducers"
import { currentOrgSelector } from "./orgs"

export const awsEntitlementSelector = (state: AppState) => {
  const orgID = currentOrgSelector(state)
  if (state.auth.authInfo) {
    const organization = state.auth.authInfo.organizations.find(org => org.id === orgID)
    if (organization && organization.aws_entitlement) {
      return organization.aws_entitlement
    }
  }
}
