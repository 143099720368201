import React, { useRef, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createStyles, makeStyles, Theme, CircularProgress } from "@material-ui/core"

import { rudderanalytics as rudderstack } from "@utils/rudderstack"
import { fetchClusters, addPendingCluster } from "@actions/clusters"
import { clustersNetworkSelector } from "@selectors/clustersNetwork"
import { clustersSelector } from "@selectors/clusters"

import { clusterExists } from "../helpers"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
      display: "inline-block",
      borderRadius: 5,
      boxShadow: "none",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      fontSize: "1rem",
      width: "auto",
      "& strong": {
        margin: theme.spacing(0, 2),
      },
    },
    content: {
      display: "flex",
      alignItems: "center",
      gridGap: theme.spacing(2),
      justifyContent: "flex-start",
    },
    success: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    spinner: {
      marginTop: theme.spacing(6),
    },
  }),
)

type CheckingAgentStatusProps = {
  orgID: string
  clusterName: string
  onSuccessCallback: () => void
}

export const CheckingAgentStatus = ({ orgID, clusterName, onSuccessCallback }: CheckingAgentStatusProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [connectionSuccessful, setConnectionSuccessful] = useState(false)
  const { isLoading, loadingError } = useSelector(clustersNetworkSelector)
  const clusters = useSelector(clustersSelector)

  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const hasCluster = clusterExists(clusters, clusterName)

  const clearIntervalRef = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
    }
  }

  useEffect(() => {
    dispatch(addPendingCluster(orgID, clusterName))
  }, [])

  useEffect(() => {
    if (hasCluster) {
      clearIntervalRef()
      rudderstack.track("add_cluster_wizard__registered_successfully", {
        cluster_name: clusterName,
      })
      setConnectionSuccessful(true)
      onSuccessCallback()
    } else if (!isLoading || loadingError) {
      intervalRef.current = setInterval(() => {
        dispatch(fetchClusters(orgID))
      }, 1000)
    }

    return clearIntervalRef
  }, [clusters, dispatch, isLoading, loadingError, clusterName, orgID])

  return (
    <div className={`${classes.container} ${connectionSuccessful ? classes.success : ""}`}>
      {!connectionSuccessful && (
        <div className={classes.content}>
          <CircularProgress size={24} />
          <span>Waiting for the cluster data...</span>
        </div>
      )}
      {connectionSuccessful && (
        <>
          <strong>Cluster Connected</strong>
        </>
      )}
    </div>
  )
}
