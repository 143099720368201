import React, { PropsWithChildren, useEffect, useMemo } from "react"
import { useLocation, matchPath } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"

import { LoadingMessage } from "@components/LoadingMessage"
import { EmailNotVerified } from "@components/EmailNotVerified"
import { CurrentOrg } from "@components/CurrentOrg"
import {
  accessTokenSelector,
  clientSelector,
  isFetchingUserSelector,
  userSelector,
  isLoggedInSelector,
  emailVerifiedSelector,
} from "@selectors/auth"
import { awsEntitlementSelector } from "@selectors/aws"
import { redirectLogin, fetchAuthInfo } from "@actions/auth"
import { configDemoCluster } from "@actions/settings"
import { updateCurrentOrg } from "@actions/orgs"
import { getOrgID } from "@routes"

import { handleError } from "@utils/errorHandling"
import { addAuth } from "@lib/auth"

type PrivateRouteProps = {
  // Using any so we can extend RouteProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: React.ReactNode
  allowUnverifiedUser?: boolean
  path: string
}

// PrivateRoute wraps a Route and enforces authentication for that route.
// This way, if the user is not logged in, this redirects to Auth0.
export const ProtectedRoute = (props: PrivateRouteProps) => {
  const { element, path, allowUnverifiedUser } = props
  const dispatch = useDispatch()
  const client = useSelector(clientSelector)
  const isLoading = useSelector(isFetchingUserSelector)
  const isLoggedIn = useSelector(isLoggedInSelector)
  const user = useSelector(userSelector)
  const emailVerified = useSelector(emailVerifiedSelector)
  const awsEntitlement = useSelector(awsEntitlementSelector)
  const token = useSelector(accessTokenSelector)
  const location = useLocation()

  const pathMatchRes = matchPath(path, location?.pathname || "")
  const orgID = getOrgID(pathMatchRes?.params || {})

  // update current org according to URL
  useEffect(() => {
    if (orgID && orgID !== "") {
      dispatch(updateCurrentOrg(orgID))
    }
  }, [orgID])

  // handle redirect to login if not logged in and retrieval of user info.
  useEffect(() => {
    if (client) {
      if (!isLoggedIn) {
        dispatch(redirectLogin())
      } else if (!isLoading && !user) {
        dispatch(fetchAuthInfo(false))
      }
    }
  }, [client, isLoading, user, dispatch, isLoggedIn])

  // change behaviour based on search query
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    // change showDemoCluster settings based on search query
    const showDemoCluster = searchParams.get("show_demo_cluster")

    if (orgID && (showDemoCluster === "true" || showDemoCluster === "false")) {
      dispatch(configDemoCluster(orgID, showDemoCluster === "true"))
    }
  }, [location.search, orgID])

  useEffect(() => {
    if (user && emailVerified && awsEntitlement && !awsEntitlement.verified) {
      axios.post(`/api/aws_registration/orgs/${orgID}/verify`, null, addAuth(token)).catch(handleError)
    }
  }, [user, orgID, awsEntitlement, token, emailVerified])

  const Wrapper = ({ children }: PropsWithChildren<unknown>) => <div style={{ padding: "5rem 0" }}>{children}</div>

  const pageComponent = useMemo(() => (orgID === "+current" ? <CurrentOrg /> : element), [orgID, element])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const render = () => {
    if (!user) {
      return !!isLoggedIn ? (
        <Wrapper>
          <LoadingMessage label="Fetching user details..." />
        </Wrapper>
      ) : (
        <Wrapper>
          <LoadingMessage label="Logging in..." />
        </Wrapper>
      )
    }
    return emailVerified || allowUnverifiedUser ? pageComponent : <EmailNotVerified />
  }

  return <>{render()}</>
}
