import { AWSPCAIssuer } from "../../types"

export type AwsPCAYaml = {
  arn: string
  region?: string
  secretRef?: {
    namespace: string
    name: string
  }
}

const renderAwsPCAYaml = (name: string, issuer: AWSPCAIssuer): AwsPCAYaml => {
  return {
    arn: issuer.arn,
    region: issuer.region,
    secretRef: issuer.secretRef,
  }
}

export { renderAwsPCAYaml }
