import React, { useRef, useState } from "react"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { Theme, Typography } from "@material-ui/core"
import { ChevronRight } from "@components/Icons"
import { useHandleOutsideClick } from "@hooks/useHandleOutsideClick"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownContainer: {
      position: "relative",
    },
    title: {
      fontSize: "1.8rem !important",
      textTransform: "uppercase" as const,
      fontWeight: 500,
      margin: theme.spacing(0, 2, 0, 0),
      transition: "all 0.3s ease-in-out",
      border: "1px solid #e0e0e0",
      padding: theme.spacing(1.5, 2, 2, 3),
      background: "white",
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(600)]: {
        fontSize: "1.4rem !important",
      },
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.primary.main,
      },
    },
    selectedTitle: {},
    icon: {
      transform: "scale(1.5) rotate(90deg)",
      margin: theme.spacing(0, 0, 0, 1.5),
    },
    iconFlip: {
      transform: "scale(1.5) rotate(270deg)",
    },
    dropDown: {
      position: "absolute",
      top: "100%",
      left: 0,
      zIndex: 10,
      background: "white",
      width: `calc(100% - ${theme.spacing(2)}px)`,
      padding: theme.spacing(1, 0),
      borderRadius: 5,
      boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
    },
    dropdownItem: {
      fontSize: "1.2rem",
      padding: theme.spacing(2, 3),
      margin: "1px 0",
      transition: "all 0.3s ease-in-out",
      [theme.breakpoints.down(600)]: {
        fontSize: "1rem",
        padding: theme.spacing(1.5, 2),
      },
      "&:hover": {
        cursor: "pointer",
        background: theme.palette.primary.main,
        color: "white",
      },
    },
    isDisabled: {
      color: theme.palette.grey[500],
      "&:hover": {
        cursor: "default",
        color: theme.palette.grey[500],
        background: "white",
      },
    },
    selectedDropdownItem: {
      cursor: "default !important",
      background: `${theme.palette.primary.main} !important`,
      color: "white !important",
    },
  }),
)

type ClusterDropdownProps = {
  clusters: ClusterOverview[]
  selectedCluster: string
  clusterLimit?: number
  onChange: (cluster: string) => void
}

export const ClusterDropdown = ({ clusters, selectedCluster, clusterLimit, onChange }: ClusterDropdownProps) => {
  const classes = useStyles()
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  useHandleOutsideClick(dropdownRef, () => setIsOpen(false))

  function handleClick(cluster: string, index: number) {
    if ((clusterLimit && index < clusterLimit) || clusterLimit === undefined) {
      onChange(cluster)
      setIsOpen(!isOpen)
    }
  }

  return (
    <div className={classes.dropdownContainer} ref={dropdownRef}>
      <Typography
        variant="h1"
        data-testid="page-title"
        className={`${classes.title} ${isOpen ? classes.selectedTitle : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedCluster} {<ChevronRight strokeWidth={2} className={`${classes.icon} ${isOpen ? classes.iconFlip : ""}`} />}
      </Typography>

      {isOpen && (
        <div className={classes.dropDown}>
          {clusters.map(({ cluster }, index) => (
            <div
              className={`${classes.dropdownItem} ${cluster === selectedCluster ? classes.selectedDropdownItem : ""} ${
                (clusterLimit && index < clusterLimit) || clusterLimit === undefined ? "" : classes.isDisabled
              }`}
              key={cluster}
              onClick={() => handleClick(cluster, index)}
            >
              {cluster}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
