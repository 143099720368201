import React, { useState } from "react"
import { createStyles, makeStyles, Theme, Button, Snackbar } from "@material-ui/core"

import MuiAlert, { AlertProps } from "@material-ui/lab/Alert"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
)

export const ForceError = () => {
  const classes = useStyles()
  const [toastOpen, setToastOpen] = useState(false)

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    event && event.stopPropagation()
    if (reason !== "clickaway") {
      setToastOpen(false)
    }
  }

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="inherit"
        onClick={() => {
          setToastOpen(true)
          throw Error("This is a forced error.")
        }}
      >
        Force Error
      </Button>

      <Snackbar open={toastOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Error triggered!
        </Alert>
      </Snackbar>
    </div>
  )
}
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
