import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  [x: string]: unknown
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& path": {
        stroke: theme.palette.grey[600],
      },
    },
  }),
)

export function KeyIcon(props: Props): ReactElement {
  const classes = useStyles()
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.root} {...props}>
      <path
        d="M8 19a4 4 0 100-8 4 4 0 000 8zM10.85 12.15L19 4M18 5l2 2M15 8l2 2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
