import React, { ReactElement } from "react"

import { createStyles, makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      maxWidth: "34rem",
    },
  }),
)

export type FieldInfoProps = {
  message: string
}

export function FieldInfo({ message }: FieldInfoProps): ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="body2" color="textSecondary">
        {message}
      </Typography>
    </div>
  )
}
