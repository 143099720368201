import { VaultIssuer } from "../../types"

type AppRoleYaml = {
  path: "approle"
  roleId: string
  secretRef: {
    name: string
    key: string
  }
}

type AuthTokenYaml = {
  name: string
  key: string
}

type KubernetesServiceAccountYaml = {
  role: string
  mountPath?: string
  secretRef: {
    name: string
    key: string
  }
}

export type VaultYaml = {
  vault: {
    path: string
    server: string
    caBundle?: string
    auth: {
      appRole?: AppRoleYaml
      tokenSecretRef?: AuthTokenYaml
      kubernetes?: KubernetesServiceAccountYaml
    }
  }
}

export const renderVaultYaml = (issuer: VaultIssuer): VaultYaml => {
  const appRoleYaml = (): AppRoleYaml => ({
    path: "approle",
    roleId: issuer.auth.appRole.roleId,
    secretRef: {
      name: issuer.auth.appRole.secretName,
      key: issuer.auth.appRole.secretKey,
    },
  })

  const authTokenYaml = (): AuthTokenYaml => ({
    name: issuer.auth.token.secretName,
    key: issuer.auth.token.secretKey,
  })

  const kubernetesServiceAccountYaml = (): KubernetesServiceAccountYaml => {
    const serviceAccount = issuer.auth.kubernetesServiceAccount
    return {
      role: serviceAccount.roleName,
      mountPath: serviceAccount.mountPath ? serviceAccount.mountPath : undefined,
      secretRef: {
        name: serviceAccount.secretName,
        key: serviceAccount.secretKey,
      },
    }
  }

  return {
    vault: {
      path: issuer.path,
      server: issuer.serverUrl,
      caBundle: issuer.caBundle ? issuer.caBundle : undefined,
      auth: {
        appRole: issuer.authType === "appRole" ? appRoleYaml() : undefined,
        tokenSecretRef: issuer.authType === "token" ? authTokenYaml() : undefined,
        kubernetes: issuer.authType === "kubernetesServiceAccount" ? kubernetesServiceAccountYaml() : undefined,
      },
    },
  }
}
