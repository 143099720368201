import React, { ReactElement } from "react"

type Props = {
  [x: string]: unknown
}

export function CertManagerIconSimple(props: Props): ReactElement {
  return (
    <svg width="23" height="23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M22.99 11.102c0 1.209-.74 2.286-1.094 3.375-.367 1.13-.414 2.434-1.099 3.375-.691.95-1.921 1.395-2.872 2.086-.94.685-1.74 1.722-2.87 2.089-1.09.354-2.341-.004-3.55-.004-1.208 0-2.46.358-3.55.004-1.13-.367-1.929-1.404-2.87-2.089-.95-.691-2.18-1.136-2.872-2.086-.684-.94-.731-2.244-1.098-3.375C.761 13.387.021 12.31.021 11.102c0-1.207.74-2.286 1.094-3.375.367-1.13.414-2.434 1.098-3.375.692-.95 1.922-1.395 2.872-2.086.941-.685 1.74-1.721 2.87-2.088 1.09-.354 2.342.003 3.55.003 1.209 0 2.46-.357 3.55-.003 1.13.367 1.93 1.403 2.87 2.088.95.691 2.18 1.136 2.872 2.086.685.941.732 2.245 1.099 3.375.353 1.09 1.093 2.167 1.093 3.375z"
          fill="#326CE5"
        />
        <path
          d="M11.505 1.347c-5.388 0-9.755 4.368-9.755 9.755 0 5.388 4.367 9.755 9.755 9.755a9.754 9.754 0 009.755-9.755 9.754 9.754 0 00-9.755-9.755zm-.003 18.662A8.902 8.902 0 012.6 11.104c0-4.925 3.993-8.916 8.916-8.906a8.903 8.903 0 018.896 8.913 8.902 8.902 0 01-8.909 8.898z"
          fill="#fff"
        />
        <path
          d="M16.993 13.617l-.372-1.524a.156.156 0 00-.107-.112.155.155 0 00-.151.037l-1.199 1.147a.148.148 0 00.061.25c.045.012.088.024.133.033.097.021.087.053.042.122a3.18 3.18 0 01-.97.95l-.01.005a4.208 4.208 0 01-1.052.34 7.728 7.728 0 01-1.14.131.09.09 0 01-.093-.09V9.553a.053.053 0 01.016-.037.052.052 0 01.037-.015c.048 0 .107.01.25.008.279-.003.57-.003.85-.019.091-.005.142-.002.2.041a.599.599 0 00.97-.468.597.597 0 00-.961-.484.197.197 0 01-.124.053c-.39-.002-.78-.002-1.17 0a.092.092 0 01-.068-.018v-.612c0-.021.007-.041.02-.057a.312.312 0 01.098-.069c.63-.32.957-.845.957-1.545 0-1.107-1.08-1.912-2.147-1.623-.847.23-1.397 1.071-1.237 1.929.117.63.488 1.071 1.091 1.305a.32.32 0 01.065.032.088.088 0 01.034.071v.5a.086.086 0 01-.087.087c-.42-.005-.84-.003-1.26 0a.268.268 0 01-.161-.054.593.593 0 00-.806.082.6.6 0 00.811.877.246.246 0 01.16-.053c.407 0 .814.006 1.221 0a.155.155 0 01.105.022.05.05 0 01.015.037v5.325a.089.089 0 01-.06.085.09.09 0 01-.037.004 9.328 9.328 0 01-1.465-.242 4.49 4.49 0 01-.87-.31 3.243 3.243 0 01-.948-1.036.067.067 0 01.048-.105l.129-.026a.15.15 0 00.077-.246l-1.128-1.23a.146.146 0 00-.248.056l-.494 1.567a.162.162 0 00.19.208l.088-.02a.25.25 0 01.276.128c.649 1.26 1.624 2.172 2.915 2.75.504.224.704.29 1.14.356a.087.087 0 01.048.025l.663.657a.238.238 0 00.332 0l.644-.63a.091.091 0 01.054-.025c.645-.061.932-.22 1.626-.538 1.074-.491 1.927-1.346 2.476-2.392.049-.093.091-.104.174-.076l.097.03a.2.2 0 00.252-.136.2.2 0 000-.105zm-5.532-6.392a.86.86 0 01-.828-.897c.023-.507.445-.882.921-.845.501.039.847.442.821.906-.027.505-.44.871-.914.836z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h23v22.188H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
