import React from "react"
import { Paper, Typography } from "@material-ui/core"
import { Table, TableHeader } from "@components/ResourcesTable"
import { ErrorMessage } from "@components/ErrorMessage"
import { FiltersBar } from "@components/FiltersBar"
import { StatusBar } from "@components/StatusBar"
import { FilterProps } from "@components/FiltersBar"
import { FilterData } from "@selectors/filters"
import { CertifcateRequestsRow } from "./CertificateRequestsRow"
import { useStyles } from "./useStyles"
import { SummaryTitle } from "../SummaryTitle"
import { CertificateRequest } from "@lib/certificateRequests"

type Props = {
  totalRequests: number
  isError: boolean
  isSuccess: boolean
  onFilter: (filter: FilterProps) => void
  filterOptions: FilterData[]
  filteredRequests: CertificateRequest[]
  filtersState: FilterProps
}

export const CertificateRequestWidget = ({
  totalRequests,
  isError,
  isSuccess,
  onFilter,
  filterOptions,
  filteredRequests,
  filtersState,
}: Props) => {
  const classes = useStyles()

  const requestCount = filteredRequests?.length || 0

  return (
    <Paper className={classes.paper}>
      {isError ? (
        <div className={classes.error}>
          <ErrorMessage message="Something went wrong while loading the Certificate Requests. Please try again or contact support." />
        </div>
      ) : (
        <>
          <SummaryTitle title="Certificate Requests" />
          <div data-testid="cluster-overview">
            <StatusBar staticStats={[{ label: "Total requests", value: totalRequests }]} />
          </div>
          <div className={classes.filterContainer}>
            <FiltersBar filtersState={filtersState} filterOptions={filterOptions} onFilter={onFilter} isDisabled={!isSuccess} />
          </div>
          <div className={classes.pageSection}>
            {requestCount > 0 && (
              <Table>
                <TableHeader rows={[{ label: "Name" }, { label: "Kind" }, { label: "Status" }]} />
                <tbody>
                  {filteredRequests?.map((request, index) => (
                    <CertifcateRequestsRow key={`${request.name}-${index}`} request={request} handleSelect={() => {}} />
                  ))}
                </tbody>
              </Table>
            )}
            {requestCount === 0 && (
              <div className={classes.errorContainer}>
                <Typography>No Issues found with this filter.</Typography>
              </div>
            )}
          </div>
        </>
      )}
    </Paper>
  )
}
