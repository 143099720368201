import React, { useState, ChangeEvent } from "react"
import { useSelector } from "react-redux"

import { createStyles, FormControl, InputLabel, makeStyles, MenuItem, Theme, Typography, Select } from "@material-ui/core"

import { isAdminInCurrentOrgSelector } from "@selectors/auth"
import { SlackIcon, TeamsIcon, PagerDutyIcon, ZoomIcon } from "@components/Icons"
import { receiversSelector } from "@selectors/receivers"
import { LoadingMessage } from "@components/LoadingMessage"

import { ConfigForm } from "./ConfigForm"
import { Channel } from "./types"
import { ReceiverRow } from "./ReceiverRow"
import { useFlags } from "launchdarkly-react-client-sdk"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    error: {
      background: theme.palette.error.main,
      padding: theme.spacing(1, 2),
      margin: theme.spacing(2, 0),
      color: "#fff",
      borderRadius: "5px",
    },
    selectContainer: {
      padding: theme.spacing(0, 0),
      display: "flex",
      width: 240,
      "& .MuiSelect-select.MuiSelect-select": {
        display: "flex",
        alignItems: "center",
      },
    },
    icon: {
      transform: "scale(.8)",
      marginRight: theme.spacing(1.5),
      marginTop: -10,
      marginBottom: -10,
    },
    receiverList: {
      marginTop: theme.spacing(3),
    },
  }),
)

interface Props {
  cluster?: string
}

export const ClusterAlertConfig = ({ cluster }: Props) => {
  const classes = useStyles()
  const isAdmin = useSelector(isAdminInCurrentOrgSelector)
  const flags = useFlags()
  let isPagerDutyAvailable = false
  let isZoomChatAvailable = false

  if (flags !== null && flags !== undefined) {
    isPagerDutyAvailable = "tlsp4K8SPagerduty" in flags && flags.tlsp4K8SPagerduty ? true : false
    isZoomChatAvailable = "tlsp4K8SZoomchat" in flags && flags.tlsp4K8SZoomchat ? true : false
  }

  const [receiverType, setReceiverType] = useState<Channel | "">("")
  const receiversData = useSelector(receiversSelector(cluster))

  if (!receiversData && cluster) {
    return <LoadingMessage label="Initializing..." />
  }

  const onChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setReceiverType(event.target.value as Channel)
  }

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="body1" align="left">
        Configure alerts to receive notifications for this {cluster ? "cluster" : "organization"}.
      </Typography>

      {isAdmin ? (
        <>
          <Typography gutterBottom variant="body1" align="left">
            Select an integration type to create a new integration:
          </Typography>
          <FormControl variant="outlined" size="small">
            <InputLabel id="receiver-type-select-label">Select integration type</InputLabel>
            <Select
              labelId="receiver-type-select-label"
              id="receiver-type-select"
              value={receiverType}
              label="Select integration type"
              onChange={onChange}
              className={classes.selectContainer}
              disabled={!isAdmin}
              data-testid="receiver-type-select"
            >
              <MenuItem value="slack">
                <SlackIcon className={classes.icon} />
                Slack
              </MenuItem>
              <MenuItem value="microsoft-teams">
                <TeamsIcon className={classes.icon} />
                Microsoft Teams
              </MenuItem>
              {isPagerDutyAvailable ? (
                <MenuItem value="pager-duty">
                  <PagerDutyIcon className={classes.icon} />
                  PagerDuty
                </MenuItem>
              ) : (
                ""
              )}
              {isZoomChatAvailable ? (
                <MenuItem value="zoom-chat">
                  <ZoomIcon className={classes.icon} />
                  Zoom Team Chat
                </MenuItem>
              ) : (
                ""
              )}
            </Select>
          </FormControl>
        </>
      ) : (
        <div className={classes.error}>
          <Typography variant="body2" align="left">
            Only organization admins can change this setting.
          </Typography>
        </div>
      )}

      {receiverType && <ConfigForm type={receiverType} onCancel={() => setReceiverType("")} cluster={cluster} />}
      {receiversData?.loadingError && (
        <div className={classes.error}>
          <Typography variant="body2" align="left">
            {receiversData?.loadingError}
          </Typography>
        </div>
      )}
      <div className={classes.receiverList}>
        <Typography gutterBottom variant="h5" align="left">
          Existing receivers
        </Typography>
        {receiversData?.isLoading ? (
          <LoadingMessage label="Receivers are loading..." />
        ) : receiversData && receiversData.receiversData && receiversData.receiversData.length > 0 ? (
          receiversData.receiversData.map(receiver => (
            <ReceiverRow key={receiver.id} receiver={receiver} isAdmin={isAdmin} cluster={cluster} />
          ))
        ) : (
          <Typography variant="body2" align="left">
            No receivers configured
          </Typography>
        )}
      </div>
    </div>
  )
}
