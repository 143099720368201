import { CertInventoryIndexItem, ClusterData, RuleMessage } from "../types"
import { Certificate } from "./certificate"
import { Resource } from "./resource"
import * as cm from "../cert-manager"
import { Issuer, isAllowedIssuer } from "./issuer"

// calculateCMCertificates creates CMCertificate objects from all the
// cert-manager certificates in the data. Optionally, it can filter by
// namespace.
export function calculateCMCertificates(data: ClusterData, namespaces?: string[]): CMCertificate[] {
  let certIDs = data?.kind_index["cert-manager.io/v1/Certificate"]
  if (namespaces && namespaces.length > 0) {
    certIDs = certIDs.filter(certID => namespaces.includes(data.resource_index[certID].resource.metadata?.namespace || ""))
  }
  // only return present certificates
  certIDs = certIDs?.filter(certID => !data.resource_index[certID].deleted_at)
  return certIDs?.map(certID => new CMCertificate(data, certID)) || []
}

// calculateOwnerlessCertificateRequests creates OwnerlessCertificateRequests
// objects from all the cert-manager certificate requests in the data.
// Optionally, it can filter by namespace.
export function calculateOwnerlessCertificateRequests(data: ClusterData, namespaces?: string[]): OwnerlessCertificateRequest[] {
  let certRequestIDs = data?.kind_index["cert-manager.io/v1/CertificateRequest"]

  if (namespaces && namespaces.length > 0) {
    certRequestIDs = certRequestIDs.filter(id => namespaces.includes(data.resource_index[id].resource.metadata?.namespace || ""))
  }

  certRequestIDs = certRequestIDs?.filter(id => isOwnerlessCertificateRequests(data, id))

  // return all certificate requests, deleted or present
  return certRequestIDs?.map(id => new OwnerlessCertificateRequest(data, id)) || []
}

// calculateCertificateRequests creates CertificateRequests
// objects from all the cert-manager certificate requests in the data.
// Optionally, it can filter by namespace.
export function calculateCertificateRequests(data: ClusterData, namespaces?: string[]): CertificateRequest[] {
  let certRequestIDs = data?.kind_index["cert-manager.io/v1/CertificateRequest"]

  if (namespaces && namespaces.length > 0) {
    certRequestIDs = certRequestIDs.filter(id => namespaces.includes(data.resource_index[id].resource.metadata?.namespace || ""))
  }

  certRequestIDs = certRequestIDs?.filter(id => !isOwnerlessCertificateRequests(data, id))

  // return all certificate requests, deleted or present
  return certRequestIDs?.map(id => new CertificateRequest(data, id)) || []
}

// CMCertificate represents a cert-manager certificate. This wraps the resource
// information and accessors for all the related cert-manager resources.
export class CMCertificate extends Certificate {
  id: string
  resource: cm.Certificate
  rule_messages: RuleMessage[]
  // Cert-manager Issuer
  issuedBy: Issuer | string

  certificateRequests: CertificateRequest[]

  constructor(data: ClusterData, id: string) {
    super(data, id)
    // re initializing the usages to avoid dupe values from the superclass call to discoverUsages
    // cmCertificates could be linked to an ingress in the graph, without going through the secret,
    // if the user is using the cert-manager ingress annotations.
    // As such since we are performing another check but via the secret, we might get the same ingress again.
    this.usages = []
    // usage is established through the resources linked to the secret rather than certificate
    data.resource_graph[id]?.forEach(resourceKey => {
      if (data.resource_index[resourceKey]?.resource.kind === "Secret") {
        this.usages.push(...super.discoverUsages(data, resourceKey))
      }
    })
    const item = data.resource_index[id]
    this.id = id
    this.resource = item.resource as cm.Certificate
    this.secret = getSecretForResource(data, id)
    if (this.secret) {
      this.rule_messages = item.rule_messages.concat(this.secret!.rule_messages)
    } else {
      this.rule_messages = item.rule_messages
    }
    this.issuedBy = getCertManagerIssuer(data, this.id)
    this.certificateRequests = getCertificateRequestsForCertificate(data, this)
  }
}

export class CertificateRequest extends Certificate {
  // id is going to be the ID of the latest certificate request
  id: string
  resource: cm.CertificateRequest
  rule_messages: RuleMessage[]
  // Cert-manager Issuer
  issuedBy: Issuer | string
  owner?: Resource
  approvalSet: boolean
  approvalStatus: string
  approvalMessage: string

  constructor(data: ClusterData, id: string, certificate?: CMCertificate) {
    super(data, id)
    // TODO: this is the same logic as for cmCertificate, we should merge both if this ends up being common.
    // cmCertificates could be linked to an ingress in the graph, without going through the secret,
    // if the user is using the cert-manager ingress annotations.
    // As such since we are performing another check but via the secret, we might get the same ingress again.
    this.usages = []
    // usage is established through the resources linked to the secret rather than certificate
    data.resource_graph[id]?.forEach(resourceKey => {
      if (data.resource_index[resourceKey]?.resource.kind === "Secret") {
        this.usages.push(...super.discoverUsages(data, resourceKey))
      }
    })

    const item = data.resource_index[id]
    this.id = id
    this.resource = item.resource as cm.CertificateRequest
    this.secret = getSecretForResource(data, id)
    if (this.secret) {
      this.rule_messages = item.rule_messages.concat(this.secret!.rule_messages)
    } else {
      this.rule_messages = item.rule_messages
    }
    this.issuedBy = getCertManagerIssuer(data, this.id)
    this.approvalSet = false
    this.approvalStatus = ""
    this.approvalMessage = ""

    // only handle the case where there is a single owner and it's a CMCertificate
    if (item.resource.metadata?.ownerReferences?.length === 1) {
      const ownerReference = item.resource.metadata?.ownerReferences[0]
      if (ownerReference.uid === certificate?.resource.metadata?.uid) {
        this.owner = certificate
      }
    }

    item.resource.status?.conditions?.forEach(condition => {
      if (condition.type === "Approved") {
        this.approvalSet = true
        this.approvalStatus = "Approved"
        this.approvalMessage = ""
      }
      if (condition.type === "Denied") {
        this.approvalSet = true
        this.approvalStatus = "Denied"
        this.approvalMessage = condition.message
      }
    })
  }
}

// OwnerlessCertificateRequest represents a CertificateRequest which has no
// CMCertificate
export class OwnerlessCertificateRequest extends Certificate {
  // id is going to be the ID of the latest certificate request
  id: string
  resource: cm.CertificateRequest
  rule_messages: RuleMessage[]
  // Cert-manager Issuer
  issuedBy: Issuer | string
  owner?: Resource
  approvalSet: boolean
  approvalStatus: string
  approvalMessage: string

  constructor(data: ClusterData, id: string) {
    super(data, id)
    // TODO: this is the same logic as for cmCertificate, we should merge both if this ends up being common.
    // cmCertificates could be linked to an ingress in the graph, without going through the secret,
    // if the user is using the cert-manager ingress annotations.
    // As such since we are performing another check but via the secret, we might get the same ingress again.
    this.usages = []
    // usage is established through the resources linked to the secret rather than certificate
    data.resource_graph[id]?.forEach(resourceKey => {
      if (data.resource_index[resourceKey]?.resource.kind === "Secret") {
        this.usages.push(...super.discoverUsages(data, resourceKey))
      }
    })

    const item = data.resource_index[id]
    this.id = id
    this.resource = item.resource as cm.CertificateRequest
    this.secret = getSecretForResource(data, id)
    if (this.secret) {
      this.rule_messages = item.rule_messages.concat(this.secret!.rule_messages)
    } else {
      this.rule_messages = item.rule_messages
    }
    this.issuedBy = getCertManagerIssuer(data, this.id)

    this.approvalSet = false
    this.approvalStatus = ""
    this.approvalMessage = ""

    item.resource.status?.conditions?.forEach(condition => {
      if (condition.type === "Approved") {
        this.approvalStatus = "Approved"
      }
      if (condition.type === "Denied") {
        this.approvalStatus = "Denied"
        this.approvalMessage = condition.reason
      }
    })
  }
}

// -----
// utils
// -----

function getCertificateRequestsForCertificate(data: ClusterData, certificate: CMCertificate): CertificateRequest[] {
  const relatedItems = data.resource_graph[certificate.id] || []
  const certRequestIDs = relatedItems.filter(i => data.resource_index[i]?.resource.kind === "CertificateRequest")
  return certRequestIDs.map(id => new CertificateRequest(data, id, certificate))
}

// isOwnerlessCertificateRequests tests if a certificate request is ephemeral
// OwnerlessCertificateRequests are certificate requests not related to
// certificate object
function isOwnerlessCertificateRequests(data: ClusterData, certificateRequestID: string): boolean {
  const relatedItems = data.resource_graph[certificateRequestID] || []
  return relatedItems.filter(i => data.resource_index[i]?.resource.kind === "Certificate").length === 0
}

// getCertManagerIssuer returns the cert-manager issuer resource, which is
// different from the decoded issuer
function getCertManagerIssuer(data: ClusterData, certificateID: string): Issuer | string {
  const relatedItems = data.resource_graph[certificateID] || []
  const issuers = relatedItems.filter(i => isAllowedIssuer(data.resource_index[i]?.resource.kind))

  if (issuers.length !== 0) {
    return issuers.map(id => new Issuer(data, id))[0]
  }

  return getCertificateIssuerReference(data, certificateID)
}

function getCertificateIssuerReference(data: ClusterData, certificateID: string): string {
  const cert = data.resource_index[certificateID] as CMCertificate | OwnerlessCertificateRequest
  return cert.resource.spec.issuerRef.name
}

function getSecretForResource(data: ClusterData, resourceID: string): CertInventoryIndexItem | undefined {
  let secret: CertInventoryIndexItem | undefined = undefined
  data.resource_graph[resourceID]?.some(resID => {
    const res = data.resource_index[resID]
    if (res.resource.kind === "Secret") {
      secret = res
      return true
    }
    return false
  })
  return secret
}
