import {
  SettingsActions,
  FETCH_SETTINGS,
  FETCH_SETTINGS_SUCCEEDED,
  FETCH_SETTINGS_FAILED,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCEEDED,
  UPDATE_SETTINGS_FAILED,
} from "@actions/settings"

import { LOGOUT_FINISHED, LogoutFinishedAction } from "@actions/auth"

export type SettingsState = {
  settingsByOrg: {
    [key: string]: {
      isLoading: boolean
      loadingError: string
      settings?: Settings
    }
  }
}

const initialState = {
  settingsByOrg: {},
}

export function settingsReducer(state: SettingsState = initialState, action: SettingsActions | LogoutFinishedAction): SettingsState {
  switch (action.type) {
    case FETCH_SETTINGS:
    case UPDATE_SETTINGS: {
      return {
        ...state,
        settingsByOrg: {
          ...state.settingsByOrg,
          [action.orgID]: {
            ...state.settingsByOrg[action.orgID],
            isLoading: true,
            loadingError: "",
          },
        },
      }
    }

    case FETCH_SETTINGS_SUCCEEDED:
    case UPDATE_SETTINGS_SUCCEEDED: {
      return {
        ...state,
        settingsByOrg: {
          ...state.settingsByOrg,
          [action.orgID]: {
            isLoading: false,
            loadingError: "",
            settings: action.settings,
          },
        },
      }
    }

    case FETCH_SETTINGS_FAILED:
    case UPDATE_SETTINGS_FAILED: {
      return {
        ...state,
        settingsByOrg: {
          ...state.settingsByOrg,
          [action.orgID]: {
            ...state.settingsByOrg[action.orgID],
            isLoading: false,
            loadingError: action.errorMessage,
          },
        },
      }
    }

    case LOGOUT_FINISHED: {
      return { ...initialState }
    }

    default:
      return state
  }
}
