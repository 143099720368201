import React, { useState } from "react"

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { Popper, Fade } from "@material-ui/core"

import { InstallationComponent } from "@lib/certInventory"
import { StatusIndicator } from "@components/StatusIndicator"
import { UpdatePopOut } from "./UpdatePopOut"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    updates: {
      display: "inline-flex",
      position: "relative",
      "& p": {
        transition: "all 0.3s ease-in-out",
      },
      "&:hover": {
        cursor: "pointer",
        "& p": {
          color: theme.palette.success.main,
        },
      },
    },
    outdated: {
      "&:hover": {
        cursor: "pointer",
        "& p": {
          color: theme.palette.primary.main,
        },
      },
    },
  }),
)

type UpdateAvailableProps = {
  items: [string, InstallationComponent][]
  updateLink: string
}

export const UpdateAvailable = ({ items, updateLink }: UpdateAvailableProps) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | null>(null)

  if (items.length < 1) return null
  const isOutdated = checkUpdates(items)

  const classes = useStyles()
  const open = Boolean(anchorEl)
  const id = open ? "simple-popper" : undefined
  return (
    <div
      aria-describedby={id}
      className={`${classes.updates} ${isOutdated ? classes.outdated : ""}`}
      onMouseEnter={event => setAnchorEl(event.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
    >
      <StatusIndicator status={isOutdated ? "outdated" : "ok"} message={isOutdated ? "Update Available" : "Up to date"} iconsOnly />

      <Popper open={open} id={id} anchorEl={anchorEl} transition style={{ zIndex: 100 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <UpdatePopOut items={items} updateLink={updateLink} isOutdated={isOutdated} />
          </Fade>
        )}
      </Popper>
    </div>
  )
}

const checkUpdate = (element: [string, InstallationComponent]): boolean => !element[1].is_latest
const checkUpdates = (list: [string, InstallationComponent][]): boolean => list.filter(checkUpdate).length > 0
