import { ClusterData, RuleMessage, ResourceStatus } from "../types"
import { Resource, collectResources } from "./resource"
import * as cm from "../cert-manager"

export function calculateIngresses(data: ClusterData): Ingress[] {
  const generateIngress = (data: ClusterData, id: string) => new Ingress(data, id)
  return collectResources(data, generateIngress, "networking.k8s.io/v1/Ingress", "networking.k8s.io/v1beta1/Ingress")
}

export class Ingress extends Resource {
  id: string
  resource: cm.Ingress
  rule_messages: RuleMessage[]
  status: ResourceStatus
  clusterId: string
  deleted_at?: string
  terms?: string[]

  constructor(data: ClusterData, id: string) {
    super()
    const item = data.resource_index[id]
    this.id = id
    this.resource = item.resource
    this.rule_messages = item.rule_messages
    this.status = item.status ? item.status : { type: "unknown", message: "Unknown" }
    this.deleted_at = item.deleted_at
    const annotationTerms = Object.entries(item.resource.metadata?.annotations ? item.resource.metadata?.annotations : {}).flatMap(i => i)
    const labelsTerms = Object.entries(item.resource.metadata?.labels ? item.resource.metadata?.labels : {}).flatMap(i => i)
    this.terms = [...annotationTerms, ...labelsTerms]
    this.clusterId = data.cluster_id
  }
}
