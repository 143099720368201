const demoParamKeys = ["include_demo"]
export type DemoOptions = {
  includeDemo?: boolean
}

// selectDemoParams selects the demo related parameters from the first argument and all of the second argument and returns a URLSearchParams object combining both.
export function selectDemoParams(params: URLSearchParams, extraParams?: URLSearchParams) {
  // demoParamKeys contains all the params that control the "demo environment".
  // The backend uses these params to synthetize demo cluster information.
  const resultParams = new URLSearchParams(extraParams)
  params.forEach((v, k) => {
    if (demoParamKeys.includes(k)) {
      resultParams.set(k, v)
    }
  })
  return resultParams
}

// genDemoParamsFromOptions generates a URLSearchParams object for the demo environment
export function genDemoParamsFromOptions(opts: DemoOptions) {
  const params = new URLSearchParams()
  if (opts.includeDemo !== undefined) {
    params.set("include_demo", String(opts.includeDemo))
  }
  return params
}
