import React from "react"
import { Typography } from "@material-ui/core"
import { MessageContainer } from "@components/MessageContainer"
import { NotesIcon } from "@components/Icons"

type HostPathsInfoProps = {
  annotations: {
    [key: string]: string
  }
}

export function AnnotationsInfo({ annotations }: HostPathsInfoProps) {
  return (
    <>
      {Object.keys(annotations).map(key => (
        <MessageContainer icon={<NotesIcon />} key={key}>
          <Typography color="textPrimary" variant="body2" display="block">
            {key}
          </Typography>
          <Typography color="textSecondary" variant="body2" display="block">
            {annotations[key]}
          </Typography>
        </MessageContainer>
      ))}
    </>
  )
}
