import React, { Fragment } from "react"
import { createStyles, Theme, makeStyles, Grid, Divider, Typography, Paper } from "@material-ui/core"

import { Route } from "@lib/certInventory/resources"
import { PathIcon } from "@components/Icons"
import { MessageContainer } from "@components/MessageContainer"
import { Issues } from "../Issues"
import { AnnotationsInfo } from "../AnnotationsInfo"
import { renderSeverity } from "../../Ingresses/helpers"
import { CloseButton } from "../CloseButton"
import { Overlay } from "../Overlay"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0.5),
      position: "relative",
    },
    panel: {
      minHeight: "100vh",
      width: 400,
      height: "100%",
      overflowX: "auto",
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 100,
      [theme.breakpoints.down(480)]: {
        width: "100vw",
      },
    },
    close: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
    section: {
      margin: theme.spacing(3, 2),
    },
    clusterTitle: {
      fontSize: "1rem",
      fontWeight: 300,
      borderRadius: "0.3rem",
      padding: theme.spacing(1, 0),
    },
    marginAround: {
      margin: theme.spacing(2),
    },
    alert: {
      display: "flex",
      marginTop: theme.spacing(2),
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    subTitleContainer: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
  }),
)

function renderField(key: string, value: string | undefined) {
  return value ? (
    <MessageContainer>
      {key} {"> "}
      <Typography color="textPrimary" variant="body2" display="inline">
        {value}
      </Typography>
    </MessageContainer>
  ) : (
    <MessageContainer>
      {key} {"> "}
      <strong>N/A</strong>
    </MessageContainer>
  )
}

type RouteDetailsProp = {
  route: Route
  selectedHostname?: string
  onClose: () => void
  showClusterName?: boolean
}

export function RouteDetails({ route, onClose, selectedHostname, showClusterName }: RouteDetailsProp) {
  const classes = useStyles()
  const selectedHostnamePath = route.resource.spec?.path ? route.resource.spec?.path : "/"
  const hasTLS = route.resource.spec?.tls

  return (
    <Overlay onClose={onClose}>
      <Paper className={classes.panel} elevation={3} data-tut="details-panel">
        <div className={classes.root}>
          <CloseButton className={classes.close} onClick={onClose} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h4">
                  Openshift Route Details
                </Typography>
              </Grid>
            </Grid>
            <Typography color="textPrimary" variant="body1" className={classes.subTitleContainer}>
              {route.resource.metadata?.namespace} / {route.resource.metadata?.name}
            </Typography>
            {showClusterName && (
              <Typography gutterBottom variant="h6" className={classes.clusterTitle}>
                Cluster: <strong>{route.clusterId}</strong>
              </Typography>
            )}
          </div>
          {route.rule_messages.length > 0 && (
            <Fragment>
              <Divider variant="middle" />
              <div className={classes.marginAround}>
                <Typography gutterBottom variant="h5">
                  Issues
                </Typography>
                <Typography gutterBottom variant="body1" display="inline">
                  Severity:
                </Typography>{" "}
                {renderSeverity(route.rule_messages)}
                <br />
                <br />
                <Typography gutterBottom color="textSecondary" variant="body2">
                  These are some potential issues with your route:
                </Typography>
                {route.rule_messages.map(({ messages, alert_id, rule: { severity } }) => (
                  <Issues key={alert_id} id={alert_id} messages={messages} severity={["warning", "warningMedium", "error"][severity]} />
                ))}
              </div>
            </Fragment>
          )}
          <Divider variant="middle" />
          <div className={classes.marginAround}>
            <Typography gutterBottom variant="h5">
              {selectedHostname}
            </Typography>
            <MessageContainer icon={<PathIcon />}>
              <Typography color="textPrimary" variant="body2" display="block">
                {selectedHostnamePath}
              </Typography>
              {route.resource.spec?.port && (
                <Typography color="textSecondary" variant="body2" display="block">
                  {route.resource.spec?.port.targetPort}
                </Typography>
              )}
            </MessageContainer>
          </div>
          <Divider variant="middle" />
          <div className={classes.marginAround}>
            <Typography gutterBottom variant="h5">
              TLS
            </Typography>
            {hasTLS ? (
              <Fragment>
                {renderField("Insecure Edge Termination Policy", route.resource.spec?.tls?.insecureEdgeTerminationPolicy)}
                {renderField("TLS Key", route.resource.spec?.tls?.key)}
                {renderField("Termination", route.resource.spec?.tls?.termination)}
                {renderField("Destination CA Certificate", route.resource.spec?.tls?.destinationCACertificate)}
                {renderField("Certificate", route.resource.spec?.tls?.certificate)}
                {renderField("CA Certificate", route.resource.spec?.tls?.caCertificate)}
              </Fragment>
            ) : (
              <MessageContainer>Your route seems to be insecure as it does not have a valid TLS configuration. </MessageContainer>
            )}
          </div>
          {route.resource.metadata?.annotations && (
            <Fragment>
              <Divider variant="middle" />
              <div className={classes.marginAround}>
                <Typography gutterBottom variant="h5">
                  Annotations
                </Typography>
                <AnnotationsInfo annotations={route.resource.metadata?.annotations} />
              </div>
            </Fragment>
          )}
        </div>
      </Paper>
    </Overlay>
  )
}
