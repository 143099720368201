import React, { ReactElement } from "react"
import { Grid, Typography } from "@material-ui/core"

import { Snippet } from "@components/Snippet"
import { InstructionStep } from "../../InstructionStep"
import { GoogleCASIssuer, IssuerCommonFields } from "../../types"
import { renderIssuerYaml } from "../../renderYaml"
import { renderGoogleCASYaml } from "./renderGoogleCASYaml"
import { VerifyIssuerInitialized } from "../../VerifyIssuerInitialized"

type GoogleCASInstructionsProps = {
  common: IssuerCommonFields
  issuer: GoogleCASIssuer
}

export const GoogleCASInstructions = ({ common, issuer }: GoogleCASInstructionsProps): ReactElement => {
  const issuerFilename = "google-cas-issuer.yml"
  const isUsingNamespace = common.useNamespace === "use-namespace"
  const namespace = isUsingNamespace ? common.namespace : "cert-manager"
  const GoogleCASYaml = renderGoogleCASYaml(common.name, issuer)
  const kind = isUsingNamespace ? "GoogleCASIssuer" : "GoogleCASClusterIssuer"
  const issuerYaml = renderIssuerYaml(common.name, isUsingNamespace, namespace, GoogleCASYaml, kind, "cas-issuer.jetstack.io/v1beta1")

  return (
    <Grid container direction="column" spacing={4}>
      <InstructionStep step={1}>
        <Typography gutterBottom>
          If you haven't already done so, you must install the{" "}
          <a href="https://github.com/jetstack/google-cas-issuer/blob/main/README.md">Google CAS issuer</a>. Following this guide will also
          install the Google CAS Custom Resource Definitions used in the next step.
        </Typography>
      </InstructionStep>

      <InstructionStep step={2}>
        <Typography gutterBottom>
          Copy the following configuration and save it in a file <strong>{issuerFilename}</strong>.
        </Typography>
        <Snippet showLineNumbers language="yaml" text={issuerYaml} />
      </InstructionStep>

      <InstructionStep step={3}>
        <Typography gutterBottom>
          Create the <strong>Issuer</strong>.
        </Typography>
        <Snippet language="shell" text={`kubectl create -f ${issuerFilename}`} />
      </InstructionStep>

      <InstructionStep step={4}>
        <VerifyIssuerInitialized name={common.name} namespace={namespace} label={issuer.label} kind={kind} />
      </InstructionStep>
    </Grid>
  )
}
