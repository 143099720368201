import { withStyles, LinearProgress } from "@material-ui/core"

const ProgressBar = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 50,
    marginTop: theme.spacing(2),
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius: 50,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress)

export { ProgressBar }
