import { K8SGenericResource, ResourceStatus, RuleMessage, ClusterData } from "../types"

export abstract class Resource {
  // id is the unique ID of the resource.
  abstract id: string
  // resource is the object that contains the native k8s properties of the resource.
  abstract resource: K8SGenericResource
  // rule_messages are the rules and results that are firing for this resource.
  abstract rule_messages: RuleMessage[]
  // status holds presentational status data for the resource
  abstract status: ResourceStatus
  // deletedAt timestamp when the resource has been deleted
  abstract deleted_at?: string
  // clusterId hold the cluster if of which the resource is part of
  abstract clusterId: string

  rawRuleMessages(): string[] {
    return this.rule_messages.flatMap(msg => msg.messages)
  }

  // hash returns a sha1 hash of the resource based on its ID
  hash(): string {
    const crypto = require("crypto-browserify")
    const shasum = crypto.createHash("sha1")
    shasum.update(this.id + this.clusterId)
    return shasum.digest("hex").substring(0, 7)
  }
}

export function collectResources<T extends Resource>(
  data: ClusterData,
  handler: (data: ClusterData, id: string) => T,
  ...kindIDs: string[]
): T[] {
  let allKindIDs: string[] = []
  allKindIDs = allKindIDs.concat(...kindIDs.map(id => data?.kind_index[id] || []))
  allKindIDs = allKindIDs.filter(id => !data.resource_index[id].deleted_at)
  return allKindIDs?.map(id => handler(data, id)) || []
}
