import React from "react"
import { Grid, Typography } from "@material-ui/core"

import { Snippet } from "@components/Snippet"
import { InstructionStep } from "../../InstructionStep"
import { IssuerCommonFields, VaultIssuer } from "../../types"
import { renderSecretYaml, renderIssuerYaml } from "../../renderYaml"
import { ButtonSeeDoc } from "../../ButtonSeeDoc"
import { renderVaultYaml } from "./renderVaultYaml"
import { VerifyIssuerInitialized } from "../../VerifyIssuerInitialized"

const AppRoleInstructions = ({ common, issuer }: VaultInstructionsProps) => {
  const issuerFilename = "approle-issuer.yml"
  const secretFilename = "approle-secret.yml"
  const isUsingNamespace = common.useNamespace === "use-namespace"
  const namespace = isUsingNamespace ? common.namespace : "cert-manager"
  const appRole = issuer.auth.appRole

  const secretData = { secretId: "<HERE SET YOUR SECRET ID>" }
  const secretYaml = renderSecretYaml(appRole.secretName, namespace, secretData)

  const vaultYaml = renderVaultYaml(issuer)
  const issuerYaml = renderIssuerYaml(common.name, common.useNamespace === "use-namespace", namespace, vaultYaml)

  const kind = isUsingNamespace ? "issuer" : "clusterissuer"

  return (
    <>
      <InstructionStep step={1}>
        <Typography gutterBottom>
          Be sure that the <strong>approle</strong> auth method has been enabled and that the <strong>AppRole</strong> you want to use has
          been created.
        </Typography>
        <ButtonSeeDoc link="https://www.vaultproject.io/docs/auth/approle.html">See Vault documentation</ButtonSeeDoc>
      </InstructionStep>

      <InstructionStep step={2}>
        <Typography>
          Create a <strong>Secret</strong> resource file <strong>{secretFilename}</strong> with the following content and change{" "}
          <strong>data.secretId</strong> with your own secret ID:
        </Typography>
        <Snippet showLineNumbers language="yaml" text={secretYaml} />
      </InstructionStep>

      <InstructionStep step={3}>
        <Typography>
          Create the <strong>Secret</strong> in Kubernetes.
        </Typography>
        <Snippet language="shell" text={`kubectl create -f ${secretFilename}`} />
      </InstructionStep>

      <InstructionStep step={4}>
        <Typography>
          Create an <strong>Issuer</strong> resource file <strong>{issuerFilename}</strong> with the following content:
        </Typography>
        <Snippet showLineNumbers language="yaml" text={issuerYaml} />
      </InstructionStep>

      <InstructionStep step={5}>
        <Typography>
          Create the <strong>Issuer</strong> in Kubernetes.
        </Typography>
        <Snippet language="shell" text={`kubectl create -f ${issuerFilename}`} />
      </InstructionStep>

      <InstructionStep step={6}>
        <VerifyIssuerInitialized name={common.name} label={issuer.label} namespace={namespace} kind={kind} />
      </InstructionStep>
    </>
  )
}

const TokenInstructions = ({ common, issuer }: VaultInstructionsProps) => {
  const issuerFilename = "token-issuer.yml"
  const secretFilename = "token-secret.yml"
  const isUsingNamespace = common.useNamespace === "use-namespace"
  const namespace = isUsingNamespace ? common.namespace : "cert-manager"
  const authToken = issuer.auth.token

  const secretData = { token: "<HERE SET YOUR TOKEN>" }
  const secretYaml = renderSecretYaml(authToken.secretName, namespace, secretData)

  const vaultYaml = renderVaultYaml(issuer)
  const issuerYaml = renderIssuerYaml(common.name, common.useNamespace === "use-namespace", namespace, vaultYaml)

  const kind = isUsingNamespace ? "issuer" : "clusterissuer"

  return (
    <>
      <InstructionStep step={1}>
        <Typography gutterBottom>
          Create a <strong>Vault auth token</strong>.
        </Typography>
        <ButtonSeeDoc link="https://www.vaultproject.io/docs/concepts/tokens">See Vault documentation</ButtonSeeDoc>
      </InstructionStep>

      <InstructionStep step={2}>
        <Typography>
          Create a <strong>Secret</strong> resource file <strong>{secretFilename}</strong> with the following content and change{" "}
          <strong>data.token</strong> value with your own auth token:
        </Typography>
        <Snippet showLineNumbers language="yaml" text={secretYaml} />
      </InstructionStep>

      <InstructionStep step={3}>
        <Typography>
          Create the <strong>Secret</strong> in Kubernetes.
        </Typography>
        <Snippet language="shell" text={`kubectl create -f ${secretFilename}`} />
      </InstructionStep>

      <InstructionStep step={4}>
        <Typography>
          Create an <strong>Issuer</strong> resource file <strong>{issuerFilename}</strong> with the following content:
        </Typography>
        <Snippet showLineNumbers language="yaml" text={issuerYaml} />
      </InstructionStep>

      <InstructionStep step={5}>
        <Typography>
          Create the <strong>Issuer</strong> in Kubernetes.
        </Typography>
        <Snippet language="shell" text={`kubectl create -f ${issuerFilename}`} />
      </InstructionStep>

      <InstructionStep step={6}>
        <VerifyIssuerInitialized name={common.name} label={issuer.label} namespace={namespace} kind={kind} />
      </InstructionStep>
    </>
  )
}

const KubernetesServiceAccountInstructions = ({ common, issuer }: VaultInstructionsProps) => {
  const issuerFilename = "service-account-issuer.yml"
  const isUsingNamespace = common.useNamespace === "use-namespace"
  const kind = isUsingNamespace ? "issuer" : "clusterissuer"
  const namespace = isUsingNamespace ? common.namespace : "cert-manager"

  const vaultYaml = renderVaultYaml(issuer)
  const issuerYaml = renderIssuerYaml(common.name, common.useNamespace === "use-namespace", namespace, vaultYaml)

  return (
    <>
      <InstructionStep step={1}>
        <Typography gutterBottom>
          Be sure that the <strong>Kubernetes</strong> auth method has been enabled and that you have access to the{" "}
          <strong>Service Account</strong> you want to use.
        </Typography>
        <ButtonSeeDoc link="https://www.vaultproject.io/docs/auth/kubernetes">See Vault documentation</ButtonSeeDoc>
      </InstructionStep>

      <InstructionStep step={2}>
        <Typography>
          Create an <strong>Issuer</strong> resource file <strong>{issuerFilename}</strong> with the following content:
        </Typography>
        <Snippet showLineNumbers language="yaml" text={issuerYaml} />
      </InstructionStep>

      <InstructionStep step={5}>
        <Typography>
          Create the <strong>Issuer</strong> in Kubernetes.
        </Typography>
        <Snippet language="shell" text={`kubectl create -f ${issuerFilename}`} />
      </InstructionStep>

      <InstructionStep step={6}>
        <VerifyIssuerInitialized name={common.name} label={issuer.label} namespace={namespace} kind={kind} />
      </InstructionStep>
    </>
  )
}

type VaultInstructionsProps = {
  common: IssuerCommonFields
  issuer: VaultIssuer
}

export const VaultInstructions = ({ common, issuer }: VaultInstructionsProps) => {
  return (
    <Grid container direction="column" spacing={4}>
      {issuer.authType === "appRole" && <AppRoleInstructions common={common} issuer={issuer} />}
      {issuer.authType === "token" && <TokenInstructions common={common} issuer={issuer} />}
      {issuer.authType === "kubernetesServiceAccount" && <KubernetesServiceAccountInstructions common={common} issuer={issuer} />}
    </Grid>
  )
}
