import { floor } from "lodash"

// Given a value between 1-100 from the expiring soon slider
// calculate the correct value in hours for query to backend
export const calculateHoursForQuery = (expiryValue: number) => {
  if (!expiryValue) return null
  if (expiryValue <= 20) {
    return floor((24 / 20) * expiryValue)
  }
  if (expiryValue <= 40) {
    return Math.ceil((48 / 20) * (expiryValue - 20)) + 24
  }
  if (expiryValue <= 60) {
    return Math.ceil((96 / 20) * (expiryValue - 40)) + 24 + 48
  }
  if (expiryValue <= 80) {
    return Math.ceil((504 / 20) * (expiryValue - 60)) + 24 + 48 + 96
  }
  if (expiryValue <= 100) {
    return Math.ceil((1344 / 20) * (expiryValue - 80)) + 24 + 48 + 96 + 504
  }
  return null
}

const getTimeString = (hours: number, unit: string) => (Number.isInteger(hours) ? `${hours}${unit}` : `${hours.toFixed(1)}${unit}`)

// Given an hour value calculate the correct label value
// for the expiring soon slider
export const calculateLabel = (expiryValue: number) => {
  const hours = calculateHoursForQuery(expiryValue)
  if (!hours) return null
  if (hours <= 23) {
    return getTimeString(hours, "h")
  }
  if (hours <= 167) {
    return getTimeString(hours / 24, "d")
  }
  if (hours <= 671) {
    return getTimeString(hours / 24 / 7, "w")
  }
  if (hours <= 2016) {
    return getTimeString(hours / 24 / 7 / 4, "m")
  }
  return null
}
