import React from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core"

import {
  Certificate,
  OwnerlessCertificateRequest,
  CertificateRequest,
  Ingress,
  Issuer,
  Resource,
  Route,
} from "@lib/certInventory/resources"
import { ErrorMessage } from "@components/ErrorMessage"
import { LoadingMessage } from "@components/LoadingMessage"
import { CertificateDetails } from "./CertificateDetails"
import { CertificateRequestDetails } from "./CertificateRequestDetails"
import { IssuerDetails } from "./IssuerDetails"
import { IngressDetails } from "./IngressDetails"
import { RouteDetails } from "./RouteDetails"
import { useHandleEscape } from "@hooks/useHandleEscape"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0.5),
      position: "relative",
    },
  }),
)

type DetailsPanelProps = {
  isLoading: boolean
  error: string
  resource?: Resource
  selectedHostname?: string
  onClose: () => void
  handleSelect: (res: Resource) => void
  showClusterName?: boolean
}

export function DetailsPanel({ isLoading, error, resource, onClose, selectedHostname, handleSelect, showClusterName }: DetailsPanelProps) {
  const classes = useStyles()
  useHandleEscape(onClose)

  const renderDetails = () => {
    if (resource instanceof OwnerlessCertificateRequest) {
      return (
        <CertificateRequestDetails
          certificateRequest={resource}
          onClose={onClose}
          handleSelect={handleSelect}
          showClusterName={showClusterName}
        />
      )
    } else if (resource instanceof CertificateRequest) {
      return (
        <CertificateRequestDetails
          certificateRequest={resource}
          onClose={onClose}
          handleSelect={handleSelect}
          showClusterName={showClusterName}
        />
      )
    } else if (resource instanceof Certificate) {
      return <CertificateDetails certificate={resource} onClose={onClose} handleSelect={handleSelect} showClusterName={showClusterName} />
    } else if (resource instanceof Issuer) {
      return <IssuerDetails issuer={resource} onClose={onClose} showClusterName={showClusterName} />
    } else if (resource instanceof Ingress) {
      return <IngressDetails ingress={resource} onClose={onClose} selectedHostname={selectedHostname} showClusterName={showClusterName} />
    } else if (resource instanceof Route) {
      return <RouteDetails route={resource} onClose={onClose} selectedHostname={selectedHostname} showClusterName={showClusterName} />
    }
    return null
  }

  return (
    <div className={classes.root}>
      {isLoading ? <LoadingMessage label="Loading details..." /> : error ? <ErrorMessage message={error} /> : renderDetails()}
    </div>
  )
}
