import React from "react"
import { Link } from "react-router-dom"
import { createStyles, makeStyles, Theme } from "@material-ui/core"

import { HOME_PATH } from "@routes"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    logoContainer: {
      display: "inline-flex",
      flexDirection: "column",
      alignItems: "center",
      margin: theme.spacing(0, "auto", 0, "auto"),
      color: "white",
      textDecoration: "none",
      [theme.breakpoints.down(768)]: {
        flexDirection: "row",
        margin: theme.spacing(0, 0, 0, 4),
      },
      "& svg": {
        width: "100%",
        padding: theme.spacing(1, 2),
      },
    },
    toolBarLogo: {
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      maxWidth: "19rem",
      "& img": {
        width: "100%",
      },
    },
    textContainer: {
      display: "inline-flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down(768)]: {
        alignItems: "flex-start",
        marginLeft: theme.spacing(2),
      },
    },
    logoLabel: {
      fontSize: "1.25rem",
    },
    beta: {
      textTransform: "uppercase",
      fontSize: "0.75rem",
      color: theme.palette.primary.main,
    },
  })
})

type LogoProps = {
  onClick: () => void
}
// <style>.st0{fill:#fff}.st1{fill:none;stroke:#ff6333;stroke-width:24.02;stroke-linecap:round;stroke-linejoin:round}</style>

export const Logo = ({ onClick }: LogoProps) => {
  const classes = useStyles()

  return (
    <Link to={HOME_PATH} className={classes.logoContainer} onClick={onClick} title="TLS Protect for Kubernetes">
      <div className={classes.toolBarLogo}>
        <img src="/static/tls-protect-for-kubernetes-full-logo-light.svg" alt="TLS Protect for Kubernetes" />
      </div>
    </Link>
  )
}
