import React, { ReactElement } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

type Props = {
  color?: "grey"
  [x: string]: unknown
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& path": {
        stroke: theme.palette.primary.main,
      },
    },
    grey: {
      "& path": {
        stroke: theme.palette.grey[600],
      },
    },
  }),
)

export function CertificateIcon(props: Props): ReactElement {
  const classes = useStyles()

  return (
    <svg
      data-testid="certificate-icon"
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.color === "grey" ? classes.grey : classes.root}
      {...props}
    >
      <path d="M14 3v4a1 1 0 001 1h4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 8V5a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2h-5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 17a3 3 0 100-6 3 3 0 000 6zM4.5 17L3 22l3-1.5L9 22l-1.5-5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
