import React, { ReactElement, PropsWithChildren } from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      backgroundColor: theme.palette.background.default,
      borderRadius: "0.25rem",
      padding: theme.spacing(1, 2, 1.3, 2),
      marginBottom: theme.spacing(1),
    },
    clickable: {
      cursor: "pointer",
    },
    content: {
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
      fontWeight: 300,
      lineHeight: 1.45,
      wordBreak: "break-word",
      width: "100%",
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    iconAlignTop: {
      alignItems: "unset",
    },
    marginTop: {
      marginTop: theme.spacing(0.7),
    },
  }),
)

export type Props = {
  icon?: ReactElement
  variant?: "iconAlignTop" | ""
  onClick?: () => void
}

export function MessageContainer({ icon, onClick, variant = "", children }: PropsWithChildren<Props>): ReactElement {
  const classes = useStyles()
  const containerStyles = `${classes.root} ${onClick ? classes.clickable : ""} ${variant ? classes[variant] : ""}`

  return (
    <div className={containerStyles} onClick={onClick}>
      {icon && <div className={`${classes.icon} ${variant ? classes.marginTop : ""}`}>{icon}</div>}
      <div className={classes.content}>{children}</div>
    </div>
  )
}
