import { GoogleCASIssuer } from "../../types"

export type GoogleCASYaml = {
  project: string
  location: string
  caPoolId: string
  // optional. Only needed if not using workloadID
  credentials?: {
    name: string
    key: string
  }
}

const renderGoogleCASYaml = (name: string, issuer: GoogleCASIssuer): GoogleCASYaml => {
  if (issuer.usingWorkloadID) {
    return {
      project: issuer.project,
      location: issuer.location,
      caPoolId: issuer.caPoolId,
    }
  }

  return {
    project: issuer.project,
    location: issuer.location,
    caPoolId: issuer.caPoolId,
    credentials: issuer.credentials,
  }
}

export { renderGoogleCASYaml }
