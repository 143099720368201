import React from "react"
import { createStyles, makeStyles, Theme, SnackbarContent } from "@material-ui/core"
import ErrorIcon from "@material-ui/icons/Error"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#fff",
      backgroundColor: theme.palette.error.main,
    },

    message: {
      display: "flex",
      alignItems: "center",
    },
    text: {
      marginLeft: theme.spacing(1.2),
    },
  }),
)

type ErrorMessageProps = {
  message: string
}

export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  const classes = useStyles()
  return (
    <SnackbarContent
      className={classes.root}
      message={
        <span className={classes.message}>
          <ErrorIcon />
          <span className={classes.text}>{message}</span>
        </span>
      }
    />
  )
}
