import React, { ReactElement } from "react"

type Props = {
  [x: string]: unknown
}

export function DiagonalArrowIcon(props: Props): ReactElement {
  return (
    <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.08366 2.91699L2.91699 7.08366" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.33301 2.91699H7.08301V6.66699" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
