export function initialize(user: UserType, orgID: string) {
  window.pendo &&
    window.pendo.initialize({
      visitor: {
        id: user.user_id,
        // TODO: check with Telemetry team if we need to send these values
        // email: user.email,
        // full_name: user.name,
      },
      account: {
        id: orgID,
        // TODO: add rest of organization info?
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    })
}

export function load(apiKey: string) {
  window.loadPendo && window.loadPendo(apiKey)
}
