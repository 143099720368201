import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-block",
      padding: theme.spacing(0.5, 1.2),
      border: `1px solid #fff`,
      color: theme.palette.success.main,
      borderRadius: "5px",
      fontSize: theme.typography.pxToRem(10),
      textTransform: "uppercase",
      fontWeight: 700,
      margin: theme.spacing(0, 0.5),
    },
    high: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    medium: {
      borderColor: theme.palette.warning.dark,
      color: theme.palette.warning.dark,
    },
    low: {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
    },
    ok: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
  }),
)

type Props = {
  label: string
  severity?: "high" | "medium" | "low" | "ok"
}

export function SeverityTag({ label, severity = "ok" }: Props) {
  const classes = useStyles()

  return <div className={`${classes.root} ${classes[severity]}`}>{label}</div>
}
