import React, { ReactElement } from "react"

import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      borderBottom: `0.375rem solid ${theme.palette.primary.main}`,

      textAlign: "center",
      margin: theme.spacing(1, 0),
      paddingBottom: theme.spacing(1),
    },
    container: {
      padding: theme.spacing(0, 0, 1, 0),
    },
    icon: {
      transform: "scale(1.8)",
      margin: theme.spacing(0, 2, 2, 2),
    },
    iconContainer: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      transform: "scale(1.2)",
      background: theme.palette.primary.main,
      width: "2.5rem",
      height: "2.5rem",
      borderRadius: "5rem",

      "& svg path": {
        stroke: "#fff",
      },
    },
    noIconBackground: {
      margin: theme.spacing(2.4, 0),
    },
  }),
)

type BlurbFeatureProps = {
  icon: ReactElement
  text: string
  noIconBackground?: boolean
}

export const BlurbFeature = ({ icon, text, noIconBackground }: BlurbFeatureProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={`${classes.icon} ${noIconBackground ? classes.noIconBackground : classes.iconContainer}`} data-testid="blurb-icon">
          {icon}
        </div>
        <Typography>{text}</Typography>
      </div>
    </div>
  )
}
