import { FilterType } from "@selectors/filters"
export const UPDATE_FILTERS = "UPDATE_FILTERS"

type FilterPayload = { [x in FilterType]?: { [x: string]: string } }
export interface UpdateFiltersAction {
  type: typeof UPDATE_FILTERS
  filter: FilterPayload
}

export const updateFilters = (filter: FilterPayload): UpdateFiltersAction => ({
  type: UPDATE_FILTERS,
  filter,
})

export type FiltersActions = UpdateFiltersAction
