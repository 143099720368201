import { CalculatedResources, AllCertsGroupedByIdentity } from "@lib/certInventory/resources"
import { ClusterData } from "@lib/certInventory"

import { UpdateCurrentOrgAction, UPDATE_CURRENT_ORG } from "../actions/orgs"
import { LOGOUT_FINISHED, LogoutFinishedAction } from "../actions/auth"

import {
  CertInventoryActions,
  FETCH_CERT_INVENTORY,
  FETCH_CERT_INVENTORY_SUCCESS,
  FETCH_CERT_INVENTORY_FAILED,
} from "../actions/certInventory"

export type CertInventoryState = {
  dataByCluster: DataByCluster
}

export type DataByCluster = {
  [key: string]: {
    isLoading: boolean
    loadingError: string
    certInventory?: ClusterData
    calculatedResources: CalculatedResources
    allCertsGroupedByIdentity: AllCertsGroupedByIdentity
  }
}

const initialState = {
  dataByCluster: {},
}

export function certInventoryReducer(
  state: CertInventoryState = initialState,
  action: UpdateCurrentOrgAction | LogoutFinishedAction | CertInventoryActions,
): CertInventoryState {
  switch (action.type) {
    case FETCH_CERT_INVENTORY: {
      const cluster = state.dataByCluster[action.clusterID] || {}
      return {
        ...state,
        dataByCluster: {
          ...state.dataByCluster,
          [action.clusterID]: {
            ...cluster,
            isLoading: true,
            loadingError: "",
          },
        },
      }
    }

    case FETCH_CERT_INVENTORY_SUCCESS: {
      return {
        ...state,
        dataByCluster: {
          ...state.dataByCluster,
          [action.clusterID]: {
            isLoading: false,
            loadingError: "",
            certInventory: action.certInventory,
            calculatedResources: action.calculatedResources,
            allCertsGroupedByIdentity: action.allCertsGroupedByIdentity,
          },
        },
      }
    }

    case FETCH_CERT_INVENTORY_FAILED: {
      const cluster = state.dataByCluster[action.clusterID] || {}
      return {
        ...state,
        dataByCluster: {
          ...state.dataByCluster,
          [action.clusterID]: {
            ...cluster,
            isLoading: false,
            loadingError: action.errorMessage,
          },
        },
      }
    }

    case LOGOUT_FINISHED:
    case UPDATE_CURRENT_ORG: {
      return initialState
    }

    default:
      return state
  }
}
