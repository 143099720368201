import { AllCerts, GroupedIdentities } from "@lib/certInventory/resources"
import { sortByDate } from "@utils/sorting"

// It looks at all the identities of certificates, and groups them based on similar identity (currently only spiffe)
// Based on the grouping, we will have a main Certificate which is the newest created, and then a group object with its history of certificates we wont show
// we return All the certificates minus the spiffe expired certificates, as well as an object with identities where we show which one we kept to show in the list
// and which goes into history, based on common identity (currently only spiffe)
export const filterOutByIdentity = (allCerts: AllCerts[]) => {
  const tempByIdentity: GroupedIdentities = {}

  const filtered: AllCerts[] = allCerts.filter(cert => {
    const identities = cert.getIdentities().filter(({ value }) => value.includes("spiffe://"))

    const hasSpiffeIdentity = identities.length > 0
    if (hasSpiffeIdentity) {
      const currentIdentity = identities[0].value
      if (tempByIdentity[currentIdentity]) {
        if (
          sortByDate(
            tempByIdentity[currentIdentity].main.resource?.metadata?.creationTimestamp,
            cert.resource?.metadata?.creationTimestamp,
          ) >= 0
        ) {
          tempByIdentity[currentIdentity].group.push(tempByIdentity[currentIdentity].main)
          tempByIdentity[currentIdentity].main = cert
        } else {
          tempByIdentity[currentIdentity].group.push(cert)
        }
      } else {
        tempByIdentity[currentIdentity] = {
          main: cert,
          group: [],
        }
      }
    }

    return !hasSpiffeIdentity
  })

  const selectedToShow = Object.keys(tempByIdentity).map(key => tempByIdentity[key].main)

  return {
    filteredByIdentities: [...filtered, ...selectedToShow],
    identities: tempByIdentity,
  }
}
