import React, { PropsWithChildren, useEffect } from "react"
import ReactGA from "react-ga"
import { useNavigate, useParams } from "react-router-dom"
import Tour from "reactour2"
import { Typography, makeStyles, createStyles, Theme, Button } from "@material-ui/core"
import { getClusterID, getOrgID, pathToCertInventoryCluster } from "@routes"
import { CLUSTER_VIEW } from "@components/CertInventory/constants"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helper: {
      padding: theme.spacing(4),
      lineHeight: 1.5,
      '& [data-tour-elem="controls"]': {
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
      },
      '& [data-tour-elem="badge"]': {
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
        fontWeight: "700",
        fontFamily: theme.typography.fontFamily,
        border: `2px solid ${theme.palette.primary.main}`,
        lineHeight: 1.6,
      },
    },
  }),
)

type DemoTourProps = {
  isOpen: boolean
  onClose: () => void
}

const DemoTour = ({ isOpen, onClose }: DemoTourProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const orgID = getOrgID(params)
  const clusterIDFromURL = getClusterID(params)

  const stepStyle = {
    maxWidth: "26rem",
  }
  const steps = [
    {
      selector: '[data-tut="nav-bar"]',
      content: (
        <CustomTooltip heading="Resource Types">
          TLS Protect for Kubernetes gathers information about various types of resources in your cluster and shows them here, along with
          highlighting any issues that we detect related to those resources.
        </CustomTooltip>
      ),
      style: stepStyle,
    },
    {
      selector: '[data-tut="certificates"]',
      content: (
        <CustomTooltip heading="Certificates">
          Here you can see all the certificates currently in the cluster.
          <br />
          This is useful to see if there are any that should not be there, whether there are any that are not managed by cert-manager, and
          any issues that we have detected, such as certificates nearing expiry or with small key sizes.
        </CustomTooltip>
      ),
      style: stepStyle,
    },
    {
      selector: '[data-tut="details-panel"]',
      content: (
        <CustomTooltip heading="Details">
          For every resource (e.g. a certificate, or an ingress) you can see more details including the configuration, current state, and
          related resources.
          <br />
          In addition this will highlight any issues or errors which you can then investigate.
        </CustomTooltip>
      ),
      style: stepStyle,
      preAction: () => {
        const element = document.querySelector('[data-tut="certificate-row"]') as HTMLElement
        element.click()
      },
      postAction: () => {
        const element = document.querySelector('[data-tut="details-panel-close-btn"]') as HTMLElement
        element.click()
      },
    },
    {
      selector: '[data-tut="ingresses"]',
      content: (
        <CustomTooltip heading="Ingresses">
          Like the certificates, you can see other resources such as issuers or ingresses.
          <br />
          This will give you an overview of the ingresses in your cluster. Here you will find if you are exposing an insegure ingress or an
          ingress with a certificate that will be blocked by some browsers.
        </CustomTooltip>
      ),
      preAction: () => {
        const element = document.querySelector('[data-tut="ingresses-nav-btn"]') as HTMLElement
        element.click()
      },
    },
    {
      selector: '[data-tut="cm-status"]',
      content: (
        <CustomTooltip heading="cert-manager status">
          This will tell you about the status of your cert-manager installation in the cluster so that you can see whether you are running
          the most recent version, and everything is working as it should.
        </CustomTooltip>
      ),
      style: stepStyle,
    },
    {
      selector: '[data-tut="connect-more-clusters-btn"]',
      content: (
        <CustomTooltip heading="Connect your own cluster">
          Click here when you are ready to connect your own cluster to TLS Protect for Kubernetes and find out about any issues that we are
          able to discover.
        </CustomTooltip>
      ),
      style: stepStyle,
    },
  ]

  const onCloseWrap = () => {
    ReactGA.event({
      category: "DemoCluster",
      action: "Closed tour",
    })
    onClose()
  }
  const onFinish = () => {
    ReactGA.event({
      category: "DemoCluster",
      action: "Finished tour",
    })
    navigate(pathToCertInventoryCluster(orgID, clusterIDFromURL, CLUSTER_VIEW.CERTIFICATES))
  }

  useEffect(() => {
    if (isOpen) {
      ReactGA.event({
        category: "DemoCluster",
        action: "Started tour",
      })
    }
  }, [isOpen])

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      onRequestClose={onCloseWrap}
      accentColor="hsl(260, 71%, 50%)"
      rounded={5}
      showNavigation={false}
      prevButton={<></>}
      badgeContent={(current: string, total: string) => `${current} of ${total}`}
      nextButton={
        // we use `component="div"` to avoid having a button inside a button as that is bad and some browsers complain about it.
        <Button component="div" variant="contained" color="primary">
          Next
        </Button>
      }
      disableKeyboardNavigation
      lastStepNextButton={
        // we use `component="div"` to avoid having a button inside a button as that is bad and some browsers complain about it.
        <Button component="div" variant="contained" color="primary" onClick={onFinish}>
          Finish
        </Button>
      }
      className={classes.helper}
    />
  )
}

type CustomTooltipProps = {
  heading?: string
}

const CustomTooltip = ({ heading, children }: PropsWithChildren<CustomTooltipProps>) => {
  return (
    <div>
      {heading && (
        <Typography variant="h5" gutterBottom>
          {heading}
        </Typography>
      )}
      {children}
    </div>
  )
}

export { DemoTour }
