import React, { useState } from "react"
import { Button, createStyles, makeStyles, Theme, Typography, CircularProgress } from "@material-ui/core"

import { clusterExists } from "../helpers"

import { ErrorMessage } from "@components/ErrorMessage"
import { LoadingMessage } from "@components/LoadingMessage"
import { useFetchClusters } from "@hooks/useFetchClusters"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 0),
    },
    inputContainer: {
      display: "flex",
      alignItems: "center",
    },
    nameInput: {
      display: "block",
      height: "3rem",
      fontSize: "1.125rem",
      borderRadius: "5px",
      padding: theme.spacing(1, 2),
      border: `1px solid ${theme.palette.grey[400]}`,
      margin: theme.spacing(1, 0),
      width: "15rem",
      "&:disabled": {
        background: theme.palette.grey[100],
      },
    },
    nameError: {
      color: theme.palette.error.main,
    },
    loader: {
      marginLeft: theme.spacing(4),
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.main,
      fontSize: "0.875rem",
      "& span": {
        marginLeft: theme.spacing(2),
      },
    },
    spinner: {
      marginTop: theme.spacing(6),
    },
    button: {
      marginTop: theme.spacing(2),
    },
  }),
)

type ClusterNameStepProps = {
  onValidName: (name: string) => void
  showServiceLoader?: boolean
  hasError?: boolean
}

export const ClusterNameStep = ({ onValidName, hasError, showServiceLoader }: ClusterNameStepProps) => {
  const classes = useStyles()

  const [name, setName] = useState("")
  const [accepted, setAccepted] = useState(false)
  const [nameError, setNameError] = useState("")
  const { clusters, isClusterError, isClusterLoading } = useFetchClusters()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    setName(name)
    setNameError("")

    if (clusterExists(clusters, name)) {
      setNameError(`Cluster '${name}' already exists. Choose a different name.`)
    } else {
      setNameError("")
    }
  }

  const handleAccept = () => {
    if (!name) {
      setNameError(`You need to give the cluster a name.`)
    } else if (clusterExists(clusters, name)) {
      setNameError(`Cluster '${name}' already exists. Choose a different name.`)
    } else if (!nameIsValid(name)) {
      setNameError("The name must contain only lowercase letters, numbers, and underscores.")
    } else {
      onValidName(name)
      setAccepted(true)
    }
  }

  const nameIsValid = (name: string): boolean => {
    return /^[a-z0-9_]+$/.test(name)
  }

  const renderContent = () => {
    if (isClusterLoading && !accepted) {
      return (
        <div className={classes.spinner}>
          <LoadingMessage label="Waiting for data..." />
        </div>
      )
    }

    if (isClusterError) {
      return <ErrorMessage message={`Cannot load existing clusters. Try refreshing the page. (Error: ${isClusterError})`} />
    }

    return (
      <div>
        <Typography variant="h5" gutterBottom>
          Step 1. Assign a name
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          This name will be used to identify your cluster within TLS Protect for Kubernetes.
        </Typography>
        <div className={classes.inputContainer}>
          <input
            className={classes.nameInput}
            type="text"
            name="Cluster name"
            value={name}
            disabled={accepted && !hasError}
            onChange={handleChange}
            placeholder="my_cluster"
          />
          {showServiceLoader && !hasError && (
            <div className={classes.loader}>
              <CircularProgress size={20} />
              <span>Creating a service account for this cluster...</span>
            </div>
          )}
        </div>
        {(!accepted || hasError || nameError) && <p className={classes.nameError}>{nameError}</p>}
        {(!accepted || hasError) && (
          <div className={classes.button}>
            <Button variant="contained" color="primary" disabled={accepted && !hasError} onClick={handleAccept}>
              Save cluster name
            </Button>
          </div>
        )}
      </div>
    )
  }

  return <div className={classes.root}>{renderContent()}</div>
}
