import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Button, Dialog, createStyles, makeStyles, Theme, Typography, Link as MuiLink } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import { pathToSettings } from "@routes"
import { useFlags } from "launchdarkly-react-client-sdk"
import { BellIcon } from "@components/Icons"
import { ClusterAlertConfig } from "../ClusterAlertConfig"
import { settingsSelector } from "@selectors/settings"
import { NotificationReceiversLevel } from "../../OrganizationSettings/Alerts"
import { currentOrgSelector } from "@selectors/orgs"
import { Link as RouterLink } from "react-router-dom"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minWidth: "32rem",
      padding: theme.spacing(4),
      position: "relative",
      [theme.breakpoints.down(768)]: {
        minWidth: "1rem",
      },
    },
    button: {
      paddingLeft: theme.spacing(1.5),
      position: "relative",
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    dot: {
      width: "1rem",
      height: "1rem",
      background: "red",
      position: "absolute",
      top: "-0.4rem",
      right: "-0.4rem",
      borderRadius: "50%",
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    closeBtn: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
  }),
)

type AlertSetupProps = {
  cluster: string
}

export function AlertSetup({ cluster }: AlertSetupProps) {
  const classes = useStyles()
  const flags = useFlags()
  const [isOpen, setIsOpen] = useState(false)
  const { settings } = useSelector(settingsSelector())
  const currentOrgID = useSelector(currentOrgSelector)

  const handleClose = () => setIsOpen(false)
  const isAlertsButtonDisabled = flags?.tlspkStandaloneGlobalAlerts
    ? settings?.notification_receivers_level === NotificationReceiversLevel.Organization
    : false

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="alerts-btn"
        disabled={isAlertsButtonDisabled}
      >
        <BellIcon />
        Configure Alerts
      </Button>
      {isAlertsButtonDisabled && (
        <Typography id="alert-dialog-title" variant="body2">
          Alerts are managed globally for all the clusters. See{" "}
          <MuiLink component={RouterLink} to={pathToSettings(currentOrgID, "alerts")}>
            global alerts
          </MuiLink>
          .
        </Typography>
      )}
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <div className={classes.dialog}>
          <CancelIcon className={classes.closeBtn} onClick={handleClose} data-testid="alerts-close-button" />
          <Typography id="alert-dialog-title" variant="h4" className={classes.title}>
            Alerts Configuration
          </Typography>
          <ClusterAlertConfig cluster={cluster} />
        </div>
      </Dialog>
    </>
  )
}
