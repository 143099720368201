import React from "react"
import { createStyles, makeStyles, Theme, Link } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  const selectedStyles = {
    background: theme.palette.primary.main,
    color: "#fff",
    textDecoration: "none",
  }

  return createStyles({
    nav: {
      background: "#fff",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: "5px",
      overflow: "hidden",
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(2),
    },
    button: {
      padding: theme.spacing(2, 4),
      fontSize: "1.125rem",
      transition: "all 0.2s ease-in-out",
      marginRight: "1px",
      "&:hover": selectedStyles,
    },
    selected: selectedStyles,
    filterContainer: {
      padding: theme.spacing(2, 4),
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
    filterBtn: {
      fontSize: "1.125rem",
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
      transition: "all 0.2s ease-in-out",
      "& svg": {
        marginLeft: theme.spacing(1),
      },
    },
    filterOpen: {
      transform: "rotate(90deg)",
    },
  })
})
type Props = {
  links: {
    id: string
    name: string
  }[]
  selected: string
  onChange: (selected: string) => void
}

export const NavBar = ({ links, onChange, selected }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.nav}>
      <div data-tut="nav-bar">
        {links.map(link => (
          <Link
            data-tut={link.id + "-nav-btn"}
            key={link.id}
            component="button"
            variant="body2"
            className={`${classes.button} ${link.id === selected ? classes.selected : ""}`}
            onClick={() => onChange(link.id)}
          >
            {link.name}
          </Link>
        ))}
      </div>
    </div>
  )
}
