import React, { PropsWithChildren, ReactElement } from "react"
import { createStyles, Paper, makeStyles, Theme, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      marginBottom: theme.spacing(2),
    },
    sectionTitle: {
      marginBottom: theme.spacing(2),
    },
  }),
)

type FormWrapperProps = {
  title: string
}
export const FormWrapper = ({ title, children }: PropsWithChildren<FormWrapperProps>): ReactElement => {
  const classes = useStyles()
  return (
    <Paper variant="outlined" className={classes.paper}>
      <Typography variant="h4" className={classes.sectionTitle}>
        {title}
      </Typography>
      {children}
    </Paper>
  )
}
