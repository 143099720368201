import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios"
import { FETCH_APP_CONFIG, fetchAppConfigFailed, fetchAppConfigSuccess } from "@actions/appConfig"
import { AppConfig, AppConfigAuthKind } from "@lib/appConfig"

import { generateErrorMessage, handleError } from "@utils/errorHandling"

function getAppConfig() {
  return axios.get("/api/v1/discovery/web")
}

function* fetchAppConfigSaga() {
  try {
    const { data } = yield call(getAppConfig)

    const appConfig: AppConfig = data
    validateAppConfig(appConfig)

    yield put(fetchAppConfigSuccess(appConfig))
  } catch (error) {
    handleError(error, "App configuration error")
    yield put(fetchAppConfigFailed(`App configuration error - ${generateErrorMessage(error)}`))
  }
}

export function* appConfigSaga() {
  yield takeEvery(FETCH_APP_CONFIG, fetchAppConfigSaga)
}

function validateAppConfig(appConfig: AppConfig) {
  if (!appConfig || !appConfig.auth || !appConfig.auth.kind) {
    throw new Error("Invalid app config received")
  }

  switch (appConfig.auth.kind) {
    case AppConfigAuthKind.auth0:
      if (!appConfig.auth.auth0) {
        throw new Error("Auth configuration is supposed to be Auth0 but Auth0 config was not found.")
      }
      break
    case AppConfigAuthKind.oauth2:
      if (!appConfig.auth.oauth2) {
        throw new Error("Auth configuration is supposed to be OAuth2 but OAuth2 config was not found.")
      }
      break
    case AppConfigAuthKind.disabled:
      break
    default:
      throw new Error(`Unexpected auth configuration kind ${appConfig.auth.kind}.`)
  }
}
