import { AppState } from "../reducers"

export const featuresSelector = (state: AppState) => {
  const currentOrgID = state.orgs.currentOrg
  const currentOrg = state.auth.authInfo?.organizations.find(o => o.id === currentOrgID)
  return currentOrg?.features
}

export const propertiesSelector = (state: AppState) => {
  const currentOrgID = state.orgs.currentOrg
  const currentOrg = state.auth.authInfo?.organizations.find(o => o.id === currentOrgID)
  return currentOrg?.properties
}
