import { ClusterData, RuleMessage } from "../types"
import { Certificate } from "./certificate"
import k8s from "@kubernetes/client-node"

// calculateNonCMCertificates creates NonCMCertificate objects from all the cert-manager certificates in the data. Optionally, it can filter by namespace.
export function calculateNonCMCertificates(data: ClusterData, namespaces?: string[]): NonCMCertificate[] {
  let secretIDs = data?.kind_index["/v1/Secret"]
  if (namespaces && namespaces.length > 0) {
    secretIDs = secretIDs.filter(secretID => namespaces.includes(data.resource_index[secretID].resource.metadata?.namespace || ""))
  }

  const tlsIDs = secretIDs?.filter(secretID => {
    const secret = data.resource_index[secretID].resource as k8s.V1Secret
    if (secret?.type !== "kubernetes.io/tls") {
      return false
    }

    // we discard TLS secrets that are cert-managed
    if (isSecretManagedByCM(data, secretID)) {
      return false
    }

    // only select the certificate that haven't been deleted
    if (data.resource_index[secretID].deleted_at) {
      return false
    }

    return true
  })

  return tlsIDs?.map(resID => new NonCMCertificate(data, resID)) || []
}

// NonCMCertificate represents a non cert-manager certificate backed by a plain secret. This wraps the resource information and some certificate details.
export class NonCMCertificate extends Certificate {
  id: string
  resource: k8s.V1Secret
  rule_messages: RuleMessage[]

  constructor(data: ClusterData, id: string) {
    super(data, id)
    const item = data.resource_index[id]
    this.id = id
    this.resource = item.resource as k8s.V1Secret
    this.rule_messages = item.rule_messages
    this.secret = {
      resource: item.resource as k8s.V1Secret,
      rule_messages: item.rule_messages,
    }
  }
}

// utils

function isSecretManagedByCM(data: ClusterData, secretID: string): boolean {
  return data.resource_graph[secretID]?.some(resID => data.resource_index[resID].resource.kind === "Certificate")
}
