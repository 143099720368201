import React, { useState } from "react"
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core"

import { ContactSales } from "@components/ContactSales"
import { NavButton } from "@components/Navigation/NavButton"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2, 1, 2, 0),
    },
  }),
)

type Props = {
  limitReached: boolean
  redirectPath: string
  text?: string
}

export function AddClusterOption({ limitReached, redirectPath, text = "More clusters?" }: Props) {
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleContactSales = () => {
    setDialogOpen(true)
  }

  return (
    <div>
      {limitReached ? (
        <>
          <Button className={classes.button} variant="contained" color="primary" onClick={handleContactSales}>
            {text}
          </Button>
          <ContactSales
            open={dialogOpen}
            closeHandler={() => {
              setDialogOpen(false)
            }}
          />
        </>
      ) : (
        <NavButton className={classes.button} variant="contained" color="primary" href={redirectPath} data-tut="connect-more-clusters-btn">
          Connect new cluster
        </NavButton>
      )}
    </div>
  )
}
