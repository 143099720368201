import React from "react"
import { Link } from "react-router-dom"
import { createStyles, Paper, makeStyles, Theme, Button } from "@material-ui/core"

import { pathToCertInventory } from "@routes"
import { OverviewTitle } from "../OverviewTitle"
import { OverviewAccordion } from "../OverviewAccordion"
import { OverviewData } from "../types"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down(480)]: {
        padding: theme.spacing(2),
      },
    },
    link: {
      textDecoration: "none",
    },
  }),
)

type OverviewSectionProps = {
  data: OverviewData
  hasButton?: boolean
  orgId?: string
  seeAllUrl?: string
}

function OverviewSection({ hasButton = false, data, orgId }: OverviewSectionProps) {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <OverviewTitle value={data.value} title={data.title} badge={data.badge}>
        {hasButton && orgId && (
          <Link to={pathToCertInventory(orgId)} className={classes.link}>
            <Button variant="contained" color="primary">
              View all clusters
            </Button>
          </Link>
        )}
      </OverviewTitle>
      {data.sections.map(section => (
        <OverviewAccordion data={section} key={section.title} seeAllUrl={section.seeAllUrl} isLoading={section.isLoading} />
      ))}
    </Paper>
  )
}

export { OverviewSection }
