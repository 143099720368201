import React, { ReactElement, useState } from "react"

import { Typography, Button, makeStyles, createStyles, Theme, Link } from "@material-ui/core"
import { genAgentCommand, SvcAccountCreds, APIToken } from "../../common"

import { ChevronRight } from "@components/Icons"
import { Snippet } from "@components/Snippet"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      marginTop: theme.spacing(5),
      paddingTop: theme.spacing(4),
      "& p": {
        marginTop: theme.spacing(2),
      },
    },
    disabled: {
      opacity: 0.5,
    },
    list: {
      listStyleType: "disc",
      listStylePosition: "inside",
      padding: theme.spacing(0.8),
    },
    copyButton: {
      margin: theme.spacing(4, 0, 0, 0),
      display: "flex",
      alignItems: "center",
    },
    generateButton: {
      marginLeft: theme.spacing(4),
      fontWeight: 500,
      "& svg": {
        transform: "scale(0.8)",
        marginLeft: theme.spacing(0.8),
      },
    },
    snippetOpen: {
      "& svg": {
        transform: "scale(0.8) rotate(90deg)",
      },
    },
    snippetContainer: {
      marginTop: theme.spacing(2),
    },
    readMore: {
      fontSize: "1rem",
      marginTop: theme.spacing(2),
    },
  }),
)

type ConfigurationStepProps = {
  orgID: string
  clusterName: string
  credentials?: SvcAccountCreds | APIToken
  useNoAuth?: boolean
  callback: () => void
  checkStatusComponent?: ReactElement
}

export const ConfigurationStep = ({
  orgID,
  clusterName,
  credentials,
  useNoAuth,
  callback,
  checkStatusComponent,
}: ConfigurationStepProps) => {
  const classes = useStyles()
  const [showSnippet, setShowSnippet] = useState(false)
  const [showReadMore, setShowReadMore] = useState(false)
  const command = credentials ? genAgentCommand(orgID, clusterName, credentials, !!useNoAuth, true) : null
  const showDisabled = !(clusterName && command)

  const onCopy = () => {
    callback()
    command && navigator.clipboard.writeText(command)
  }
  const onShowCommand = () => {
    callback()
    setShowSnippet(!showSnippet)
  }

  return (
    <div className={`${classes.root} ${showDisabled ? classes.disabled : ""}`}>
      <Typography variant="h5" gutterBottom>
        Step 2. Install agent
      </Typography>
      <Typography gutterBottom>This agent runs in your cluster and sends data to TLS Protect for Kubernetes for its analysis.</Typography>
      <Typography gutterBottom>You need to have kubectl configured with the context of your cluster enabled.</Typography>
      <Typography gutterBottom>
        Copy the provided command and run it in your terminal (it contains a secret with your service account credentials).
      </Typography>
      <Link component="button" onClick={() => setShowReadMore(!showReadMore)} className={classes.readMore}>
        Read more
      </Link>
      {showReadMore && (
        <div data-testid="read-more">
          <Typography gutterBottom>
            The agent will gather data from your infrastructure and send it back to TLS Protect for Kubernetes, to do this it needs the
            following (all included in the provided installation command):
          </Typography>
          <ul className={classes.list}>
            <li className={classes.list}>A configuration to instruct the agent what data to gather</li>
            <li className={classes.list}>Kubernetes credentials and permissions to gather resources</li>
            <li className={classes.list}>
              Credentials for a TLS Protect for Kubernetes service account to post that data back to TLS Protect for Kubernetes
            </li>
          </ul>
          <Typography gutterBottom>
            The provided command is adequate for most environments. Feel free to check the details and adjust it to your special needs.
          </Typography>
        </div>
      )}
      <br />
      <br />
      {checkStatusComponent}
      <div className={classes.copyButton}>
        <Button variant="contained" color="primary" onClick={onCopy} disabled={showDisabled}>
          Copy installation command to clipboard
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={`${classes.generateButton} ${showSnippet ? classes.snippetOpen : ""}`}
          onClick={onShowCommand}
          disabled={showDisabled}
        >
          <span>Reveal generated command (includes secret)</span> <ChevronRight />
        </Button>
      </div>
      {showSnippet && command && (
        <div className={classes.snippetContainer} data-testid="snippet-container">
          <Snippet text={command} language="shell" />
        </div>
      )}
      <Typography gutterBottom>
        <strong>Note</strong> TLS Protect for Kubernetes works best with clusters running cert-manager.{" "}
        <Link target="_blank" href="https://cert-manager.io/docs/installation/">
          Install cert-manager
        </Link>{" "}
        to get the best experience.
      </Typography>
    </div>
  )
}
