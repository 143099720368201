import React from "react"
import { Theme, FormControl, InputLabel, Select, MenuItem, createStyles, makeStyles } from "@material-ui/core"
import { useFetchClusters } from "@hooks/useFetchClusters"
import { LoadingMessage } from "@components/LoadingMessage"
import { DiscoveredCertificatesFilterState } from "@lib/discoveredCertificates"

export type FilterProps = { [x: string]: string }

type Props = {
  filterState: DiscoveredCertificatesFilterState
  isDisabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    formControl: {
      minWidth: 160,
      margin: theme.spacing(1, 2, 1, 0),
      position: "relative",
    },
    disabled: {
      opacity: 0.5,
    },
  })
})

export const ClusterSelect = ({ filterState, isDisabled = false }: Props) => {
  const { clusters, isClusterError, isClusterLoading } = useFetchClusters()
  const classes = useStyles()

  return (
    <FormControl variant="outlined" className={`${classes.formControl} ${isDisabled ? classes.disabled : ""}`} size="small">
      <InputLabel>By Clusters</InputLabel>
      <Select
        value={filterState.cluster}
        onChange={e => filterState.setCluster(e?.target?.value as string)}
        label="By Clusters"
        disabled={isDisabled}
      >
        {isClusterLoading && <LoadingMessage />}
        {!isClusterLoading &&
          !isClusterError &&
          clusters.map((item, index) => (
            <MenuItem key={item.cluster + index} value={item.cluster}>
              {item.cluster}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
