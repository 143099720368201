import { FilterType } from "@selectors/filters"

export const CLUSTER_VIEW = Object.freeze({
  CERTIFICATES: FilterType.certInventory,
  ISSUERS: "issuers",
  INGRESSES: FilterType.ingresses,
})

export const tabLinks = [
  {
    id: FilterType.certInventory,
    name: "Certificates",
  },
  {
    id: "issuers",
    name: "Issuers",
  },
  {
    id: FilterType.ingresses,
    name: "Ingresses",
  },
]
