import React from "react"

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { Paper, Link } from "@material-ui/core"
import { InstallationComponent } from "@lib/certInventory"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popperContainer: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1, 2, 2, 2),
      background: "white",
      borderRadius: "5px",
      fontSize: "0.875rem",
      zIndex: 10,
      clear: "both",
    },
    versionRow: {
      display: "flex",
      justifyContent: "flex-end",
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      textAlign: "right",
      "& > div": {
        padding: theme.spacing(1.5, 2),
        minWidth: "90px",
      },
      "& div:first-child": {
        width: "100%",
      },
    },
    versionCurrent: {
      color: theme.palette.error.main,
    },
    versionLatest: {
      color: theme.palette.success.main,
    },
    versionTitle: {
      color: theme.palette.text.secondary,
    },
    versionName: {
      textAlign: "left",
    },
    howToLink: {
      textAlign: "right",
      margin: theme.spacing(2, 1, 0.5, 0),
    },
  }),
)

type UpdatePopOutProps = {
  items: [string, InstallationComponent][]
  updateLink: string
  isOutdated: boolean
}

export function UpdatePopOut({ items, updateLink, isOutdated }: UpdatePopOutProps) {
  const classes = useStyles()
  return (
    <Paper className={classes.popperContainer} elevation={3}>
      <div className={`${classes.versionRow} ${classes.versionTitle}`}>
        <div></div>
        <div>Current</div>
        <div>Latest</div>
      </div>
      {items.map((item, index) => (
        <div key={index} className={classes.versionRow}>
          <div className={classes.versionName}>{item[0]}</div>
          <div className={!item[1].is_latest ? classes.versionCurrent : classes.versionLatest}>
            {item[1].version ? `v${item[1].version}` : "N/A"}
          </div>
          <div className={classes.versionLatest}>{item[1].latest_available_version ? `v${item[1].latest_available_version}` : "N/A"}</div>
        </div>
      ))}
      {isOutdated && (
        <div className={classes.howToLink}>
          <Link href={updateLink} target="_blank">
            How to update?
          </Link>
        </div>
      )}
    </Paper>
  )
}
