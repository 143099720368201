import React from "react"
import { Button as MUIButton, ButtonProps as MUIButtonProps } from "@material-ui/core"
import { useNavigate } from "react-router"

export const NavButton = (props: MUIButtonProps) => {
  const navigate = useNavigate()
  const href = props.href
  let onClick = props.onClick
  if (href && !props.onClick) {
    onClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      navigate(href)
      ev.preventDefault()
    }
  }
  return <MUIButton {...props} onClick={onClick} />
}
