import { takeEvery, put, call, select, take } from "redux-saga/effects"
import axios, { AxiosRequestHeaders } from "axios"
import {
  calculateCertificateRequests,
  calculateCMCertificates,
  calculateNonCMCertificates,
  calculateOwnerlessCertificateRequests,
  calculateIssuers,
  calculateIngresses,
  calculateRoutes,
} from "@lib/certInventory/resources"
import {
  FETCH_CERT_INVENTORY,
  FetchCertInventoryAction,
  fetchCertInventoryFailed,
  fetchCertInventorySuccess,
} from "../actions/certInventory"

import { accessTokenSelector } from "@selectors/auth"
import { generateErrorMessage, handleError } from "@utils/errorHandling"
import { genDemoParamsFromOptions, selectDemoParams, DemoOptions } from "@utils/demo"
import { addAuth } from "@lib/auth"

import { settingsSelector } from "@selectors/settings"
import { fetchSettings, FETCH_SETTINGS_SUCCEEDED } from "@actions/settings"
import { filterOutByIdentity } from "./helpers/filter-out-by-identity"

async function getCertInventory(accessToken: string, orgID: string, clusterID: string, demoOpts: DemoOptions) {
  const params = new URLSearchParams(selectDemoParams(new URLSearchParams(window.location.search), genDemoParamsFromOptions(demoOpts)))
  const response = await fetch(`/api/v1/org/${orgID}/cert-inventory/${clusterID}?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response.body
}

function* fetchCertInventorySaga(action: FetchCertInventoryAction) {
  const { orgID, clusterID, isDemo } = action
  try {
    let orgSettings: { isLoading: boolean; settings: Settings } = yield select(settingsSelector)

    if (orgSettings?.settings === undefined) {
      if (!orgSettings?.isLoading) {
        yield put(fetchSettings(action.orgID))
      }
      yield take(FETCH_SETTINGS_SUCCEEDED)
      orgSettings = yield select(settingsSelector)
    }

    const token: string = yield select(accessTokenSelector)
    const stream: ReadableStream<Uint8Array> = yield call(getCertInventory, token, orgID, clusterID, { includeDemo: isDemo })

    const reader = stream.getReader()
    let data = ""
    let done = false
    while (!done) {
      const { value, done: doneReading } = yield reader.read()
      done = doneReading
      if (value) {
        data += new TextDecoder("utf-8").decode(value)
      }
    }

    const jsonData = JSON.parse(data)

    const calculated = {
      certManagerCertificates: calculateCMCertificates(jsonData),
      nonCertManagerCertificates: calculateNonCMCertificates(jsonData),
      ephemeralCertificates: calculateOwnerlessCertificateRequests(jsonData),
      certificateRequests: calculateCertificateRequests(jsonData),
      issuers: calculateIssuers(jsonData),
      ingresses: calculateIngresses(jsonData),
      routes: calculateRoutes(jsonData),
    }

    const allCertsGroupedByIdentity = filterOutByIdentity([
      ...calculated.certManagerCertificates,
      ...calculated.nonCertManagerCertificates,
      ...calculated.ephemeralCertificates,
    ])

    yield put(fetchCertInventorySuccess(orgID, clusterID, jsonData, calculated, allCertsGroupedByIdentity))
  } catch (error) {
    handleError(error, `Unable to load cert inventory`)
    yield put(fetchCertInventoryFailed(orgID, clusterID, `Unable to load cert inventory - ${generateErrorMessage(error)}`))
  }
}

export function* certInventorySaga() {
  yield takeEvery(FETCH_CERT_INVENTORY, fetchCertInventorySaga)
}
