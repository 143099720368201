import { AppState } from "@reducers/index"
import { CMCertificate, NonCMCertificate, OwnerlessCertificateRequest, Issuer, Ingress } from "@lib/certInventory/resources"
import { allResourcesByClusterSelector, allResourcesSelector } from "./certInventory"

export enum FilterType {
  certInventory = "certificates",
  clusterSummary = "clusters",
  ingresses = "ingresses",
  issuers = "issuers",
}
type FilterOption = {
  value: string
  label: string
}
export type FilterData = {
  id: string
  label: string
  default?: string
  type?: "checkbox"
  tooltip?: string
  options: FilterOption[]
}

type AllCertificateTypes = (CMCertificate | NonCMCertificate | OwnerlessCertificateRequest | Ingress)[]

export function filterSelector(type: FilterType) {
  return (state: AppState) => (state.filters[type] ? state.filters[type] : {})
}

export function filterOptionsSelector(type: FilterType, cluster = "", allResourcesView = false) {
  return (state: AppState): FilterData[] => {
    let allCertificates: AllCertificateTypes = []
    let allIssuers: Issuer[] = []
    let allIngresses: Ingress[] = []
    if (cluster) {
      const clusterData = state.certInventory.dataByCluster[cluster]
      const certInventory = clusterData?.certInventory

      if (certInventory && clusterData) {
        const { certManagerCertificates, nonCertManagerCertificates, ephemeralCertificates, issuers, ingresses } =
          allResourcesByClusterSelector(cluster, allResourcesView)(state)

        allCertificates = [...certManagerCertificates, ...nonCertManagerCertificates, ...ephemeralCertificates]
        allIssuers = issuers
        allIngresses = ingresses
      }
    } else if (allResourcesView) {
      const { certManagerCertificates, nonCertManagerCertificates, ephemeralCertificates, issuers, ingresses } =
        allResourcesSelector()(state)
      allCertificates = [...certManagerCertificates, ...nonCertManagerCertificates, ...ephemeralCertificates]
      allIssuers = issuers
      allIngresses = ingresses
    }

    switch (type) {
      case FilterType.certInventory:
        return [
          {
            id: "filterByStatus",
            label: "By status",
            default: "",
            options: [
              {
                value: "notManagedByCM",
                label: "Not managed by cert-manager",
              },
              {
                value: "managedByCM",
                label: "Managed by cert-manager",
              },
              {
                value: "withIssues",
                label: "Errors and warnings",
              },
              {
                value: "withErrors",
                label: "With errors",
              },
              {
                value: "withWarnings",
                label: "With warnings",
              },
            ],
          },
          {
            id: "filterByNamespace",
            label: "By namespace",
            default: "",
            options: getNamespaceOptionList(allCertificates),
          },
          {
            id: "filterByIssuer",
            label: "By issuer",
            default: "",
            options: getIssuersOptionList(allIssuers),
          },
          {
            id: "sortBy",
            label: "Sort by",
            default: "",
            options: [
              {
                value: "asc",
                label: "By name (asc)",
              },
              {
                value: "desc",
                label: "By name (desc)",
              },
              {
                value: "expiryAsc",
                label: "Expiry Date (asc)",
              },
              {
                value: "expiryDesc",
                label: "Expiry Date (desc)",
              },
            ],
          },
          {
            id: "showSystemCerts",
            label: "Show system Certificates",
            default: "",
            type: "checkbox",
            tooltip: "By default, certificates bundled with your Kubernetes distribution are not shown, use this checkbox to display them",
            options: [],
          },
        ]
      case FilterType.clusterSummary:
        return [
          {
            id: "filterByStatus",
            label: "Filter by status",
            default: "",
            options: [
              {
                value: "withIssues",
                label: "Errors and warnings",
              },
              {
                value: "withErrors",
                label: "With errors",
              },
              {
                value: "withWarnings",
                label: "With warnings",
              },
              {
                value: "requiresUpdate",
                label: "Requires update",
              },
              {
                value: "missingCM",
                label: "Missing Cert Manager",
              },
            ],
          },
          {
            id: "sortBy",
            label: "Sort by",
            default: "",
            options: [
              {
                value: "asc",
                label: "By name (asc)",
              },
              {
                value: "desc",
                label: "By name (desc)",
              },
            ],
          },
        ]
      case FilterType.ingresses:
        return [
          {
            id: "filterBySeverity",
            label: "Filter by severity",
            default: "",
            options: [
              {
                value: "high",
                label: "High severity",
              },
              {
                value: "medium",
                label: "Medium severity",
              },
              {
                value: "low",
                label: "Low severity",
              },
            ],
          },
          {
            id: "filterByNamespace",
            label: "By namespace",
            default: "",
            options: getNamespaceOptionList(allIngresses),
          },
          {
            id: "sortBy",
            label: "Sort by",
            default: "",
            options: [
              {
                value: "asc",
                label: "By name (asc)",
              },
              {
                value: "desc",
                label: "By name (desc)",
              },
            ],
          },
        ]
      default:
        return []
    }
  }
}

export function getNamespaceOptionList(items: AllCertificateTypes): FilterOption[] {
  const uniqueItems = [...new Set(items.map(c => c.resource.metadata?.namespace))]
  return uniqueItems.map((nameSpace = "") => ({
    value: nameSpace,
    label: nameSpace,
  }))
}

export function getIssuersOptionList(issuers: Issuer[]) {
  const mappedIssuers = issuers
    .map(issuer => issuer.resource.metadata?.namespace + " > " + issuer.resource.metadata?.name)
    .sort((a, b) => a.localeCompare(b))
  const uniquerIssuers = [...new Set(mappedIssuers)]
  return uniquerIssuers.map((issuer = "") => ({
    value: issuer,
    label: issuer,
  }))
}
