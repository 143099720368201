import React, { Fragment } from "react"
import { Typography, makeStyles, createStyles, Theme } from "@material-ui/core"

import * as x509 from "@fidm/x509"
import { removeCamelCase } from "@utils/certificate"
import { Certificate, getCertificateAlternativeNames, OwnerlessCertificateRequest } from "@lib/certInventory/resources"
import { MessageContainer } from "@components/MessageContainer"
import { TextBlockIcon, ListIcon } from "@components/Icons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginBottom: theme.spacing(2),
    },
    inner: {
      marginBottom: theme.spacing(1),
    },
  }),
)

type IdentitiesProps = {
  certificate?: Certificate | OwnerlessCertificateRequest
}

function Identities({ certificate }: IdentitiesProps) {
  const classes = useStyles()

  if (!certificate) {
    return <Typography>No details were found.</Typography>
  }

  const decodedCerts = certificate.decode()
  const lastDecodedCert = decodedCerts.length === 0 ? undefined : decodedCerts[0]
  // renderSubject all the fields in the subject of a certificate
  const renderSubject = (cert?: x509.Certificate) => {
    if (cert) {
      return cert.subject.attributes.length > 0 ? (
        cert.subject.attributes.map((attr, index) => {
          if (attr) {
            return (
              <MessageContainer icon={<TextBlockIcon />} key={index}>
                <p>{removeCamelCase(attr["name"])}</p>
                <Typography color="textPrimary" variant="body2">
                  {attr["value"] ? attr["value"] : "N/A"}
                </Typography>
              </MessageContainer>
            )
          }
        })
      ) : (
        <MessageContainer>
          <strong>N/A</strong>
        </MessageContainer>
      )
    }
    return <MessageContainer>Unable parse the certificate's subject data</MessageContainer>
  }

  // renderNames render all common and alternative names present in the certificate
  const renderNames = (cert?: x509.Certificate) => {
    if (cert) {
      const names = getCertificateAlternativeNames(cert)
      if (names.length === 0) {
        return <MessageContainer>No alternative names were found"</MessageContainer>
      }
      return names.map(({ key, value }, index) => (
        <MessageContainer icon={<ListIcon />} key={index}>
          {key} {"> "}
          <Typography color="textPrimary" variant="body2" display="inline">
            {value}
          </Typography>
        </MessageContainer>
      ))
    }
    return <MessageContainer>Unable to parse the certificate's alternative names</MessageContainer>
  }

  const subjectInfo = renderSubject(lastDecodedCert)
  const alternativeNames = renderNames(lastDecodedCert)
  return (
    <Fragment>
      <div className={classes.section}>
        <Typography gutterBottom variant="h5">
          Subject Info
        </Typography>
        <div className={classes.inner}>{subjectInfo}</div>
      </div>

      <div className={classes.section}>
        <Typography gutterBottom variant="h5">
          Alternative Names
        </Typography>
        <div className={classes.inner}>{alternativeNames}</div>
      </div>
    </Fragment>
  )
}

export { Identities }
