import { AppState } from "../reducers"

export function clustersWithCertInventorySelector(state: AppState) {
  return state.clusters.clusters.filter(c => c.certInventoryLastUpdated)
}

export function clustersSelector(state: AppState) {
  return state.clusters.clusters
}

export function clustersStateSelector(state: AppState) {
  return state.clusters
}
