import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      ".tk-sofia-pro": {
        fontFamily: "'sofia-pro', sans-serif",
      },
      body: {
        fontFamily: "'sofia-pro', sans-serif",
        backgroundColor: theme.palette.background.default,
      },
      "#root": {
        backgroundColor: "#f4f5f7",
      },
      strong: {
        fontWeight: 600,
      },
      em: {
        fontStyle: "italic",
      },

      "::selection": {
        background: theme.palette.primary.main,
        color: "white",
      },

      /* Workaround for when using Material Button with a
       * react-router NavLink, as it gets styled as a button,
       * but also a link so picks up the link color for the text.
       * This overrides the link color to match the button color.
       */
      "a.MuiButton-contained:link, a.MuiButton-contained:visited": {
        color: "white",
      },
    },
  }),
)

const GlobalStyles = () => {
  useStyles()

  return null
}

export { GlobalStyles }
