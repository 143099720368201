import React, { ReactElement } from "react"

type Props = {
  [x: string]: unknown
}

export function IngressIcon(props: Props): ReactElement {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M11.922.616c-.201.01-.398.06-.58.147L3.438 4.539a1.51 1.51 0 00-.817 1.017L.67 14.04a1.487 1.487 0 00.29 1.269l5.472 6.802a1.51 1.51 0 001.181.564l8.773-.002a1.52 1.52 0 001.182-.563l5.469-6.803a1.495 1.495 0 00.292-1.269l-1.953-8.483a1.493 1.493 0 00-.818-1.017L12.654.763a1.522 1.522 0 00-.732-.147z"
          fill="#326CE5"
        />
        <path
          d="M15.657 17.064h-3.022L6.087 8.872H4.192V6.19h3.08l6.552 8.191h1.833v-2.12l4.152 3.46-4.152 3.462v-2.12zm-3.295-6.328l1.462-1.83h1.833v2.12l4.152-3.46-4.152-3.462v2.12h-3.022l-1.939 2.427 1.666 2.085zm-4.788 1.816l-1.487 1.864H4.192v2.681h3.08l1.968-2.46-1.666-2.085z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h24v23.288H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
