import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core"
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      paddingTop: theme.spacing(4),
      "& p": {
        padding: theme.spacing(1),
      },
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: "5rem",
    },
    link: {
      color: theme.palette.primary.main,
    },
  }),
)

export const NotFound = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <NotListedLocationIcon fontSize="inherit" />
      </div>
      <p>404 page not found</p>
      <p>
        <a href="/" title="Return to main page" className={classes.link}>
          Return to main page
        </a>
      </p>
    </div>
  )
}
