import React from "react"
import { Typography } from "@material-ui/core"
import { MessageContainer } from "@components/MessageContainer"

export const IngestTimeSection = ({ ingestTime }: { ingestTime?: string }) => {
  return ingestTime ? (
    <>
      <Typography gutterBottom>Ingest Time</Typography>
      <MessageContainer>
        <Typography color="textPrimary" variant="body2" display="inline">
          {ingestTime}
        </Typography>
      </MessageContainer>
    </>
  ) : null
}
