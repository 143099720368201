import React from "react"
import FuzzySearch from "fuzzy-search"

import { SeverityTag } from "@components/SeverityTag"
import { RuleMessage } from "@lib/certInventory"
import { IngressWithHost } from "."

export const renderSeverity = (messages: RuleMessage[]) => {
  let SeverityComponent
  if (messages.some(msg => msg.rule.severity === 3 || msg.rule.id === "ingress_tls_missing")) {
    SeverityComponent = <SeverityTag label="High" severity="high" />
  } else if (messages.some(msg => msg.rule.severity === 2)) {
    SeverityComponent = <SeverityTag label="Medium" severity="medium" />
  } else if (messages.some(msg => msg.rule.severity === 1)) {
    SeverityComponent = <SeverityTag label="Low" severity="low" />
  }

  return (
    <>
      {SeverityComponent}
      {messages.some(msg => msg.rule.id === "ingress_tls_missing") && <SeverityTag label="Insecure" severity="high" />}
    </>
  )
}

export const getSortedFilteredIngresses = (filtersState: { [x: string]: string }, ingresses: IngressWithHost[]) => {
  let filteredSortedIngresses: IngressWithHost[] = [...ingresses]

  // Filter certificates by status
  const filterBySeverity = filtersState["filterBySeverity"]

  if (filterBySeverity) {
    if (filterBySeverity === "high")
      filteredSortedIngresses = filteredSortedIngresses.filter(({ resource: { rule_messages } }) =>
        rule_messages.some(msg => msg.rule.severity === 3 || msg.rule.id === "ingress_tls_missing"),
      )
    if (filterBySeverity === "medium")
      filteredSortedIngresses = filteredSortedIngresses.filter(({ resource: { rule_messages } }) =>
        rule_messages.some(msg => msg.rule.severity === 2),
      )
    if (filterBySeverity === "low")
      filteredSortedIngresses = filteredSortedIngresses.filter(({ resource: { rule_messages } }) =>
        rule_messages.some(msg => msg.rule.severity === 1),
      )
  }

  // Filter certificates by namespace
  const filterByNamespace = filtersState["filterByNamespace"]
  if (filterByNamespace) {
    filteredSortedIngresses = filteredSortedIngresses.filter(
      ({
        resource: {
          resource: { metadata },
        },
      }) => metadata?.namespace === filterByNamespace,
    )
  }

  // Filter certificates based on the search term
  const searchTerm = filtersState["search"]
  if (searchTerm) {
    // Inside the array we pass in where the FuzzySearch should look into when we have a search term
    const searchProps = [
      "hostName",
      "resource.id",
      "resource.resource.metadata.name",
      "resource.resource.metadata.namespace",
      "resource.terms",
    ]
    const searcher = new FuzzySearch(filteredSortedIngresses, searchProps, {
      caseSensitive: false,
    })
    filteredSortedIngresses = searcher.search(searchTerm)
  }

  // Sort certificates
  const sortBy = filtersState["sortBy"]
  if (sortBy) {
    if (sortBy === "asc") filteredSortedIngresses = filteredSortedIngresses.sort(sortByName)
    if (sortBy === "desc") filteredSortedIngresses = filteredSortedIngresses.sort(sortByName).reverse()
  }
  return filteredSortedIngresses
}

export const sortByName = (a: IngressWithHost, b: IngressWithHost) =>
  `${a.resource.resource.metadata?.name}`.localeCompare(`${b.resource.resource.metadata?.name}`)

const reduceSeverity = (accumulator: number, item: RuleMessage) => {
  return accumulator + item.rule.severity
}

const sortByNumberOfIssues = (a: IngressWithHost, b: IngressWithHost) => {
  return b.resource.rule_messages.reduce(reduceSeverity, 0) - a.resource.rule_messages.reduce(reduceSeverity, 0)
}

export const sortByStatus = (data: IngressWithHost[]) => [...data].sort(sortByNumberOfIssues)
