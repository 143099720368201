import React, { useEffect, ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"

import { createStyles, makeStyles } from "@material-ui/core"
import { LoadingMessage } from "@components/LoadingMessage"
import { FatalError } from "@components/FatalError"

import { fetchAppConfig } from "@actions/appConfig"
import { appConfigSelector } from "@selectors/appConfig"

const useStyles = makeStyles(() => {
  return createStyles({
    fullPage: {
      display: "flex",
      height: "100vh",
    },
  })
})

type Props = {
  children: ReactNode
}

export const AppConfig: React.FunctionComponent<Props> = ({ children }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { appConfig, errorMessage, isLoading } = useSelector(appConfigSelector)

  useEffect(() => {
    if (!isLoading && !errorMessage && !appConfig) {
      dispatch(fetchAppConfig())
    }
  }, [dispatch, appConfig, errorMessage, isLoading])

  if (isLoading || !appConfig) {
    return (
      <div className={classes.fullPage}>
        <LoadingMessage label="Loading app config..." />
      </div>
    )
  }

  if (errorMessage) {
    return (
      <div className={classes.fullPage}>
        <FatalError message={errorMessage || ""} />
      </div>
    )
  }

  return <>{children}</>
}
