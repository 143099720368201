import { AppState } from "../reducers"
import { currentOrgSelector } from "./orgs"

export const errorSelector = (state: AppState) => state.auth.error
export const clientSelector = (state: AppState) => state.auth.client

export const userSelector = (state: AppState) => {
  if (state.auth.authInfo) {
    return state.auth.authInfo.user
  }
}

export const organizationsSelector = (state: AppState) => {
  if (state.auth.authInfo) {
    return state.auth.authInfo.organizations
  }
}

export const isAdminInCurrentOrgSelector = (state: AppState) => {
  const orgID = currentOrgSelector(state)
  if (state.auth.authInfo) {
    const organization = state.auth.authInfo.organizations.find(org => org.id === orgID)
    if (organization && organization.roles.length > 0) {
      return organization.roles.includes("admin")
    }
  }
}

export const orgRolesSelector = (state: AppState) => {
  const orgID = currentOrgSelector(state)
  if (state.auth.authInfo) {
    const organization = state.auth.authInfo.organizations.find(org => org.id === orgID)
    if (organization && organization.roles.length > 0) {
      return organization.roles.sort((a: string, b: string) => a.localeCompare(b))
    }
  }
}

export const isStartingClientSelector = (state: AppState) => state.auth.isStartingClient
export const isFetchingUserSelector = (state: AppState) => state.auth.isFetchingUser
export const isLoggedInSelector = (state: AppState) => state.auth.isLoggedIn
export const isLoggingInSelector = (state: AppState) => state.auth.isLoggingIn
export const accessTokenSelector = (state: AppState) => state.auth.accessToken
export const emailVerifiedSelector = (state: AppState) => state.auth.emailVerified
export const domainSelector = (state: AppState) => state.auth.domain
