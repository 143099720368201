import React, { ReactElement } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      tableLayout: "fixed",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down(1024)]: {
        overflowX: "auto",
      },
    },
    table: {
      width: "100%",
      tableLayout: "fixed",
      [theme.breakpoints.down(1024)]: {
        width: "auto",
        minWidth: "100%",
      },
    },
  }),
)

export function Table({ children }: { children: JSX.Element | JSX.Element[] }): ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <table className={classes.table}>{children}</table>
    </div>
  )
}
