import { RedirectResult } from "./types"

export class NoopAuthClient {
  constructor() {}

  async initialize() {
    return this
  }

  async redirectToLogin() {}

  async handleRedirectCallback(): Promise<RedirectResult> {
    return new Promise(resolve => {
      resolve({})
    })
  }

  async getToken(): Promise<undefined> {
    return new Promise(resolve => {
      resolve(undefined)
    })
  }

  async isAuthenticated(): Promise<boolean> {
    return new Promise(resolve => {
      resolve(true)
    })
  }

  async logout(): Promise<void> {}
}
