import { ComponentStatus } from "@lib/certInventory"

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const sliceListByPage = (list: any[], page: number, perPage: number) => {
  const startPage = page > 1 ? (page - 1) * perPage : 0
  return list.slice(startPage, startPage + perPage)
}

export const getComponentStatus = (status: ComponentStatus): "ok" | "pending" | "error" | "unknown" => {
  switch (status) {
    case "Running": {
      return "ok"
    }
    case "Pending": {
      return "pending"
    }
    case "Failed": {
      return "error"
    }
    default: {
      return "unknown"
    }
  }
}
