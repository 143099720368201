import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Typography, createStyles, makeStyles, Theme } from "@material-ui/core"
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUserSharp"
import Apartment from "@material-ui/icons/Apartment"
import HelpIcon from "@material-ui/icons/Help"
import DashboardIcon from "@material-ui/icons/Dashboard"

import { DOCS_PATH, pathToCertInventory, pathToOverview, pathToSettings } from "@routes"

import { FeatureCertInventory } from "@utils/features"

import { userSelector } from "@selectors/auth"
import { currentOrgSelector } from "@selectors/orgs"
import { featuresSelector } from "@selectors/features"
import { appConfigSelector } from "@selectors/appConfig"

import { ToolbarNavItem } from "./ToolbarNavItem"
import { UserMenu } from "./UserMenu"
import { Logo } from "./Logo"
import { useSupportsMultiClusterSelector } from "@hooks/useSupportsMultiCluster"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    toolbar: {
      width: "100%",
      padding: theme.spacing(2, 0),
      backgroundColor: theme.palette.common.black,
      zIndex: 1,
      "& nav": {
        maxHeight: 0,
        clear: "both",
        transition: "max-height 0.3s ease-out",
        overflow: "auto",
        display: "block",
      },
      [theme.breakpoints.up(768)]: {
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        width: "220px",
        height: "100%",
        padding: theme.spacing(4, 0),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& nav": {
          maxHeight: "none",
          flexGrow: 1,
          flexBasis: "100%",
        },
      },
    },
    navContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    menuTop: {
      [theme.breakpoints.up(768)]: {
        flex: "1 1 100%",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      },
    },
    menuIcon: {
      cursor: "pointer",
      userSelect: "none",
      float: "right",
      position: "relative",
      padding: "24px 20px",
      marginTop: "14px",
      [theme.breakpoints.up(768)]: {
        display: "none",
      },
    },
    organisation: {
      marginTop: "14px",
      display: "flex",
      flexDirection: "column",
      background: "rgba(255,255,255, 0.05)",
      padding: "14px 0",
    },
    currentOrgText: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      color: theme.palette.grey[400],
      textTransform: "uppercase",
      fontSize: "0.725rem",
      letterSpacing: "1px",
      [theme.breakpoints.down(768)]: {
        paddingLeft: theme.spacing(4),
      },
    },
    currentOrganisation: {
      padding: "0 20px",
      color: theme.palette.grey[100],
    },
    hamburgerMenu: {
      marginTop: theme.spacing(2),
      display: "inline-block",
      float: "right",
    },
    hamburger: {
      background: "#7e8087",
      display: "block",
      height: "3px",
      position: "relative",
      transition: "background 0.3s ease-out",
      width: "20px",
      "&:after, &:before": {
        background: "#7e8087",
        content: `""`,
        display: "block",
        height: "100%",
        position: "absolute",
        transition: "all 0.3s ease-out",
        width: "100%",
      },
      "&:after": {
        top: "-6px",
      },
      "&:before": {
        top: " 6px",
      },
      [theme.breakpoints.up(768)]: {
        display: "none",
      },
    },
    menuBtn: {
      display: "none",
      "&:checked": {
        "& ~ label:not(.steps) span:before, & ~ label:not(.steps) span:after": {
          top: 0,
        },
        "& ~ label span": {
          background: "transparent",
          "&:before": {
            transform: "rotate(-45deg)",
          },
          "&:after": {
            transform: "rotate(45deg)",
          },
        },
        "& ~ nav": {
          [theme.breakpoints.down(768)]: {
            maxHeight: "1240px",
          },
        },
      },
    },
    category: {
      color: theme.palette.grey[400],
      textTransform: "uppercase",
      padding: theme.spacing(5, 0, 0.5, 2.7),
      fontSize: "0.725rem",
      letterSpacing: "1px",
      [theme.breakpoints.down(768)]: {
        paddingLeft: theme.spacing(4),
      },
    },
  })
})

export const Toolbar = () => {
  const classes = useStyles()
  const user = useSelector(userSelector)
  const appConfig = useSelector(appConfigSelector).appConfig
  const customerPortalEnabled = appConfig?.global_features.customer_portal
  const currentOrg = useSelector(currentOrgSelector)
  const features = useSelector(featuresSelector)
  const { supportsMultiCluster } = useSupportsMultiClusterSelector()
  const [navIsOpen, setNavIsOpen] = useState(false)
  const closeNav = () => setNavIsOpen(false)

  const genToolbarNavItem = (
    id: string,
    path: string,
    icon: React.ComponentType,
    text: string,
    requiresAuth: boolean,
    external = false,
  ) => {
    if (user || currentOrg !== "" || !requiresAuth) {
      if (external) {
        return <ToolbarNavItem id={id} href={path} Icon={icon} text={text} exact onClick={closeNav} />
      } else {
        return <ToolbarNavItem id={id} path={path} Icon={icon} text={text} exact onClick={closeNav} />
      }
    }
  }

  return (
    <div className={classes.toolbar}>
      <Logo onClick={closeNav} />

      <input className={classes.menuBtn} type="checkbox" id="menu-btn" checked={navIsOpen} onChange={() => setNavIsOpen(!navIsOpen)} />
      <label className={classes.menuIcon} htmlFor="menu-btn">
        <span className={classes.hamburger}></span>
      </label>
      <div className={classes.organisation}>
        <div className={classes.currentOrgText}>Current Organization</div>
        <div className={classes.currentOrganisation}>{currentOrg}</div>
      </div>
      <nav>
        <div className={classes.navContainer}>
          <div className={classes.menuTop}>
            <Typography className={classes.category}>Explore</Typography>
            {features &&
              features.includes(FeatureCertInventory) &&
              supportsMultiCluster &&
              genToolbarNavItem("overview", pathToOverview(currentOrg), DashboardIcon, "Overview", true)}
            {features &&
              features.includes(FeatureCertInventory) &&
              genToolbarNavItem("clusters", pathToCertInventory(currentOrg), VerifiedUserIcon, "Clusters", true)}
            {customerPortalEnabled &&
              genToolbarNavItem("manage organization", pathToSettings(currentOrg, "tier"), Apartment, "Organization", true)}

            <Typography className={classes.category}>Documentation</Typography>
            {genToolbarNavItem("documentation", DOCS_PATH, ChromeReaderModeIcon, "Documentation", false, true)}

            <ToolbarNavItem id="help" Icon={HelpIcon} text="Help" path="/support" />
          </div>

          <div>
            <UserMenu />
          </div>
        </div>
      </nav>
    </div>
  )
}
