import { useEffect } from "react"

function useHandleEscape(callback: () => void) {
  useEffect(() => {
    document.addEventListener("keydown", handleEscape)
    return () => document.removeEventListener("keydown", handleEscape)
  }, [])

  const handleEscape = ({ key, code }: KeyboardEvent) => {
    if (key === "Escape" || code === "Escape") {
      callback()
    }
  }
}

export { useHandleEscape }
