import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { getOrgID } from "@routes"
import { accessTokenSelector } from "@selectors/auth"
import { CertificateRequest } from "./types"
import { addAuth } from "@lib/auth"
import { handleError } from "@utils/errorHandling"
import { QUERYKEYS } from "@lib/queryKeys"

// query keys for everything to do with Cert Manager Issuer queries
export function useGetCertificateRequests() {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)

  const { data, ...rest } = useQuery(
    QUERYKEYS.certificates.all(orgID),
    () => axios.get<CertificateRequest[]>(`/api/resources/v1beta/org/${orgID}/certificaterequests?include_demo=true`, addAuth(token)),
    { onError: error => handleError(error), enabled: true },
  )

  return {
    requests: data?.data,
    ...rest,
  }
}
